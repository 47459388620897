import View from './view'
import { connect } from 'react-redux'

const mapStateToProps = state => {
  const {
    configureAppForOffboardingPopup,
    offboardingPreviewPopup,
    triggerPreviewPopup,
    configureExecuteActionOnUsersPopup,
    manualActionStartedPopup,
    appDetailHistoryPopup,
    userDetailHistoryPopup,
    contractDetailHistoryPopup,
    askToUpdateLicenseCostPopup,
    appAccountSelectPopup,
    renewalForecastNotSupportedPopup,
    configureBrowserExtensionActivationEmailPopup,
    shareReportPopup,
    sentEmailsPopup,
    csvExportPopup,
    manuallyRunWorkflowPopup,
    rolePopup,
    isUserLifecycleSettingsOpen,
    customActionResponsePopup,
    usersOverlapPopup,
    activateWorkflowPopup,
    workflowStartedPopup,
    addAppCatalogPolicyPopup,
    importUsersPopup,
    syncStatusPopup,
    deleteImportedUsersPopup,
    workflowAuditLogs,
    addApplication,
    contractPopup,
    licenseEditPopup,
    discoveredAppsPreviewPopup,
    editWorkflowTagsPopup,
    createWorkflowTagsPopup,
    editWorkflowTagPopup
  } = state.ui

  return {
    configureAppForOffboardingPopup,
    offboardingPreviewPopup,
    triggerPreviewPopup,
    configureExecuteActionOnUsersPopup,
    manualActionStartedPopup,
    appDetailHistoryPopup,
    userDetailHistoryPopup,
    contractDetailHistoryPopup,
    askToUpdateLicenseCostPopup,
    appAccountSelectPopup,
    renewalForecastNotSupportedPopup,
    configureBrowserExtensionActivationEmailPopup,
    shareReportPopup,
    sentEmailsPopup,
    csvExportPopup,
    manuallyRunWorkflowPopup,
    rolePopup,
    isUserLifecycleSettingsOpen,
    customActionResponsePopup,
    usersOverlapPopup,
    activateWorkflowPopup,
    workflowStartedPopup,
    addAppCatalogPolicyPopup,
    importUsersPopup,
    syncStatusPopup,
    deleteImportedUsersPopup,
    workflowAuditLogs,
    addApplication,
    contractPopup,
    licenseEditPopup,
    discoveredAppsPreviewPopup,
    editWorkflowTagsPopup,
    createWorkflowTagsPopup,
    editWorkflowTagPopup
  }
}

export default connect(mapStateToProps)(View)
