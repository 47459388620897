import React, { ReactElement, useState } from 'react'
import Table from '@components/table'
import { EMPTY_OBJECT, fieldTypes, ITEMS_PER_PAGE, TABLES } from '@root/constants'
import { getWorkflowExecutionsFieldValues, getWorkflowsExecutionsAudit } from '@actions/workflows'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { getWorkflowsFullAuditLogsFieldValues, getWorkflowsFullAuditLogsInfo, isLoadingMoreWorkflowsFullAuditLogs, isLoadingWorkflowsFullAuditLogs } from '@selectors/workflows'
import { getColumns } from '@pages/workflowsExecutionsAudit/components/Audits/columns'
import { getAppDetailsFieldsByIdField } from '@selectors/apps'
import { getContractsFieldsById } from '@selectors/contracts'
import { getUserPreferences } from '@selectors/ui'
import { getTableFiltersURLQueryParam, sortStringFromArray } from '@shared/utils'
import useEffectOnce from '@shared/hooks/useEffectOnce'
import { getValidFilters } from '@shared/filters'
import { FilterOption } from '@root/constants.t'
import { useDebouncedCallback } from '@shared/hooks/useDebouncedCallback'
import { exportCSVReport } from '@actions/'

const Audits = (): ReactElement => {
  const { idOrg } = useParams()
  const dispatch = useDispatch()

  const { key: tableKey, filtersOptions }: { key: string, filtersOptions: FilterOption[] } = TABLES.workflowsExecutionsAudit
  const appFields = useSelector(getAppDetailsFieldsByIdField) || EMPTY_OBJECT
  const contractsFields = useSelector(getContractsFieldsById) || EMPTY_OBJECT
  const { apps: appsById, users: usersById, audits: workflowsExecutionsAudit, total } = useSelector(getWorkflowsFullAuditLogsInfo)
  const isLoading = useSelector(isLoadingWorkflowsFullAuditLogs)
  const isLoadingMore = useSelector(isLoadingMoreWorkflowsFullAuditLogs)
  const userPreferences = useSelector(getUserPreferences)
  const { defaultSort = [], filters } = userPreferences[tableKey] || {}
  const filtersFromQueryParam = getTableFiltersURLQueryParam()
  const activeFilters = getValidFilters(
    filtersFromQueryParam.length ? filtersFromQueryParam : filters || []
  )
  const filterValues = useSelector(getWorkflowsFullAuditLogsFieldValues)
  const [searchText, setSearchText] = useState('')
  const sort = sortStringFromArray(defaultSort)

  const getAudits = useDebouncedCallback(
    async ({
      limit = ITEMS_PER_PAGE,
      offset = 0,
      reset = false,
      validFilters = activeFilters,
      q = searchText
    }: {
      limit?: number,
      offset?: number,
      reset?: boolean,
      validFilters?: any[],
      q?: string
    }) => {
      await dispatch(getWorkflowsExecutionsAudit({
        idOrg,
        limit,
        offset,
        reset,
        sort,
        filters: validFilters,
        q
      }))
    },
    350
  )

  useEffectOnce(() => {
    getAudits({ reset: true })
  })

  const onFilterChange = (updatedFilters) => {
    getAudits({ reset: true, validFilters: getValidFilters(updatedFilters || []) })
  }

  const fetchAuditsData = (reset = false): void => {
    getAudits({ offset: reset ? 0 : workflowsExecutionsAudit.length, reset })
  }

  const fetchFieldValues = (field) => {
    const selectedFilterOption = filtersOptions.find(f => f.value === field)
    const isFieldSupportValues = [fieldTypes.text, fieldTypes.user, fieldTypes.dropdown, fieldTypes.dropdownMulti, fieldTypes.userMulti].includes(selectedFilterOption?.type || '')
    if (isFieldSupportValues) {
      dispatch(getWorkflowExecutionsFieldValues({ idOrg, fields: [field] }))
    }
  }

  const handleSearchText = (text: string) => {
    setSearchText(text)
    getAudits({ reset: true, q: text })
  }

  const handleExportCsvClick = () => {
    dispatch(exportCSVReport({
      idOrg,
      type: 'actionsLog',
      sort,
      q: searchText,
      filters: activeFilters,
      sendAnalytics: false
    }))
  }

  return <Table
    tableKey={tableKey}
    data={workflowsExecutionsAudit}
    columns={getColumns({ appsById, usersById, appFields, contractsFields })}
    emptyStateMessage='No actions triggered yet'
    loading={isLoading}
    manual
    filterable
    filtersOptions={filtersOptions}
    fetchData={fetchAuditsData}
    loadingMore={isLoadingMore}
    totalCount={total}
    filterOptionsValuesPerKey={filterValues}
    fetchFieldValues={fetchFieldValues}
    onFilterChange={onFilterChange}
    searchable
    forceShowSearch
    onSearch={handleSearchText}
    exportable
    exportFunction={handleExportCsvClick}
  />
}

export default Audits
