import { ComponentType, Fragment, ReactElement } from 'react'
import { Field, Form } from 'react-final-form'
import Select from '@components/select'
import { formFieldTypes } from '@root/constants'
import { Divider, FormElement, Input, Spacer, Subtitle1 } from '@toriihq/design-system'
import Filters from './filters/filters'
import { ops } from '@root/lenses/filters'
import { FormFieldWrapper } from './styles'
import { noop } from 'lodash'
import { getValidFilters } from '@shared/filters'

const EditWidgetForm = (props): ReactElement => {
  const { sections, initialValues, onFieldChange } = props

  const renderSelectComponent = ({ name, label, input, options, meta, ...props }) => {
    return (
      <FormElement label={label} errorMessage={meta.touched && meta.error}>
        <Select
          options={options}
          labelKey='label'
          valueKey='value'
          clearable={false}
          searchable
          name={name}
          {...input}
          {...props}
          onChange={(selection : { value: string }) => {
            input.onChange(selection.value)
            onFieldChange(input.name, selection.value)
          }}
        />
      </FormElement>
    )
  }

  const renderFiltersComponent = ({ name, label, input, options, meta, values }) => {
    const { filtersOptions, fieldOptionsValuesPerKey, fetchFieldValues } = props
    return (
      <FormElement label={label} errorMessage={meta.touched && meta.error}>
        <Filters
          optionsKey={filtersOptions}
          optionsValuesPerKey={fieldOptionsValuesPerKey}
          filters={input.value}
          fetchFieldValues={fetchFieldValues}
          onFiltersChange={(filters) => {
            input.onChange(filters)
          }}
          onFiltersPopupClose={(filters) => {
            onFieldChange(input.name, getValidFilters(filters))
          }} />
      </FormElement>
    )
  }

  const renderInputComponent = ({ name, label, input, options, meta, values }) => {
    return (
      <FormElement label={label} errorMessage={meta.touched && meta.error}>
        <Input
          {...input}
          onBlur={(e) => {
            input.onChange(e.target.value)
            onFieldChange(input.name, e.target.value)
          }}
        />
      </FormElement>
    )
  }

  const formFieldTypeToConfigMap: Record<string, { component: ComponentType<any> }> = {
    [formFieldTypes.singleLine]: {
      component: renderInputComponent
    },
    [formFieldTypes.dropdown]: {
      component: renderSelectComponent
    },
    [formFieldTypes.filters]: {
      component: renderFiltersComponent
    }
  }

  const shouldShowField = (field, formValues) => {
    if (!field.showOnFieldSpecificValue) {
      return true
    }
    const dependsOnFieldValue = formValues[field.showOnFieldSpecificValue.key]
    return field.showOnFieldSpecificValue.op === ops.equals
      ? dependsOnFieldValue === field.showOnFieldSpecificValue.value
      : dependsOnFieldValue !== field.showOnFieldSpecificValue.value
  }

  return (
    <Form
      onSubmit={noop}
      initialValues={initialValues}
      render={(formProps) => {
        const { handleSubmit, values } = formProps

        return (
          <Fragment>
            <form onSubmit={handleSubmit}>
              {sections.map((section, index) => {
                const isLastSection = index === sections.length - 1

                return <Fragment key={section.key}>
                  {section.label && <Spacer bottom='space-100'>
                    <Subtitle1>{section.label}</Subtitle1>
                  </Spacer>}

                  {section.fields.map((field) => {
                    if (!shouldShowField(field, values)) {
                      return null
                    }

                    return (
                      <FormFieldWrapper key={field.key}>
                        <Field
                          name={field.key}
                          label={field.label}
                          component={formFieldTypeToConfigMap[field.type].component}
                          options={field.options}
                          values={field.values}
                          isRequired
                        />
                      </FormFieldWrapper>
                    )
                  })}

                  {!isLastSection && (
                    <FormFieldWrapper>
                      <Divider orientation='Vertical' />
                    </FormFieldWrapper>
                  )}
                </Fragment>
              }) }
            </form>
          </Fragment>
        )
      }}
    />

  )
}

export default EditWidgetForm
