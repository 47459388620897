import { AlertBox, AlertBoxLayout, AlertBoxType, Body2, Link } from '@toriihq/design-system'
import { DATA_COMPLETENESS_CHECK_STATES } from './types'
import { AnimatedAlertBoxContainer } from './styles'
import { isNil, noop } from 'lodash'
import { SYNC_STATUS } from '@root/constants'
import moment from 'moment'
import { APP_ACCOUNT_TYPE } from '@root/constants.t'
import { useEffect } from 'react'

const DataCompletenessCheckAlertBox = (props) => {
  const { appAccount, accountStatistics, isLoading, totalUsers, setHasLicenseData,
    onImportUserListClick, hasAccessToImportUsers } = props

  const { lastSyncTime, appAccountType: accountType, syncStatus, connectedAt,
    lastUsersAndLicensesFileLastSyncTime, licensesManagedManually: isLicensesManagedManually } = appAccount || {}

  const alertBoxPropsByStatus = getDataCompletenessCheckAlertBoxPropsByStatus(onImportUserListClick, hasAccessToImportUsers)

  const renderAlertBoxByStatus = (status: DATA_COMPLETENESS_CHECK_STATES) => {
    const alertBoxProps = alertBoxPropsByStatus[status]
    const { type, title, description } = alertBoxProps
    return <AnimatedAlertBoxContainer>
      <AlertBox type={type} layout={AlertBoxLayout.HORIZONTAL} title={title} description={description} dismissible={false} onDismiss={noop} />
    </AnimatedAlertBoxContainer>
  }

  const isFailedIntegrationSync = syncStatus === SYNC_STATUS.FINISHED_FAILED
  const ACCOUNT_NOT_UPDATED_THRESHOLD_IN_DAYS = 30
  const nonSyncingPeriodInDays = moment().utc().diff(moment(lastSyncTime ?? connectedAt).utc(), 'days')
  const isSyncOutdated = nonSyncingPeriodInDays > ACCOUNT_NOT_UPDATED_THRESHOLD_IN_DAYS

  const { licenseTypes, maxUserLastVisitTime, maxUserCreationTime } = accountStatistics || {}
  const isAccountHasUserWithLastUsedDate = !isNil(maxUserLastVisitTime)
  const uniqueLicenseTypes = licenseTypes?.length || 0

  useEffect(() => {
    setHasLicenseData(uniqueLicenseTypes > 0)
  }, [setHasLicenseData, uniqueLicenseTypes])

  if (isLoading) {
    return null
  }

  if (totalUsers === 0) {
    if (!accountType) {
      return renderAlertBoxByStatus(DATA_COMPLETENESS_CHECK_STATES.NO_USERS_NO_INTEGRATION)
    }
    return renderAlertBoxByStatus(DATA_COMPLETENESS_CHECK_STATES.NO_USERS)
  }

  if (!uniqueLicenseTypes) {
    if (isFailedIntegrationSync) {
      return renderAlertBoxByStatus(DATA_COMPLETENESS_CHECK_STATES.NO_LICENSE_DATA_AVAILABLE_WITH_FAILED_INTEGRATION)
    }

    return renderAlertBoxByStatus(DATA_COMPLETENESS_CHECK_STATES.NO_LICENSE_DATA_AVAILABLE)
  }

  if (!accountStatistics || !appAccount) {
    return null
  }

  const areNewUsersWereAddedSinceLastUpload = isLicensesManagedManually && moment(maxUserCreationTime).isAfter(lastUsersAndLicensesFileLastSyncTime)

  if (isSyncOutdated) {
    if (accountType === APP_ACCOUNT_TYPE.MANUAL) {
      return renderAlertBoxByStatus(DATA_COMPLETENESS_CHECK_STATES.MANUAL_ACCOUNT_OUTDATED)
    } else if (isLicensesManagedManually) {
      if (isFailedIntegrationSync) {
        return renderAlertBoxByStatus(DATA_COMPLETENESS_CHECK_STATES.MANUAL_LICENSES_OUTDATED_WITH_FAILED_INTEGRATION)
      }
      return renderAlertBoxByStatus(DATA_COMPLETENESS_CHECK_STATES.MANUAL_LICENSES_OUTDATED)
    }
  }

  if (areNewUsersWereAddedSinceLastUpload) {
    if (isFailedIntegrationSync) {
      return renderAlertBoxByStatus(DATA_COMPLETENESS_CHECK_STATES.MANUAL_LICENSES_NEW_USERS_AFTER_IMPORT_WITH_FAILED_INTEGRATION)
    }
    return renderAlertBoxByStatus(DATA_COMPLETENESS_CHECK_STATES.MANUAL_LICENSES_NEW_USERS_AFTER_IMPORT)
  }

  if (accountType === APP_ACCOUNT_TYPE.REGULAR && !isLicensesManagedManually && uniqueLicenseTypes === 1) {
    if (isFailedIntegrationSync) {
      return renderAlertBoxByStatus(DATA_COMPLETENESS_CHECK_STATES.INTEGRATION_LICENSES_NON_GRANULAR_WITH_FAILED_INTEGRATION)
    }
    return renderAlertBoxByStatus(DATA_COMPLETENESS_CHECK_STATES.INTEGRATION_LICENSES_NON_GRANULAR)
  }

  if (!isAccountHasUserWithLastUsedDate) {
    if (isFailedIntegrationSync) {
      return renderAlertBoxByStatus(DATA_COMPLETENESS_CHECK_STATES.NO_LAST_USED_DATE_WITH_FAILED_INTEGRATION)
    }
    return renderAlertBoxByStatus(DATA_COMPLETENESS_CHECK_STATES.NO_LAST_USED_DATE)
  }

  if (accountType === APP_ACCOUNT_TYPE.MANUAL || isLicensesManagedManually) {
    if (isFailedIntegrationSync) {
      return renderAlertBoxByStatus(DATA_COMPLETENESS_CHECK_STATES.MANUAL_LICENSES_COMPLETE_WITH_FAILED_INTEGRATION)
    }
    return renderAlertBoxByStatus(DATA_COMPLETENESS_CHECK_STATES.MANUAL_LICENSES_COMPLETE)
  }

  if (accountType === APP_ACCOUNT_TYPE.REGULAR) {
    if (isFailedIntegrationSync) {
      return renderAlertBoxByStatus(DATA_COMPLETENESS_CHECK_STATES.INTEGRATION_LICENSES_COMPLETE_WITH_FAILED_INTEGRATION)
    }
    return renderAlertBoxByStatus(DATA_COMPLETENESS_CHECK_STATES.INTEGRATION_LICENSES_COMPLETE)
  }

  console.error('No data completeness check status matched')
  return null
}

const getDataCompletenessCheckAlertBoxPropsByStatus = (onImportUserListClick, hasAccessToImportUsers) => {
  const failSyncMessage = 'This action is currently unavailable because the integration is in a failed state. An integration sync is required for manual import to succeed.'
  const linkToImportUsers = <Link onClick={onImportUserListClick} disabled={!hasAccessToImportUsers}>import an up-to-date user list</Link>

  return {
    [DATA_COMPLETENESS_CHECK_STATES.NO_USERS]: {
      type: AlertBoxType.NEGATIVE,
      title: 'No users detected',
      description: <Body2>No users were found. The renewal forecast is unavailable.</Body2>
    },
    [DATA_COMPLETENESS_CHECK_STATES.NO_USERS_NO_INTEGRATION]: {
      type: AlertBoxType.NEGATIVE,
      title: 'No users detected',
      description: <Body2>No users were found. To proceed, please {linkToImportUsers} with license data.</Body2>
    },
    [DATA_COMPLETENESS_CHECK_STATES.NO_LICENSE_DATA_AVAILABLE]: {
      type: AlertBoxType.NEGATIVE,
      title: 'License data is missing',
      description: <Body2>License data is missing, making it impossible to proceed with the renewal forecast. Please {linkToImportUsers} with license details to continue.</Body2>
    },
    [DATA_COMPLETENESS_CHECK_STATES.NO_LICENSE_DATA_AVAILABLE_WITH_FAILED_INTEGRATION]: {
      type: AlertBoxType.NEGATIVE,
      title: 'License data is missing',
      description: <Body2>License data is missing, making it impossible to proceed with the renewal forecast. Please import an up-to-date user list with license details to continue. {failSyncMessage}</Body2>
    },
    [DATA_COMPLETENESS_CHECK_STATES.MANUAL_ACCOUNT_OUTDATED]: {
      type: AlertBoxType.NOTICE,
      title: 'Outdated Data Detected',
      description: <Body2>User and license data appears outdated (last updated over 30 days ago). Please {linkToImportUsers}.</Body2>
    },
    [DATA_COMPLETENESS_CHECK_STATES.MANUAL_LICENSES_OUTDATED]: {
      type: AlertBoxType.NOTICE,
      title: 'Outdated Data Detected',
      description: <Body2>License data appears outdated (last updated over 30 days ago). Please {linkToImportUsers}.</Body2>
    },
    [DATA_COMPLETENESS_CHECK_STATES.MANUAL_LICENSES_OUTDATED_WITH_FAILED_INTEGRATION]: {
      type: AlertBoxType.NOTICE,
      title: 'Outdated Data Detected',
      description: <Body2>License data appears outdated (last updated over 30 days ago). Please import an up-to-date user list. {failSyncMessage}</Body2>
    },
    [DATA_COMPLETENESS_CHECK_STATES.MANUAL_LICENSES_NEW_USERS_AFTER_IMPORT]: {
      type: AlertBoxType.NOTICE,
      title: 'Outdated Data Detected',
      description: <Body2>License data appears outdated. Please {linkToImportUsers}.</Body2>
    },
    [DATA_COMPLETENESS_CHECK_STATES.MANUAL_LICENSES_NEW_USERS_AFTER_IMPORT_WITH_FAILED_INTEGRATION]: {
      type: AlertBoxType.NOTICE,
      title: 'Outdated Data Detected',
      description: <Body2>License data appears outdated. Please import an up-to-date user list. {failSyncMessage}</Body2>
    },
    [DATA_COMPLETENESS_CHECK_STATES.INTEGRATION_LICENSES_NON_GRANULAR]: {
      type: AlertBoxType.NOTICE,
      title: 'Limited license granularity detected',
      description: <Body2>User and license data lacks detailed granularity (only one license type detected from the integration). If this app has multiple license types, please {linkToImportUsers} with more detailed license information.</Body2>
    },
    [DATA_COMPLETENESS_CHECK_STATES.INTEGRATION_LICENSES_NON_GRANULAR_WITH_FAILED_INTEGRATION]: {
      type: AlertBoxType.NOTICE,
      title: 'Limited license granularity detected',
      description: <Body2>User and license data lacks detailed granularity (only one license type detected from the integration). To improve accuracy, you can import an up-to-date user list with more detailed license information. {failSyncMessage}</Body2>
    },
    [DATA_COMPLETENESS_CHECK_STATES.NO_LAST_USED_DATE]: {
      type: AlertBoxType.NOTICE,
      title: 'No last used date detected',
      description: <Body2>No users have a recorded last used date from any source. To receive an accurate renewal forecast, please {linkToImportUsers} with the last used date.</Body2>
    },
    [DATA_COMPLETENESS_CHECK_STATES.NO_LAST_USED_DATE_WITH_FAILED_INTEGRATION]: {
      type: AlertBoxType.NOTICE,
      title: 'No last used date detected',
      description: <Body2>No users have a recorded last used date. To receive an accurate renewal forecast, please import an up-to-date user list. {failSyncMessage}</Body2>
    },
    [DATA_COMPLETENESS_CHECK_STATES.MANUAL_LICENSES_COMPLETE]: {
      type: AlertBoxType.POSITIVE,
      title: 'Data is complete',
      description: <Body2>User and license data appears complete. If needed, you can always {linkToImportUsers} to improve license granularity or freshness.</Body2>
    },
    [DATA_COMPLETENESS_CHECK_STATES.MANUAL_LICENSES_COMPLETE_WITH_FAILED_INTEGRATION]: {
      type: AlertBoxType.POSITIVE,
      title: 'Data is complete',
      description: <Body2>User and license data appears complete. If needed, you can always import an up-to-date user list to improve license granularity or freshness. {failSyncMessage}</Body2>
    },
    [DATA_COMPLETENESS_CHECK_STATES.INTEGRATION_LICENSES_COMPLETE]: {
      type: AlertBoxType.POSITIVE,
      title: 'Data is complete',
      description: <Body2>User and license data appears complete. If needed, you can always {linkToImportUsers} to improve license granularity or freshness.</Body2>
    },
    [DATA_COMPLETENESS_CHECK_STATES.INTEGRATION_LICENSES_COMPLETE_WITH_FAILED_INTEGRATION]: {
      type: AlertBoxType.POSITIVE,
      title: 'Data is complete',
      description: <Body2>User and license data appears complete. If needed, you can always import an up-to-date user list to improve license granularity or freshness. {failSyncMessage}</Body2>
    }
  }
}

export default DataCompletenessCheckAlertBox
