import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import Page from '@components/page'
import { useSelector, useDispatch } from 'react-redux'
import { getDashboards as getDashboardsSelector, getIsLoadingDashboards } from '@selectors/dashboards'
import { getContractsFields, getUserDetailsFields } from '@store/actions'
import { toggleDashboardWidgetEditConfigDrawer } from '@store/actions/dashboards'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { getCurrentOrg } from '@selectors/org'
import { getDashboards, updateDashboard } from '@actions/dashboards'
import Dashboard from '@components/dashboard'
import { Body1, Button, ButtonSize, ButtonType, Stack } from '@toriihq/design-system'
import {
  ButtonsContainer,
  DashboardChangesText,
  DashboardChangesContainer,
  PageContentContainer,
  DashboardContentElement
} from '@pages/dashboards/styles'
import DashboardEditWidget from 'src/components/dashboard/dashboardEditWidget'
import { getDashboardWidgetEditConfigDrawer, getUserPreferences } from '@selectors/ui'
import Drawer from '@components/drawer'
import Placeholder from '@components/placeholder'
import PageHeader from '@root/components/pageHeader'
import { SCOPES } from '@root/constants.t'
import { Dashboard as DashboardType } from '@reducers/dashboards/types'
import { isEqual } from 'lodash'
import { getWidgetTableKey } from '@components/dashboard/widgets/shared'
import { UPDATE_USER_PREFERENCES } from '@root/constants'
import keyBy from 'lodash/keyBy'
import Analytics from '@components/dashboard/analytics'

const DashboardContent = ({
  dashboard,
  isEditMode,
  isLoading,
  onEditButtonClick,
  onDashboardUpdate
}: {
  dashboard: DashboardType | null
  isEditMode: boolean
  isLoading: boolean
  onEditButtonClick: () => void
  onDashboardUpdate: (dashboard: DashboardType) => void
}) => {
  const { title, description } = dashboard || {}

  return (
    <DashboardContentElement>
      <PageHeader
        title={(
          <Stack gap={'space-200'}>
            <div>{title}</div>
            <Body1 color='secondary'>{description}</Body1>
          </Stack>
        )}
        overrideStyle={isEditMode ? { style: { paddingTop: 0 } } : {}}
        menuButtonText={!isEditMode && <>Edit dashboard</>}
        menuButtonOnClick={onEditButtonClick}
        allowedScopes={[SCOPES.DASHBOARDS_WRITE]} />
      <Placeholder loading={isLoading && !dashboard} rows={10} style={{ maxWidth: '80%', marginTop: '10px' }}>
        {dashboard && <Dashboard dashboard={dashboard} isEditMode={isEditMode} onDashboardUpdate={onDashboardUpdate} />}
      </Placeholder>
    </DashboardContentElement>
  )
}

const EditModeButtons = React.memo(({
  scrolledDown,
  onCancelButtonClick,
  onApplyButtonClick,
  isDashboardEdited
}: {
  scrolledDown: boolean
  onCancelButtonClick: () => void
  onApplyButtonClick: () => void
  isDashboardEdited: boolean
}) => (
  <ButtonsContainer scrolledDown={scrolledDown}>
    <Stack direction='row' gap='space-100' justifyContent='end'>
      <DashboardChangesText><div>{isDashboardEdited ? 'Dashboard has changes' : 'No changes made' }</div></DashboardChangesText>
      <Button type={ButtonType.secondary} size={ButtonSize.small} onClick={onCancelButtonClick} label='Cancel' />
      <Button disabled={!isDashboardEdited} type={ButtonType.primary} size={ButtonSize.small} onClick={onApplyButtonClick} label='Apply changes' />
    </Stack>
  </ButtonsContainer>
))

const DashboardsPage = (): ReactElement => {
  const dispatch = useDispatch()

  const [isEditMode, setIsEditMode] = useState(false)

  const dashboards = useSelector(getDashboardsSelector)
  const isLoading = useSelector(getIsLoadingDashboards)
  const { id: idOrg } = useSelector(getCurrentOrg)
  const { isOpen: isDrawerOpen } = useSelector(getDashboardWidgetEditConfigDrawer)
  const userPreferences = useSelector(getUserPreferences)

  const [isDashboardEdited, setIsDashboardEdited] = useState(false)
  const [editedDashboard, setEditedDashboard] = useState<DashboardType | null>(null)
  const [originalDashboard, setOriginalDashboard] = useState<DashboardType | null>(null)

  useEffect(() => {
    setEditedDashboard(dashboards[0])
  }, [dashboards])

  useEffect(() => {
    setOriginalDashboard(dashboards[0])
  }, [dashboards])

  const onDrawerClose = useCallback(() => {
    dispatch(toggleDashboardWidgetEditConfigDrawer({ isOpen: false, widget: {}, idDashboard: null, sections: [], onWidgetUpdate: () => {} }))
  }, [dispatch])

  useEffect(() => {
    dispatch(getDashboards({ idOrg }))
    dispatch(getContractsFields({ idOrg }))
    dispatch(getUserDetailsFields({ idOrg }))
  }, [dispatch, idOrg])

  useEffect(() => {
    if (!isDrawerOpen || !isEditMode) {
      onDrawerClose()
    }
  }, [isDrawerOpen, isEditMode, onDrawerClose])

  const onEditButtonClick = () => {
    editedDashboard && Analytics.clickOnEditButton({ dashboardName: editedDashboard?.title })
    setIsEditMode(true)
  }

  const onApplyButtonClick = async () => {
    editedDashboard && Analytics.clickOnSaveDashboardChanges({ dashboardName: editedDashboard.title })
    setIsEditMode(false)

    if (editedDashboard) {
      const { id: idDashboard, ...restUpdatedDashboard } = editedDashboard
      await dispatch(updateDashboard({ idOrg, idDashboard, dashboard: restUpdatedDashboard }))
      setIsDashboardEdited(false)
    }
  }

  const updateWidgetsUserPreferences = () => {
    const editDashboardWidgetsByKey = keyBy(editedDashboard?.widgets, getWidgetTableKey)

    const updatedWidgets = originalDashboard?.widgets.reduce<Record<string, { fields: string[] }>>((acc, widget) => {
      const widgetTableKey = getWidgetTableKey(widget)
      const editedWidget = editDashboardWidgetsByKey[widgetTableKey]

      if (!isEqual(widget.drillDownConfig.fields, editedWidget.drillDownConfig.fields)) {
        acc[widgetTableKey] = { fields: widget.drillDownConfig.fields }
      }

      return acc
    }, {})

    updatedWidgets && Object.keys(updatedWidgets).forEach((widgetKey) => {
      const widget = updatedWidgets[widgetKey]
      const widgetTableUserPreferences = userPreferences[widgetKey]
      const updatedWidgetUserPreferences = { ...widgetTableUserPreferences, columnsConfiguration: widget.fields }

      dispatch({
        type: UPDATE_USER_PREFERENCES,
        payload: { table: widgetKey, userPreference: updatedWidgetUserPreferences }
      })
    })
  }

  const onCancelButtonClick = () => {
    editedDashboard && Analytics.clickOnCancelDashboardChanges({ dashboardName: editedDashboard.title })
    setIsEditMode(false)

    setIsDashboardEdited(false)
    setEditedDashboard(originalDashboard)

    updateWidgetsUserPreferences()
  }

  const onDashboardUpdate = (updatedDashboard: DashboardType) => {
    const isEdited = !isEqual(originalDashboard, updatedDashboard)
    setEditedDashboard(updatedDashboard)
    setIsDashboardEdited(isEdited)
  }

  const [scrolledDown, setscrolledDown] = useState(false)

  useEffect(() => {
    const elements = [
      document.querySelector('.drawerLeftContent'),
      document.querySelector('.mainWrapper')
    ].filter(Boolean) as HTMLElement[]

    const handleScroll = () => {
      setscrolledDown(elements.some(el => el.scrollTop > 0))
    }

    elements.forEach(el => {
      el.addEventListener('scroll', handleScroll)
    })

    return () => {
      elements.forEach(el => {
        el.removeEventListener('scroll', handleScroll)
      })
    }
  }, [])

  return (
    <Drawer
      isOpen={isDrawerOpen}
      onClose={onDrawerClose}
      title='Edit Widget'
      drawerContent={<DashboardEditWidget dashboardName={editedDashboard?.title ?? ''} />}
      children={<Page title='Dashboards'>
        <PageContentContainer>
          {isEditMode && (
            <EditModeButtons
              scrolledDown={scrolledDown}
              onCancelButtonClick={onCancelButtonClick}
              onApplyButtonClick={onApplyButtonClick}
              isDashboardEdited={isDashboardEdited}
            />
          )}
          <DashboardChangesContainer isEditMode={isEditMode} >
            <DashboardContent
              dashboard={editedDashboard}
              isEditMode={isEditMode}
              isLoading={isLoading}
              onEditButtonClick={onEditButtonClick}
              onDashboardUpdate={onDashboardUpdate}
            />
          </DashboardChangesContainer>
        </PageContentContainer>
      </Page>
      }
    />
  )
}

export default DashboardsPage
