import { PopupStepConfig } from '../../wizardPopup/types'
import MapColumns from './mapColumns'
import { ImportUserSubmitParams, SharedProps, SharedState, WIZARD_STEPS } from './types'
import UploadFile from './uploadFile'
import ReviewImportChanges from './reviewImportChanges'
import LoadingAfterImport from './loadingAfterImport'
import LoadingAfterMapColumns from './loadingAfterMapColumns'
import Failure from './failure'

export const stepsConfig = (): PopupStepConfig<SharedState, SharedProps, WIZARD_STEPS, ImportUserSubmitParams>[] => {
  const steps = [
    { stepName: WIZARD_STEPS.UPLOAD_FILE, stepRenderer: UploadFile },
    { stepName: WIZARD_STEPS.MAP_COLUMNS, stepRenderer: MapColumns },
    { stepName: WIZARD_STEPS.LOADING_AFTER_MAP_COLUMNS, stepRenderer: LoadingAfterMapColumns },
    { stepName: WIZARD_STEPS.REVIEW_IMPORT_CHANGES, stepRenderer: ReviewImportChanges },
    { stepName: WIZARD_STEPS.LOADING_AFTER_IMPORT, stepRenderer: LoadingAfterImport },
    { stepName: WIZARD_STEPS.FAILURE, stepRenderer: Failure }
  ]
  return steps
}
