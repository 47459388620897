import { Avatar, Button, ButtonType } from '@toriihq/design-system'
import RelativeTeamUserLink from '@components/relativeTeamUserLink'
import React from 'react'
import * as Style from './style'
import { EMPTY_OBJECT } from '@root/constants'
import { getFormattedDate } from '@lenses/utils'
import { AnalyticsInfo, AuditLogInfo, AuditLogUsers } from '../auditLog.types'
import Analytics from '@root/helpers/analytics'
import { ACTION_DESCRIPTION_BY_EDIT_HISTORY_TYPE } from '@lenses/workflows.t'
import { useHistory } from 'react-router-dom'
import { toggleWorkflowAuditLogs } from '@store/actions'
import { useDispatch } from 'react-redux'

type Props = AuditLogInfo & {
  users: AuditLogUsers,
  openURLPrefix: string,
  openURLAnalytics: AnalyticsInfo
}

const getActionDescriptionElement = ({ editType, editTime, metadata }: Pick<AuditLogInfo, 'editType' | 'editTime' | 'metadata'>) => {
  return <Style.TextContainer>
    <div>
      {ACTION_DESCRIPTION_BY_EDIT_HISTORY_TYPE[editType](metadata)}
    </div>
    <Style.Date>{getFormattedDate({ date: editTime, includeTime: true })}</Style.Date>
  </Style.TextContainer>
}

const AuditLogRow = ({ id, users, editedBy, editTime, editType, openURLPrefix, openURLAnalytics, metadata }: Props): JSX.Element => {
  const { id: idUser, firstName, lastName, photoUrl } = users[editedBy] || EMPTY_OBJECT

  const history = useHistory()
  const dispatch = useDispatch()

  const onClick = (): void => {
    Analytics.track(openURLAnalytics.name, openURLAnalytics.props)
    dispatch(toggleWorkflowAuditLogs({ isOpen: false, isByUser: false }))
    history.push(`${openURLPrefix}?previewId=${id}`)
  }

  return (
    <Style.Wrapper key={id}>
      <Style.Item>
        <RelativeTeamUserLink idUser={idUser}>
          <Avatar
            firstName={firstName}
            lastName={lastName}
            imageUrl={photoUrl}
          />
        </RelativeTeamUserLink>
        {getActionDescriptionElement({ editType, editTime, metadata })}
        <Style.ActionButton>
          <Button type={ButtonType.primary} onClick={onClick} label='View' />
        </Style.ActionButton>
      </Style.Item>
    </Style.Wrapper>
  )
}

export default AuditLogRow
