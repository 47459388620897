import React, { ReactElement } from 'react'
import {
  TextBadge,
  TextBadgeColors,
  TextBadgeSemanticColors,
  TextBadgeSize
} from '@toriihq/design-system'
import { WORKFLOW_EXECUTION_STATUS, WORKFLOW_EXECUTION_STATUS_LABELS } from '@root/constants.t'

type Props = {
  status: WORKFLOW_EXECUTION_STATUS
}

const EXECUTION_STATUS_COLORS: Record<WORKFLOW_EXECUTION_STATUS, TextBadgeColors | TextBadgeSemanticColors > = {
  COMPLETED: 'success',
  COMPLETED_WITH_ERRORS: 'warning',
  IN_PROGRESS: 'blue',
  ERROR: 'error',
  STOPPED: 'greyscale',
  STOPPING: 'gray'
}

const ExecutionStatus = ({ status }: Props): ReactElement => {
  const color = EXECUTION_STATUS_COLORS[status]
  const text = WORKFLOW_EXECUTION_STATUS_LABELS[status]

  return <TextBadge
    color={color}
    size={TextBadgeSize.Small}>
    {text}
  </TextBadge>
}

export default ExecutionStatus
