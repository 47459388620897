import React from 'react'
import { WorkflowExecution } from '../types'
import TriggerInfo from '@components/TriggerInfo'
import ExecutionStatus from '@components/workflowExecutions/executionStatus'
import { TriggerLabel } from './styles'
import { Stack } from '@toriihq/design-system'
import { UserModel } from '@shared/types'

interface Props {
  workflowExecution: WorkflowExecution
  idTriggerUsersToParents: Record<number, UserModel>
}

const TriggerDetails = ({
  workflowExecution,
  idTriggerUsersToParents
}: Props) => {
  const {
    triggerType,
    triggerOutput,
    status
  } = workflowExecution

  return (
    <Stack direction='row' gap={'space-100'}>
      <div>
        <TriggerLabel>Trigger details:</TriggerLabel>
        <TriggerInfo
          triggerType={triggerType}
          triggerOutput={triggerOutput}
          idTriggerUsersToParents={idTriggerUsersToParents}
          triggeredBy={null}
          executionType={null}
          hideTriggerPayload
        />
      </div>
      <div>
        <ExecutionStatus status={status} />
      </div>
    </Stack>

  )
}

export default TriggerDetails
