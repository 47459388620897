import analytics from '@root/helpers/analytics'

const Analytics = {
  clickOnNextButton: ({ appName, stepName }: { appName: string, stepName: string }) => analytics.track('Click on Next-button', { 'App name': appName, 'Step name': stepName }),
  clickOnCloseButton: ({ appName, stepName }: { appName: string, stepName: string }) => analytics.track('Click on Close-button', { 'App name': appName, 'Step name': stepName }),
  clickOnBackButton: ({ appName, stepName }: { appName: string, stepName: string }) => analytics.track('Click on Back-button', { 'App name': appName, 'Step name': stepName }),
  clickOnViewRelatedContracts: ({ appName }: { appName: string }) => analytics.track('Click on view-related-contracts', { 'App name': appName }),
  selectContractDoc: ({ appName }: { appName: string }) => analytics.track('Select contract-doc', { 'App name': appName }),
  clickOnClosePanelButton: ({ appName }: { appName: string }) => analytics.track('Click on Close-panel-button', { 'App name': appName }),
  clickOnXPanelButton: ({ appName }: { appName: string }) => analytics.track('Click on x-panel-button', { 'App name': appName }),
  expandLicenseBreakdown: ({ appName, licenseName }: { appName: string, licenseName: string }) => analytics.track('Expand license-breakdown', { 'App name': appName, 'License name': licenseName }),
  collapseLicenseBreakdown: ({ appName, licenseName }: { appName: string, licenseName: string }) => analytics.track('Collapse license-breakdown', { 'App name': appName, 'License name': licenseName }),
  filterUtilizationStatus: ({ appName, licenseName, utilizationStatus }: { appName: string, licenseName: string, utilizationStatus: string }) => analytics.track('Filter utilization-status', { 'App name': appName, 'License name': licenseName, 'Utilization status': utilizationStatus }),
  updateLicenses: ({ appName, updatedFields }: { appName: string, updatedFields: string[] }) => analytics.track('Update licenses', { 'App name': appName, 'Updated fields': updatedFields.join(', ') }),
  selectLicensesToAnalyze: ({ appName, selectedLicenses }: { appName: string, selectedLicenses: string }) => analytics.track('Select licenses-to-analyze', { 'App name': appName, 'License type': selectedLicenses }),
  updateRenewalBuffer: ({ appName, bufferPercentage }: { appName: string, bufferPercentage: string }) => analytics.track('Update renewal-buffer', { 'App name': appName, 'Buffer percentage': bufferPercentage }),
  exportCSV: ({ appName }: { appName: string }) => analytics.track('Export forecast', { 'App name': appName })
}

export default Analytics
