import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import { fontSize } from '../../../shared/style/sizes'
import { roundedBorderStyle } from '../../form/formStyle'
import AdvancedFilters from '../../advancedFilters'
import { clearEmptyFilters } from '@shared/filters'
import { boxesMaxHeights } from '@shared/style/sizes'
import { Tooltip, ButtonSize, Button, ButtonType, Popover } from '@toriihq/design-system'
import EnableFor from '@components/enableFor'
import noop from 'lodash/noop'

const CSS = {
  filterButton: css(roundedBorderStyle, {
    fontSize: fontSize.small,
    minHeight: boxesMaxHeights.medium,
    maxHeight: boxesMaxHeights.medium,
    minWidth: '30px',
    maxWidth: '30px',
    padding: 'auto 0',
    marginRight: 0
  }),
  onFilter: css({
    minWidth: '50px',
    justifyContent: 'flex-start'
  }),
  button: css({
    display: 'flex',
    alignItems: 'center'
  }),
  popoverOverrideStyle: css({
    width: '690px'
  }),
  gap: css({
    marginLeft: 6
  })
}

const RemoveTableFiltersURLQueryParam = () => {
  window.history.replaceState(null, '', window.location.pathname)
}

class TableFilter extends React.Component {
  createFilter = () => {
    const { onFiltersChange, filters } = this.props
    if (!filters || filters.length === 0) {
      onFiltersChange([{ id: Date.now() }])
    }
  }

  onPopoverToggle = ({ isOpen }) => {
    const { onFiltersChange, filters } = this.props
    if (isOpen) {
      this.createFilter()
    } else {
      clearEmptyFilters(onFiltersChange, filters)
    }
  }

  onFiltersChange = (filters) => {
    const { onFiltersChange, supportURLFilters } = this.props
    onFiltersChange(filters)
    supportURLFilters && RemoveTableFiltersURLQueryParam(filters)
  }

  componentWillUnmount () {
    const { onFiltersChange, filters } = this.props
    clearEmptyFilters(onFiltersChange, filters)
  }

  render () {
    const { filters, optionsKey, optionsValuesPerKey, disabled, disabledReason } = this.props

    return (
      <div>
        <Tooltip placement='top' label={disabledReason || 'Filter Table'}>
          <Popover
            position='bottom'
            align='start'
            maxWidth={730}
            openOnClick
            onToggle={this.onPopoverToggle}
            content={<div {...CSS.popoverOverrideStyle}>
              <AdvancedFilters
                optionsKey={optionsKey}
                optionsValuesPerKey={optionsValuesPerKey}
                filters={filters}
                onChange={this.onFiltersChange}
                disabled={disabled}
              />
            </div>}
          >
            <EnableFor allowForToriiAdmin>
              <Button
                size={ButtonSize.small}
                type={ButtonType.secondary}
                icon='Filter'
                disabled={disabled}
                label={filters.length === 0 ? '' : filters.length}
                onClick={noop}
              />
            </EnableFor>
          </Popover>
        </Tooltip>
      </div>
    )
  }
}

TableFilter.propTypes = {
  tableKey: PropTypes.string,
  filters: PropTypes.array,
  onFiltersChange: PropTypes.func
}

TableFilter.defaultProps = {
  filters: []
}

export default TableFilter
