import React from 'react'
import { Link } from '@toriihq/design-system'
import RelativeTeamUserLink from '@components/relativeTeamUserLink'
import { getDisplayName } from '@lenses/users'
import { getFormattedDate } from '@lenses/utils'
import moment from 'moment'

interface Props {
  stoppedByIdUser: number,
  stoppedByUser: any,
  stoppedTime: Date | null
}

const StoppedInfo = ({
  stoppedByIdUser,
  stoppedByUser,
  stoppedTime
}: Props) => {
  return (
    <div>
      <span>Stopped by </span>
      <RelativeTeamUserLink idUser={stoppedByIdUser}>
        <Link>{getDisplayName(stoppedByUser)}</Link>
      </RelativeTeamUserLink>
      <span> on {getFormattedDate({
        date: stoppedTime
      })} </span>
      <span>at {moment.utc(stoppedTime).format('h:mm A')}</span>
    </div>
  )
}

export default StoppedInfo
