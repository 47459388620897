import { createSelector } from 'reselect'
import { State } from '@reducers/types'
import { Calendar } from '@reducers/calendars/types'
import identity from 'lodash/identity'

export const getCalendars = createSelector<State, Calendar[], Calendar[]>(
  (state) => state.calendars.calendars,
  identity
)

export const getIsLoadingCalendars = createSelector<State, boolean, boolean>(
  (state) => state.calendars.loading,
  identity
)
