import React, { useEffect, useState } from 'react'
import * as Style from './style'
import { Button, ButtonSize, ButtonType, H4 } from '@toriihq/design-system'
import { createPortal } from 'react-dom'

interface DrawerProps {
  isOpen: boolean
  title: string
  children: React.ReactNode
  drawerContent: React.ReactNode
  drawerWidth?: number
  onClose: () => void
}

const DEFAULT_DRAWER_WIDTH = 346

export const Drawer: React.FC<DrawerProps> = ({
  isOpen,
  title,
  children,
  drawerContent,
  drawerWidth = DEFAULT_DRAWER_WIDTH,
  onClose
}) => {
  const [drawerContainer, setDrawerContainer] = useState<HTMLElement>()
  useEffect(() => {
    const el = window.document.querySelector(`[data-component='layout-drawer-container']`) as HTMLElement
    if (el) {
      setDrawerContainer(el)
    }
  }, [])

  if (!drawerContainer) {
    return null
  }

  return (
    <Style.Wrapper data-component='drawer'>
      <Style.LeftContent data-component='drawer-left' className='drawerLeftContent'>
        {children}
      </Style.LeftContent>
      {createPortal(
        <Style.Drawer data-component='drawer-right' className={isOpen ? 'drawer drawer-open' : 'drawer'} isOpen={isOpen} drawerWidth={drawerWidth}>
          <Style.Header>
            <H4>{title}</H4>
            <Style.CloseButtonContainer>
              <Button size={ButtonSize.small} type={ButtonType.tertiary} icon='Close' onClick={onClose} />
            </Style.CloseButtonContainer>
          </Style.Header>
          <Style.DrawerContent>
            {drawerContent}
          </Style.DrawerContent>
        </Style.Drawer>,
        drawerContainer
      )}
    </Style.Wrapper>
  )
}

export default Drawer
