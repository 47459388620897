import { WORKFLOW_TRIGGER_TYPE, WORKFLOW_TYPE } from '@shared/types'
import { getSpecificWorkflowPath, getWorkflowsPathPrefix } from '@lenses/workflows'
import { WorkflowNavigationBackData } from './types'
import { useWorkflowEditorContext } from '@pages/workflow_v2/workflowEditor/context'

export const useNavigationBackData = (workflowType: WORKFLOW_TYPE = WORKFLOW_TYPE.regular, triggerType?: WORKFLOW_TRIGGER_TYPE | null): WorkflowNavigationBackData => {
  const { isPreviewMode, idWorkflow } = useWorkflowEditorContext()

  if (isPreviewMode) {
    const pathPrefix = getSpecificWorkflowPath({ workflowType, idWorkflow, triggerType })
    return { path: pathPrefix, title: 'Back to current version' }
  }

  const defaultTitle = 'Back to workflows'
  const workflowTypeToTitleMap = {
    [WORKFLOW_TYPE.appCatalog]: 'Back to policies'
  }

  const title = workflowTypeToTitleMap[workflowType] || defaultTitle
  const pathPrefix = getWorkflowsPathPrefix({ workflowType, triggerType: triggerType ?? null })

  return { path: pathPrefix, title }
}
