import send from '@shared/redux-fetch'
import { GET_RENEWALS_SUMMARY } from '@root/constants.t'
import { GetRenewalsSummary } from '@actions/contracts/types'
import { GET_CONTRACT_ATTACHMENTS } from '@root/constants'

export const getRenewalsSummary = ({ idOrg }: GetRenewalsSummary) => {
  return send(GET_RENEWALS_SUMMARY, {
    url: `/api/orgs/${idOrg}/contracts/renewalsSummary`,
    method: 'GET'
  })
}

export const getContractAttachments = ({ idOrg, idApp, idUploads }) => {
  return send(GET_CONTRACT_ATTACHMENTS, {
    method: 'GET',
    url: `/api/orgs/${idOrg}/contracts/attachments`,
    query: {
      idApp,
      idUploads: JSON.stringify(idUploads)
    }
  })
}
