import React, { useMemo, useState } from 'react'
import Table from '@components/table'
import { sortColumns } from '@components/table/view'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import * as Style from './style'
import { Spacer, AppIcon, Tooltip, Icon } from '@toriihq/design-system'
import { deprecatedGetAppsByIds } from '@selectors/apps'
import { useDispatch, useSelector } from 'react-redux'
import UsageIcon from '@components/usageIcon'
import { EMPTY_ARRAY, EMPTY_OBJECT, fieldTypes, ITEMS_PER_PAGE, TABLES } from '@root/constants'
import { APP_USER_STATUS } from '@shared/types'
import { getAppActiveUsers, getAppUsersFieldValues, getUserDetailsFields } from '@actions/'
import { getUserTablesInfo } from '@selectors/tables'
import UserDetails from '@components/userDetails'
import moment from 'moment'
import SourceIcon from '@components/sourceIcon'
import userToolTip from '@shared/userToolTip'
import { getDisplayName } from '@lenses/users'
import getColumnByFieldType from '@components/table/columnFieldTypes'
import debounce from 'lodash/debounce'
import { getAppActiveUserConfigurableColumnsOptions } from '@selectors/userDetails'
import config from '@root/config'
import { getUserPreferences } from '@selectors/ui'
import { useIsMounted } from '@shared/hooks'
import { getFormattedDate } from '@lenses/utils'
import useEffectOnce from '@shared/hooks/useEffectOnce'

const ActiveUsersPopup = (props) => {
  const { onClose, isOpen, idApp, idOrg } = props

  const selectedApp = useSelector(deprecatedGetAppsByIds)[idApp]
  const tableInfo = useSelector(getUserTablesInfo)[TABLES.appActiveUsersListPopup.key] || EMPTY_OBJECT
  const configurableColumnsOptions = useSelector(getAppActiveUserConfigurableColumnsOptions)
  const userPreferences = useSelector(getUserPreferences)
  const userTablePreferences = userPreferences[TABLES.appActiveUsersListPopup.key] || EMPTY_OBJECT
  const { defaultSort = EMPTY_ARRAY } = userTablePreferences

  const dispatch = useDispatch()

  const isMounted = useIsMounted()

  const [users, setUsers] = useState([])
  const [totalUsers, setTotalUsers] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [query, setQuery] = useState()

  useEffectOnce(() => {
    fetchUsersData(true)
    dispatch(getAppUsersFieldValues({ idOrg, idApp, status: APP_USER_STATUS.ACTIVE, fields: ['role', 'externalStatus', 'licenses.name', 'appAccountName', 'idAppAccount'] }))
  })

  const totalActiveUsersCount = useMemo(() => selectedApp.activeUsersCount, [selectedApp])

  const columns = useMemo(() => {
    if (!tableInfo || tableInfo === EMPTY_OBJECT) {
      return []
    }
    const { columnsConfiguration, dynamicColumnsInfo } = tableInfo
    return [
      {
        Header: 'Users',
        id: 'fullName',
        accessor: ({ firstName, lastName, email }) => [firstName, lastName, email].join(' ').trim().toLowerCase(),
        minWidth: 220,
        Cell: ({ row: { firstName, lastName, idUser, email, isExternal, photoUrl } }) => (
          <UserDetails
            firstName={firstName}
            lastName={lastName}
            email={email}
            idUser={idUser}
            isExternal={isExternal}
            photoUrl={photoUrl}
          />
        ),
        textValue: ({ firstName, lastName, email }) => getDisplayName({ firstName, lastName, email })
      },
      {
        Header: 'Has license',
        idForColumnsOrder: 'licenses.name',
        accessor: 'licenses',
        Cell: ({ row: { licenses = [] } }) => {
          if (!licenses.length) {
            return '-'
          }
          return <Tooltip
            placement='top'
            label={licenses.map(license => <div key={license.type}>{license.name}</div>)}
          >
            <Icon name='CheckCircleFill' color='interactive' />
          </Tooltip>
        },
        show: Boolean(columnsConfiguration['licenses.name'])
      },
      {
        Header: (
          <Tooltip
            label={<Style.UsageHeaderTooltipLabel>Usage is based on visits to {selectedApp.name} in the last 30 days</Style.UsageHeaderTooltipLabel>}
          >
            Usage
          </Tooltip>
        ),
        textHeader: 'Usage',
        accessor: 'score',
        style: { display: 'flex', alignItems: 'center ' },
        Cell: ({ value: score, row: { lastVisitTime } }) => <UsageIcon isUserUsage score={score} lastVisitTime={lastVisitTime} />,
        show: Boolean(columnsConfiguration.score)

      },
      {
        Header: 'Last used date',
        id: 'lastVisitTime',
        Cell: ({ value: lastVisitTime }) => getFormattedDate({ date: lastVisitTime }),
        show: Boolean(columnsConfiguration.lastVisitTime)
      },
      {
        Header: 'Sources',
        accessor: 'sources',
        Cell: ({ row: { firstName, lastName, sources, email } }) => (
          <div>
            {(sources || []).map(source => (<SourceIcon key={source} sourceType={source} tooltipText={userToolTip({ displayName: getDisplayName({ firstName, lastName, email }), appName: selectedApp.name, source })} />))}
          </div>
        ),
        show: Boolean(columnsConfiguration.sources)
      },
      {
        accessor: 'firstName',
        show: false
      },
      {
        accessor: 'lastName',
        show: false
      },
      {
        accessor: 'idUser',
        show: false
      },
      {
        accessor: 'email',
        show: false
      },
      {
        accessor: 'lastVisitTime',
        show: false
      },
      {
        accessor: 'isExternal',
        show: false
      },
      {
        accessor: 'photoUrl',
        show: false
      }
    ].concat(getColumnByFieldType({ columnsInfo: dynamicColumnsInfo, fieldIdentifier: 'key' }))
  }, [tableInfo, selectedApp.name])

  const sortStringFromArray = sortArray => {
    return sortArray.map(s => `${s.id}:${s.desc ? 'desc' : 'asc'}`).join(',')
  }

  const getActiveUsersList = ({ limit = ITEMS_PER_PAGE, offset = 0, sort = defaultSort, q = query, reset = false, filters = tableInfo.filters }) => {
    setIsLoading(true)
    setIsLoadingMore(!reset)

    if (reset) {
      dispatch(getUserDetailsFields({ idOrg }))
    }

    dispatch(getAppActiveUsers({ idOrg, idApp, limit, offset, sort: sortStringFromArray(sort), q, reset, filters }))
      .then(results => {
        if (!isMounted) {
          return
        }
        const { activeUsers, total } = results
        setUsers(reset ? activeUsers : [ ...users, ...activeUsers ])
        setTotalUsers(total)
      })
      .finally(() => {
        if (!isMounted) {
          return
        }
        setIsLoading(false)
        setIsLoadingMore(false)
      })
  }

  const fetchUsersData = (reset = false) => {
    getActiveUsersList({ offset: reset ? 0 : users.length, reset })
  }

  const exportToCsv = ({ sort, query }) => {
    sortColumns(columns, userTablePreferences)

    const sortParam = 'sort=' + sort.map(s => `${s.id}:${s.desc ? 'desc' : 'asc'}`).join(',')
    const queryParam = `q=${query}`
    const fieldsParam = `fields=${columns.filter(col => ((col.show === undefined || col.show) && !col.hideFromCSV)).map(col => col.id || col.accessor).join(',').replace('fullName', 'firstName,lastName,email')}`
    const statusParam = `status=${APP_USER_STATUS.ACTIVE}`

    const url = `${config.apiBaseUrl}/api/orgs/${idOrg}/apps/${idApp}/users/csv?${sortParam}&${queryParam}&${statusParam}&${fieldsParam}`
    const newWindow = window.open(url, '_blank')
    newWindow.opener = null
  }

  const onSearch = debounce((q) => {
    setQuery(q)
    getActiveUsersList({ reset: true, q })
  }, 500)

  const searchFilterMethod = (row, search) => {
    const values = [
      [row.firstName, row.lastName].join(' '),
      row.email
    ]
    return values.some(value => value && value.toLowerCase().includes(search))
  }

  const onSortedChange = debounce((sort) => {
    getActiveUsersList({ sort, reset: true })
  }, 500)

  const fetchFieldValues = (field) => {
    const selectedFilterOption = tableInfo.filtersOptions.find(f => f.value === field) || {}
    const isFieldSupportValues = [fieldTypes.text, fieldTypes.user, fieldTypes.dropdownMulti].includes(selectedFilterOption.type)
    if (isFieldSupportValues) {
      dispatch(getAppUsersFieldValues({ idOrg, idApp, status: APP_USER_STATUS.ACTIVE, fields: [field] }))
    }
  }

  const getHeader = () => {
    const subTitle = `Last 30 days: ${moment.utc().subtract(30, 'days').format('MMMM D')}  - ${moment().format('MMMM D')}`

    return (
      <div>
        <div {...Style.CountHeader}>{`ACTIVE USERS (${totalUsers})`}</div>
        <div {...Style.Period}>{subTitle}</div>
      </div>
    )
  }

  const renderPopupHeader = () => {
    return (
      <div {...Style.Box}>
        <Spacer right={'space-200'}>
          <AppIcon appName={selectedApp.name} appImageUrl={selectedApp.imageUrl} />
        </Spacer>
        <div {...Style.Name}>{selectedApp.name}</div>
        <UsageIcon score={selectedApp.score} isCore={selectedApp.isCore} />
      </div>
    )
  }
  return (
    <ToriiPopup isOpen={isOpen} onCloseAction={onClose} styles={Style.Popup} contentAreaStyle={Style.MainTableArea}>
      <ToriiPopup.Header header={renderPopupHeader()} />
      <ToriiPopup.Content>
        <Table
          tableKey={TABLES.appActiveUsersListPopup.key}
          getTdProps={() => Style.TableTd}
          data={users}
          header={getHeader}
          columns={columns}
          loading={isLoading}
          loadingMore={isLoadingMore}
          exportable
          exportFunction={exportToCsv}
          searchable
          searchFilterMethod={searchFilterMethod}
          manual
          configurableColumnsOptions={configurableColumnsOptions}
          configurableColumns
          scrollObjectId='contentArea'
          fetchData={fetchUsersData}
          forceShowSearch={totalActiveUsersCount > 0}
          onSearch={onSearch}
          onSortedChangeCB={onSortedChange}
          fetchFieldValues={fetchFieldValues}
          totalCount={totalUsers}
        />
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        showCancelButton={false}
        mainButtonAction={onClose}
        mainButtonText={'Close'}
      />
    </ToriiPopup>
  )
}

export default ActiveUsersPopup
