import { WORKFLOW_EXECUTION_STATUS_LABELS, WORKFLOW_THROTTLED_STATUS } from '@root/constants.t'
import { Workflow } from './types'

export const getWorkflowLastStatus = (workflow: Workflow): string | null => {
  const { latestExecution, isThrottled } = workflow
  if (isThrottled) {
    return WORKFLOW_THROTTLED_STATUS
  } else if (latestExecution?.status) {
    return WORKFLOW_EXECUTION_STATUS_LABELS[latestExecution.status]
  }
  return null
}
