import React from 'react'
import {
  Body2,
  Caption1,
  Card,
  Col,
  Divider,
  Grid,
  Stack,
  Subtitle2
} from '@toriihq/design-system'
import { FullWidth, RightAlignedDiv, RowWithMargin } from '@pages/renewalForecast/recommendation/style'
import Currency, { CurrencyFormat } from '@components/currency'
import LicenseBarAndTableRow from '@pages/renewalForecast/recommendation/licenseBarAndTableRow'
import { LicenseBarsAndTableCardProps } from '@pages/renewalForecast/recommendation/types'

const LicenseBarsAndTableCard = ({
  idOrg,
  idApp,
  licensesDetails,
  license,
  bufferAmount,
  bufferValue
}: LicenseBarsAndTableCardProps): JSX.Element => {
  const foreCastedChange = ((license.activeAmount + bufferAmount) * license.pricePerUserConverted) - license.totalCostConverted
  return (<FullWidth>
    <Card>
      <FullWidth>
        <Stack gap={'space-150'} direction={'column'}>
          <Subtitle2>
            Current vs recommended licenses breakdown
          </Subtitle2>
          <Stack gap={'space-200'} direction={'column'}>
            <FullWidth>
              <Grid>
                <RowWithMargin>
                  <Col xs={6} sm={7.63} md={7.5} />
                  <Col xs={2} sm={1.47} md={1.5}>
                    <RightAlignedDiv>
                      <Caption1 color='tertiary'>
                        Total licenses
                      </Caption1>
                    </RightAlignedDiv>
                  </Col>
                  <Col xs={2} sm={1.45} md={1.5}>
                    <RightAlignedDiv>
                      <Caption1 color='tertiary'>
                        Annual cost
                      </Caption1>
                    </RightAlignedDiv>
                  </Col>
                  <Col xs={2} sm={1.45} md={1.5}>
                    <RightAlignedDiv>
                      <Caption1 color='tertiary'>
                        Total cost
                      </Caption1>
                    </RightAlignedDiv>
                  </Col>
                </RowWithMargin>
                <LicenseBarAndTableRow
                  idOrg={idOrg}
                  idApp={idApp}
                  licensesDetails={licensesDetails}
                  rowMarginBottom={'16px'}
                  rowName={'Current'}
                  shouldRenderBar
                  licenseActiveAmount={license.activeAmount}
                  licenseInactiveAmount={license.inactiveAmount}
                  licenseUnassignedAmount={license.unassignedAmount}
                  licenseValidTotalAmount={license.validTotalAmount}
                  licenseBufferAmount={0}
                  bufferValue={bufferValue}
                  barWidthAmount={license.validTotalAmount}
                  totalLicenses={license.totalAmount}
                  annualCost={license.pricePerUserConverted}
                  totalCost={license.totalCostConverted}
                />
                <LicenseBarAndTableRow
                  idOrg={idOrg}
                  idApp={idApp}
                  licensesDetails={licensesDetails}
                  rowMarginBottom={'0px'}
                  rowName={'Recommended'}
                  shouldRenderBar={license.activeAmount + bufferAmount > 0}
                  licenseActiveAmount={license.activeAmount}
                  licenseInactiveAmount={0}
                  licenseUnassignedAmount={0}
                  licenseValidTotalAmount={license.activeAmount + bufferAmount}
                  licenseBufferAmount={bufferAmount}
                  bufferValue={bufferValue}
                  barWidthAmount={license.activeAmount + bufferAmount}
                  totalLicenses={license.activeAmount + bufferAmount}
                  annualCost={license.pricePerUserConverted}
                  totalCost={(license.activeAmount + bufferAmount) * license.pricePerUserConverted}
                />
              </Grid>
            </FullWidth>
            <FullWidth>
              <Divider orientation={'Vertical'} />
            </FullWidth>
            <RightAlignedDiv>
              <Body2 color='secondary'>
                Forecasted change: {<Currency value={foreCastedChange} format={foreCastedChange > 0 ? CurrencyFormat.POSITIVE : CurrencyFormat.DEFAULT} />}
              </Body2>
            </RightAlignedDiv>
          </Stack>
        </Stack>
      </FullWidth>
    </Card>
  </FullWidth>
  )
}

export default LicenseBarsAndTableCard
