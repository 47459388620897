import { createSelector } from 'reselect'
import identity from 'lodash/identity'
import get from 'lodash/get'
import some from 'lodash/some'
import { createParameterSelector } from '@shared/utils'
import { getAllPlans } from '@selectors/settings'
import { DISCOVERED_APPS_SOURCES, SOURCE_TYPES } from '@root/sourcesConfig'
import { ALL_SOURCES_CUSTOM_SELECT_OPTION } from '@root/constants'

export const getCurrentOrg = createSelector(
  state => state.org,
  identity
)

export const isTrial = createSelector(
  getCurrentOrg,
  org => Boolean(org && org.trialEndTime)
)

export const isPaidAccount = createSelector(
  getCurrentOrg,
  org => Boolean(org && org.paidPlanEndTime)
)

export const isOrgLoading = createSelector(
  state => state.org.loading,
  identity
)

export const getServicesConnectionStatus = createSelector(
  state => get(state, ['org', 'servicesConnectionStatus'], {}),
  identity
)

export const getSupportedDiscoveredAppsServicesOptions = createSelector(
  [getServicesConnectionStatus],
  (allConnectedServices) => {
    const allConnectedAndSupportedDiscoveredApps = DISCOVERED_APPS_SOURCES.filter(source => allConnectedServices[source]).sort()

    const sourcesOptions = allConnectedAndSupportedDiscoveredApps.map(source => ({
      value: source,
      label: SOURCE_TYPES[source].name
    }))

    return [ALL_SOURCES_CUSTOM_SELECT_OPTION, ...sourcesOptions]
  }
)

export const getAppCatalogSettings = createSelector(
  state => get(state, ['org', 'catalogSettings'], {}),
  identity
)

export const getAppCatalogSettingsResources = createSelector(
  state => get(state, ['org', 'catalogSettings', 'resources'], {}),
  identity
)

export const isLoadingAppCatalogSettings = createSelector(
  state => get(state, ['org', 'loadingCatalogSettings'], false),
  identity
)

export const isLoadedAppCatalogSettings = createSelector(
  state => get(state, ['org', 'loadedCatalogSettings'], false),
  identity
)

export const getAppOffboardingSettings = createSelector(
  state => get(state, ['org', 'offboardingSettings'], {}),
  identity
)

export const isLoadingAppOffboardingSettings = createSelector(
  state => get(state, ['org', 'loadingOffboardingSettings']),
  identity
)

export const isAnyServiceConnected = createSelector(
  getServicesConnectionStatus,
  servicesConnectionStatus => some(servicesConnectionStatus, serviceConnectionStatus => serviceConnectionStatus)
)

export const getSupportedFeatures = createSelector(
  state => get(state, ['org', 'supportedFeatures'], {}),
  identity
)

export const isLoadedSupportedFeatures = createSelector(
  state => get(state, ['org', 'isLoadedSupportedFeatures']),
  identity
)

export const getLifecycleApps = createSelector(
  state => get(state, ['org', 'lifecycleApps', 'apps']),
  identity
)

export const getLifecycleSettings = createSelector(
  state => get(state, ['org', 'lifecycleSettings', 'userLifecycleConfig']),
  identity
)

const createTypeParameterSelector = createParameterSelector(params => params.type)

export const getUserTypeSources = createSelector(
  state => get(state, ['org', 'userTypeSources'], []),
  identity
)

export const getUserTypeResources = createSelector(
  state => get(state, ['org', 'userTypeSourcesApps'], []),
  identity
)

export const getUserTypeSourcesApps = createSelector(
  [getUserTypeSources, getUserTypeResources, createTypeParameterSelector],
  (userTypeSources, appsById, type) => {
    if (type) {
      userTypeSources = userTypeSources.filter(source => source.type === type)
    }
    return userTypeSources.map(typeSource => {
      const appInfo = appsById[typeSource.idApp] || {}
      return {
        ...typeSource,
        name: appInfo.name,
        imageUrl: appInfo.imageUrl
      }
    })
  }
)

export const isLoadingUserTypeSources = createSelector(
  state => get(state, ['org', 'loadingUserTypeSources'], false),
  identity
)

export const getMergeUsersRules = createSelector(
  state => get(state, ['org', 'mergeUsersRules', 'rules'], []),
  identity
)

export const isLoadingMergeUsersRules = createSelector(
  state => get(state, ['org', 'mergeUsersRules', 'loading'], false),
  identity
)

export const isLoadingLifecycleSettings = createSelector(
  state => get(state, ['org', 'lifecycleSettings', 'loading']),
  identity
)

export const isLoadedUserLifecycleApps = createSelector(
  state => get(state, ['org', 'lifecycleApps', 'isLoaded']),
  identity
)

export const isLoadingUserLifecycleApps = createSelector(
  state => get(state, ['org', 'lifecycleApps', 'loading']),
  identity
)

export const getLifecyclePreview = createSelector(
  state => get(state, ['org', 'lifecyclePreview', 'previewData']),
  identity
)

export const isLoadedUserLifecyclePreview = createSelector(
  state => get(state, ['org', 'lifecyclePreview', 'isLoaded']),
  identity
)

export const isLoadingUserLifecyclePreview = createSelector(
  state => get(state, ['org', 'lifecyclePreview', 'loading']),
  identity
)

export const isUpdatingUserLifecycle = createSelector(
  state => get(state, ['org', 'lifecyclePreview', 'updating']),
  identity
)

export const getCurrency = createSelector(
  state => get(state, ['org', 'defaultCurrency']),
  identity
)

const getCurrencySymbols = createSelector(
  state => get(state, ['ui', 'currencies'], {}),
  identity
)

export const getCurrencySymbol = createSelector(
  [getCurrency, getCurrencySymbols],
  (currency, currencies) => currencies[currency] || ''
)

export const getCompanyName = createSelector(
  state => get(state, ['org', 'companyName']),
  identity
)

export const getAppNotInUsePeriod = createSelector(
  state => get(state, ['org', 'appNotInUsePeriod']),
  identity
)

export const getInactivityTimeout = createSelector(
  state => get(state, ['org', 'inactivityTimeout']),
  identity
)

export const getSessionTimeout = createSelector(
  state => get(state, ['org', 'sessionTimeout']),
  identity
)

export const getOrgEmailAlias = createSelector(
  state => get(state, ['org', 'emailAlias']),
  identity
)

export const getIdOrg = createSelector(
  state => get(state, ['org', 'id']),
  identity
)

export const getOrgDomains = createSelector(
  state => get(state, ['org', 'domains', 'list']),
  identity
)

export const isOrgDomainsLoaded = createSelector(
  state => get(state, ['org', 'domains', 'isLoaded']),
  identity
)

export const getExtensionMode = createSelector(
  state => get(state, ['org', 'extensionMode']),
  identity
)

export const getOrgIsDisabled = createSelector(
  state => get(state, ['org', 'isDisabled']),
  identity
)

export const getOrgNickName = createSelector(
  state => get(state, ['org', 'nickname']),
  identity
)

export const getGeoLocationSettings = createSelector(
  state => get(state, ['org', 'isGeoLocationAllowed'], true),
  identity
)

export const getRenewalCalendarSettings = createSelector(
  state => get(state, ['org', 'isRenewalCalendarAllowed'], false),
  identity
)

export const getIsToriiSupportAllowedForOrg = createSelector(
  state => get(state, ['org', 'isToriiSupportAllowedForOrg']),
  identity
)

export const getBenchmarkSettings = createSelector(
  state => get(state, ['org', 'isBenchmarkAllowed'], false),
  identity
)

export const getIsScimEnabledSelector = createSelector(
  state => get(state, ['org', 'isScimEnabled'], false),
  identity
)

export const getIsAppOwnerActive = createSelector(
  state => Boolean(get(state, ['org', 'isAppOwnerActive'], false)),
  identity
)

export const getOrgFeatures = createSelector(
  state => get(state, ['org', 'features'], []),
  identity
)

export const getOrgIdPlan = createSelector(
  state => get(state, ['org', 'idPlan']),
  identity
)

export const getOrgAllowOnlyYearlySubscription = createSelector(
  state => get(state, ['org', 'allowOnlyYearlySubscription']),
  identity
)

export const getOrgPlanDetails = createSelector(
  [getOrgIdPlan, getAllPlans],
  (idPlan, allPlans) => {
    return allPlans.find(plan => plan.id === idPlan)
  }
)

export const getOrgSubscriptions = createSelector(
  state => get(state, ['org', 'subscriptions', 'data']).filter(subscription => !subscription.trueUpsForExternalId),
  identity
)

export const getIsLoadingSubscriptions = createSelector(
  state => get(state, ['org', 'subscriptions', 'loading']),
  identity
)
export const isDemoOrg = createSelector(
  state => get(state, ['org', 'isDemo']),
  identity
)

export const getIsBlockThirdPartyAppsEnabled = createSelector(
  state => get(state, ['org', 'isBlockThirdPartyAppsEnabled'], false),
  identity
)
