import React, { PropsWithChildren, useEffect, useState } from 'react'
import Analytics from '@helpers/analytics'

const OnHoverAnalyticsWrapper = ({ children, triggerAfterInMs, analyticsName, analyticsProps }: PropsWithChildren<{ triggerAfterInMs: number, analyticsName: string, analyticsProps: object }>): JSX.Element => {
  const [enterTime, setEnterTime] = useState(0)
  const [leaveTime, setLeaveTime] = useState(0)

  const onMouseEnter = () => {
    setEnterTime(new Date().getTime())
  }

  const onMouseLeave = () => {
    setLeaveTime(new Date().getTime())
  }

  useEffect(() => {
    if (leaveTime - enterTime >= triggerAfterInMs) {
      console.log('User hovered for', (leaveTime - enterTime) / 1000, 'seconds')
      Analytics.track(analyticsName, analyticsProps)
    }
  }, [leaveTime, enterTime, triggerAfterInMs, analyticsName, analyticsProps])

  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {children}
    </div>
  )
}

export default OnHoverAnalyticsWrapper
