import { ReactElement, useEffect, useState } from 'react'
import { getCurrentUsersFieldValues } from '@store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getIdOrg } from '@selectors/org'
import { getUserTablesInfo } from '@selectors/tables'
import { EMPTY_OBJECT, SCOPES, TABLES } from '@root/constants'
import AccessControl from '@lenses/accessControl'
import { getColumns as getUserColumns } from '@components/usersTabs/employees/view'
import { getUserConfigurableColumnsOptions } from '@selectors/userDetails'
import { getCurrentUsersFieldValues as getCurrentUsersFieldValuesSelector } from '@selectors/users'
import { isFieldSupportAutocompleteValues } from '@shared/fieldUtils'
import { mapSortArray } from '@shared/utils'
import config from '@root/config'
import { InternalProps } from '../types'

const UserDataPopup = (props: InternalProps): ReactElement => {
  const dispatch = useDispatch()
  const { renderFunc, widget } = props

  const idOrg = useSelector(getIdOrg)
  const hasLicenseCostAndChargebackAccess = useSelector(state => AccessControl.isAccessibleWithState({ scopes: [SCOPES.LICENSE_AND_CHARGEBACK_READ], state }))

  const usersTableInfo = useSelector(getUserTablesInfo)[TABLES.usersTable.key]
  const userFieldValues = useSelector(getCurrentUsersFieldValuesSelector)
  const userConfigurableColumnsOptions = useSelector(getUserConfigurableColumnsOptions) ?? EMPTY_OBJECT

  const [columns, setColumns] = useState([])

  const configurableColumnsOptions = userConfigurableColumnsOptions
  const fieldValues = userFieldValues
  const filtersOptions = [ ...usersTableInfo.filtersOptions, ...TABLES.usersTable.dashboardFilterOptions ]

  const fetchFieldValues = (field) => {
    const options = usersTableInfo.filtersOptions

    const isFieldSupportValues = isFieldSupportAutocompleteValues({
      fieldOptions: options,
      fieldOption: field
    })
    if (isFieldSupportValues) {
      dispatch(getCurrentUsersFieldValues({ idOrg, fields: [field] }))
    }
  }

  useEffect(() => {
    const columnsRestrictions = { hasLicenseCostAndChargebackAccess }

    const columnsConfiguration = (widget.drillDownConfig.fields ?? []).reduce((acc, field) => {
      acc[field] = true
      return acc
    }, {})

    setColumns(getUserColumns({
      preDefinedColumnsMapping: usersTableInfo.preDefinedColumnsMapping,
      dynamicColumnsInfo: usersTableInfo.allDynamicColumnsInfo.filter(a => columnsConfiguration[a.key]),
      columnsConfiguration,
      columnsRestrictions }) as any
    )
  }, [hasLicenseCostAndChargebackAccess, usersTableInfo.allDynamicColumnsInfo, usersTableInfo.preDefinedColumnsMapping, widget.drillDownConfig.fields])

  const exportToCsv = ({ sort, query, filters }) => {
    const columnsOrder = widget.drillDownConfig.fields
    const columnsOrdered = columns.sort((a: any, b:any) => columnsOrder.indexOf(a.id || a.accessor) - columnsOrder.indexOf(b.id || b.accessor))
    const fields = columnsOrdered.filter((col: any) => ((col.show === undefined || col.show) && !col.hideFromCSV)).map((col: any) => col.id || col.accessor).flatMap(field => field === 'fullName' ? ['firstName', 'lastName', 'email'] : field)

    const sortParam = 'sort=' + mapSortArray(sort).join(',')
    const queryParam = `q=${query}`
    const fieldsParam = `fields=${fields.join(',')}`
    const filtersParam = `filters=${encodeURIComponent(JSON.stringify([...filters, { 'key': 'idParentUser', 'op': 'isNotSet' }]))}`

    const url = `${config.apiBaseUrl}/api/orgs/${idOrg}/users/csv?${sortParam}&${queryParam}&${fieldsParam}&${filtersParam}`
    const newWindow = window.open(url, '_blank') as any
    newWindow.opener = null
  }

  return renderFunc({ columns, filtersOptions, fieldValues, configurableColumnsOptions, fetchFieldValues, exportToCsv })
}

export default UserDataPopup
