import UserDetails from '@components/userDetails'
import { StatusDot, theme } from '@toriihq/design-system'
import { getFormattedDate } from '@lenses/utils'
import getColumnByFieldType from '@components/table/columnFieldTypes'
import React from 'react'
import Currency from '@components/currency'

const UTILIZATION_STATUS_CONFIG = {
  'active': {
    label: 'Active',
    color: theme.palette.chart.categorical10
  },
  'inactive': {
    label: 'Inactive',
    color: theme.palette.chart.categorical05
  }
}

export const getColumns = ({ tableInfo, license }) => {
  const { columnsConfiguration, dynamicColumnsInfo } = tableInfo
  return [
    {
      Header: 'Assigned to',
      id: 'fullName',
      accessor: ({ firstName, lastName, email }) => [firstName, lastName, email].join(' ').trim().toLowerCase(),
      minWidth: 220,
      Cell: ({ row: { firstName, lastName, idUser, email, isExternal, photoUrl, lifecycleStatus, isDeletedInIdentitySources } }) => (
        <UserDetails
          firstName={firstName}
          lastName={lastName}
          email={email}
          idUser={idUser}
          isExternal={isExternal}
          photoUrl={photoUrl}
          lifecycleStatus={lifecycleStatus}
          isDeletedInIdentitySources={isDeletedInIdentitySources}
          showPastUserBadge
        />
      )
    },
    {
      id: 'originEmail',
      Header: 'Email',
      accessor: 'originEmail',
      Cell: ({ row: { originEmail } }) => originEmail,
      show: Boolean(columnsConfiguration['originEmail'])
    },
    {
      Header: 'Utilization status',
      Cell: ({ row: { _original: appUser } }) => {
        const userLicense = appUser.license
        const { status } = userLicense?.calculatedStatus ?? {}
        const { label, color } = UTILIZATION_STATUS_CONFIG[status] || {}
        return <StatusDot label={label} color={color} />
      },
      accessor: 'utilizationStatus'
    },
    {
      Header: 'Reason',
      accessor: 'reason',
      Cell: ({ row: { _original: appUser } }) => {
        const userLicense = appUser.license
        const { reason } = userLicense?.calculatedStatus ?? {}
        return reason
      }
    },
    {
      Header: 'Annual cost per license',
      accessor: 'annualCostPerLicense',
      Cell: () => {
        return <Currency value={license.pricePerUserConverted} />
      }
    },
    {
      Header: 'Last used date',
      accessor: 'lastVisitTime',
      Cell: ({ value: lastVisitTime }) => getFormattedDate({ date: lastVisitTime }),
      minWidth: 200,
      show: Boolean(columnsConfiguration.lastVisitTime)
    },
    {
      Header: 'Recommendation',
      Cell: ({ row: { _original: appUser } }) => {
        const userLicense = appUser.license
        const { recommendation } = userLicense?.calculatedStatus ?? {}
        return recommendation
      },
      accessor: 'recommendation'
    },
    {
      accessor: 'firstName',
      show: false
    },
    {
      accessor: 'lastName',
      show: false
    },
    {
      accessor: 'idUser',
      show: false
    },
    {
      accessor: 'email',
      show: false
    },
    {
      accessor: 'isExternal',
      show: false
    },
    {
      accessor: 'photoUrl',
      show: false
    },
    {
      accessor: 'lifecycleStatus',
      show: false
    }
  ].concat(getColumnByFieldType({ columnsInfo: dynamicColumnsInfo, fieldIdentifier: 'key' }))
}
