import React, { ReactElement } from 'react'
import DataInfoBox from '@components/dataInfoBox'
import StatusIcon from '@components/statusIcon'
import { CAPABILITY_TO_NAME } from '@root/components/testConnection/constants'
import { Status, StatusByResource, SyncError, SyncStatus } from './types'
import { SYNC_ERRORS, SYNC_STATUS } from '@root/constants'
import AlertBanner from '@components/alertBanner'
import { theme } from '@toriihq/design-system'
import { buildFirstSyncMsg } from '@components/service/nativeIntegrationService/index'
import { getSyncedText } from '@components/service/baseService/index'
import { getTimePassedText } from '@shared/utils/getTimePassedText'

const MANDATORY_CAPABILITIES = new Set([CAPABILITY_TO_NAME.users, CAPABILITY_TO_NAME.expenses, CAPABILITY_TO_NAME.contracts])

export const shouldRenderAsUnprocessed = (capability: string, isMandatoryDataFailed: boolean) => {
  return (isMandatoryDataFailed && !MANDATORY_CAPABILITIES.has(capability))
}

const shouldRenderUsageAsUnprocessed = ({ capability, usageOverallStatus, statusByResource }: { capability: string, usageOverallStatus: SyncStatus, statusByResource: StatusByResource[] }) => {
  const syncErrors = statusByResource.map(({ status }) => status?.syncError)
  const isUsageFailed = syncErrors.every((error: string) => error === 'error_to_ignore_show_message')
  return (capability === CAPABILITY_TO_NAME.usage && usageOverallStatus === SYNC_STATUS.FINISHED_SUCCESSFULLY && isUsageFailed)
}

export const getErrorMessageLogic = (errorMessage: string, syncError: SyncError) => errorMessage || SYNC_ERRORS[syncError]

export const statusToBanner = {
  [SYNC_STATUS.FINISHED_SUCCESSFULLY]: <AlertBanner
    color={theme.palette.background.successSubtle}
    iconName={'CheckCircle'}
    iconColor={'success'}
    bannerText={'Successfully synced'}
  />,
  [SYNC_STATUS.FINISHED_PARTIALLY]: <AlertBanner
    color={theme.palette.background.warningSubtle}
    iconName={'Info'}
    iconColor={'warning'}
    bannerText={'Last sync partially successful. Please review the highlighted issues below'}
  />,
  [SYNC_STATUS.FINISHED_FAILED]: <AlertBanner
    color={theme.palette.background.errorSubtleHover}
    iconName={'Danger'}
    iconColor={'error'}
    bannerText={'Sync failed. Please review the highlighted issues below'}
  />
}

export const renderInfoBox = ({ capability, status, lastSyncTime, isMandatoryDataFailed, licensesManagedManually, lastUsersAndLicensesFileLastSyncTime }: { capability: string, status: Status, lastSyncTime: string, isMandatoryDataFailed: boolean, licensesManagedManually?: boolean, lastUsersAndLicensesFileLastSyncTime?: string }): JSX.Element => {
  let lastSyncTimeMessage = getSyncedText({ lastSyncTime })

  if (licensesManagedManually) {
    lastSyncTimeMessage = getSyncedText({ lastSyncTime: lastUsersAndLicensesFileLastSyncTime })
    return renderInfoBoxOnUnprocessed({ capability, smallComment: lastSyncTimeMessage, additionalInfoText: ['Managed manually'] })
  }

  if (shouldRenderAsUnprocessed(capability, isMandatoryDataFailed)) {
    return renderInfoBoxOnUnprocessed({ capability, smallComment: lastSyncTimeMessage })
  }

  if (status.statusToShow === SYNC_STATUS.FINISHED_SUCCESSFULLY) {
    return renderInfoBoxOnSuccess({ capability, smallComment: lastSyncTimeMessage })
  }

  const additionalInfoText = [getErrorMessageLogic(status.errorMessage, status.syncError)]
  return renderInfoBoxOnFailure({ capability, additionalInfoText, smallComment: lastSyncTimeMessage, emphasizeError: true })
}

export const renderInfoBoxForResourcesCapability = ({ statusByResource, usageOverallStatus, lastSyncTime, isMandatoryDataFailed, connectedAt, capability }: { statusByResource: StatusByResource[], usageOverallStatus: SyncStatus, lastSyncTime: string, isMandatoryDataFailed: boolean, connectedAt: string, capability: string }): JSX.Element => {
  const lastSyncTimeMessage = getSyncedText({ lastSyncTime })

  if (shouldRenderAsUnprocessed(CAPABILITY_TO_NAME.usage, isMandatoryDataFailed)) {
    return renderInfoBoxOnUnprocessed({ capability, smallComment: lastSyncTimeMessage })
  }

  const isFirstSyncFailedWithUnknownError = (usageOverallStatus === SYNC_STATUS.FINISHED_SUCCESSFULLY && !lastSyncTime)
  if (isFirstSyncFailedWithUnknownError) {
    return renderInfoBoxOnUnprocessed({ capability, smallComment: buildFirstSyncMsg(connectedAt) })
  }

  const errorMessagesFromAllResources = statusByResource.reduce((result, resourceStatus) => {
    const errorMessage = getErrorMessageLogic(resourceStatus.status.errorMessage, resourceStatus.status.syncError)
    errorMessage && result.add(errorMessage)
    return result
  }, new Set<string>())

  const additionalInfoText = Array.from(errorMessagesFromAllResources)

  if (shouldRenderUsageAsUnprocessed({ capability, usageOverallStatus, statusByResource })) {
    return renderInfoBoxOnUnprocessed({ capability, additionalInfoText })
  }

  if (usageOverallStatus === SYNC_STATUS.FINISHED_SUCCESSFULLY) {
    return renderInfoBoxOnSuccess({ capability, smallComment: lastSyncTimeMessage, additionalInfoText })
  }

  if (usageOverallStatus === SYNC_STATUS.FINISHED_PARTIALLY) {
    return renderInfoBoxOnWarning({ capability, additionalInfoText: [`${capability} data fetched partially`, ...additionalInfoText], smallComment: lastSyncTimeMessage })
  }

  return renderInfoBoxOnFailure({ capability, additionalInfoText, smallComment: lastSyncTimeMessage, emphasizeError: false })
}

export const renderInfoBoxForRealTimeEvents = ({
  status,
  isMandatoryDataFailed
}: {
  status: Status,
  isMandatoryDataFailed: boolean,
  }): ReactElement => {
  const capability = CAPABILITY_TO_NAME.realTimeEvents
  const { lastSyncTime } = status
  const lastSyncTimeMessage = lastSyncTime
    ? `Last event received ${getTimePassedText({ date: lastSyncTime, smallestUnit: 'minutes' })}`
    : 'Never synced'

  if (shouldRenderAsUnprocessed(capability, isMandatoryDataFailed)) {
    return renderInfoBoxOnUnprocessed({ capability, smallComment: lastSyncTimeMessage })
  }

  if (status.statusToShow === SYNC_STATUS.FINISHED_SUCCESSFULLY) {
    return renderInfoBoxOnSuccess({ capability, smallComment: lastSyncTimeMessage })
  }

  const suffixErrorMessage = 'User lifecycle attributes are not updated in real time'
  const additionalInfoText = [status.errorMessage ? `${status.errorMessage}. ${suffixErrorMessage}` : suffixErrorMessage]

  return renderInfoBoxOnFailure({
    capability,
    additionalInfoText,
    smallComment: lastSyncTimeMessage,
    emphasizeError: false
  })
}

const renderInfoBoxOnSuccess = ({ capability, smallComment, additionalInfoText }: { capability: string, smallComment: string, additionalInfoText?: string[] }): JSX.Element => (
  <DataInfoBox
    key={capability}
    status={StatusIcon.statuses.SUCCESS}
    dataDisplayName={capability}
    additionalInfoText={additionalInfoText}
    smallComment={smallComment}
  />)

const renderInfoBoxOnFailure = ({ capability, additionalInfoText, smallComment, emphasizeError }: { capability: string, additionalInfoText: string[], smallComment: string, emphasizeError: boolean }): JSX.Element => (
  <DataInfoBox
    key={capability}
    status={StatusIcon.statuses.FAILURE}
    dataDisplayName={capability}
    additionalInfoText={additionalInfoText}
    smallComment={smallComment}
    emphasizeError={emphasizeError}
  />)

const renderInfoBoxOnWarning = ({ capability, additionalInfoText, smallComment }: { capability: string, additionalInfoText: string[], smallComment: string }): JSX.Element => (
  <DataInfoBox
    key={capability}
    status={StatusIcon.statuses.WARNING}
    dataDisplayName={capability}
    additionalInfoText={additionalInfoText}
    smallComment={smallComment}
  />)

const renderInfoBoxOnUnprocessed = ({ capability, smallComment, additionalInfoText }: { capability: string, smallComment?: string, additionalInfoText?: string[] }): JSX.Element => (
  <DataInfoBox
    key={capability}
    status={StatusIcon.statuses.UNPROCESSED}
    dataDisplayName={capability}
    smallComment={smallComment}
    additionalInfoText={additionalInfoText}
  />)
