import Confirmation from '@root/components/confirmation'
import EnableFor from '@root/components/enableFor'
import { TABLES } from '@root/constants'
import { Button, ButtonType } from '@toriihq/design-system'
import pluralize from 'pluralize'
import Analytics from '../../../helpers/analytics'
import { useState } from 'react'

const TableBulkDelete = ({ selectedItems, showDeleteButton, allowedScopes, tableKey, onDelete, itemsName, clearSelection }) => {
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)
  const showDeleteConfirmation = () => {
    Analytics.track('Clicked bulk delete', {
      'Table': (TABLES[tableKey] || {}).name
    })
    setIsConfirmDeleteOpen(true)
  }
  const closeDeleteConfirmation = () => setIsConfirmDeleteOpen(false)

  const onDeleteItems = async () => {
    Analytics.track('Bulk delete', {
      'Table': (TABLES[tableKey] || {}).name
    })
    await onDelete(selectedItems)
    clearSelection()
    closeDeleteConfirmation()
  }

  return (<>
    {showDeleteButton && <EnableFor scopes={allowedScopes}>
      <Button type={ButtonType.secondary} onClick={showDeleteConfirmation} label={`Delete ${pluralize(itemsName, selectedItems.length, true)}`} />
    </EnableFor>}
    <EnableFor scopes={allowedScopes}>
      <Confirmation
        isOpen={isConfirmDeleteOpen}
        header={`Delete ${pluralize(itemsName, selectedItems.length, true)}?`}
        text={<div>The {pluralize(itemsName, selectedItems.length)} will be permanently removed.</div>}
        confirmText='Delete'
        declineText='Cancel'
        confirm={onDeleteItems}
        decline={closeDeleteConfirmation}
        close={closeDeleteConfirmation}
        mainButtonType={ButtonType.destructive}
      />
    </EnableFor>
  </>
  )
}

export default TableBulkDelete
