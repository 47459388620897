import React, { ReactElement } from 'react'
import OptionsRenderers from '@shared/optionRenderers'
import ToriiSelect from '@components/select'
import { DropdownOption } from './types'

export interface Props {
  field: {
    options?: DropdownOption[]
    optionsRenderer?: string
    clearable?: boolean
    loading?: boolean
  }
  input: {
    value?: DropdownOption[]
    onBlur: (value: any) => void
    onChange: (value: any) => void
    disabled?: boolean
  }
  overrideStyle: Partial<CSSStyleDeclaration>
}

export const DropdownMulti = ({
  field,
  input
}: Props): ReactElement => {
  const updatedSelectedOption = input.value
    ? input.value.map(inputValue => (field.options && field.options.find(option => option.value === inputValue.value)) || inputValue)
    : input.value
  const additionalProps = {
    optionRenderer: field.optionsRenderer ? OptionsRenderers[field.optionsRenderer] : undefined
  }

  return (
    <ToriiSelect
      options={field.options}
      clearable={Boolean(field.clearable)}
      value={updatedSelectedOption}
      onBlur={() => input.onBlur(input.value)}
      onChange={(selectedOptions) => {
        (selectedOptions as unknown[]).length === 0 ? input.onChange(null) : input.onChange(selectedOptions)
      }}
      isLoading={!input.disabled && field.loading && (!field.options || !field.options.length)}
      multi
      disabled={input.disabled}
      {...additionalProps}
    />
  )
}
