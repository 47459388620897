import React, { useCallback, useState } from 'react'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import {
  setRenewalForecastData,
  toggleAppAccountSelectPopup
} from '@actions/'
import { useDispatch, useSelector } from 'react-redux'
import { getAppAccountSelectPopup } from '@selectors/ui'
import Analytics from '@helpers/analytics'
import { Stack, Subtitle2 } from '@toriihq/design-system'
import ToriiSelect from '@components/select'
import { SYNC_TYPE } from '@root/constants'
import { useHistory } from 'react-router-dom'
import { getCurrentOrg } from '@selectors/org'
import { WIZARD_STEPS } from '@root/pages/renewalForecast/wizardSteps/types'

const AppAccountSelectPopup = (): JSX.Element => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { isOpen, appName, idApp, appAccounts } = useSelector(getAppAccountSelectPopup)
  const mainButtonText = 'Continue to audit'
  const cancelButtonText = 'Cancel'
  const idOrg = useSelector(getCurrentOrg)?.id

  const [selectedAppAccount, setSelectedAppAccount] = useState(appAccounts?.[0] || {})

  const closePopup = () => {
    dispatch(toggleAppAccountSelectPopup({ isOpen: false }))
  }

  const trackButtonClick = (buttonLabel: string) => {
    Analytics.track('Click on button', {
      'Dialog name': 'AppAccountSelectPopup',
      'Button label': buttonLabel
    })
  }
  const onCancel = () => {
    trackButtonClick(cancelButtonText)
    closePopup()
  }

  const onContinue = async () => {
    trackButtonClick(mainButtonText)
    dispatch(setRenewalForecastData({ idApp, idAppAccount: selectedAppAccount.idAppAccount }))
    history.push(`/team/${idOrg}/app/${idApp}/account/${selectedAppAccount.idAppAccount}/renewalForecast/${WIZARD_STEPS.USER_REVIEW}`)
    closePopup()
  }

  const getAppAccountWithType = useCallback((appAccount) => {
    const { appAccountName, source } = appAccount
    if (appAccountName) {
      return appAccountName + (source === SYNC_TYPE.MANUAL ? ' (Manual)' : ' (Integrated)')
    } else {
      return `${appName} imported account (Manual)`
    }
  }, [appName])

  const optionRenderer = (props) => {
    const option = props.data
    return getAppAccountWithType(option)
  }

  return (
    <ToriiPopup
      isOpen={isOpen}
      onCloseAction={onCancel}
      styles={{ modal: { width: '518px' } }}>
      <ToriiPopup.Header
        header={`Select the account for your forecast`}
      />
      <ToriiPopup.Content>
        <Stack gap='space-050'>
          <Subtitle2>Select account</Subtitle2>
          <ToriiSelect
            options={appAccounts}
            valueRenderer={optionRenderer}
            optionRenderer={optionRenderer}
            valueKey='idAppAccount'
            labelKey='appAccountName'
            value={selectedAppAccount}
            onChange={setSelectedAppAccount} />
        </Stack>
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        showCancelButton
        cancelButtonAction={onCancel}
        cancelButtonText={cancelButtonText}
        mainButtonAction={onContinue}
        mainButtonText={mainButtonText}
      />
    </ToriiPopup>
  )
}

export default AppAccountSelectPopup
