import { WORKFLOW_OUTPUT_FIELD_TYPES } from '@root/constants'
import { BaseTag } from '@shared/workflows/types'
import { capitalize, partition } from 'lodash'
import { RestoreMetadata } from '@components/auditLogs/auditLog.types'
import { getFormattedDate } from '@lenses/utils'
import {
  getWorkflowExecutions
} from '@selectors/workflows'
import { IdWorkflow, IdWorkflowExecution } from '@store/types'

const FIELD_TYPE_TO_LABEL = {
  [WORKFLOW_OUTPUT_FIELD_TYPES.APP]: 'Application',
  [WORKFLOW_OUTPUT_FIELD_TYPES.USER]: 'User'
}

export const mapOutputFieldToBranchingEntity = (outputField: any): { label: string, value: string } => {
  const { type } = outputField

  return {
    label: FIELD_TYPE_TO_LABEL[type] || capitalize(type),
    value: type
  }
}

export enum WORKFLOW_EDIT_HISTORY_TYPE {
  CREATE = 'create',
  UPDATE = 'update',
  ENABLE = 'enable',
  DISABLE = 'disable',
  RESTORE = 'restore'
}

export const ACTION_DESCRIPTION_BY_EDIT_HISTORY_TYPE: Record<string, (metadata? : RestoreMetadata | null) => string> = {
  [WORKFLOW_EDIT_HISTORY_TYPE.CREATE]: () => 'Created',
  [WORKFLOW_EDIT_HISTORY_TYPE.UPDATE]: () => 'Updated',
  [WORKFLOW_EDIT_HISTORY_TYPE.ENABLE]: () => 'Activated',
  [WORKFLOW_EDIT_HISTORY_TYPE.DISABLE]: () => 'Deactivated',
  [WORKFLOW_EDIT_HISTORY_TYPE.RESTORE]: (metadata) => `Restored version from ${getFormattedDate({ date: metadata?.restoredFrom?.editTime, includeTime: true })}`
}

export const getSortedTags = (tags: BaseTag[]): BaseTag[] => {
  const [orgCustomTags, predefinedTags] = partition(tags, tag => tag.idOrg)
  orgCustomTags.sort((a, b) => a.id - b.id)
  predefinedTags.sort((a, b) => a.label.localeCompare(b.label))

  return [...predefinedTags, ...orgCustomTags]
}

export const getWorkflowExecutionsByIdWorkflow = (
  state: any,
  idWorkflow: IdWorkflow,
  idWorkflowExecution: IdWorkflowExecution
): any => {
  const executions: Record<string, any[] | null> = getWorkflowExecutions(state) as unknown as Record<string, any>
  const workflowExecutions = executions[idWorkflow] || []
  const currentExecution = workflowExecutions.find(execution => execution.id === idWorkflowExecution)
  return currentExecution ?? {}
}
