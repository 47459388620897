import { TooltipContainer } from '@components/dashboard/widgets/pieChartWidget/PieChartWidgetTooltip/styles'
import { Body2, Subtitle2 } from '@toriihq/design-system'
import React, { ReactElement } from 'react'

type Payload = { name: string, value: number, color: string }
type Props = {
    payload: Array<{ payload: Payload }> | undefined,
    active: boolean | undefined,
    valueDisplayFunction: (value: any, payload: any | undefined) => any
}

const PieChartWidgetTooltip = ({ payload, active, valueDisplayFunction }: Props): ReactElement | null => {
  if (!active || !payload) {
    return null
  }

  const data = payload[0].payload
  const { name, value } = data

  return (
    <TooltipContainer>
      <Subtitle2 color={'primary'}>{name}</Subtitle2>
      <Body2 color={'primary'}>{!value ? 'Missing data' : valueDisplayFunction(value, data)}</Body2>
    </TooltipContainer>
  )
}

export default PieChartWidgetTooltip
