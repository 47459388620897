import React, { ReactElement } from 'react'
import { ActionsLine, Container, HeaderLine, HeaderSide, WorkflowNameHeaderContainer } from './styles'
import { BackButton } from './backButton'
import { WorkflowNameHeader } from './workflowNameHeader'
import { ActiveWorkflowToggle } from './activeWorkflowToggle'
import { WorkflowActionsButtons } from './workflowActionsButtons'
import { NavToExecutionLog } from './navToExecutionLog'
import { WorkflowTagsContainer } from './workflowTags'
import { ThrottledInfo } from './throttledInfo'

export const WorkflowHeader = (): ReactElement => {
  return (<Container>
    <HeaderLine>
      <BackButton />
      <WorkflowNameHeaderContainer>
        <WorkflowNameHeader />
      </WorkflowNameHeaderContainer>
      <HeaderSide>
        <ActiveWorkflowToggle />
      </HeaderSide>
    </HeaderLine>
    <ActionsLine>
      <WorkflowTagsContainer />
      <NavToExecutionLog />
      <WorkflowActionsButtons />
    </ActionsLine>
    <ThrottledInfo />
  </Container>)
}
