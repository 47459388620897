import React, { ChangeEvent, FocusEvent, ReactElement } from 'react'
import { RadioButton } from '@toriihq/design-system'
import {
  Container,
  INPUT_ERROR_CLASS_NAME,
  RadioButtonsContainer
} from './styles'
import EnableFor from '@components/enableFor'
import { SCOPES } from '@root/constants'
import { Dropdown } from '../dropdown'
import { DropdownMulti } from '../dropdownMulti'
import { InputComponent } from '../../InputComponent'
import { exhaustiveCheck } from '@shared/utils'
import { MultiValueProps, SingleValueProps } from './types'
import { getOverrideValue, isOverrideInput } from './utils'
import pluralize from 'pluralize'

type Props = SingleValueProps | MultiValueProps

export const OverridableDropdown = ({
  field,
  input,
  overrideStyle,
  useSelectState,
  dropdownType
}: Props): ReactElement => {
  const { disabled, name } = input
  const { errorMsg } = field
  const overrideLabel = `Enter ${pluralize.singular(name)} ID${dropdownType === 'multi' ? 's' : ''}`
  const isOverride = isOverrideInput(input)
  const errorsToShow = [
    'ID format',
    'this account.',
    'You cannot add more than'
  ]
  const shouldShowError = errorMsg && errorsToShow.some(error => errorMsg.includes(error))

  let component: ReactElement

  if (isOverride) {
    const placeholder = `${overrideLabel}...`
    const { value, onChange, onBlur } = input

    const handleChange = (e: ChangeEvent<HTMLInputElement>, locallyOnly: boolean) => {
      onChange(getOverrideValue(e.target.value), locallyOnly)
    }

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
      onBlur(getOverrideValue(e.target.value))
    }

    component = <>
      <InputComponent
        input={{
          ...input,
          value: value?.value,
          onChange: handleChange,
          onBlur: handleBlur,
          className: shouldShowError ? INPUT_ERROR_CLASS_NAME : ''
        }}
        field={{ placeholder }}
      />
    </>
  } else if (dropdownType === 'multi') {
    component = <DropdownMulti
      field={field}
      input={input}
      overrideStyle={overrideStyle}
    />
  } else if (dropdownType === 'single') {
    component = <Dropdown
      field={field}
      input={input}
      useSelectState={useSelectState}
    />
  } else {
    exhaustiveCheck(dropdownType)
    throw new Error(`Unknown dropdownType: ${dropdownType}`)
  }

  const OverrideRadioButton = ({ label, checked, onChange }) => (
    <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
      <RadioButton
        label={label}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
    </EnableFor>
  )

  const disableOverride = () => {
    const defaultValue = dropdownType === 'multi' ? [] : null
    input.onChange(defaultValue)
  }

  const enableOverride = () => {
    input.onChange(getOverrideValue(''))
  }

  return (<Container>
    <RadioButtonsContainer>
      <OverrideRadioButton
        label='Select from the list'
        checked={!isOverride}
        onChange={disableOverride}
      />
      <OverrideRadioButton
        label={overrideLabel}
        checked={isOverride}
        onChange={enableOverride}
      />
    </RadioButtonsContainer>
    {component}
  </Container>)
}
