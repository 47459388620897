import React from 'react'
import PropTypes from 'prop-types'
import { TextBadge } from '@toriihq/design-system'

const EXECUTION_STATUSES = {
  completed: 'Completed',
  completedWithError: 'Completed with errors',
  inProgress: 'In Progress',
  error: 'Error'
}

const getActionExecutionStatus = (completionTime, hasError) => {
  return completionTime
    ? (hasError ? EXECUTION_STATUSES.completedWithError : EXECUTION_STATUSES.completed)
    : (hasError ? EXECUTION_STATUSES.error : EXECUTION_STATUSES.inProgress)
}

const ActionExecutionStatus = ({
  completionTime,
  hasError
}) => {
  const color = completionTime ? (hasError ? 'warning' : 'success') : (hasError ? 'error' : 'blue')
  const text = getActionExecutionStatus(completionTime, hasError)

  return <TextBadge color={color} size='Small'>{text}</TextBadge>
}

ActionExecutionStatus.propTypes = {
  completionTime: PropTypes.any,
  hasError: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
}
export default ActionExecutionStatus
