import View from './view'
import { connect } from 'react-redux'
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  formFieldTypes,
  SCOPES,
  TABLES
} from '@root/constants'
import { getUserPreferences } from '@selectors/ui'
import { withRouter } from 'react-router-dom'
import { getCurrentUsers, getCurrentUsersFieldValues, isParentUserFilter, toggleShareReportPopup } from '@actions/'
import { getUserTablesInfo } from '@selectors/tables'
import { getPastUserConfigurableColumnsOptions, getUserConfigurableColumnsOptions } from '@selectors/userDetails'
import { getCompanyName, getCurrentOrg } from '@selectors/org'
import { getCurrentUsersES, getCurrentUsersFieldValues as getCurrentUsersFieldValuesSelector } from '@selectors/users'
import { ops } from '@lenses/filters'
import AccessControl from '@lenses/accessControl'
import { filterOutNotAccessableColumns } from '../columns'

export const getCustomSelectOptions = () => {
  const isEmployeeFilter = { op: { label: 'is', value: ops.equals, type: formFieldTypes.dropdown }, key: { label: 'User Type', value: 'userType', type: 'text' }, value: { label: 'employee', value: 'employee' } }
  return [
    {
      value: 'currentEmployees',
      label: 'Current Employees',
      filters: [
        { key: 'isDeletedInIdentitySources', op: ops.equals, value: false },
        isEmployeeFilter,
        isParentUserFilter
      ]
    },
    {
      value: 'pastEmployees',
      label: 'Past Employees',
      filters: [
        { key: 'isDeletedInIdentitySources', op: ops.equals, value: true },
        isEmployeeFilter,
        isParentUserFilter
      ]
    },
    {
      value: 'allEmployees',
      label: 'All Employees',
      filters: [
        isEmployeeFilter,
        isParentUserFilter
      ]
    }
  ]
}

const filterUserTypeColumn = (columns) => columns.filter(col => col.value !== 'userType')

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const companyName = getCompanyName(state)
  const { domain } = getCurrentOrg(state)
  const tableInfos = getUserTablesInfo(state)
  const tableInfo = tableInfos[TABLES.usersTable.key]
  const configurableColumnsOptions = filterUserTypeColumn(getUserConfigurableColumnsOptions(state))
  const configurablePastColumnsOptions = filterUserTypeColumn(getPastUserConfigurableColumnsOptions(state))
  const currentUsers = getCurrentUsersES(state)
  const userPreferences = getUserPreferences(state)
  const { defaultSort = EMPTY_ARRAY } = userPreferences[TABLES.usersTable.key] || EMPTY_OBJECT
  const fieldsValues = getCurrentUsersFieldValuesSelector(state)
  const customSelectOptions = getCustomSelectOptions()
  const hasLicenseCostAndChargebackAccess = AccessControl.isAccessibleWithState({ scopes: [SCOPES.LICENSE_AND_CHARGEBACK_READ], state })
  const columnsRestrictions = { hasLicenseCostAndChargebackAccess }

  return {
    idOrg,
    companyName,
    domain,
    tableInfo,
    defaultSort,
    configurableColumnsOptions: filterOutNotAccessableColumns({ columns: configurableColumnsOptions, columnsRestrictions }),
    configurablePastColumnsOptions: filterOutNotAccessableColumns({ columns: configurablePastColumnsOptions, columnsRestrictions }),
    users: currentUsers.users,
    loading: currentUsers.loading,
    loadingMore: currentUsers.loadingMore,
    fieldsValues,
    total: currentUsers.total,
    defaultCustomSelectOption: customSelectOptions[0],
    customSelectOptions,
    tableInfos,
    columnsRestrictions
  }
}

const mapDispatchToProps = {
  getCurrentUsers,
  getCurrentUsersFieldValues,
  toggleShareReportPopup
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
