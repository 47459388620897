import AppIconWithBadge from '@root/components/appIconWithBadge'
import { Body2, H3, Stack } from '@toriihq/design-system'
import { HeaderProps } from './types'
import { WIZARD_STEPS } from '../types'
import currentOption1 from '@media/current_option1.svg'
import currentOption2 from '@media/current_option2.svg'
import currentOption3 from '@media/current_option3.svg'
import completed from '@media/completed.svg'
import notStartedOption2 from '@media/not_started_option2.svg'
import notStartedOption3 from '@media/not_started_option3.svg'
import { STEPS_NAMES } from '../config'
import { Container } from '@pages/renewalForecast/wizardSteps/header/style'
import BackLink from '@components/backLink'

const Header = ({ accountName, currentStep, app, onBackClick }: HeaderProps) => {
  const { name: appName, imageUrl, isCustom } = app

  const getSvg = (indexOfStep: number, i: number) => {
    if (indexOfStep > i) {
      return completed
    } else if (indexOfStep === i) {
      switch (i) {
        case 0:
          return currentOption1
        case 1:
          return currentOption2
        default:
          return currentOption3
      }
    } else {
      return i === 1 ? notStartedOption2 : notStartedOption3
    }
  }

  const StepsIndicator = () => (
    <Stack gap={'space-200'} direction={'row'} alignItems='flex-start'>
      {Object.values(WIZARD_STEPS).map((step, i) => {
        const indexOfStep = Object.values(WIZARD_STEPS).indexOf(currentStep)
        const svg = getSvg(indexOfStep, i)
        const stepName = STEPS_NAMES[step]
        return <Stack gap={'space-100'} direction={'row'}>
          <img alt='step icon' src={svg} />
          <Body2>
            {stepName}
          </Body2>
        </Stack>
      })}
    </Stack>
  )

  return (
    <Container>
      <Stack direction='column' gap='space-300'>
        <Stack gap={'space-600'} direction={'row'} justifyContent={'space-between'} >
          <BackLink linkText='Back to application' onClick={onBackClick} overrideStyle={{ padding: 0 }} />
          <StepsIndicator />
        </Stack>
        <Stack gap={'space-300'} direction={'row'}>
          <AppIconWithBadge name={appName} src={imageUrl} badge={Boolean(isCustom) && 'CUSTOM'} size='x-large' />
          <Stack gap={'space-025'} direction={'column'} alignItems={'start'} >
            <H3>
              {appName} Renewal Forecast
            </H3>
            <Body2 color={'tertiary'}>
              {accountName}
            </Body2>
          </Stack>
        </Stack>
      </Stack>
    </Container>)
}

export default Header
