import analytics from '@root/helpers/analytics'

export enum ENTITY_TYPE {
  DRAWER = 'Drawer',
  POPUP = 'Popup'
}

type DashboardAnalyticsParams = {
  dashboardName: string
}

type WidgetAnalyticsParams = DashboardAnalyticsParams & {
  widgetTitle?: string
  fieldName?: string
  entityType?: ENTITY_TYPE
}

const Analytics = {
  clickOnEditButton: ({ dashboardName }: DashboardAnalyticsParams) => analytics.track('Click on edit dashboard', { 'Dashboard name': dashboardName }),
  clickOnCancelDashboardChanges: ({ dashboardName }: DashboardAnalyticsParams) => analytics.track('Click on cancel dashboard changes', { 'Dashboard name': dashboardName }),
  clickOnSaveDashboardChanges: ({ dashboardName }: DashboardAnalyticsParams) => analytics.track('Save dashboard changes', { 'Dashboard name': dashboardName }),
  clickOnDrillDown: ({ dashboardName, widgetTitle }: WidgetAnalyticsParams) => analytics.track('Click on widget drill down', { 'Dashboard name': dashboardName, 'Widget title': widgetTitle }),
  resizeWidget: ({ dashboardName, widgetTitle }: WidgetAnalyticsParams) => analytics.track('Resize widget', { 'Dashboard name': dashboardName, 'Widget title': widgetTitle }),
  dragWidget: ({ dashboardName, widgetTitle }: WidgetAnalyticsParams) => analytics.track('Drag widget', { 'Dashboard name': dashboardName, 'Widget title': widgetTitle }),
  updateWidgetConfiguration: ({ dashboardName, widgetTitle, fieldName, entityType }: WidgetAnalyticsParams) => analytics.track('Update widget configuration', { 'Dashboard name': dashboardName, 'Widget title': widgetTitle, 'Field name': fieldName, 'Entity type': entityType }),
  clickOnExportCSV: ({ dashboardName, widgetTitle }: WidgetAnalyticsParams) => analytics.track('Click on CSV download', { 'Dashboard name': dashboardName, 'Widget title': widgetTitle })
}

export default Analytics
