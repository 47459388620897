import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Location from 'react-app-location'
import * as Yup from 'yup'
import SignupPage from '../../pages/signup'
import EmailVerification from '../../pages/emailVerification'
import Validation from '../../pages/validation'
import LoginApp from '../../pages/login'
import ServicesPage from '../../pages/services'
import SettingsPage from '../../pages/settings'
import AppPage from '../../pages/app'
import UserPage from '../../pages/user'
import NoPermissionsPage from '../../pages/noPermissions'
import InsightsPage from '../../pages/insights'
import AppCatalogPage from '../../pages/appCatalog'
import ApplicationsPageV2 from '../../pages/applicationsV2'
import UsersPage from '../../pages/users'
import ReportsPage from '../../pages/reports'
import ReportPage from '../../pages/report'
import ExpensesPage from '../../pages/expenses'
import ExpenseUploadPage from '../../pages/expenseUploadPage'
import ContractsRenewalsPage from '../../pages/contractsRenewals'
import AuthenticatedRoute from '../authenticatedRoute'
import TeamFallbackRoute from '../teamFallbackRoute'
import InvalidUrlPage from '../../pages/invalidUrl'
import LicensesPage from '../../pages/licenses'
import WorkflowsPage from '../../pages/workflows'
import WorkflowExecutionsPage from '../../pages/workflowExecutions'
import WorkflowActionExecutionsPage from '../../pages/workflowActionExecutions'
import NotFound from '../notFound'
import Popups from '../popups/popupsContainer'
import WorkflowFormPage from '../../pages/workflowForm'
import WorkflowRequestApprovalPage from '../../pages/workflowRequestApproval'
import OffboardingSettingsPage from '../../pages/offboardingSettings'
import OffboardingPage from '../../pages/offboarding'
import ConnectIntegrationPage from '../../pages/connectIntegration'
import WorkflowsTemplatesPage from '../../pages/workflowsTemplates'
import UserOffboardingTasksPage from '../../pages/offboardingTasks'
import ThirdPartyReport from '../thirdPartyReport'
import NotificationsPage from '../../pages/notifications'
import InstantAccessLogin from '../../pages/instantAccessLogin'
import ExtensionLogin from '../../pages/extensionLogin'
import WorkflowsSettingsPage from '@pages/workflowsSettings'
import ErrorBoundary from '@components/errorBoundary'
import { ERROR_TYPES } from '@components/errorBoundary/view'
import Zendesk from '@components/zendesk'
import RecommendationPage from '@root/pages/recommendation'
import { SCOPES } from '@root/constants'
import AppCatalogPolicyExecutionsPage from '@pages/appCatalogPolicyExecutions'
import AppCatalogPolicyActionExecutionsPage from '@pages/appCatalogPolicyActionExecutions'
import IntegrationsTable from '@components/services/components/integrationsTable'
import WorkflowV2Page from '@pages/workflow_v2'
import AppCatalogPolicyV2Page from '@pages/appCatalogPolicy_v2'
import PluginMarketplacePage from '@pages/pluginMarketplace'
import PluginPage from '@pages/pluginMarketplace/components/pluginPage'
import { FEATURES } from '@shared/features'
import config from '@root/config'
import CreateWorkflowPage from '@pages/createWorkflow'
import UnmatchedContractsPage from '@root/pages/unmatchedContracts'
import ConnectServicesSignupStep from '@components/signup/connectServicesSignupStep'
import Churnzero from '@components/churnzero'
import WorkflowsExecutionsAuditPage from '@pages/workflowsExecutionsAudit'
import AppCatalogNewAppPolicyActionExecutionsPage from '@root/pages/appCatalogNewAppPolicyActionExecutions'
import AppCatalogNewAppPolicyExecutionsPage from '@root/pages/appCatalogNewAppPolicyExecutions'
import AppCatalogNewAppPolicyV2Page from '@root/pages/appCatalogNewAppPolicy_v2'
import RenewalForecast from '@pages/renewalForecast'
import DashboardsPage from '@pages/dashboards'

const WorkflowRequestApprovalLocation = new Location(
  '/team/:idOrg/requestApproval/:response/:idActionExe/token/:id/:secret',
  {
    idOrg: Yup.number().integer().required(),
    response: Yup.string().matches(/(approve|decline)/).required(),
    idActionExe: Yup.number().integer().required(),
    id: Yup.number().integer().required(),
    secret: Yup.string().required()
  })

class App extends Component {
  render () {
    const previewId = parseInt(new URLSearchParams(window.location.search).get('previewId'))
    const errorBoundaryType = previewId ? ERROR_TYPES.WORKFLOW_PREVIEW : ERROR_TYPES.DEFAULT

    return (
      <ErrorBoundary type={errorBoundaryType}>
        <Popups />
        <Switch>
          <Route path='/signup/check_your_email/:email' component={EmailVerification} />
          <Route path='/signup/validate/:id/:key' component={Validation} />
          <Route path='/signup/:step/:id/:key' component={SignupPage} />
          <Route path='/signup/check_your_email/:email' component={SignupPage} />
          <AuthenticatedRoute path='/team/:idOrg/signup/connectIntegrationStep' component={ConnectServicesSignupStep} unauthorizedComponent={NoPermissionsPage} withoutLayout />

          <Route path='/login' component={LoginApp} />
          <Route path='/instantAccess/login/token/:id/:secret' component={InstantAccessLogin} />
          <Route path='/team/:idOrg/welcome' render={() => <Redirect to='/team/insights' />} />
          <Route path='/team/:idOrg/reports/3rdParty' render={(props) => <Redirect to={`/team/${props.match.params.idOrg}/security`} />} />
          <Route path='/team/:idOrg/users/:idUser/offboardingTasks/token/:id/:secret' component={UserOffboardingTasksPage} />
          <Route path='/team/:idOrg/removeApp/:idApp/user/:idUser/token/:id/:secret' component={InvalidUrlPage} />
          <Route path='/team/:idOrg/form/:idActionExe/token/:id/:secret' component={WorkflowFormPage} />
          <Route path='/team/:idOrg/services/:idService/connect/:id/:secret' component={ConnectIntegrationPage} />
          {WorkflowRequestApprovalLocation.toRoute({ component: WorkflowRequestApprovalPage, invalid: NotFound }, true)}

          <AuthenticatedRoute path='/team/settings/users' component={TeamFallbackRoute} unauthorizedComponent={NoPermissionsPage} fallback />
          <AuthenticatedRoute path='/team/offboarding/settings' component={TeamFallbackRoute} unauthorizedComponent={NoPermissionsPage} fallback />
          <AuthenticatedRoute path='/team/settings/security' component={TeamFallbackRoute} unauthorizedComponent={NoPermissionsPage} fallback />
          <AuthenticatedRoute path='/team/workflows/settings' component={TeamFallbackRoute} unauthorizedComponent={NoPermissionsPage} fallback />

          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/insights' component={InsightsPage} feature={FEATURES.INSIGHTS.PAGE} />
          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/dashboards' component={DashboardsPage} accessScopes={[SCOPES.DASHBOARDS_READ]} />
          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/app/:idApp/recommendation/:idRecommendation' component={RecommendationPage} feature={FEATURES.APPLICATION.TABS.RECOMMENDATIONS} />
          <AuthenticatedRoute adminAndAppOwner unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/app/:idApp/account/:idAppAccount/renewalForecast/:step' component={RenewalForecast} feature={FEATURES.LICENSES.COMPONENTS.LICENSE_AUDIT} />
          <AuthenticatedRoute adminAndAppOwner unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/app/:idApp/:tabName?/:idContract?' component={AppPage} accessScopes={[SCOPES.APPLICATIONS_READ, SCOPES.APP_OWNER_READ]} feature={FEATURES.APPLICATION.PAGE} />
          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/user/:idUser/:tabName?' component={UserPage} accessScopes={[SCOPES.USERS_READ]} feature={FEATURES.USER.PAGE} />
          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/services/capabilities' component={IntegrationsTable} feature={FEATURES.INTEGRATIONS.PAGE} />
          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/services/:tabName?' component={ServicesPage} feature={FEATURES.INTEGRATIONS.PAGE} />
          <AuthenticatedRoute adminAndAppOwner unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/settings/notifications' component={NotificationsPage} accessScopes={[SCOPES.BASIC_ADMIN, SCOPES.APP_OWNER_READ]} feature={FEATURES.SETTINGS.PAGE} />
          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/settings/:tabName?' component={SettingsPage} feature={FEATURES.SETTINGS.PAGE} />
          <AuthenticatedRoute adminAndAppOwner unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/applications/:tabName?' component={ApplicationsPageV2} accessScopes={[SCOPES.APPLICATIONS_READ, SCOPES.APP_OWNER_READ]} feature={FEATURES.APPLICATIONS.PAGE} />
          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/expenses/uploads/:idUpload' component={ExpenseUploadPage} accessScopes={[SCOPES.EXPENSE_READ]} feature={FEATURES.EXPENSES.PAGE} />
          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/expenses/:tabName?/:year?/:month?' component={ExpensesPage} accessScopes={[SCOPES.EXPENSE_READ]} feature={FEATURES.EXPENSES.PAGE} />
          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/security' component={ThirdPartyReport} feature={FEATURES.SECURITY.PAGE} />

          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/reports/reports/:type' component={ReportPage} feature={FEATURES.REPORTS.PAGE} />
          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/reports/:tabName?' component={ReportsPage} feature={FEATURES.REPORTS.PAGE} />

          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/renewals/unmatchedContracts/:idContract?' component={UnmatchedContractsPage} accessScopes={[SCOPES.CONTRACTS_READ]} feature={FEATURES.RENEWALS.PAGE} />
          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/renewals/:tabName?/:idContract?' component={ContractsRenewalsPage} accessScopes={[SCOPES.CONTRACTS_READ]} feature={FEATURES.RENEWALS.PAGE} />
          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/users/:tabName?' component={UsersPage} accessScopes={[SCOPES.USERS_READ]} feature={FEATURES.USERS.PAGE} />
          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/licenses/recommendation/:idRecommendation' component={RecommendationPage} feature={FEATURES.LICENSES.TABS.RECOMMENDATIONS} />
          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/licenses/:tabName?' component={LicensesPage} feature={FEATURES.LICENSES.PAGE} />

          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/workflows/template' component={WorkflowsTemplatesPage} accessScopes={[SCOPES.AUTOMATION_WRITE]} feature={FEATURES.WORKFLOWS.PAGE} />
          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/workflows/new' component={CreateWorkflowPage} accessScopes={[SCOPES.AUTOMATION_WRITE]} feature={FEATURES.WORKFLOWS.PAGE} />
          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/workflows/actions/audit' component={WorkflowsExecutionsAuditPage} accessScopes={[SCOPES.AUTOMATION_READ]} feature={FEATURES.WORKFLOWS.PAGE} />
          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/workflows/settings' component={WorkflowsSettingsPage} accessScopes={[SCOPES.AUTOMATION_READ]} feature={FEATURES.WORKFLOWS.PAGE} />
          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/workflows' component={WorkflowsPage} accessScopes={[SCOPES.AUTOMATION_READ]} feature={FEATURES.WORKFLOWS.PAGE} />

          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/workflow/:idWorkflow/executions/:idWorkflowExecution' component={WorkflowActionExecutionsPage} accessScopes={[SCOPES.AUTOMATION_READ]} feature={FEATURES.WORKFLOWS.PAGE} />
          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/workflow/:idWorkflow/executions' component={WorkflowExecutionsPage} accessScopes={[SCOPES.AUTOMATION_READ]} feature={FEATURES.WORKFLOWS.PAGE} />
          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/workflow/:idWorkflow/:idSelectedNode?/:stepIndex?' component={WorkflowV2Page} accessScopes={[SCOPES.AUTOMATION_READ]} feature={FEATURES.WORKFLOWS.PAGE} />

          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/appCatalog/accessPolicy/:idWorkflow/executions/:idWorkflowExecution' component={AppCatalogPolicyActionExecutionsPage} accessScopes={[SCOPES.AUTOMATION_READ]} feature={FEATURES.APP_CATALOG.PAGE} />
          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/appCatalog/accessPolicy/:idWorkflow/executions' component={AppCatalogPolicyExecutionsPage} accessScopes={[SCOPES.AUTOMATION_READ]} feature={FEATURES.APP_CATALOG.PAGE} />
          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/appCatalog/accessPolicy/:idWorkflow/:idSelectedNode?/:stepIndex?' component={AppCatalogPolicyV2Page} accessScopes={[SCOPES.AUTOMATION_READ]} feature={FEATURES.APP_CATALOG.PAGE} />

          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/appCatalog/newAppPolicy/:idWorkflow/executions/:idWorkflowExecution' component={AppCatalogNewAppPolicyActionExecutionsPage} accessScopes={[SCOPES.AUTOMATION_READ]} feature={FEATURES.APP_CATALOG.PAGE} />
          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/appCatalog/newAppPolicy/:idWorkflow/executions' component={AppCatalogNewAppPolicyExecutionsPage} accessScopes={[SCOPES.AUTOMATION_READ]} feature={FEATURES.APP_CATALOG.PAGE} />
          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/appCatalog/newAppPolicy/:idWorkflow/:idSelectedNode?/:stepIndex?' component={AppCatalogNewAppPolicyV2Page} accessScopes={[SCOPES.AUTOMATION_READ]} feature={FEATURES.APP_CATALOG.PAGE} />

          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/appCatalog/:tabName?' component={AppCatalogPage} accessScopes={[SCOPES.APPLICATION_CATALOG_READ]} feature={FEATURES.APP_CATALOG.PAGE} />
          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/formPreview' component={WorkflowFormPage} previewMode feature={FEATURES.WORKFLOWS.PAGE} />
          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/offboarding/settings' component={OffboardingSettingsPage} accessScopes={[SCOPES.OFFBOARDING_READ]} feature={FEATURES.OFFBOARDING.PAGE} />
          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/offboarding/:tabName?' component={OffboardingPage} accessScopes={[SCOPES.OFFBOARDING_READ]} feature={FEATURES.OFFBOARDING.PAGE} />
          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/plugins' component={PluginMarketplacePage} accessScopes={[SCOPES.BASIC_ADMIN]} feature={FEATURES.PLUGIN_MARKETPLACE.PAGE} />
          <AuthenticatedRoute adminOnly unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/plugin/:uuidPlugin/:tabName?' component={PluginPage} accessScopes={[SCOPES.BASIC_ADMIN]} feature={FEATURES.PLUGIN_MARKETPLACE.PAGE} />
          <AuthenticatedRoute unauthorizedComponent={NoPermissionsPage} path='/team/:idOrg/extensionLogin' component={ExtensionLogin} withoutLayout />
          <AuthenticatedRoute path='/team/:idOrg?' component={TeamFallbackRoute} unauthorizedComponent={NoPermissionsPage} fallback />

          <Route exact path='/' render={() => <Redirect to='/team' />} />
          <Route component={NotFound} />
        </Switch>
        {config?.churnzero?.enabled ? <Churnzero /> : null}
        {config?.zendesk?.enabled ? <Zendesk /> : null}
      </ErrorBoundary>
    )
  }
}

export default App
