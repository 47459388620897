import moment from 'moment'

export const emailValidator = value =>
  value && !/^\S+@\S+\.\S+/i.test(value)
    ? 'Invalid email address' : undefined

export const dateValidator = value => {
  const isValidDate = moment(value).isValid()
  return value && !isValidDate
    ? 'Invalid date format' : undefined
}

export const numberValidator = value => {
  return isNaN(value)
    ? 'Invalid number' : undefined
}
