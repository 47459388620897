import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getIdOrg } from '@selectors/org'
import { TABLES } from '@root/constants'
import { getWidgetTableKey } from '@components/dashboard/widgets/shared'
import {
  getContractFilterOptionsValuesPerKey,
  getContractsConfigurableColumnsOptions,
  getContractsFields,
  getContractsFieldsBySystemKey,
  getContractsTablesInfo,
  getContractStatusOptions,
  getContractStatusOptionsOrder
} from '@selectors/contracts'
import getContractColumns from '@components/contracts/columns'
import { InternalProps } from '../types'
import { getContracts, toggleContractPopup } from '@store/actions'
import config from '@root/config'
import { mapSortArray } from '@shared/utils'

const ContractDataPopup = (props: InternalProps): ReactElement => {
  const dispatch = useDispatch()
  const { renderFunc, widget, widgetData } = props

  const idOrg = useSelector(getIdOrg)

  const contractFields = useSelector(getContractsFields)
  const contractFieldsById = useSelector(getContractsFieldsBySystemKey)
  const contractStatusOptions = useSelector(getContractStatusOptions)
  const contractStatusOptionsOrder = useSelector(getContractStatusOptionsOrder)
  const contractFilterOptionsValuesPerKey = useSelector(getContractFilterOptionsValuesPerKey)
  const contractTableInfo = useSelector(getContractsTablesInfo)[TABLES.contractsTable.key]
  const contractConfigurableColumnsOptions = useSelector(getContractsConfigurableColumnsOptions)

  // TODO: move to dashboardPage OR better, have the contract field values come from the resources in widgets
  useEffect(() => {
    dispatch(getContracts({ idOrg }))
  }, [dispatch, idOrg])

  const columnsConfiguration = (widget.drillDownConfig.fields ?? []).reduce((acc, field) => {
    acc[field] = true
    return acc
  }, {})

  const configurableColumnsOptions = contractConfigurableColumnsOptions
  const filtersOptions = contractTableInfo.filtersOptions

  const fetchFieldValues = () => {}
  const fieldValues = contractFilterOptionsValuesPerKey
  const contractPreDefinedColumnsMapping = contractTableInfo.preDefinedColumnsMapping
  const dynamicColumnsInfo = contractConfigurableColumnsOptions.filter(a =>
    !contractTableInfo.specialColumnsInfo[a.value] &&
      !contractPreDefinedColumnsMapping[a.value] &&
      columnsConfiguration[a.value]
  ).map(column => ({ systemKey: column.value, key: column.value, name: column.label, ...column, ...contractFieldsById[column.value] }))

  const onEditContract = (idContract) => {
    dispatch(toggleContractPopup({ isDuplicated: false, idContract, isOpen: true } as any))
  }

  const columns = getContractColumns({
    contractStatusOptions,
    contractStatusOptionsOrder,
    usersById: widgetData.data.resources.users,
    appsById: widgetData.data.resources.apps,
    onEditContract,
    contractsById: widgetData.data.resources.contracts,
    onDuplicateContract: () => {},
    contractsFields: contractFields.map(field => ({ ...field, key: field.systemKey })),
    idOrg,
    idApp: null,
    onDeleteContract: () => {},
    columnsConfiguration,
    dynamicColumnsInfo,
    name: getWidgetTableKey(widget),
    preDefinedColumnsMapping: contractPreDefinedColumnsMapping,
    specialColumnsInfo: contractTableInfo.specialColumnsInfo,
    showActions: false
  }) as any

  const exportToCsv = ({ sort, query, filters }) => {
    const columnsOrder = widget.drillDownConfig.fields
    const columnsOrdered = [...columns].sort((a, b) => columnsOrder.indexOf(a.id || a.accessor) - columnsOrder.indexOf(b.id || b.accessor))
    const qFields = ['id', 'name', 'app.name']

    const sortParam = `sort=${mapSortArray(sort.map(s => s.id === 'idApp' ? { ...s, id: 'app.name' } : s)).join(',')}`
    const queryParam = `q=${query}`
    const fieldsParam = `fields=${columnsOrdered.filter(col => ((col.forceCSV || col.show || col.show === undefined) && !col.hideFromCSV)).map(col => col.id || col.accessor).join(',')}`
    const filtersParam = `filters=${encodeURIComponent(JSON.stringify(filters))}`
    const qFieldsParam = `qFields=${qFields.join(',')}`

    const url = `${config.apiBaseUrl}/api/orgs/${idOrg}/contracts/csv?${sortParam}&${queryParam}&${filtersParam}&${fieldsParam}&${qFieldsParam}`
    const newWindow = window.open(url, '_blank') as any
    newWindow.opener = null
  }

  return renderFunc({ columns, filtersOptions, fieldValues, configurableColumnsOptions, fetchFieldValues, exportToCsv })
}

export default ContractDataPopup
