import { getUploadsResources } from '@selectors/uploads'
import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { editTransaction, getUpload, getUploadTransactions, getSingleApp, toggleAddApplication, resetTransactions } from '@actions/'
import { EMPTY_ARRAY, TABLES } from '@root/constants'
import { getTransactionsByUpload, getTransactionsResources, getTotalTransactions } from '@selectors/transactions'
import { isAppsLoading } from '@selectors/apps'
import { getUserPreferences } from '@selectors/ui'

const mapStateToProps = (state, ownProps) => {
  const { loading: loadingUploads, uploads } = state.uploads
  const { loading: loadingTransactions, uploadCounter } = state.transactions
  const { idUpload } = ownProps

  const loadingApps = isAppsLoading(state)
  const transactionsByUpload = getTransactionsByUpload(state)
  const transactions = transactionsByUpload[idUpload] || EMPTY_ARRAY
  const totalTransactions = getTotalTransactions(state)
  const upload = uploads.find(upload => upload.id === idUpload)
  const { users: usersById, apps: appsById } = getTransactionsResources(state)
  if (upload && !usersById[upload.idUser]) {
    usersById[upload.idUser] = getUploadsResources(state).users[upload.idUser]
  }
  const loading = (loadingTransactions || loadingUploads || loadingApps)
  const userPreferences = getUserPreferences(state)
  const { defaultSort } = userPreferences[TABLES.expensesUploadActivityTable.key] || {}

  return {
    upload,
    usersById,
    appsById,
    transactions,
    totalTransactions,
    uploadCounter,
    loading,
    defaultSort
  }
}

const mapDispatchToProps = {
  getUpload,
  getUploadTransactions,
  editTransactionApp: editTransaction,
  getSingleApp,
  toggleAddApplication,
  resetTransactions
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { idOrg } = ownProps
  return {
    ...stateProps,
    ...dispatchProps,
    editTransactionApp: async (idApp, mappingStatus, transaction) => {
      const { id: idTransaction, idApp: prevIdApp } = transaction
      if (idApp) {
        await dispatchProps.getSingleApp({ idOrg, idApp })
      }
      await dispatchProps.editTransactionApp({ idOrg, prevIdApp, idApp, idTransaction, mappingStatus })
    },
    ...ownProps
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(View))
