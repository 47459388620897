import View from './view'
import { connect } from 'react-redux'
import AccessControl from '@lenses/accessControl'
import { SCOPES, SCOPES_PREFIX } from '@root/constants'

const mapStateToProps = (state, ownProps) => {
  const { idOrg, idApp } = ownProps
  const hasLicensesWriteScope = AccessControl.isAccessibleWithState({ scopes: [SCOPES.LICENSE_AND_CHARGEBACK_WRITE], state })
  const isAppOwner = AccessControl.isAccessibleWithState({ scopes: [`${SCOPES_PREFIX.APP_OWNER}-${idOrg}-app-${idApp}`], state })
  const hasLicenseEditAccess = hasLicensesWriteScope || isAppOwner
  return {
    hasLicenseEditAccess
  }
}

export default connect(mapStateToProps)(View)
