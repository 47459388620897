import { getCurrentUsersFieldValues, getAppsFieldValues } from '@store/actions'
import { useSelector, useDispatch } from 'react-redux'
import { sortBy } from 'lodash'
import { TABLES, fieldTypes } from '@root/constants'
import { getUserDetailsFieldsSelector } from '@selectors/userDetails'
import { getUserTablesInfo, getAppsV2TablesInfo } from '@selectors/tables'
import { getCurrentUsersFieldValues as getCurrentUsersFieldValuesSelector } from '@selectors/users'
import { getAppDetailsFields, getFieldValues as getAppFieldValuesSelector } from '@selectors/apps'
import { getContractsFields as getContractsFieldsSelector, getContractsTablesInfo, getContractFilterOptionsValuesPerKey } from '@selectors/contracts'
import { isFieldSupportAutocompleteValues } from '@root/shared/fieldUtils'
import { getIdOrg } from '@root/store/selectors/org'
import { ENTITY_TYPES, WIDGET_TYPES } from '@root/store/reducers/dashboards/types'
import metricConfig from '@components/dashboard/widgets/metricWidget/config'
import pieChartConfig from '@components/dashboard/widgets/pieChartWidget/config'
import keyBy from 'lodash/keyBy'
import barChartConfig from '@components/dashboard/widgets/barChartWidget/config'

export const getWidgetTableKey = (widget) => `${widget.entityType}-widget-${widget.id}`

export const useGetWidgetFieldsDataByEntityType = (entityType: ENTITY_TYPES) => {
  const dispatch = useDispatch()
  const idOrg = useSelector(getIdOrg)

  const userDetails = useSelector(getUserDetailsFieldsSelector)
  const usersTableInfo = useSelector(getUserTablesInfo)[TABLES.usersTable.key]
  const userFieldValues = useSelector(getCurrentUsersFieldValuesSelector)
  const appFields = useSelector(getAppDetailsFields)
  const appsTableInfo = useSelector(getAppsV2TablesInfo)
  const appFieldValues = useSelector(getAppFieldValuesSelector)
  const contractFields = useSelector(getContractsFieldsSelector)
  const contractTableInfo = useSelector(getContractsTablesInfo)[TABLES.contractsTable.key]
  const contractFilterOptionsValuesPerKey = useSelector(getContractFilterOptionsValuesPerKey)

  let fieldsOptions
  let filtersOptions
  let fieldOptionsValuesPerKey
  let fetchFieldValues

  switch (entityType) {
    case ENTITY_TYPES.USERS:
      fieldsOptions = sortBy([
        ...TABLES.usersTable.filtersOptions,
        ...(userDetails.map(field => ({ label: field.name, value: field.key, type: field.type })))
      ], 'label')
      filtersOptions = [ ...usersTableInfo.filtersOptions, ...TABLES.usersTable.dashboardFilterOptions ]
      fieldOptionsValuesPerKey = userFieldValues
      fetchFieldValues = (field) => {
        const options = filtersOptions
        const isFieldSupportValues = isFieldSupportAutocompleteValues({
          fieldOptions: options,
          fieldOption: field
        })
        if (isFieldSupportValues) {
          dispatch(getCurrentUsersFieldValues({ idOrg, fields: [field] }))
        }
      }
      break
    case ENTITY_TYPES.APPS:
      const appFieldsBySystemKey = keyBy(appFields, 'systemKey')
      fieldsOptions = sortBy([
        ...TABLES.appsV2Table.filtersOptions.filter(f => !appFieldsBySystemKey[f.value]),
        ...(appFields.map((field) => ({ label: field.name, value: field.systemKey, type: field.type })))
      ], 'label')
      filtersOptions = appsTableInfo.filtersOptions
      fieldOptionsValuesPerKey = appFieldValues
      fetchFieldValues = (field) => {
        const selectedFilterOption = filtersOptions.find(f => f.value === field) || {}
        const isFieldSupportValues = [fieldTypes.text, fieldTypes.user, fieldTypes.dropdown, fieldTypes.dropdownMulti, fieldTypes.userMulti].includes(selectedFilterOption.type)
        if (isFieldSupportValues) {
          dispatch(getAppsFieldValues({ idOrg, fields: [field] }))
        }
      }
      break
    case ENTITY_TYPES.CONTRACTS:
      fieldsOptions = sortBy([
        ...TABLES.contractsTable.filtersOptions,
        ...(contractFields.map(field => ({ label: field.name, value: field.systemKey, type: field.type })))
      ], 'label')
      filtersOptions = contractTableInfo.filtersOptions
      fieldOptionsValuesPerKey = contractFilterOptionsValuesPerKey
      fetchFieldValues = () => {}
      break
    default:
      break
  }

  return { fieldsOptions, filtersOptions, fieldOptionsValuesPerKey, fetchFieldValues }
}

export const WIDGET_TO_CONFIG = {
  [WIDGET_TYPES.METRIC_WIDGET]: metricConfig,
  [WIDGET_TYPES.PIE_CHART_WIDGET]: pieChartConfig,
  [WIDGET_TYPES.BAR_CHART_WIDGET]: barChartConfig
}
