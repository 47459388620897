import { isNil } from 'lodash'

const MINOR_UNIT_MULTIPLIER = 100

const formatCurrency = (value: number): string => (value / MINOR_UNIT_MULTIPLIER).toLocaleString(undefined, {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
  useGrouping: false
})

export const formatCurrencyForInput = (value?: number | null | ''): string => {
  const isEmpty = value === '' || isNil(value)
  return isEmpty ? '' : formatCurrency(value)
}

export const parseCurrencyFromInput = (value: string | null): number | null => {
  if (!value) {
    return null
  }

  const valueWithoutCommas = value.replace(/,/g, '').trim()
  const numberValue = Number(valueWithoutCommas)

  if (Number.isNaN(numberValue)) {
    throw new Error('Invalid string value')
  }

  const parsedValue = Math.round(numberValue * MINOR_UNIT_MULTIPLIER)

  if (!Number.isSafeInteger(parsedValue)) {
    throw new Error('Value is too large')
  }

  return parsedValue
}
