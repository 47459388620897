export const Placeholder = {
  height: '60px',
  width: '300px'
}

export const CardContent = {
  width: '100%'
}

export const axisLineStyle = { axisLine: false, tickLine: false }
