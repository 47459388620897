import styled from 'styled-components'

export const FullWidth = styled.div`
  width: 100%;
`

export const AppAccountUsersWrapper = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.border.primary};
  border-radius: ${({ theme }) => theme.corner('radius-100')};
  padding: ${({ theme }) => theme.spacing('space-300')};
`

export const FullHeight = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

export const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
