import React, { useEffect, useMemo, useState } from 'react'
import Page from '../../components/page'
import { useDispatch, useSelector } from 'react-redux'
import { getAppAccountStatuses, getCurrentApp } from '@selectors/apps'
import { getAppV2 } from '@actions/apps'
import { getAppContracts, getAppUsersStats, getLicensesTypes } from '@store/actions'
import { getLicensesTypes as getLicensesTypesSelector, isLoadingLicensesTypes } from '@selectors/licenses'
import { getCurrentOrg } from '@selectors/org'
import Wizard from '@root/components/popups/wizardPopup'
import { stepsConfig } from './wizardSteps/config'
import { useHistory } from 'react-router-dom'
import { WIZARD_STEPS } from './wizardSteps/types'
import InaccessiblePage from '../inaccessiblePage/inaccessiblePage'
import AccessControl from '@lenses/accessControl'
import { SCOPES, SCOPES_PREFIX } from '@root/constants'

const parseUrlParams = (url: string): { idApp: number, idAppAccount: number, stepName: string } | null => {
  const regex = /\/team\/\d+\/app\/(\d+)\/account\/(\d+)\/renewalForecast\/(\w+)/
  const match = url.match(regex)

  if (match) {
    const [, idApp, idAppAccount, stepName] = match
    return { idApp: Number(idApp), idAppAccount: Number(idAppAccount), stepName }
  }

  return null
}

const RenewalForecast = (props) => {
  const dispatch = useDispatch()
  const { id: idOrg } = useSelector(getCurrentOrg)
  const parseResult = parseUrlParams(window.location.pathname)
  const { idApp, idAppAccount, stepName } = parseResult ?? { idApp: null, idAppAccount: null, stepName: '' }
  const { app = {}, loading: isCurrentAppLoading } = useSelector(getCurrentApp) ?? {}
  const { appAccounts: allAppAccounts = [], loading: isAppAccountLoading } = useSelector(getAppAccountStatuses)
  const appAccount = allAppAccounts.find((appAccount) => appAccount.idAppAccount === idAppAccount) || {}
  const { name: appName } = app
  const orgLicenses = useSelector(getLicensesTypesSelector)
  const isLoadingLicenses = useSelector(isLoadingLicensesTypes)
  const history = useHistory()
  const firstStep = Object.entries(WIZARD_STEPS).find(([key, value]) => value === stepName)?.[0]
  const [hasAllDataLoaded, setIsAllDataLoaded] = useState(false)
  const hasRequiredScopes = AccessControl.useIsAccessible({ scopes: [SCOPES.CONTRACTS_READ, SCOPES.LICENSE_AND_CHARGEBACK_READ, SCOPES.USERS_READ], mustHaveAllScopes: true })
  const isAppOwner = AccessControl.useIsAccessible({ scopes: [`${SCOPES_PREFIX.APP_OWNER}-${idOrg}-app-${idApp}`] })
  const isPageAccessible = hasRequiredScopes || isAppOwner

  const appAccountLicenses = useMemo(() => {
    if (idApp) {
      const appLicenses = orgLicenses[idApp] || []
      return appLicenses.filter(license => license.idAppAccount === idAppAccount)
    } else return []
  }, [orgLicenses, idApp, idAppAccount])

  useEffect(() => {
    if ([isCurrentAppLoading, isAppAccountLoading, isLoadingLicenses].every(loading => !loading)) {
      setIsAllDataLoaded(true)
    }
  }, [isCurrentAppLoading, isAppAccountLoading, isLoadingLicenses])

  useEffect(() => {
    if (idApp && idOrg) {
      dispatch(getAppV2({ idOrg, idApp }))
      dispatch(getLicensesTypes({ idOrg, idApp }))
      dispatch(getAppContracts({ idOrg, idApp }))
      dispatch(getAppUsersStats({ idOrg, idApp }))
    }
  }, [dispatch, idApp, idOrg])

  const onClose = () => {
    history.push(`/team/${idOrg}/app/${idApp}`)
  }

  return (
    !isPageAccessible ? <InaccessiblePage />
      : <Page title={'Renewal Forecast'} fillContainerHeight>
        {(hasAllDataLoaded && idApp) && <Wizard
          name='renewalForecast'
          initialSharedState={{
            initialAppAccountLicenses: appAccountLicenses,
            appAccountLicenses,
            isFirstTimeInUserReviewStep: true
          }}
          sharedProps={{
            idOrg,
            idApp,
            idAppAccount: idAppAccount || null,
            appName,
            appAccount,
            licenses: appAccountLicenses,
            app
          }}
          onCancel={onClose}
          stepsConfig={stepsConfig()}
          startOnStep={firstStep ? WIZARD_STEPS[firstStep] : null}
        />}
      </Page>
  )
}

export default RenewalForecast
