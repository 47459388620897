import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import { fontSize } from '../../shared/style/sizes'

const CSS = {
  alert: {
    padding: '15px 30px',
    backgroundColor: colors.background,
    color: colors.darkText,
    fontSize: fontSize.small,
    lineHeight: '18px',
    margin: '15px 0'
  },
  success: {
    backgroundColor: 'rgb(122, 255, 244)'
  },
  error: {
    backgroundColor: 'rgb(252, 192, 204)'
  }
}

export const ALERT_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error'
}

const Alert = ({ type, children, ...rest }) => (
  <div {...css(CSS.alert, CSS[type])} {...rest}>
    {children}
  </div>
)

Alert.propTypes = {
  type: PropTypes.oneOf(Object.keys(ALERT_TYPE).map(st => ALERT_TYPE[st])),
  children: PropTypes.node
}

export default Alert
