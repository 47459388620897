import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  isOrgLoading,
  getCurrency,
  getCompanyName,
  getOrgEmailAlias,
  getGeoLocationSettings,
  getRenewalCalendarSettings,
  getBenchmarkSettings,
  getIsBlockThirdPartyAppsEnabled,
  getIsToriiSupportAllowedForOrg,
  getSupportedFeatures
} from '@selectors/org'
import { updateIsToriiSupportAllowedForOrg, updateOrg } from '@actions/'
import { getDisplayCurrencyOptions } from '@selectors/ui'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const loading = isOrgLoading(state)
  const defaultCurrency = getCurrency(state)
  const companyName = getCompanyName(state)
  const emailAlias = getOrgEmailAlias(state)
  const isGeoLocationAllowed = getGeoLocationSettings(state)
  const isRenewalCalendarAllowed = getRenewalCalendarSettings(state)
  const isBenchmarkAllowed = getBenchmarkSettings(state)
  const displayCurrencyOptions = getDisplayCurrencyOptions(state).map(currency => ({ label: currency, value: currency }))
  const isBlockThirdPartyAppsEnabled = getIsBlockThirdPartyAppsEnabled(state)
  const isToriiSupportAllowedForOrg = getIsToriiSupportAllowedForOrg(state)
  const supportedFeatures = getSupportedFeatures(state)
  const isToriiSupportAllowedForOrgFeatureSupported = Boolean(supportedFeatures['isToriiSupportAllowedForOrgFeature'])

  return {
    idOrg,
    loading,
    defaultCurrency,
    companyName,
    emailAlias,
    isGeoLocationAllowed,
    isRenewalCalendarAllowed,
    isBenchmarkAllowed,
    displayCurrencyOptions,
    isBlockThirdPartyAppsEnabled,
    isToriiSupportAllowedForOrgFeatureSupported,
    isToriiSupportAllowedForOrg
  }
}

const mapDispatchToProps = {
  updateOrg,
  updateIsToriiSupportAllowedForOrg
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
