import styled from 'styled-components'
import { RowWithoutMargin, ScreenBreakpoints } from '@toriihq/design-system'

export const FullWidth = styled.div`
  padding: 0px 1px;
  width: 100%;
`

export const FullHeight = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

export const WithBorder = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.border.primary};
  border-radius: ${({ theme }) => theme.corner('radius-100')};
  padding: 16px 24px;
  height: 100%;
`

export const FullHeightWidth = styled.div`
  height: 100%;
  width: 100%;
`
export const RightAlignedDiv = styled.div`
    text-align: right;
    white-space: nowrap;
    text-overflow: ellipsis;
`

export const RowWithMargin = styled(RowWithoutMargin)<{ marginBottom?: string }>`
  margin-bottom: ${({ marginBottom }) => marginBottom === 0 ? 0 : marginBottom || '12px'};

  @media (max-width: ${ScreenBreakpoints.sm}) {
    height: auto !important;
    .col {
      margin-bottom: 24px;
    } 
  }
`

export const RecommendationContainer = styled.div`
  margin-bottom: 12px;
`

export const DropdownSettingsContainer = styled.div`
  width: 600px;
`

export const LicenseBarContainer = styled(FullHeightWidth)`
  display: flex;
  justify-content: center;
`
