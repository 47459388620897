import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AUTH_STATE } from '@root/constants'
import DemoBar from '@components/demoBar'
import SearchBar from '@components/searchBar'
import Navigation from '@components/navigation'
import {
  getSelf,
  getMyOrg,
  isToriiAdmin as getIsToriiAdmin
} from '@selectors/me'
import { useDispatch, useSelector } from 'react-redux'
import config from '@root/config'
import ReactFacebookPixel from 'react-facebook-pixel'
import {
  toggleSmallScreen,
  getAppDetailsFields,
  getStateDetails,
  getAppDetailsGroups,
  getSupportedFeatures,
  deprecatedGetApps,
  getServicesSyncData,
  getRoles,
  getLifecycleSettings,
  toggleViewOnlyMode,
  getCurrencies
} from '@actions/'
import { useMedia, usePrevious, useWindowDimensions } from '@shared/hooks'
import { useRouteMatch } from 'react-router-dom'
import { StyledLayout, RightSide, DrawerContainer, TopMessageBarsContainer, MainWrapper, MobileMainWrapper, MobileBodyWrapper, SearchBarWrapper, SearchBarMobileWrapper, MobileHeaderWrapper, BodyWrapper } from './style'
import ScrollToTop from '@components/scrollToTop'
import { ScreenBreakpoints, Grid, Toaster, Body2 } from '@toriihq/design-system'
import Confirmation from '@components/confirmation'
import useEffectOnce from '@shared/hooks/useEffectOnce'

const MESSAGE_BAR_HEIGHT = 50
const SMALL_SCREEN_WIDTH = ScreenBreakpoints.sm

const Layout = (props) => {
  const { children = [], shouldFetchInitialData } = props

  const me = useSelector(getSelf)
  const myOrg = useSelector(getMyOrg)
  const isToriiAdmin = useSelector(getIsToriiAdmin)
  const previewId = parseInt(new URLSearchParams(window.location.search).get('previewId'))

  const dispatch = useDispatch()
  const screenChanged = useMedia(`(max-width: ${SMALL_SCREEN_WIDTH})`)

  const [idOrg, setIdOrg] = useState()
  const [authState, setAuthState] = useState()
  const [isDemoMode, setIsDemoMode] = useState()
  const [isDemoBarVisible, setIsDemoBarVisible] = useState(true)
  const [isSmallScreen, setIsSmallScreen] = useState()
  const [isMainAreaScrolling, setIsMainAreaScrolling] = useState(false)

  const prevShouldFetchInitialData = usePrevious(shouldFetchInitialData)
  const prevIdOrg = usePrevious(idOrg)
  const match = useRouteMatch('/team/:idOrg')

  const goToLoginPage = () => {
    window.location.href = `/login?next=${encodeURIComponent(window.location.href)}`
  }

  useEffect(() => {
    dispatch(toggleViewOnlyMode({ viewOnlyMode: Boolean(previewId) }))
  }, [dispatch, previewId])

  useEffectOnce(() => {
    if (config.facebook.pixelId) {
      ReactFacebookPixel.init(config.facebook.pixelId)
    }
  })

  const fetchInitialData = useCallback(() => {
    if (!idOrg) {
      return
    }

    dispatch(getLifecycleSettings({ idOrg }))
    dispatch(getAppDetailsFields({ idOrg }))
    dispatch(getStateDetails({ idOrg }))
    dispatch(getAppDetailsGroups({ idOrg }))
    dispatch(getSupportedFeatures({ idOrg }))
    dispatch(deprecatedGetApps({ idOrg }))
    dispatch(getServicesSyncData({ idOrg }))
    dispatch(getRoles({ idOrg }))
    dispatch(getCurrencies())
  }, [dispatch, idOrg])

  useEffectOnce(() => {
    if (shouldFetchInitialData) {
      fetchInitialData()
    }
  })

  useEffect(() => {
    const shouldFetchInitialDataChanged = (shouldFetchInitialData && (shouldFetchInitialData !== prevShouldFetchInitialData))
    const idOrgChanged = (idOrg && (idOrg !== prevIdOrg))
    if (shouldFetchInitialDataChanged || idOrgChanged) {
      fetchInitialData()
    }
  }, [fetchInitialData, idOrg, prevIdOrg, prevShouldFetchInitialData, shouldFetchInitialData])

  useEffect(() => {
    setIsSmallScreen(screenChanged)
    dispatch(toggleSmallScreen(screenChanged))
  }, [dispatch, screenChanged])

  useEffect(() => {
    setAuthState(me.authState)
  }, [me])

  useEffect(() => {
    if (match) {
      const idOrg = parseInt(match.params.idOrg, 10)
      setIdOrg(idOrg)
      setIsDemoMode((isToriiAdmin))
    }
  }, [myOrg, isToriiAdmin, match])

  const mainAreaRef = useRef()
  const navElement = useRef(null)
  const { width: windowWidth } = useWindowDimensions()

  const getNavigationElementWidth = () => {
    if (navElement.current) {
      const navigationWidth = navElement.current.getBoundingClientRect().width
      return `${windowWidth - navigationWidth}px`
    }
    return '100%'
  }

  const showDemoBar = useMemo(() => isDemoMode && isDemoBarVisible, [isDemoMode, isDemoBarVisible])

  const messageBarHeight = useMemo(() => showDemoBar ? MESSAGE_BAR_HEIGHT : 0, [showDemoBar])

  const onMainScroll = () => {
    const scrollTop = mainAreaRef.current.scrollTop
    setIsMainAreaScrolling(scrollTop > 0)
  }

  const onCloseDemoBar = () => {
    setIsDemoBarVisible(false)
  }

  return (
    <StyledLayout data-component='Layout'>
      <Toaster />
      <Confirmation
        isOpen={(authState === AUTH_STATE.NO_LONGER_AUTHENTICATED)}
        header={`Time to login again`}
        text={<Body2>Your session has ended. For security reasons, you have been logged out.</Body2>}
        confirmText='Go to Login page'
        confirm={goToLoginPage}
        close={goToLoginPage}
        showCancelButton={false}
      />
      {showDemoBar &&
        <TopMessageBarsContainer>
          <DemoBar style={{ flexGrow: 1 }} onClose={onCloseDemoBar} isUserOwnOrg={myOrg === idOrg} />
        </TopMessageBarsContainer>}
      {isSmallScreen &&
        <MobileBodyWrapper $top={messageBarHeight}>
          <MobileHeaderWrapper $hasShadow={isMainAreaScrolling}>
            <Navigation headerSpace={messageBarHeight} />
            <SearchBarMobileWrapper $hasShadow={isMainAreaScrolling}>
              <SearchBar />
            </SearchBarMobileWrapper>
          </MobileHeaderWrapper>
          <ScrollToTop>
            <MobileMainWrapper ref={mainAreaRef} className='mainWrapper' onScroll={onMainScroll}>
              <Grid style={{ width: '100%' }}>
                {children}
              </Grid>
              <DrawerContainer data-component='layout-drawer-container' />
            </MobileMainWrapper>
          </ScrollToTop>
        </MobileBodyWrapper>
      }
      {!isSmallScreen &&
        <BodyWrapper $top={messageBarHeight}>
          <Navigation ref={navElement} />
          <RightSide $width={getNavigationElementWidth()}>
            <SearchBarWrapper $hasShadow={isMainAreaScrolling}>
              <SearchBar />
            </SearchBarWrapper>
            <ScrollToTop>
              <MainWrapper ref={mainAreaRef} className='mainWrapper' onScroll={onMainScroll}>
                <Grid style={{ width: '100%' }}>
                  {children}
                </Grid>
                <DrawerContainer data-component='layout-drawer-container' />
              </MainWrapper>
            </ScrollToTop>
          </RightSide>
        </BodyWrapper>
      }
    </StyledLayout>
  )
}

export default Layout
