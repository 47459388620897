import { getWorkflowLastStatus } from '.'
import moment from 'moment'
import { LastExecution, Workflow } from './types'

export const sortLastStatus = (
  lastExecutionA: LastExecution | null,
  lastExecutionB: LastExecution| null,
  workflowsById: Record<string, Workflow>
): number => {
  const workflowInfoA = lastExecutionA && workflowsById[lastExecutionA.idWorkflow]
  const workflowInfoB = lastExecutionB && workflowsById[lastExecutionB.idWorkflow]

  if (!workflowInfoA && !workflowInfoB) {
    return 0
  }
  if (!workflowInfoA) {
    return 1
  }
  if (!workflowInfoB) {
    return -1
  }

  const aWorkflowStatus = getWorkflowLastStatus(workflowInfoA) ?? ''
  const bWorkflowStatus = getWorkflowLastStatus(workflowInfoB) ?? ''

  const compareStatus = aWorkflowStatus.localeCompare(bWorkflowStatus)

  if (compareStatus !== 0) {
    return compareStatus
  }

  const timeA = moment.utc(lastExecutionA.completionTime || lastExecutionA.creationTime || 0).valueOf()
  const timeB = moment.utc(lastExecutionB.completionTime || lastExecutionB.creationTime || 0).valueOf()

  return timeB - timeA
}
