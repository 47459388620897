import React from 'react'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import { fontSize } from '../../shared/style/sizes'
import { Field } from 'react-final-form'
import Select from '../select'
import FormGroup from '../form/formGroup'
import { FORM_ERROR } from 'final-form'
import LicenseCostAndTotalAmountTable from './licenseCostAndTotalAmountTable'
import Input from '../form/input'
import PropTypes from 'prop-types'
import UserFieldPercentagesDivisionTable from './userFieldPercentagesDivisionTable'
import { CHARGEBACK_ALLOCATION_COST, CHARGEBACK_ALLOCATION_STRATEGY, SCOPES } from '../../constants'
import { ToriiPopupContext } from '@components/popups/ToriiPopupV2/popupContext'
import moment from 'moment'
import CurrencySelector from '@components/currencySelector'
import DatePicker from '@components/datePicker'
import { difference, isNumber, sumBy, isEqual, get, keyBy } from 'lodash'
import { Icon, Tooltip } from '@toriihq/design-system'

const ALLOCATION_STRATEGY_OPTIONS = {
  [CHARGEBACK_ALLOCATION_STRATEGY.topDown]: { label: 'Top down', value: CHARGEBACK_ALLOCATION_STRATEGY.topDown },
  [CHARGEBACK_ALLOCATION_STRATEGY.bottomUp]: { label: 'Bottom up', value: CHARGEBACK_ALLOCATION_STRATEGY.bottomUp },
  [CHARGEBACK_ALLOCATION_STRATEGY.notConfigured]: { label: 'Not configured', value: CHARGEBACK_ALLOCATION_STRATEGY.notConfigured }
}

const ALLOCATION_COST_OPTIONS = {
  [CHARGEBACK_ALLOCATION_COST.contract]: { label: 'Active contracts value', value: CHARGEBACK_ALLOCATION_COST.contract },
  [CHARGEBACK_ALLOCATION_COST.expenses]: { label: 'Expenses value', value: CHARGEBACK_ALLOCATION_COST.expenses },
  [CHARGEBACK_ALLOCATION_COST.manual]: { label: 'Manually', value: CHARGEBACK_ALLOCATION_COST.manual },
  [CHARGEBACK_ALLOCATION_COST.license]: { label: 'Cost per license', value: CHARGEBACK_ALLOCATION_COST.license },
  [CHARGEBACK_ALLOCATION_COST.user]: { label: 'Cost per user', value: CHARGEBACK_ALLOCATION_COST.user }
}

const maxLicenseDate = moment.utc().subtract(1, 'day').toDate()
const minLicenseDate = moment.utc('01/01/2016', 'MM/DD/YYYY').toDate()

const getAllocationCostOptionsByFormValues = (formValues = {}, isLoadingLicenses, currencySymbol) => {
  const { allocationStrategy, contractYearlyPrice, totalExpensesOfYear, licenses } = formValues

  if (allocationStrategy === ALLOCATION_STRATEGY_OPTIONS.topDown.value) {
    const contractOption = {
      value: ALLOCATION_COST_OPTIONS[CHARGEBACK_ALLOCATION_COST.contract].value,
      label: `${ALLOCATION_COST_OPTIONS[CHARGEBACK_ALLOCATION_COST.contract].label} - ${currencySymbol}${(contractYearlyPrice || 0).toFixed(2)}`
    }
    const expensesOption = {
      value: ALLOCATION_COST_OPTIONS[CHARGEBACK_ALLOCATION_COST.expenses].value,
      label: `${ALLOCATION_COST_OPTIONS[CHARGEBACK_ALLOCATION_COST.expenses].label} - ${currencySymbol}${(totalExpensesOfYear || 0).toFixed(2)}`
    }

    return [contractOption, expensesOption, ALLOCATION_COST_OPTIONS.manual]
  } else if (allocationStrategy === ALLOCATION_STRATEGY_OPTIONS.bottomUp.value) {
    return isLoadingLicenses || licenses.length > 0 ? [ALLOCATION_COST_OPTIONS.license, ALLOCATION_COST_OPTIONS.user] : [ALLOCATION_COST_OPTIONS.user]
  } else {
    return []
  }
}

const CSS = {
  component: css({
    background: colors.white,
    paddingTop: '20px',
    overflowX: 'hidden'
  }),
  licenseCurrencyAndDate: css({
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
    alignItems: 'center',
    padding: 0,
    marginBottom: '8px'
  }),
  dateSelectContainer: css({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginBottom: '1px'
  }),
  currencySelectContainer: css({
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  }),
  errorText: css({
    color: colors.error,
    fontSize: fontSize.small
  }),
  actions: css({
    padding: '20px 45px 5px',
    display: 'flex',
    justifyContent: 'flex-end'
  }),
  wrapper: css({
    border: `1px solid ${colors.border}`,
    borderRadius: '4px'
  })
}

const getInitialValues = (props, initialLoad = false) => {
  const { appChargebackConfig, licenses } = props

  const { config: chargebackConfig = {}, metadata = {} } = appChargebackConfig
  const allocationStrategy = appChargebackConfig.allocationStrategy || (initialLoad ? null : CHARGEBACK_ALLOCATION_STRATEGY.notConfigured)
  const costPerUser = chargebackConfig.costPerUser && chargebackConfig.costPerUser / 100
  const allocateCostByUserField = appChargebackConfig.allocateCostByUserField && { label: appChargebackConfig.allocateCostByUserField.label, value: appChargebackConfig.allocateCostByUserField.key }
  const contractYearlyPrice = metadata.contractYearlyPrice && metadata.contractYearlyPrice / 100
  const totalExpensesOfYear = metadata.totalExpensesOfYear && metadata.totalExpensesOfYear / 100
  const totalCost = chargebackConfig.totalCost && chargebackConfig.totalCost / 100
  const userFieldPercentagesDivision = chargebackConfig.percentage ? Object.entries(chargebackConfig.percentage).map(([userFieldDisplayName, allocatedPercentage]) => ({ userFieldDisplayName, allocatedPercentage })) : []
  const licenseStatus = chargebackConfig.licenseStatus
  const licenseCurrencyCode = get(licenses, [0, 'currency'], '')
  const licenseConversionDate = get(licenses, [0, 'conversionDate'], '')
  const licenseDate = licenseConversionDate ? moment(licenseConversionDate).utc().format('MM/DD/YYYY').toString() : moment().utc().subtract(1, 'day').format('MM/DD/YYYY').toString()

  return { ...appChargebackConfig, allocationStrategy, allocateCostByUserField, costPerUser, totalCost, licenses, licenseCurrencyCode, licenseDate, contractYearlyPrice, totalExpensesOfYear, userFieldPercentagesDivision, licenseStatus }
}

class ConfigureAppChargeback extends React.Component {
  state = { formInitialValues: getInitialValues(this.props, true) }

  componentDidMount () {
    const { idOrg, onInitialValuesChanged } = this.props
    const { formInitialValues } = this.state

    if (idOrg) {
      this.fetchData()
    }
    onInitialValuesChanged(formInitialValues)
    this.context.submitAction.current = this.onSubmit
    this.context.setScopes([SCOPES.LICENSE_AND_CHARGEBACK_WRITE])
  }

  componentDidUpdate (prevProps, prevState) {
    const { idOrg, onInitialValuesChanged, form, appChargebackConfig } = this.props

    if (prevProps.idOrg !== idOrg) {
      this.fetchData()
    }

    if (!isEqual(prevProps.appChargebackConfig, appChargebackConfig)) {
      this.fetchUserFieldDisplayNames()
      const formInitialValues = getInitialValues(this.props)
      this.setState({ formInitialValues })
      onInitialValuesChanged(formInitialValues)
    }

    if (!isEqual(prevState.formInitialValues, this.state.formInitialValues)) {
      const outerFormBatchUpdateFunction = get(form, ['batch'], () => {})
      const outerFormChangeFunction = get(form, ['change'], () => {})

      outerFormBatchUpdateFunction(() => {
        Object.entries(this.state.formInitialValues).forEach(([key, value]) => outerFormChangeFunction(key, value))
      })
    }
  }

  fetchData () {
    const { getUserDetailsFields, getAppChargebackConfig, getLicensesTypes, idOrg, idApp } = this.props

    getUserDetailsFields({ idOrg })
    getAppChargebackConfig({ idOrg, idApp })
    getLicensesTypes({ idOrg, idApp })
  }

  fetchUserFieldDisplayNames () {
    const { idOrg, appChargebackConfig, getUserFieldDisplayNames } = this.props

    const userFieldKey = (appChargebackConfig && appChargebackConfig.allocateCostByUserField) && appChargebackConfig.allocateCostByUserField.key

    if (userFieldKey) {
      getUserFieldDisplayNames({ idOrg, userFieldKey })
    }
  }

  createChargebackConfig = (form) => {
    const { allocationStrategy, allocationCost, costPerUser, totalCost, userFieldPercentagesDivision } = form

    if (allocationCost === CHARGEBACK_ALLOCATION_COST.user && costPerUser) {
      return { costPerUser: costPerUser * 100 }
    } else if (allocationStrategy === CHARGEBACK_ALLOCATION_STRATEGY.topDown) {
      const userFieldNamesPercentagesDivision = userFieldPercentagesDivision.reduce((percentageDivision, userFieldNameDetails) => {
        if (userFieldNameDetails.allocatedPercentage > 0) {
          percentageDivision[userFieldNameDetails.userFieldDisplayName] = userFieldNameDetails.allocatedPercentage
        }
        return percentageDivision
      }, {})
      if (allocationCost === CHARGEBACK_ALLOCATION_COST.contract || allocationCost === CHARGEBACK_ALLOCATION_COST.expenses) {
        return { percentage: userFieldNamesPercentagesDivision }
      }
      if (allocationCost === CHARGEBACK_ALLOCATION_COST.manual) {
        return { percentage: userFieldNamesPercentagesDivision, totalCost: totalCost * 100 }
      }
    } else {
      return {}
    }
  }

  onSubmit = async (form) => {
    const {
      idOrg,
      idApp,
      updateAppChargebackConfig,
      deleteAppChargebackConfig,
      getAppChargeback,
      bulkUpdateLicensesTypes,
      updateLicenseCurrencyAndDate,
      reset,
      onSuccess
    } = this.props
    const { licenseCurrencyCode: initialCurrencyCode, licenseDate: initialLicenseConversionDate, licenses: initialLicenses } = this.state.formInitialValues
    const { allocationStrategy, allocationCost, allocateCostByUserField, licenses, licenseCurrencyCode, licenseDate } = form

    const onConnectSuccess = () => {
      getAppChargeback({ idOrg, idApp })
      const SUCCESS_DELAY = 1000
      setTimeout(reset, SUCCESS_DELAY)
      return onSuccess()
    }

    if (allocationStrategy === CHARGEBACK_ALLOCATION_STRATEGY.notConfigured) {
      return deleteAppChargebackConfig({ idOrg, idApp })
        .then(onConnectSuccess).catch(e => { return { [FORM_ERROR]: 'Error deleting chargeback configuration, please try again' } })
    }

    const formattedForm = {
      ...form,
      allocateCostByUserField: { key: allocateCostByUserField.value, label: allocateCostByUserField.label },
      config: this.createChargebackConfig(form)
    }

    if (allocationCost === CHARGEBACK_ALLOCATION_COST.license) {
      const updatedLicensesProps = licenses.map(license => ({
        idLicense: license.id,
        pricePerUser: license.pricePerUser,
        totalAmountEnteredByUser: license.totalAmountEnteredByUser
      }))
      const shouldUpdateLicenseCurrencyAndDate = licenseCurrencyCode !== initialCurrencyCode || licenseDate !== initialLicenseConversionDate
      const shouldUpdateLicensesTypes = difference(licenses, initialLicenses).length > 0

      if (shouldUpdateLicensesTypes) {
        await bulkUpdateLicensesTypes({
          idOrg,
          idApp,
          licenses: updatedLicensesProps,
          LicensesFullDetails: licenses,
          waitForES: false
        })
      }
      if (shouldUpdateLicenseCurrencyAndDate) {
        await updateLicenseCurrencyAndDate({
          idOrg,
          idApp,
          currency: licenseCurrencyCode,
          conversionDate: licenseDate
        })
      }
      (shouldUpdateLicenseCurrencyAndDate || shouldUpdateLicensesTypes) && this.fetchData()
    }

    return updateAppChargebackConfig({ idOrg, idApp, ...formattedForm })
      .then(onConnectSuccess).catch(e => { return { [FORM_ERROR]: 'Error updating chargeback configuration, please try again' } })
  }

  InputWithPrefixField = ({ label, prefix, disabled, isRequired, input, meta, ...props }) => {
    return (
      <FormGroup label={label} disabled={disabled} isRequired={isRequired} error={meta.touched && meta.error}>
        <Input
          prefix={prefix}
          {...props}
          {...input}
        />
      </FormGroup>
    )
  }

  selectFieldComponent = ({ label, disabled, isLoading, isRequired, input, meta, onChange, saveFullOptionDetails, ...props }) => {
    return (
      <FormGroup label={label} disabled={disabled} isRequired={isRequired} error={meta.touched && meta.error}>
        <Select
          labelKey='label'
          valueKey='value'
          clearable={false}
          searchable={false}
          disabled={disabled}
          isLoading={isLoading}
          {...props}
          {...input}
          onChange={selection => {
            onChange && onChange(selection)
            input.onChange(saveFullOptionDetails ? selection : selection.value)
          }}
        />
      </FormGroup>
    )
  }

  currencySelectorComponent = ({ input, meta, ...props }) => {
    const licenseCurrencyCode = input.value

    const onChange = (selection) => {
      input.onChange(selection.value)
    }
    return (
      <FormGroup label='Currency' labelStyle={CSS.labelStyle}>
        <CurrencySelector
          {...props}
          {...input}
          selectedCurrency={licenseCurrencyCode}
          onChange={onChange}
        />
      </FormGroup>
    )
  }

  dayInputComponent = ({ input, meta, ...props }) => {
    const { licenseDate } = input.value
    const onDateChange = (date) => input.onChange(moment(date).format('MM/DD/YYYY').toString())

    return (
      <FormGroup
        label='Conversion date'
        tooltip={<Tooltip label={'Your entered license cost will be converted based on this date'}><Icon name={'Info'} /></Tooltip>}
        labelStyle={CSS.labelStyle}
      >
        <DatePicker
          {...props}
          {...input}
          value={licenseDate}
          format={'MM/DD/YYYY'}
          onDayChange={onDateChange}
          placeholder={licenseDate}
          dayPickerProps={{
            disabledDays: {
              before: minLicenseDate,
              after: maxLicenseDate
            }
          }}
        />
      </FormGroup>
    )
  }

  licensesTableComponent = ({ input, meta, ...props }) => {
    const { isLoadingLicenses, form, currencySymbols, appName, idOrg, idApp } = this.props

    const { values } = form.getState()
    const licenseCurrencyCode = values.licenseCurrencyCode

    return (
      <div {...CSS.wrapper}>
        <LicenseCostAndTotalAmountTable
          {...props}
          {...input}
          idOrg={idOrg}
          idApp={idApp}
          appName={appName}
          licenses={input.value}
          isLoading={isLoadingLicenses}
          currencySymbol={currencySymbols[licenseCurrencyCode]}
        />
      </div>
    )
  }

  userFieldPercentagesDivisionTableComponent = ({ input, meta, ...props }) => {
    return (
      <FormGroup error={meta.modified && meta.error}>
        <UserFieldPercentagesDivisionTable
          {...props}
          {...input}
        />
      </FormGroup>
    )
  }

  onChangeSelectedOption = (selected) => {
    const { idOrg, getUserFieldDisplayNames } = this.props

    const userFieldKey = selected && selected.value

    if (userFieldKey) {
      getUserFieldDisplayNames({ idOrg, userFieldKey })
    }
  }

  onAllocationStrategyChange = (selection) => {
    const { formInitialValues } = this.state

    const newFormInitialState = {
      allocationStrategy: selection.value,
      allocationCost: null,
      allocateCostByUserField: null,
      costPerUser: null,
      totalCost: null,
      licenses: formInitialValues.licenses,
      contractYearlyPrice: formInitialValues.contractYearlyPrice,
      totalExpensesOfYear: formInitialValues.totalExpensesOfYear,
      userFieldPercentagesDivision: [],
      licenseStatus: null,
      licenseCurrencyCode: formInitialValues.licenseCurrencyCode,
      licenseDate: formInitialValues.licenseDate
    }

    this.setState({ formInitialValues: newFormInitialState })
  }

  render () {
    const { userFieldsOptions, isLoading, isLoadingLicenses, userFieldsDisplayNames, currencySymbol } = this.props
    const { handleSubmit, submitError, values } = this.props

    let userFieldNamesPercentagesDetails = []
    if (values && values.allocateCostByUserField) {
      userFieldNamesPercentagesDetails = userFieldsDisplayNames[values.allocateCostByUserField.value].displayNames.map(userFieldDisplayName => {
        const userFieldPercentagesDivisionByDisplayName = keyBy(values.userFieldPercentagesDivision, 'userFieldDisplayName')
        const allocatedPercentage = ((userFieldPercentagesDivisionByDisplayName && userFieldPercentagesDivisionByDisplayName[userFieldDisplayName]) && userFieldPercentagesDivisionByDisplayName[userFieldDisplayName].allocatedPercentage) || 0
        return { userFieldDisplayName, allocatedPercentage }
      })
    }

    const isLoadingUserFieldDisplayNames =
      values &&
      values.allocateCostByUserField &&
      userFieldsDisplayNames[values.allocateCostByUserField.value] &&
      userFieldsDisplayNames[values.allocateCostByUserField.value].displayNames.length === 0 &&
      userFieldsDisplayNames[values.allocateCostByUserField.value].isLoading

    return (
      <form onSubmit={handleSubmit}>
        <Field
          name='allocationStrategy'
          label='Allocation strategy'
          options={Object.values(ALLOCATION_STRATEGY_OPTIONS)}
          isLoading={isLoading}
          isRequired
          component={this.selectFieldComponent}
          onChange={this.onAllocationStrategyChange}
        />

        <Field
          name='allocateCostByUserField'
          label='Allocate cost by'
          options={userFieldsOptions}
          onChange={this.onChangeSelectedOption}
          isLoading={isLoading}
          isRequired
          saveFullOptionDetails
          component={this.selectFieldComponent}
        />

        <Field
          name='allocationCost'
          label='Allocation cost'
          options={getAllocationCostOptionsByFormValues(values, isLoadingLicenses, currencySymbol)}
          disabled={Boolean(!values || !values.allocationStrategy || values.allocationStrategy === CHARGEBACK_ALLOCATION_STRATEGY.notConfigured)}
          isLoading={isLoading}
          isRequired
          component={this.selectFieldComponent}
        />

        {(values && values.allocationCost === CHARGEBACK_ALLOCATION_COST.manual) &&
        <Field
          name='totalCost'
          label='Total cost'
          isRequired
          prefix={currencySymbol}
          type='number'
          component={this.InputWithPrefixField}
        />
        }

        {(values && values.allocationCost === CHARGEBACK_ALLOCATION_COST.user) &&
        <Field
          name='costPerUser'
          label='Annual cost per user'
          isRequired
          prefix={currencySymbol}
          type='number'
          component={this.InputWithPrefixField}
        />
        }

        {(values && values.allocationCost === CHARGEBACK_ALLOCATION_COST.license) &&
          (<div>
            <div {...CSS.licenseCurrencyAndDate}>
              <div {...CSS.currencySelectContainer}>
                <Field
                  name='licenseCurrencyCode'
                  component={this.currencySelectorComponent}
                />
              </div>
              <div {...CSS.dateSelectContainer}>
                <Field
                  name='licenseDate'
                  component={this.dayInputComponent}
                />
              </div>
            </div>
            <Field
              name='licenses'
              component={this.licensesTableComponent}
            />
          </div>
          )
        }

        {(values && values.allocationStrategy === CHARGEBACK_ALLOCATION_STRATEGY.topDown && values.allocationCost && values.allocateCostByUserField) &&
        <Field
          name='userFieldPercentagesDivision'
          userField={values.allocateCostByUserField}
          userFieldNamesPercentagesDetails={userFieldNamesPercentagesDetails}
          isLoading={isLoadingUserFieldDisplayNames}
          totalCost={values.allocationCost === CHARGEBACK_ALLOCATION_COST.contract
            ? values.contractYearlyPrice
            : (values.allocationCost === CHARGEBACK_ALLOCATION_COST.expenses
              ? values.totalExpensesOfYear
              : values.totalCost)}
          component={this.userFieldPercentagesDivisionTableComponent}
        />
        }

        {submitError && (
          <div {...CSS.errorText}>
            {submitError}
          </div>
        )}
      </form>
    )
  }
}

ConfigureAppChargeback.propTypes = {
  idOrg: PropTypes.number,
  idApp: PropTypes.number,
  app: PropTypes.object,
  isLoading: PropTypes.bool,
  userFieldsOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  })),
  userFieldsDisplayNames: PropTypes.object,
  appChargebackConfig: PropTypes.object,
  onCancel: PropTypes.func,
  reset: PropTypes.func,
  onSuccess: PropTypes.func,
  onInitialValuesChanged: PropTypes.func
}

ConfigureAppChargeback.defaultProps = {
  onSuccess: () => {},
  onInitialValuesChanged: () => {}
}

ConfigureAppChargeback.contextType = ToriiPopupContext

export const validate = ({ allocationStrategy, allocationCost, allocateCostByUserField, licenses, costPerUser, totalCost, userFieldPercentagesDivision, licenseDate }) => {
  const errors = {}
  if (!allocationStrategy) {
    errors.allocationStrategy = 'Required'
  }
  if (allocationStrategy !== ALLOCATION_STRATEGY_OPTIONS.notConfigured.value) {
    if (!allocationCost) {
      errors.allocationCost = 'Required'
    }
    if (!allocateCostByUserField) {
      errors.allocateCostByUserField = 'Required'
    }
    if (allocationCost === ALLOCATION_COST_OPTIONS.user.value) {
      if (!costPerUser || costPerUser < 0) {
        errors.costPerUser = 'Invalid cost'
      }
    } else if (allocationCost === ALLOCATION_COST_OPTIONS.license.value && licenses.some(license => license.pricePerUser < 0)) {
      errors.licenses = 'Invalid license cost'
    } else if (allocationCost === ALLOCATION_COST_OPTIONS.license.value && licenses.some(license => isNumber(license.totalAmount) && license.isTotalAmountEnteredByUser && license.totalAmount < license.assignedAmount)) {
      errors.licenseDate = 'Invalid total amount'
    } else if (allocationCost === ALLOCATION_COST_OPTIONS.license.value && !moment(licenseDate).isBetween(minLicenseDate, maxLicenseDate)) {
      errors.licenseDate = 'Invalid license date'
    } else if (allocationCost === ALLOCATION_COST_OPTIONS.manual.value && (!totalCost || totalCost < 0)) {
      errors.totalCost = 'Invalid cost'
    } else if (allocationCost === ALLOCATION_COST_OPTIONS.manual.value || allocationCost === ALLOCATION_COST_OPTIONS.contract.value || allocationCost === ALLOCATION_COST_OPTIONS.expenses.value) {
      const userFieldPercentagesDivisionSum = sumBy(userFieldPercentagesDivision, 'allocatedPercentage')
      if (userFieldPercentagesDivision.length === 0) {
        errors.userFieldPercentagesDivision = 'Missing user field names'
      } else if (userFieldPercentagesDivision.some(userFieldNameDetails => userFieldNameDetails.allocatedPercentage < 0)) {
        errors.userFieldPercentagesDivision = 'Negative percentage detected. Please ensure all percentages are positive and total 100%.'
      } else if (userFieldPercentagesDivisionSum !== 100) {
        errors.userFieldPercentagesDivision = `Total ${userFieldPercentagesDivisionSum > 100 ? 'exceeds' : 'below'} 100%. Please ensure the percentages equal 100%.`
      }
    }
  }

  return errors
}

export default ConfigureAppChargeback
