import React from 'react'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import { ButtonType, IconName } from '@toriihq/design-system'
import * as Style from './style'

type Props = {
  decline?: Function,
  confirm?: Function,
  close?: (...args: any[]) => any,
  isOpen: boolean,
  header?: JSX.Element | string
  text?: JSX.Element | string
  confirmText?: string,
  declineText?: string,
  mainButtonType?: typeof ButtonType[keyof typeof ButtonType],
  mainButtonIcon?: IconName
  modalWidth?: string
  showCancelButton?: boolean
  ignoreViewOnlyMode?: boolean
}

export const Confirmation = ({
  decline = () => {},
  confirm = () => {},
  close = () => {},
  isOpen,
  showCancelButton = true,
  header = 'Are you sure?',
  text,
  confirmText = 'Yes',
  declineText = 'No',
  mainButtonType = ButtonType.primary, modalWidth,
  ignoreViewOnlyMode = false,
  mainButtonIcon
}: Props) : JSX.Element => {
  return <ToriiPopup isOpen={isOpen} onCloseAction={close} styles={Style.Popup(modalWidth)}>
    <ToriiPopup.Header header={header} />
    {text && <ToriiPopup.Content>
      {text}
    </ToriiPopup.Content>}
    <ToriiPopup.Footer
      showMainButton
      mainButtonAction={confirm}
      mainButtonText={confirmText}
      showCancelButton={showCancelButton}
      cancelButtonAction={decline}
      cancelButtonText={declineText}
      mainButtonType={mainButtonType}
      mainButtonIcon={mainButtonIcon}
      ignoreViewOnlyMode={ignoreViewOnlyMode}
    />
  </ToriiPopup>
}

export default Confirmation
