import styled from 'styled-components'

export const AnimatedAlertBoxContainer = styled.div`
  width: 100%;
  animation: slideIn 0.3s ease-in-out;

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-10%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`
