import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getIsOnlyAppOwner } from '@selectors/me'
import { SUPPORT_ARTICLES } from '@root/articles'

const mapStateToProps = (state) => {
  const isOnlyAppOwner = getIsOnlyAppOwner(state)
  const helpCenterURL = isOnlyAppOwner ? SUPPORT_ARTICLES.APP_OWNER_INTRODUCTION : SUPPORT_ARTICLES.HELP_CENTER
  return {
    helpCenterURL
  }
}

export default withRouter(connect(mapStateToProps)(View))
