export enum REPORT_KEY {
    USERS = 'users',
    CONTRACTS = 'contracts',
    APPS = 'apps',
    TRANSACTIONS = 'transactions',
    APP_USERS = 'appUsers'
}

export enum SCHEDULE_FREQUENCY {
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
    ONCE = 'once',
    DAILY = 'daily'
}

type sharedPayload = {
    reportKey: REPORT_KEY
    config: Record<string, any>
    reportName: string
    messageDetails: Record<string, any>
}

type OncePayload = sharedPayload & { scheduleFrequency: SCHEDULE_FREQUENCY.ONCE }
type NonOncePayload = sharedPayload & {
    scheduleFrequency: Exclude<SCHEDULE_FREQUENCY, SCHEDULE_FREQUENCY.ONCE>
    timeZone: string
    cron: string
}

export type CreateReportParams = {
    idOrg: number
    payload: OncePayload | NonOncePayload
}
