import { AGGREGATION_TYPES, METRIC_FUNC, WidgetData } from '@reducers/dashboards/types'

export const generateMetricParams = ({ metricFunc, metricField }: { metricFunc?: METRIC_FUNC, metricField?: string }): {} | { withoutContent: boolean } | { limit: number, aggs: object } => {
  if (!metricFunc) {
    return {}
  }

  if (metricFunc === METRIC_FUNC.TOTAL) {
    return { withoutContent: true }
  }

  if ([METRIC_FUNC.MIN, METRIC_FUNC.MAX, METRIC_FUNC.AVG, METRIC_FUNC.SUM].includes(metricFunc) && metricField) {
    return {
      limit: 0,
      aggs: {
        field: metricField,
        aggregationType: AGGREGATION_TYPES.METRIC,
        options: {
          metricFunction: metricFunc
        }
      }
    }
  }

  return {}
}

export const getMetricValue = ({ metricFunc, data }: { metricFunc?: METRIC_FUNC, data?: WidgetData['data'] }): number => {
  if (metricFunc === METRIC_FUNC.TOTAL) {
    return data?.total ?? 0
  }

  if (metricFunc && [METRIC_FUNC.MIN, METRIC_FUNC.MAX, METRIC_FUNC.AVG, METRIC_FUNC.SUM].includes(metricFunc)) {
    return (data?.aggregations as number) ?? 0
  }

  return 0
}
