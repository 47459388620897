import React from 'react'
import { isEqual } from 'lodash'
import { DropdownOption } from '@components/workflows/formField/components/dropdowns/types'
import { Dropdown } from '@components/workflows/formField/components/dropdowns/dropdown'

export interface SimpleDropdownProps {
  options: DropdownOption[]
  value: DropdownOption | null
  onChange: (value: DropdownOption) => void
  disabled?: boolean
  loading?: boolean
  innerSelectKey?: string
}

export const SimpleDropdown = ({
  options,
  value,
  onChange,
  disabled,
  loading,
  innerSelectKey
}: SimpleDropdownProps) => <Dropdown
  field={{
    options,
    loading
  }}
  input={{
    value: value ?? undefined,
    onChange: (updatedValue) => {
      if (!isEqual(updatedValue?.value ?? null, value?.value ?? null)) {
        onChange(updatedValue)
      }
    },
    disabled
  }}
  innerSelectKey={innerSelectKey}
/>
