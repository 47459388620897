import styled from 'styled-components'
import { ScreenBreakpoints } from '@toriihq/design-system'

const DEFAULT_DRAWER_WIDTH = 346

export const RightSide = styled.div<{ $width: string }>`
  display: flex;
  position: relative;
  overflow-x: hidden;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.palette.background.primary};
  box-shadow: -20px 0 20px -20px rgba(206, 225, 252, 0.5);
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  width: ${({ $width }) => $width};
`

export const DrawerContainer = styled.div`
  width: 0;
  height: 0;
  z-index: 6;
`

export const StyledLayout = styled.div`
  min-height: 100vh;
  width: 100%;
  background: ${({ theme }) => theme.palette.background.primary};
  overscroll-behavior: none;

  .mainWrapper:has(.drawer) {
    transition: margin-right 0.3s ease;
    margin-right: 0;
  }

  .mainWrapper:has(.drawer-open) {
    margin-right: ${DEFAULT_DRAWER_WIDTH}px;
  }
`

export const TopMessageBarsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  z-index: 10;
`

export const MainWrapper = styled.section`
  display: flex;
  flex: 1;
  min-height: 0;
  overflow-y: auto;
  overflow-x: hidden;

  @media(min-width: ${ScreenBreakpoints.sm}) {
    padding-left: 48px;
    padding-right: 48px;
  }

  @media(min-width: ${ScreenBreakpoints.md}) {
    padding-left: 64px;
    padding-right: 64px;
  }
`

export const MobileMainWrapper = styled.section`
  display: flex;
  flex-grow: 1;
  min-height: 0;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 20px;
  padding-right: 20px;
`

export const MobileBodyWrapper = styled.div<{ $top: number }>`
  position: absolute;
  right: 0;
  top: ${({ $top }) => `${$top}px`};
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overscroll-behavior: none;
`

export const BodyWrapper = styled.div<{ $top: number }>`
  position: absolute;
  right: 0;
  top: ${({ $top }) => `${$top}px`};
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
`

export const SearchBarWrapper = styled.div<{ $hasShadow?: boolean }>`
  display: flex;
  flex-direction: row;
  position: sticky;
  background-color: ${({ theme }) => theme.palette.background.primaryActive};
  border-bottom: ${({ $hasShadow, theme }) => `1px solid ${$hasShadow ? theme.palette.border.secondary : theme.palette.background.primary}`};
  z-index: 5;
`

export const SearchBarMobileWrapper = styled(SearchBarWrapper)`
  flex-grow: 1;
`

export const MobileHeaderWrapper = styled.div<{ $hasShadow?: boolean }>`
  display: flex;
  flex-direction: row;
  border-bottom: ${({ $hasShadow, theme }) => `1px solid ${$hasShadow ? theme.palette.border.secondary : theme.palette.background.primary}`};
`
