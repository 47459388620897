export enum IMPORT_TYPE {
  USERS = 'users',
  LICENSES = 'licenses'
}

export interface ImportUsersPopup {
  isOpen: boolean
  idApp: number
  appName: string
  importMode: IMPORT_TYPE
  isFirstTimeImport: boolean
  lastSyncTime?: string
  idAppAccount?: number
  appAccountName?: string
  onSubmitSuccess?: (params: { idAppAccount: number }) => void
}
