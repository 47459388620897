import emptyImage from '@media/dance.svg'
import { getCurrentApp } from '@selectors/apps'
import { getServicesWithConnectStatusMultipleAccounts } from '@selectors/services'
import { toggleConnectSCIMService, toggleConnectService, toggleConnectSource, toggleImportUsers } from '@actions/'
import { useDispatch, useSelector } from 'react-redux'
import { getServiceInfo } from '@shared/services'
import isEmpty from 'lodash/isEmpty'
import { SCOPES } from '@root/constants'
import { getCurrentOrg } from '@selectors/org'
import openConnectServicePopup from '@shared/services/openConnectServicePopup'
import ImportUsersAnalytics from '@components/popups/importUsersPopup/analytics'
import { getAppOwnerScopeByIdOrgAndIdApp } from '@lenses/scopes'
import EnableFor from '@components/enableFor'
import { Button, ButtonType, EmptyState } from '@toriihq/design-system'
import { IMPORT_TYPE } from '@root/store/selectors/importUsers/types'

const UsersEmptyState = (): JSX.Element | null => {
  const dispatch = useDispatch()
  const { id: idOrg } = useSelector(getCurrentOrg)
  const currentApp = useSelector(getCurrentApp)
  const services = useSelector(getServicesWithConnectStatusMultipleAccounts)
  const isLoading = !idOrg || currentApp.loading

  if (isLoading) {
    return null
  }

  const { app } = currentApp
  const { id: idApp, name: appName } = app
  const service = getServiceInfo({ services, idApp, appName })
  const hasConnectedUsersIntegration = !isEmpty(service) && service.isNativeUsersIntegration && !service.isConnected

  if (hasConnectedUsersIntegration) {
    const {
      source,
      integrationType,
      supportsMultipleAccounts
    } = service

    const handleButtonClick = () => openConnectServicePopup({
      idApp,
      appName,
      integrationType,
      source,
      supportsMultipleAccounts,
      calledFrom: 'App users tab',
      dispatchers: {
        toggleConnectSource: params => dispatch(toggleConnectSource(params)),
        toggleConnectService: params => dispatch(toggleConnectService(params)),
        toggleConnectSCIMService: params => dispatch(toggleConnectSCIMService(params))
      }
    })

    return <EmptyState
      image={<img src={emptyImage} alt='No users' />}
      title={`Torii has not discovered any ${appName} users`}
      description={`Discover users and get insights by connecting the ${appName} integration.`}
      buttons={[
        <EnableFor scopes={[SCOPES.APPLICATIONS_WRITE]} allowForToriiAdmin>
          <Button key='configureChargeback' type={ButtonType.primary} onClick={handleButtonClick} label='Connect integration' />
        </EnableFor>
      ]}
    />
  }

  const handleButtonClick = () => {
    const importMode = IMPORT_TYPE.USERS
    dispatch(toggleImportUsers({ isOpen: true, idApp, appName, importMode, isFirstTimeImport: true }))
    ImportUsersAnalytics.onOpenImportPopup(importMode, 'Import users (empty state)', appName)
  }
  return <EmptyState
    image={<img src={emptyImage} alt='No users' />}
    title={`Torii has not discovered any ${appName} users`}
    description={`
        Make sure ${appName} is connected to your SSO provider and the Torii browser extension
        has been fully deployed. You can also import users from a CSV.
      `}
    buttons={[
      <EnableFor scopes={[SCOPES.APPLICATIONS_WRITE, getAppOwnerScopeByIdOrgAndIdApp(idOrg, idApp)]} allowForToriiAdmin>
        <Button key='configureChargeback' type={ButtonType.primary} onClick={handleButtonClick} label='Import users' />
      </EnableFor>
    ]}
  />
}

export default UsersEmptyState
