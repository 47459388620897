import { createSelector } from 'reselect'
import identity from 'lodash/identity'
import get from 'lodash/get'
import { State } from '@reducers/types'
import { ScheduledReport } from '@actions/scheduledReports/types'
import { SCOPES } from '@root/constants'
import { getSelf } from '@selectors/me'

const getScheduledReports = createSelector<State, ScheduledReport[], ScheduledReport[]>(
  (state: State) => get(state, ['scheduledReports', 'scheduledReports'], []),
  identity
)

export const getScheduledReportsExtraData = createSelector<State, ScheduledReport[], State['me'], Array<ScheduledReport & { isAbleToEditReport: boolean }>>(
  [getScheduledReports, getSelf],
  (reports, currentUser) => {
    const userScopes = new Set(currentUser.scopes ?? [])

    return reports.map(report => ({
      ...report,
      isAbleToEditReport: userScopes.has(SCOPES.ALL_SCHEDULED_REPORTS_WRITE) || currentUser.id === report.createdBy
    }))
  }
)

export const getScheduledReportsResources = createSelector(
  (state: State) => get(state, ['scheduledReports', 'resources'], {}),
  identity
)

export const isLoadingScheduledReports = createSelector(
  (state: State) => get(state, ['scheduledReports', 'loading'], false),
  identity
)
