import React from 'react'
import {
  Body2,
  Col
} from '@toriihq/design-system'
import { LicenseBarContainer, RightAlignedDiv, RowWithMargin } from '@pages/renewalForecast/recommendation/style'
import Currency from '@components/currency'
import LicenseBar from '@components/licensesChart/licenseBar'
import { LicenseBarAndTableRowProps } from '@pages/renewalForecast/recommendation/types'

const LicenseBarAndTableRow = ({
  idOrg,
  idApp,
  licensesDetails,
  rowMarginBottom,
  rowName,
  shouldRenderBar,
  licenseActiveAmount,
  licenseInactiveAmount,
  licenseUnassignedAmount,
  licenseValidTotalAmount,
  licenseBufferAmount,
  bufferValue,
  barWidthAmount,
  totalLicenses,
  annualCost,
  totalCost
}:LicenseBarAndTableRowProps): JSX.Element => {
  return (<RowWithMargin marginBottom={rowMarginBottom}>
    <Col xs={3} sm={2.5} md={2} >
      <Body2>
        {rowName}
      </Body2>
    </Col>
    <Col xs={3} sm={5.1} md={5.5}>
      {shouldRenderBar ? <LicenseBarContainer>
        <LicenseBar
          key={1}
          idLicense={1}
          idApp={idApp}
          idOrg={idOrg}
          activeAmount={licenseActiveAmount}
          inactiveAmount={licenseInactiveAmount}
          inactiveReasons={{}}
          unassignedAmount={licenseUnassignedAmount}
          bufferAmount={licenseBufferAmount}
          bufferValue={bufferValue}
          totalAmount={licenseValidTotalAmount}
          name={'Current'}
          disableInactiveClick
          barWidth={(barWidthAmount * 100) / licensesDetails.maxLicensesAmount}
          barOnly
        />
      </LicenseBarContainer> : null}
    </Col>
    <Col xs={2} sm={1.5} md={1.5}>
      <RightAlignedDiv>
        <Body2>
          {totalLicenses}
        </Body2>
      </RightAlignedDiv>
    </Col>
    <Col xs={2} sm={1.45} md={1.5}>
      <RightAlignedDiv>
        <Body2>
          <Currency value={annualCost} />
        </Body2>
      </RightAlignedDiv>
    </Col>
    <Col xs={2} sm={1.45} md={1.5}>
      <RightAlignedDiv>
        <Body2>
          <Currency value={totalCost} />
        </Body2>
      </RightAlignedDiv>
    </Col>
  </RowWithMargin>
  )
}

export default LicenseBarAndTableRow
