import styled, { css } from 'styled-components'

export const Placeholder = {
  height: '48px',
  width: '300px'
}

export const CardContent = styled.div<{ isSelected: boolean }>`
  width: 100%;

  ${({ isSelected }) => !isSelected && css`
    button {
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    &:hover button {
      opacity: 1;
    }
  `}
`

export const TitleWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font: ${({ theme }) => theme.typography.font.header02};
`
