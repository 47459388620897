import { Reducer } from 'redux'
import { UPDATE_SCHEDULED_REPORT, DELETE_SCHEDULED_REPORT, GET_SCHEDULED_REPORTS } from '@root/constants'
import { ScheduledReportsState } from '@reducers/scheduledReports/types'

const initialState: ScheduledReportsState = {
  scheduledReports: [],
  loading: false,
  resources: { users: {} },
  scheduledReportsTimestamp: new Date()
}

const scheduledReportsReducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_SCHEDULED_REPORTS}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case `${GET_SCHEDULED_REPORTS}_RESPONSE`: {
      const { reports, resources } = action.payload
      return {
        ...state,
        scheduledReports: reports,
        loading: false,
        resources: { users: resources.users }
      }
    }
    case `${DELETE_SCHEDULED_REPORT}_RESPONSE`: {
      const { idReport } = action.meta
      const scheduledReports = state.scheduledReports
      const updatedScheduledReports = scheduledReports.filter(report => report.id !== idReport)

      return {
        ...state,
        scheduledReports: updatedScheduledReports
      }
    }
    case `${UPDATE_SCHEDULED_REPORT}_PENDING`: {
      const { idReport, isEnabled } = action.meta
      const scheduledReports = state.scheduledReports

      const updatedScheduledReports = scheduledReports.map(report => report.id === idReport ? {
        ...report,
        isEnabled
      } : report)

      return {
        ...state,
        scheduledReports: updatedScheduledReports
      }
    }
    case `${UPDATE_SCHEDULED_REPORT}_RESPONSE`: {
      const { timestamp: newTimestamp } = action.meta
      const { report: { id, reportName, messageDetails, scheduleDetails, isEnabled, lastUpdatedOn, lastUpdatedBy, nextScheduledTime } } = action.payload
      const { scheduledReports, scheduledReportsTimestamp: lastTimestamp } = state
      const gotNewerResult = lastTimestamp ? lastTimestamp < newTimestamp : true

      const updatedScheduledReports = scheduledReports.map(report => report.id === id ? {
        ...report,
        ...{ reportName, messageDetails, scheduleDetails, isEnabled, lastUpdatedOn, lastUpdatedBy, nextScheduledTime }
      } : report)

      return {
        ...state,
        ...(gotNewerResult && {
          scheduledReports: updatedScheduledReports
        })
      }
    }
    default: {
      return state
    }
  }
}

export default scheduledReportsReducer
