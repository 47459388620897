import { createSelector } from 'reselect'
import identity from 'lodash/identity'
import { ConnectedAppUsersStatusesBreakdown, AppUsersStats, State as AppState } from '@reducers/app.types'

export const getConnectedAppUsersStatusesBreakdown = createSelector(
  (state: { app: AppState }) => state.app.connectedAppUsersStatusesBreakdown as ConnectedAppUsersStatusesBreakdown,
  identity
)

export const getAppUsersStats = createSelector(
  (state: { app: AppState }) => state.app.appUsersStats as AppUsersStats,
  identity
)
