import {
  runServicesSync,
  markSyncAsRun,
  updateAppAccountName,
  getOrg,
  getAppDetailsFields,
  getStateDetails,
  getServicesSyncData,
  disconnectService,
  toggleConnectSource,
  toggleConnectService,
  toggleConnectSCIMService,
  toggleSyncStatusPopup
} from '@actions/'
import AccessControl from '@lenses/accessControl'
import { getCompanyName } from '@selectors/org'
import { getTimePassedText } from '@shared/utils/getTimePassedText'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const companyName = getCompanyName(state)

  const isServiceEnabledInPlan = AccessControl.isFeatureEnabledInPlan({ feature: ownProps.feature, state })

  return {
    idOrg,
    isServiceEnabledInPlan,
    companyName
  }
}

const mapDispatchToProps = {
  runServicesSync,
  markSyncAsRun,
  updateAppAccountName,
  disconnectService,
  getOrg,
  getAppDetailsFields,
  getStateDetails,
  getServicesSyncData,
  toggleConnectSource,
  toggleConnectService,
  toggleConnectSCIMService,
  toggleSyncStatusPopup
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { idOrg } = stateProps
  const { appName, appAccountName, idAppToken, source, idApp, idAppAccount } = ownProps

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    disconnectService: dispatchProps.disconnectService.bind(null, { idOrg, source, appName, accountName: appAccountName, idApp, idAppToken, idAppAccount })
  }
}

export const getSyncedText = ({ lastSyncTime, isCustomIntegration = false }) => {
  if (isCustomIntegration && !lastSyncTime) {
    return 'Waiting for first data sync from API'
  }

  if (!lastSyncTime) {
    return 'Never fully synced'
  }

  const timePassedText = getTimePassedText({ date: lastSyncTime, smallestUnit: 'hours' })
  return `Successfully synced ${timePassedText}`
}

const exported = {
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
}

export default exported
