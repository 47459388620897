import { type WorkBook, type WorkSheet } from 'xlsx'

export const exportExcel = async (filename: string, data: any[], columnNames: string[]): Promise<void> => {
  const { utils, writeFile } = await import('xlsx')
  const worksheet: WorkSheet = utils.json_to_sheet(data, { header: columnNames })
  const workbook: WorkBook = utils.book_new()
  utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  writeFile(workbook, filename)
}

export default exportExcel
