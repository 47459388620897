import React from 'react'
import { Tooltip, Link } from '@toriihq/design-system'
import { formFieldTypes, SCOPES,
  EXPENSES_TABLE_SECONDARY_CUSTOM_SELECT_OPTIONS,
  EXPENSES_RANGE_OPTIONS } from '../../constants'
import SelectState from '../selectState'
import AppDetails from '../appDetails'
import UsageIcon from '../usageIcon'
import RelativeTeamLink from '../../components/relativeTeamLink'
import colors from '../../shared/style/colors'
import { fontWeight } from '@shared/style/sizes'
import Table from '../table'
import { css } from 'glamor'
import moment from 'moment'
import SourceIcon from '../sourceIcon'
import appsToolTip from '../../shared/appToolTip'
import getColumnByFieldType from '../table/columnFieldTypes'
import RiskIcon from '@components/riskIcon'
import { get, round } from 'lodash'
import Analytics from '@helpers/analytics'
import EnableFor from '@components/enableFor'
import { getFormattedDate } from '@lenses/utils'
import ActiveUsersColumn from './activeUsersColumn'
import Currency from '@components/currency'
import * as Style from './styles'
import OverflowTooltip from '@components/overflowTooltip'
import { getAppOwnerScopeByIdOrgAndIdApp } from '@lenses/scopes'

const CSS = {
  nameAndIcon: css({
    ':hover': {
      color: colors.blue
    }
  }),
  connectServiceButton: css({
    pointerEvents: 'auto'
  }),
  riskLevel: css({
    display: 'flex'
  })
}

CSS.icon = css({
  width: '30px',
  marginRight: '15px',
  borderRadius: '4px',
  border: '1px solid transparent',
  transition: 'border-color .2s',
  [`.${CSS.nameAndIcon}:hover &, [data-${CSS.nameAndIcon}]:hover &`]: {
    borderColor: colors.blue
  }
})

CSS.appName = css({
  display: 'flex',
  alignItems: 'center',
  transition: 'color .2s',
  fontWeight: fontWeight.semiBold,
  [`.${CSS.nameAndIcon}:hover &, [data-${CSS.nameAndIcon}]:hover &`]: {
    color: colors.blue
  }
})

const AppDetailsCell = ({ value: name, row: { id, category, imageUrl, isNew } }) => (
  <AppDetails
    id={id}
    name={name}
    category={category}
    imageUrl={imageUrl}
    isNew={isNew}
    component='Apps list'
  />
)
const UsersCell = ({ value: activeUsersCount, row }) => {
  return <ActiveUsersColumn idApp={row.id} activeUsersCount={activeUsersCount} />
}

const ExpensesInLast12MonthsCell = ({ value: expenses, row }) => {
  const last12MonthsSelectValue = EXPENSES_TABLE_SECONDARY_CUSTOM_SELECT_OPTIONS[EXPENSES_RANGE_OPTIONS.LAST_12_MONTHS]

  return <EnableFor scopes={[SCOPES.EXPENSE_READ]} allowForToriiAdmin>
    <RelativeTeamLink key={`/app/${row.id}/expenses`} to={`/app/${row.id}/expenses`} search={`secondaryCustomSelectSelectedValue=${JSON.stringify(last12MonthsSelectValue)}`}>
      <Link><Currency value={expenses} /></Link>
    </RelativeTeamLink>
  </EnableFor>
}

const ExpensesInActiveContractDuration = ({ value: expensesInActiveContractTerm, row: { expenses, activeContractTerm } }) => {
  const hasExpensesAndActiveContractsDuration = expenses && activeContractTerm?.latestEndDate && activeContractTerm?.earliestStartDate

  return expensesInActiveContractTerm || hasExpensesAndActiveContractsDuration
    ? <EnableFor scopes={[SCOPES.EXPENSE_READ]} allowForToriiAdmin>
      <Currency value={expensesInActiveContractTerm} />
    </EnableFor> : null
}

const ExpensesComparedToActiveContractValueInPercentage = ({ value, row: { expensesInActiveContractTerm } }) => expensesInActiveContractTerm ? `${round(value)}%` : null

const ActiveContractsTotalValueCell = ({ value: activeContractsTotalValue, row: { activeContractsCount } }) => activeContractsCount ? <Currency value={activeContractsTotalValue} /> : null
const ActiveContractsAmountCell = ({ value: activeContractsCount, row }) => {
  const onClick = () => Analytics.track('Click on active contracts / Review tab / Applications')
  return activeContractsCount ? (
    <EnableFor scopes={[SCOPES.CONTRACTS_READ]} allowForToriiAdmin>
      <RelativeTeamLink key={`/app/${row.id}}/contracts`} to={`/app/${row.id}/contracts`} search={`filters=[{"key":"status","op":"equals","value":{"value": "active"}}]`} onClick={onClick}>
        <Link>{activeContractsCount}</Link>
      </RelativeTeamLink>
    </EnableFor>
  ) : null
}
const UsageCell = ({ original: { score, isCore, lastVisitTime } }) => <UsageIcon score={score} isCore={isCore} lastVisitTime={lastVisitTime} tooltipText='on average' />
const CreationTimeCell = ({ row: { creationTime } }) => getFormattedDate({ date: creationTime, emptyValueDefaultDisplay: null })
const LastVisitTimeCell = ({ row: { lastVisitTime } }) => getFormattedDate({ date: lastVisitTime, emptyValueDefaultDisplay: 'No date' })
const SourcesCell = ({ row: { sources, name, addedBy } }) => (
  <div>
    {sources.map(source => (
      <SourceIcon isAppSource key={source} sourceType={source} tooltipText={appsToolTip({ appName: name, source, addedBy })} />
    ))}
  </div>
)
const RiskLevelCell = ({ riskLevel }) => <div {...CSS.riskLevel}><RiskIcon riskLevel={riskLevel} />{riskLevel}</div>

const HasIntegrationCell = ({ original: { hasIntegration } }) => hasIntegration ? <Style.StyledIcon name='CheckCircleFill' color='interactive' /> : null

const IsConnectedCell = ({ original: { isConnected } }) => isConnected ? <Style.StyledIcon name='CheckCircleFill' color='interactive' /> : null

const RecommendedToConnectCell = ({ original: { recommendedToConnect } }) => recommendedToConnect ? <Style.StyledIcon name='CheckCircleFill' color='interactive' /> : null

const customFieldPropsByType = (columnsRestrictions) => {
  return {
    [formFieldTypes.fileUpload]: () => ({
      width: 150,
      Cell: ({ value, row: { id } }) => {
        const count = (value && value.length) || (value && 1)
        const countDisplay = (
          <EnableFor scopes={[SCOPES.EXPENSE_READ]} allowForToriiAdmin>
            <RelativeTeamLink to={`/app/${id}/info`}><Link>{count}</Link></RelativeTeamLink>
          </EnableFor>
        )
        return count > 0 ? countDisplay : null
      }
    }),
    [formFieldTypes.currency]: () => ({
      show: !columnsRestrictions || (columnsRestrictions.hasContractsAccess && columnsRestrictions.hasExpensesAccess && columnsRestrictions.hasLicenseCostAndChargebackAccess)
    })
  }
}

const getPreDefinedColumns = ({ columns, columnsConfiguration, stateInfo, columnsRestrictions, idOrg }) =>
  [
    {
      Header: columns.name,
      accessor: 'name',
      minWidth: 200,
      maxWidth: 300,
      className: CSS.nameAndIcon.toString(),
      Cell: AppDetailsCell,
      show: Boolean(columnsConfiguration.name),
      style: {
        paddingLeft: '5px'
      }
    },
    {
      Header: columns.displayActiveUsersCount,
      accessor: 'displayActiveUsersCount',
      width: 100,
      Cell: UsersCell,
      show: Boolean(columnsConfiguration.displayActiveUsersCount) || Boolean(columnsConfiguration.activeUsersCount),
      ...Table.numericFieldProps
    },
    {
      Header: columns.expenses,
      accessor: 'expenses',
      Cell: ExpensesInLast12MonthsCell,
      textHeader: columns.expenses,
      ...Table.numericFieldProps,
      width: 180,
      show: columnsRestrictions.hasExpensesAccess && Boolean(columnsConfiguration.expenses)
    },
    {
      Header: columns.expensesInActiveContractTerm,
      accessor: 'expensesInActiveContractTerm',
      Cell: ExpensesInActiveContractDuration,
      ...Table.numericFieldProps,
      width: 180,
      show: columnsRestrictions.hasExpensesAccess && Boolean(columnsConfiguration.expensesInActiveContractTerm)
    },
    {
      Header: columns.expensesComparedToActiveContractValue,
      accessor: 'expensesComparedToActiveContractValue',
      Cell: ExpensesComparedToActiveContractValueInPercentage,
      ...Table.numericFieldProps,
      width: 180,
      show: columnsRestrictions.hasContractsAccess && columnsRestrictions.hasExpensesAccess && Boolean(columnsConfiguration.expensesComparedToActiveContractValue)
    },
    {
      Header: columns['activeContractTerm.earliestStartDate'],
      id: 'activeContractTerm.earliestStartDate',
      accessor: ({ activeContractTerm }) => getFormattedDate({ date: activeContractTerm?.earliestStartDate, emptyValueDefaultDisplay: null }),
      minWidth: 160,
      show: columnsRestrictions.hasContractsAccess && Boolean(columnsConfiguration['activeContractTerm.earliestStartDate'])
    },
    {
      Header: columns['activeContractTerm.latestEndDate'],
      id: 'activeContractTerm.latestEndDate',
      accessor: ({ activeContractTerm }) => getFormattedDate({ date: activeContractTerm?.latestEndDate, emptyValueDefaultDisplay: null }),
      minWidth: 160,
      show: columnsRestrictions.hasContractsAccess && Boolean(columnsConfiguration['activeContractTerm.latestEndDate'])
    },
    {
      Header: (
        <Tooltip
          label={<Style.HeaderTooltipLabel>Usage is calculated based on visit frequency and total number of users in the last 30 days</Style.HeaderTooltipLabel>}
        >
          {columns.score}
        </Tooltip>
      ),
      textHeader: columns.score,
      accessor: 'score',
      maxWidth: 140,
      Cell: UsageCell,
      show: Boolean(columnsConfiguration.score)
    },
    {
      Header: columns.state,
      accessor: 'state',
      Cell: ({ value, row: { id } }) => <SelectState
        {...stateInfo}
        idApp={id}
        selectedValue={stateInfo && get(stateInfo.options.find(o => o.label === value), 'value')}
        allowedScopes={[SCOPES.APPLICATIONS_WRITE, SCOPES.APP_OWNER_WRITE, getAppOwnerScopeByIdOrgAndIdApp(idOrg, id)]}
        pageName='Apps page'
      />,
      style: { overflow: 'inherit' },
      width: 210,
      show: Boolean(columnsConfiguration.state)
    },
    {
      Header: columns.tags,
      accessor: 'tags',
      Cell: ({ value: tags }) => tags ? tags.join(', ') : null,
      show: Boolean(columnsConfiguration.tags)
    },
    {
      Header: columns.creationTime,
      id: 'creationTime',
      accessor: ({ creationTime }) => creationTime ? moment.utc(creationTime).toDate().getTime() : null,
      Cell: CreationTimeCell,
      minWidth: 160,
      show: Boolean(columnsConfiguration.creationTime)
    },
    {
      Header: columns.category,
      accessor: 'category',
      minWidth: 160,
      show: Boolean(columnsConfiguration.category)
    },
    {
      Header: columns.vendor,
      accessor: 'vendor',
      minWidth: 160,
      show: Boolean(columnsConfiguration.vendor)
    },
    {
      accessor: 'imageUrl',
      show: false
    },
    {
      accessor: 'isNew',
      show: false
    },
    {
      accessor: 'integrationActiveUsersCount',
      show: false
    },
    {
      Header: columns.lastVisitTime,
      id: 'lastVisitTime',
      accessor: ({ lastVisitTime }) => lastVisitTime ? moment.utc(lastVisitTime).toDate().getTime() : null,
      Cell: LastVisitTimeCell,
      minWidth: 160,
      show: Boolean(columnsConfiguration.lastVisitTime)
    },
    {
      Header: columns.sources,
      accessor: 'sources',
      minWidth: 110,
      show: Boolean(columnsConfiguration.sources),
      Cell: SourcesCell
    },
    {
      Header: columns.GSuiteRiskLevel,
      accessor: 'GSuiteRiskLevel',
      Cell: ({ row: { GSuiteRiskLevel } }) => GSuiteRiskLevel ? <RiskLevelCell riskLevel={GSuiteRiskLevel} /> : null,
      minWidth: 120,
      show: Boolean(columnsConfiguration.GSuiteRiskLevel)
    },
    {
      Header: columns.azureRiskLevel,
      accessor: 'azureRiskLevel',
      Cell: ({ row: { azureRiskLevel } }) => azureRiskLevel ? <RiskLevelCell riskLevel={azureRiskLevel} /> : null,

      minWidth: 120,
      show: Boolean(columnsConfiguration.azureRiskLevel)
    },
    {
      Header: columns.slackRiskLevel,
      accessor: 'slackRiskLevel',
      Cell: ({ row: { slackRiskLevel } }) => slackRiskLevel ? <RiskLevelCell riskLevel={slackRiskLevel} /> : null,

      minWidth: 120,
      show: Boolean(columnsConfiguration.slackRiskLevel)
    },
    {
      Header: columns.activeContractsCount,
      accessor: 'activeContractsCount',
      Cell: ActiveContractsAmountCell,
      minWidth: 140,
      maxWidth: 200,
      show: columnsRestrictions.hasContractsAccess && Boolean(columnsConfiguration.activeContractsCount)
    },
    {
      Header: columns.activeContractsTotalValue,
      accessor: 'activeContractsTotalValue',
      Cell: ActiveContractsTotalValueCell,
      ...Table.numericFieldProps,
      minWidth: 175,
      maxWidth: 250,
      show: columnsRestrictions.hasContractsAccess && Boolean(columnsConfiguration.activeContractsTotalValue)
    },
    {
      accessor: 'id',
      show: false
    },
    {
      accessor: 'addedBy',
      show: false
    },
    {
      accessor: 'activeContractTerm',
      show: false
    },
    {
      Header: columns.hasIntegration,
      accessor: 'hasIntegration',
      width: 120,
      Cell: HasIntegrationCell,
      show: Boolean(columnsConfiguration.hasIntegration)
    },
    {
      Header: columns.isConnected,
      accessor: 'isConnected',
      width: 120,
      Cell: IsConnectedCell,
      show: Boolean(columnsConfiguration.isConnected)
    },
    {
      Header: columns.recommendedToConnect,
      accessor: 'recommendedToConnect',
      width: 120,
      Cell: RecommendedToConnectCell,
      show: Boolean(columnsConfiguration.recommendedToConnect)
    },
    {
      Header: columns.id,
      accessor: 'id',
      width: 120,
      Cell: ({ original: { id } }) => id,
      show: Boolean(columnsConfiguration.id)
    },
    {
      Header: columns.description,
      accessor: 'description',
      show: Boolean(columnsConfiguration.description),
      width: 200,
      Cell: ({ value }) => <OverflowTooltip label={value}>{value}</OverflowTooltip>
    },
    {
      Header: columns.url,
      accessor: 'url',
      show: Boolean(columnsConfiguration.url),
      width: 200,
      Cell: ({ value }) => <Link href={value} target='_blank'>{value}</Link>
    }
  ]

const getColumns = ({ stateOrder = null, preDefinedColumnsMapping, columnsConfiguration, dynamicColumnsInfo, stateInfo, assignUserCB = null, columnsRestrictions, isOnlyAppOwner = false, idOrg }) => {
  return getPreDefinedColumns({ stateOrder, columns: preDefinedColumnsMapping, columnsConfiguration, stateInfo, columnsRestrictions, idOrg })
    .concat(getColumnByFieldType({ columnsInfo: dynamicColumnsInfo, usersById: {}, customFieldPropsByType: customFieldPropsByType(columnsRestrictions), fieldIdentifier: 'systemKey', assignUserCB, isOnlyAppOwner }))
}

export default getColumns
