import React, { useEffect } from 'react'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentOrg } from '@selectors/org'
import { toggleWorkflowAuditLogs } from '@actions/'
import AuditLogs from '@components/auditLogs'
import * as Style from '@components/popups/workflowAuditLogsPopup/style'
import { getWorkflowPathPrefix } from '@lenses/workflows'
import { getWorkflowEditHistoryLogs } from '@actions/workflows'

const WorkflowAuditLogsPopup = (): JSX.Element => {
  const { id: idOrg } = useSelector(getCurrentOrg)
  const { isOpen, idWorkflow, workflowName, workflowType, triggerType } = useSelector(state => state.ui.workflowAuditLogs)

  const dispatch = useDispatch()

  const analyticsInfo = {
    name: 'Click to view workflow edit history row',
    props: { 'Workflow ID': idWorkflow, 'Workflow name': workflowName }
  }

  useEffect(() => {
    dispatch(getWorkflowEditHistoryLogs({ idOrg, idWorkflow }))
  }, [dispatch, idOrg, idWorkflow])

  const header = 'Workflow edit history'

  const onClose = (): void => {
    dispatch(toggleWorkflowAuditLogs({ isOpen: false, idWorkflow, workflowName }))
  }

  const auditPathPrefix = getWorkflowPathPrefix({ workflowType, triggerType })

  return <ToriiPopup styles={Style.Popup} isOpen={isOpen} onCloseAction={onClose}>
    <ToriiPopup.Header header={header} subHeader={workflowName} />
    <ToriiPopup.Content>
      <AuditLogs idOrg={idOrg} idWorkflow={idWorkflow} openURLPrefix={`/team/${idOrg}/${auditPathPrefix}/${idWorkflow}`} openURLAnalytics={analyticsInfo} />
    </ToriiPopup.Content>
    <ToriiPopup.Footer showCancelButton={false} showMainButton={false} />
  </ToriiPopup>
}

export default WorkflowAuditLogsPopup
