import Analytics from '@helpers/analytics'
import {
  TRIAL_SIGNUP,
  CREATE_SOURCE_TOKEN,
  DOWNLOAD_EXTENSION,
  EXPORT_MONTHLY_EXPENSES_CSV,
  EXPORT_THIRD_PARTY_CSV,
  INVITE_USER,
  SORT_TABLE,
  TABLES,
  TOGGLE_ASSIGN_USER,
  TOGGLE_INVITE_USER,
  TOGGLE_UPLOAD_EXPENSES,
  UPDATE_AUTOMATION_RULE,
  PARSE_EXPENSE_FILE,
  UPLOAD_APP_ATTACHMENT,
  DELETE_APP_DETAILS_FIELD_VALUE,
  DISCONNECT_SERVICE,
  EXPORT_USERS_EXTENSION_CSV,
  UPDATE_USER_SETTINGS,
  CONFIGURE_TABLE_COLUMNS,
  UPDATE_USER_LIFECYCLE,
  UPDATE_APP_USER_INFO,
  TOGGLE_SELECT_FIELD_TYPE_POPUP,
  EDIT_APP_DETAILS_GROUPS,
  ADD_APP_DETAILS_GROUPS,
  UPDATE_APP_DETAILS_FIELD,
  DELETE_APP_DETAILS_GROUPS,
  DELETE_APP_DETAILS_FIELD,
  UPDATE_APP_CATALOG_SETTINGS,
  TOGGLE_CONNECT_SERVICE,
  CREATE_WORKFLOW,
  UPDATE_WORKFLOW,
  SUBMIT_WORKFLOW_FORM_INFO,
  SUBMIT_WORKFLOW_REQUEST_APPROVAL,
  TOGGLE_UPDATE_APP_DETAIL_FIELD_POPUP,
  GET_PARSING_STATUS,
  PARSING_STATUS,
  CREATE_API_KEY,
  DELETE_API_KEY,
  UPDATE_IS_SCIM_ENABLED,
  EXPORT_INACTIVE_USERS_CSV,
  UPDATE_APPS_DETAILS,
  ADD_TABLE_VIEW,
  DELETE_TABLE_VIEW,
  SET_LICENSE_TREND_FILTER,
  EMPTY_OBJECT,
  TOGGLE_MATCHING_RULES_POPUP,
  UPDATE_ORG_MATCHING_RULES,
  TOGGLE_CONTRACT_POPUP,
  CREATE_CONTRACT,
  UPDATE_CONTRACT_DETAILS,
  EXPORT_SSO_AUDIT_CSV,
  EXPORT_CSV_REPORT,
  TOGGLE_MANUALLY_RUN_WORKFLOW_POPUP,
  TOGGLE_IMPORT_CONTRACTS_FILE,
  TOGGLE_USER_LIFECYCLE_SETTINGS,
  TOGGLE_ADD_USER,
  ADD_USER_REPORTING,
  UPDATE_APP_OFFBOARDING_SETTINGS,
  TOGGLE_USERS_OVERLAP_POPUP,
  TOGGLE_CONNECT_CUSTOM_SERVICE,
  TOGGLE_ADD_APPLICATION,
  TABLE_CUSTOM_SELECT_CHANGE,
  TOGGLE_ADD_APP_CATALOG_POLICY_POPUP,
  ANALYTICS_EVENT,
  REORDER_APP_DETAILS_GROUPS,
  REORDER_CONTRACT_DETAILS_GROUPS,
  TOGGLE_WORKFLOW_AUDIT_LOGS,
  DUPLICATE_WORKFLOW,
  INSTALL_PLUGIN,
  UNINSTALL_PLUGIN,
  RESET_MODIFY_DISCOVERED_APP,
  TOGGLE_LICENSE_EDIT_POPUP,
  UPDATE_DISCOVERED_APPS,
  TOGGLE_CONNECT_SCIM_SERVICE
} from '@root/constants'
import { getAppDetailsFieldsByIdField, getAppDetailsGroups, deprecatedGetAppsByIds } from '@selectors/apps'
import { USER_SETTINGS } from '@components/settings/settings'
import { getAppDetailsByIdApp, getAppDetailsByIdAppIdField } from '@lenses/apps'
import { getDisplayName } from '@lenses/users'
import get from 'lodash/get'
import partition from 'lodash/partition'
import isUndefined from 'lodash/isUndefined'
import { USER_LIFECYCLE_STATUS } from '@shared/types'
import TestConnectionAnalytics from '@components/testConnection/analytics'
import { LicenseEditSelectedColumn } from '@components/licenseEdit'
import ParseValidation from '@helpers/parse-validation'

const analyticsMiddleWare = ({ getState }) => next => action => {
  const handlers = {
    [`${TRIAL_SIGNUP}_FAILED`]: (payloadAndMeta) => {
      const { email } = payloadAndMeta
      const { help, error } = ParseValidation(payloadAndMeta)
      Analytics.track('Request to signup for trial rejected', {
        'Email': email,
        'Error Message': help?.email || error
      })
    },
    [`${TRIAL_SIGNUP}_RESPONSE`]: ({ email }) => {
      Analytics.track('Request to signup for trial accepted', {
        'Email': email
      })
    },
    [`${DUPLICATE_WORKFLOW}_RESPONSE`]: ({ idWorkflow, name, triggerName, buttonLocation }) => {
      Analytics.track('Duplicate workflow', {
        'Workflow ID': idWorkflow,
        'Workflow name': name,
        'Trigger name': triggerName,
        'Button Location': buttonLocation
      })
    },
    [UPDATE_CONTRACT_DETAILS]: () => {
      Analytics.track('Edited contract details')
    },
    [`${REORDER_CONTRACT_DETAILS_GROUPS}_RESPONSE`]: ({ sourceGroup, destinationGroup }) => {
      Analytics.track('Reordered contract details groups', {
        'Source group': {
          'ID': sourceGroup.id,
          'name': sourceGroup.label
        },
        'Destination group': {
          'ID': destinationGroup.id,
          'name': destinationGroup.label
        }
      })
    },
    [`${CREATE_CONTRACT}_RESPONSE`]: ({ isDuplicatedContract, contractToUpdateStore, flow }) => {
      if (isDuplicatedContract) {
        Analytics.track('Create duplicate-contract-dialog', { 'Button Label': 'Save' })
      } else {
        Analytics.track('Created contract', {
          'Flow type': flow,
          'Contract start date': contractToUpdateStore?.startDate,
          'Contract end date': contractToUpdateStore?.endDate,
          'Contract value': contractToUpdateStore?.amount?.value,
          'Application ID': contractToUpdateStore?.idApp
        })
      }
    },
    [TOGGLE_WORKFLOW_AUDIT_LOGS]: ({ isOpen, idWorkflow, workflowName, isByUser }) => {
      if (!isByUser) {
        return
      }

      const event = isOpen ? 'Click to view workflow edit history popup' : 'Click to close workflow edit history popup'

      Analytics.track(event, {
        'Workflow ID': idWorkflow,
        'Workflow name': workflowName
      })
    },
    [TOGGLE_CONTRACT_POPUP]: ({ isOpen, isByUser, idContract, initialValues, buttonLabel }) => {
      if (isByUser) {
        if (initialValues && !idContract) {
          Analytics.track(`${isOpen ? 'Open' : 'Close'} duplicate-contract-dialog`, { 'Button Label': buttonLabel })
        } else {
          const action = idContract ? 'view' : 'add'
          const event = isOpen ? `Clicked to ${action} contract` : `Dismissed ${action} contract`
          Analytics.track(event)
        }
      }
    },
    [TOGGLE_MATCHING_RULES_POPUP]: ({ isOpen, isByUser }) => {
      if (isByUser) {
        const event = isOpen ? 'Clicked to edit matching rules' : 'Dismissed edit matching rules'
        Analytics.track(event)
      }
    },
    [TOGGLE_LICENSE_EDIT_POPUP]: ({ isOpen, idApp, appName, selectedColumn }) => {
      Analytics.track(`${isOpen ? 'Open' : 'Close'} edit-licenses-dialog`, {
        'App name': appName,
        'App id': idApp,
        'Clicked column': selectedColumn === LicenseEditSelectedColumn.PricePerUser ? 'License Cost' : selectedColumn === LicenseEditSelectedColumn.TotalAmount ? 'Total licenses' : null
      })
    },
    [`${UPDATE_ORG_MATCHING_RULES}_RESPONSE`]: () => {
      Analytics.track('Updated org matching rules')
    },
    [TOGGLE_INVITE_USER]: ({ isInviteOpen, isByUser }) => {
      if (isByUser) {
        const event = isInviteOpen ? 'Click to open invite sheet' : 'Dismiss invite'
        Analytics.track(event)
      }
    },
    [TOGGLE_USER_LIFECYCLE_SETTINGS]: ({ isUserLifecycleOpen, isByUser }) => {
      if (isByUser) {
        const event = isUserLifecycleOpen ? 'Click to edit user lifecycle' : 'Dismissed edit user lifecycle'
        Analytics.track(event)
      }
    },
    [TOGGLE_CONNECT_SERVICE]: ({ isConnectServiceOpen, isByUser, idApp }) => {
      if (isByUser && !idApp) {
        const event = isConnectServiceOpen ? 'Clicked to open connect service' : 'Dismissed connect service'
        Analytics.track(event)
      }
    },
    [TOGGLE_CONNECT_CUSTOM_SERVICE]: ({ isOpen, isByUser }) => {
      if (isByUser && isOpen) {
        Analytics.track('Click to add integration / Integrations', {
          'Integration type': 'Custom'
        })
      }
    },
    [TOGGLE_ADD_APPLICATION]: ({ openFrom }) => {
      Analytics.track('Clicked to open popup: add custom application', {
        'Open From': openFrom
      })
    },
    [SORT_TABLE]: ({ table, sortingInfo = [{}] }) => {
      const sortingObject = sortingInfo[0] || {}
      return Analytics.track('Clicked to sort table', {
        'Table name': (TABLES[table] || {}).name,
        'Sorted by': sortingObject.id,
        'Sort order': sortingObject.desc ? 'Desc' : sortingObject ? 'Asc' : undefined
      })
    },
    [CONFIGURE_TABLE_COLUMNS]: ({ table, selectedColumns = [], options }) => {
      const selectedColumnsLabels = options.reduce((result, option) => {
        if (selectedColumns.includes(option.value)) {
          result.push(option.label)
        }
        return result
      }, [])
      Analytics.track('Clicked to configure table columns', {
        'Table': (TABLES[table] || {}).name,
        'Selected columns': selectedColumnsLabels
      })
    },
    [`${INVITE_USER}_RESPONSE`]: ({ email, idRole }) =>
      Analytics.track('User invited to Torii', {
        invitee: email,
        idRole
      }),
    [DOWNLOAD_EXTENSION]: () =>
      Analytics.track('Click to download extension'),
    [TOGGLE_ASSIGN_USER]: ({ isSelectUserOpen, actionDescription, idApp, componentPage }) => {
      if (isSelectUserOpen) {
        const analyticsProps = componentPage ? { 'Page': componentPage, idApp } : { idApp }
        Analytics.track(`Clicked to ${actionDescription}`, analyticsProps)
      }
    },
    [TOGGLE_ADD_USER]: ({ isOpen }) => {
      Analytics.track(isOpen ? 'Click on Add new user link/ Workflow send email action' : 'Click on Cancel / Add new user from email action popup')
    },
    [ADD_USER_REPORTING]: ({ actionType, creator, ...values }) => {
      Analytics.track('Click on Add new user button / Add new user from email action popup', { actionType, createdBy: creator, ...values })
    },
    [TOGGLE_SELECT_FIELD_TYPE_POPUP]: ({ isOpen, isClosedByUser = true }) => {
      if (isOpen) {
        Analytics.track(`Clicked add field`)
      } else if (isClosedByUser) {
        Analytics.track(`Closed add field popup`)
      }
    },
    [TOGGLE_UPLOAD_EXPENSES]: ({ isUploadExpensesOpen, isByUser }) => {
      if (isByUser) {
        const event = isUploadExpensesOpen ? 'Click to open upload expenses dialog' : 'Dismiss upload expenses dialog'
        Analytics.track(event)
      }
    },
    [`${PARSE_EXPENSE_FILE}_RESPONSE`]: ({ isAuto }) => {
      if (isAuto) {
        Analytics.track('Expenses report uploaded')
      } else {
        Analytics.track('Manual match was filled')
      }
    },
    [`${GET_PARSING_STATUS}_RESPONSE`]: ({ parsingStatus }) => {
      if (parsingStatus !== PARSING_STATUS.parsingInProgress) {
        Analytics.track('Expenses report parsing', {
          successfullyParsed: parsingStatus === PARSING_STATUS.parsedSuccessfully
        })
      }
    },
    [`${CREATE_SOURCE_TOKEN}_FAILED`]: ({ message, source }) => {
      Analytics.track('App connection failed', {
        'App name': source,
        Reason: message
      })
    },
    [`${CREATE_SOURCE_TOKEN}_RESPONSE`]: ({ source, permission }) => {
      Analytics.track('App connected', {
        'App name': source,
        'Permission': permission
      })
    },
    [`${UPDATE_AUTOMATION_RULE}_RESPONSE`]: ({ idRule, isEnabled }) => {
      Analytics.track('Rule updated', {
        'Rule id': idRule,
        isEnabled
      })
    },
    [`${UPDATE_USER_SETTINGS}_RESPONSE`]: ({ idField, frequency }) => {
      Analytics.track('Notification settings updated', {
        'Notification id': idField,
        'Notification name': USER_SETTINGS.find(setting => setting.id === idField).name,
        Frequency: frequency
      })
    },
    [EXPORT_MONTHLY_EXPENSES_CSV]: () => {
      Analytics.track('Export report', {
        'Report type': 'monthly',
        'Export format': 'CSV'
      })
    },
    [EXPORT_THIRD_PARTY_CSV]: () => {
      Analytics.track('Export report', {
        'Report type': '3rdParty',
        'Export format': 'CSV'
      })
    },
    [EXPORT_USERS_EXTENSION_CSV]: () => {
      Analytics.track('Export report', {
        'Report type': 'extensionRolloutStatus',
        'Export format': 'CSV'
      })
    },
    [EXPORT_INACTIVE_USERS_CSV]: () => {
      Analytics.track('Export report', {
        'Report type': 'inactiveUsers',
        'Export format': 'CSV'
      })
    },
    [EXPORT_SSO_AUDIT_CSV]: () => {
      Analytics.track('Export report', {
        'Report type': 'ssoAudit',
        'Export format': 'CSV'
      })
    },
    [EXPORT_CSV_REPORT]: ({ type, sendAnalytics }) => {
      sendAnalytics && Analytics.track('Export report', {
        'Report type': { type },
        'Export format': 'CSV'
      })
    },
    [`${UPDATE_APPS_DETAILS}_RESPONSE`]: ({ idOrg, idApps, fieldsValues, isBulk }, getState) => {
      const state = getState()
      const appsByIds = deprecatedGetAppsByIds(state)
      const apps = idApps.map(idApp => appsByIds[idApp].name)
      const [ownerFieldValues, nonOwnerFieldsValues] = partition(fieldsValues, field => ['owner', 'primaryOwner'].includes(field.idField))
      const details = getAppDetailsByIdApp(state, idApps[0])
      const values = nonOwnerFieldsValues.map(field => ({ name: details.find(fieldDetail => field.idField.toString() === fieldDetail.idField.toString()).name, values: field.values }))
      values.length && Analytics.track(`${isBulk ? 'Bulk ' : ''}Edit applications details`, {
        'Apps names': apps,
        'Fields Values': values
      })
      const ownerValues = (ownerFieldValues[0] || {}).values
      ownerValues && Analytics.track(`${isBulk ? 'Bulk ' : ''}Edit applications owner`, {
        'Apps names': apps,
        'Fields Values': values
      })
    },
    [`${UPLOAD_APP_ATTACHMENT}_RESPONSE`]: ({ idApp, idField, values }, getState) => {
      const state = getState()
      const { name } = deprecatedGetAppsByIds(state)[idApp] || EMPTY_OBJECT
      const details = getAppDetailsByIdApp(state, idApp)
      const field = details.find(field => idField === field.idField)
      const updatedValue = getAppDetailsByIdAppIdField({ values, idApp, idField })
      Analytics.track('Edit application details', {
        'App name': name,
        'Field': field.name,
        'Value': (updatedValue.length === 0 ? '<empty>' : (updatedValue.length === 1 ? updatedValue[0] : updatedValue))
      })
    },
    [`${DELETE_APP_DETAILS_FIELD_VALUE}_RESPONSE`]: ({ idApp, idField }, getState) => {
      const state = getState()
      const { name } = deprecatedGetAppsByIds(state)[idApp] || EMPTY_OBJECT
      const details = getAppDetailsByIdApp(state, idApp)
      const field = details.find(field => idField === field.idField)
      Analytics.track('Remove application details', {
        'App name': name,
        'Field': field.name
      })
    },
    [`${DISCONNECT_SERVICE}_PENDING`]: ({ appName, accountName }) => {
      Analytics.track('Are you sure popup', {
        'Type': 'Disconnect app',
        'App name': appName,
        'Account name': accountName,
        'Value': 'yes'
      })
    },
    [`${DISCONNECT_SERVICE}_RESPONSE`]: ({ appName }) => {
      Analytics.track('App disconnected', {
        'App name': appName
      })
    },
    [`${UPDATE_USER_LIFECYCLE}_RESPONSE`]: ({ user }) => {
      const userAction =
        user.lifecycleStatus === USER_LIFECYCLE_STATUS.OFFBOARDING ? 'start'
          : (user.lifecycleStatus === USER_LIFECYCLE_STATUS.OFFBOARDED ? 'finish' : 'stop')
      Analytics.track(`Clicked to ${userAction} offboarding`, {
        'Offboarded user name': getDisplayName(user),
        'Offboarded user id': user.id
      })
    },
    [`${UPDATE_APP_USER_INFO}_RESPONSE`]: ({ appsRemovalStatus, pageName = 'User offboarding log', taskType }) => {
      const isUserRemovedFromApp = get(appsRemovalStatus, '0.isUserRemovedFromApp')
      const eventName = isUserRemovedFromApp ? 'Mark app-as-offboarded' : 'Unmark app-as-offboarded'
      const properties = isUserRemovedFromApp ? {
        'Button location': pageName,
        'Task type': taskType
      } : {}
      Analytics.track(eventName, properties)
    },
    [`${EDIT_APP_DETAILS_GROUPS}_RESPONSE`]: ({ idGroup, label }) => {
      Analytics.track('Edited application fields group', {
        'Group ID': idGroup,
        'Group name': label
      })
    },
    [`${ADD_APP_DETAILS_GROUPS}_RESPONSE`]: ({ idGroup, label }) => {
      Analytics.track('Added new application fields group', {
        'Group ID': idGroup,
        'Group name': label
      })
    },
    [`${REORDER_APP_DETAILS_GROUPS}_RESPONSE`]: ({ sourceGroup, destinationGroup }) => {
      Analytics.track('Reordered application details groups', {
        'Source group': {
          'ID': sourceGroup.id,
          'name': sourceGroup.label
        },
        'Destination group': {
          'ID': destinationGroup.id,
          'name': destinationGroup.label
        }
      })
    },
    [`${UPDATE_APP_DETAILS_FIELD}_RESPONSE`]: ({ idField, isShown, name }, getState) => {
      if (name) {
        Analytics.track('Edited application field', {
          'Field ID': idField,
          'Field name': name
        })
      } else {
        const state = getState()
        const field = getAppDetailsFieldsByIdField(state)[idField]
        const action = isShown ? 'show' : 'hide'
        Analytics.track(`Clicked ${action} application field`, {
          'Field ID': idField,
          'Field name': field.name
        })
      }
    },
    [`${DELETE_APP_DETAILS_GROUPS}_RESPONSE`]: ({ idGroup }, getState) => {
      const state = getState()
      const groups = getAppDetailsGroups(state)

      const group = groups.find(group => group.id === idGroup)
      Analytics.track('Deleted application fields group', {
        'Group ID': idGroup,
        'Group name': group.label
      })
    },
    [`${DELETE_APP_DETAILS_FIELD}_RESPONSE`]: ({ idField }, getState) => {
      const state = getState()
      const field = getAppDetailsFieldsByIdField(state)[idField]

      Analytics.track('Deleted application field', {
        'Field ID': field.id,
        'Field name': field.name
      })
    },
    [`${CREATE_API_KEY}_RESPONSE`]: () => {
      Analytics.track('Created API Key')
    },
    [`${DELETE_API_KEY}_RESPONSE`]: () => {
      Analytics.track('Deleted API Key')
    },
    [`${UPDATE_IS_SCIM_ENABLED}_RESPONSE`]: ({ isScimEnabled }) => {
      Analytics.track(`Updated isScimEnabled to ${isScimEnabled}`)
    },
    [`${UPDATE_APP_CATALOG_SETTINGS}_RESPONSE`]: ({ idOrg }) => {
      Analytics.track('Updated app catalog settings', {
        'Org id': idOrg
      })
    },
    [`${CREATE_WORKFLOW}_RESPONSE`]: ({ workflow }) => {
      Analytics.track('Created new workflow', {
        'Workflow ID': workflow.id,
        'Workflow name': workflow.name,
        'Workflow type': workflow.type
      })
    },
    [`${UPDATE_WORKFLOW}_RESPONSE`]: ({ workflow }) => {
      Analytics.track('Updated workflow', {
        'Workflow ID': workflow.id,
        'Workflow name': workflow.name,
        'Workflow type': workflow.type
      })
    },
    [`${SUBMIT_WORKFLOW_FORM_INFO}_RESPONSE`]: ({ idActionExe, workflowFormInfo, rejectForm }) => {
      const eventName = rejectForm ? 'Reject workflow form' : 'Submit workflow form'
      Analytics.track(eventName, {
        'Workflow action execution id': idActionExe,
        'App name': workflowFormInfo.app.name,
        'App id': workflowFormInfo.app.idApp
      })
    },
    [`${SUBMIT_WORKFLOW_REQUEST_APPROVAL}_RESPONSE`]: ({ idActionExe, approved }) => {
      Analytics.track('Submit workflow request approval', {
        'Workflow action execution id': idActionExe,
        'Approved': approved
      })
    },
    [TOGGLE_UPDATE_APP_DETAIL_FIELD_POPUP]: ({ isOpen, isByUser, idField, name }) => {
      if (isOpen) {
        Analytics.track('Clicked to edit application field', {
          'Field ID': idField,
          'Field name': name
        })
      } else if (isByUser) {
        Analytics.track('Closed edit application field', {
          'Field ID': idField,
          'Field name': name
        })
      }
    },
    [`${ADD_TABLE_VIEW}_RESPONSE`]: ({ viewName, templateName, tableKey }) => {
      Analytics.track('Created a view', {
        'Table name': TABLES[tableKey].name,
        'View name': viewName,
        'Template name': templateName
      })
    },
    [`${DELETE_TABLE_VIEW}_RESPONSE`]: ({ viewName, tableKey }) => {
      Analytics.track('Deleted a view', {
        'Table name': TABLES[tableKey].name,
        'View name': viewName
      })
    },
    [SET_LICENSE_TREND_FILTER]: ({ selectedUsageIdsFilter, selectedLicenseIdsFilter, selectedTimePeriod, loading }) => {
      if (!loading) {
        const selectedFilter = {}
        if (selectedUsageIdsFilter) {
          selectedFilter.Usage = selectedUsageIdsFilter
        } else if (selectedLicenseIdsFilter) {
          selectedFilter['License ids'] = selectedLicenseIdsFilter
        } else {
          selectedFilter['Time period'] = selectedTimePeriod
        }
        Analytics.track('Clicked to filter trends', selectedFilter)
      }
    },
    [TOGGLE_MANUALLY_RUN_WORKFLOW_POPUP]: ({ isOpen, triggerType, idWorkflow, workflowName, source }) => {
      if (isOpen) {
        Analytics.track('Open run-workflow-actions-dialog', {
          'Workflow name': workflowName,
          'Workflow ID': idWorkflow,
          'Trigger name': triggerType,
          'Button location': source
        })
      }
    },
    [TOGGLE_IMPORT_CONTRACTS_FILE]: ({ isImportContractsOpen, isByUser }) => {
      if (isByUser) {
        const event = isImportContractsOpen ? 'Click to open import contracts popup' : 'Dismiss import contracts popup'
        Analytics.track(event)
      }
    },
    [`${UPDATE_APP_OFFBOARDING_SETTINGS}_RESPONSE`]: ({ remindersEnabled }) => {
      if (!isUndefined(remindersEnabled)) {
        Analytics.track('Click on Send offboarding reminders toggle/ Offboarding Settings page', {
          'Move to state': remindersEnabled ? 'selected' : 'unselected'
        })
      }
    },
    [TOGGLE_USERS_OVERLAP_POPUP]: ({ appsNames, idUsers, source, withLicenses }, getState) => {
      const state = getState()
      const { isOpen } = state.ui.usersOverlapPopup
      if (!isOpen) {
        const props = {
          apps: appsNames,
          users: idUsers,
          tab: withLicenses ? 'users with licenses' : 'all users'
        }
        if (source === 'view_users_link') {
          Analytics.track('Click on N of users link / Overlap widget / Comparison page / Compare tab / Applications', props)
        } else if (source === 'view_users_button') {
          Analytics.track('Click on View users button / Overlap widget / Comparison page / Compare tab / Applications', props)
        }
      }
    },
    [TABLE_CUSTOM_SELECT_CHANGE]: ({ tableKey, selectedOption, appName }) => {
      if (tableKey === TABLES.appUsersTable.key) {
        const state = get(selectedOption, 'value')
        Analytics.track('Update User-status-filter', {
          state,
          appName
        })
      }
    },
    [TOGGLE_ADD_APP_CATALOG_POLICY_POPUP]: ({ isOpen, isByUser, buttonLabel, isViewOnly, idWorkflow }) => {
      if (isViewOnly) {
        isOpen && isByUser && Analytics.track('Open app-assigned-with-the-default-request-access-policy-dialog')
        !isOpen && isByUser && Analytics.track('Close app-assigned-with-the-default-request-access-policy-dialog', { 'Button label': buttonLabel })
      } else {
        const actionPerformed = idWorkflow ? 'Duplicate policy' : 'Add new custom policy'
        isOpen && isByUser && Analytics.track(ANALYTICS_EVENT.OPEN_NEW_CUSTOM_POLICY, { 'Page Name': 'App catalog' })
        !isOpen && isByUser && Analytics.track('Close add-new-custom-policy-dialog', { 'Button label': buttonLabel, 'Open from': actionPerformed })
        !isByUser && Analytics.track('Add add-new-custom-policy-dialog')
      }
    },
    [`${INSTALL_PLUGIN}_RESPONSE`]: ({ idOrg, idPlugin }) => {
      Analytics.track('Install plugin', {
        'Plugin ID': idPlugin,
        'Org ID': idOrg
      })
    },
    [`${UNINSTALL_PLUGIN}_RESPONSE`]: ({ idOrg, idPlugin }) => {
      Analytics.track('Uninstall plugin', {
        'Plugin ID': idPlugin,
        'Org ID': idOrg
      })
    },
    [RESET_MODIFY_DISCOVERED_APP]: ({ isCanceledByUser }) => {
      if (!isCanceledByUser) {
        return
      }

      Analytics.track('Canceled saved change of discovered apps')
    },
    [`${UPDATE_DISCOVERED_APPS}_RESPONSE`]: () => {
      Analytics.track('Saved changes of discovered apps')
    },
    [TOGGLE_CONNECT_SCIM_SERVICE]: ({ isOpen, idApp, serviceName }) => {
      if (isOpen) {
        TestConnectionAnalytics.toggleIntegrationConnection(true, serviceName, idApp, 'Test before connect', 'Connect in app')
      }
    }
  }
  handlers[action.type] && handlers[action.type]({ ...action.meta, ...action.payload }, getState)
  next(action)
}

export default analyticsMiddleWare
