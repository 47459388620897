import { IMPORT_TYPE } from '@selectors/importUsers/types'

export enum WIZARD_STEPS {
  UPLOAD_FILE = 'uploadFile',
  MAP_COLUMNS = 'mapColumns',
  LOADING_AFTER_MAP_COLUMNS = 'loadingAfterMapColumns',
  REVIEW_IMPORT_CHANGES = 'reviewImportChanges',
  LOADING_AFTER_IMPORT = 'loadingAfterImport',
  FAILURE = 'failure'
}

export enum USER_ERROR_TYPES {
  INVALID_EMAIL = 'INVALID_EMAIL',
  DUPLICATE_EMAIL = 'DUPLICATE_EMAIL',
  INVALID_LAST_VISIT_TIME = 'INVALID_LAST_VISIT_TIME'
}

export enum PARSED_USERS_IMPORT_FILE_NAMES_V2 {
  INVALID_USERS = 'invalidUsersFromFile',
  VALID_USERS = 'validUsersFromFile',
  MISSING_USERS = 'missingUsers',
  EXISTING_INVALID_USERS = 'existingInvalidUsers'
}

export interface ImportUserSubmitParams {
  idAppAccount: number
}

export interface SharedProps {
  importMode: IMPORT_TYPE
  idOrg: number
  idApp: number
  appName: string
  isFirstTimeImport: boolean
  lastSyncTime?: string
  idAppAccount?: number
  appAccountName?: string
}

export interface BasicUser {
  email: string
}

export interface UserData extends BasicUser {
  license?: string[]
  lastVisitTime?: string
}

export interface ValidUser extends UserData {
  alreadyInApp: boolean
}

export interface InvalidUser extends UserData {
  errorType: USER_ERROR_TYPES
}

export enum IMPORT_USERS_COLUMNS {
  EMAIL = 'email',
  LICENSES = 'licenses',
  LAST_VISIT_TIME = 'lastVisitTime'
}

export interface ColumnMapping {
  [IMPORT_USERS_COLUMNS.EMAIL]: string
  [IMPORT_USERS_COLUMNS.LICENSES]: string
  [IMPORT_USERS_COLUMNS.LAST_VISIT_TIME]: string
}

export interface SharedState {
  parsingPreviewChanges: {
    validUsers: ValidUser[]
    invalidUsers: InvalidUser[]
    missingUsers: BasicUser[]
    newUsersWithLicenses: ValidUser[]
  }
  suggestedDateFormat?: string[]
  selectedDateFormat?: string
  selectedColumnsToImport: IMPORT_USERS_COLUMNS[]
  originalSuggestions?: {
    suggestedMapping: ColumnMapping
    suggestedDateFormat: string[]
  }
  filePreview: Record<string, string | number>
  previewDataMapping: ColumnMapping
  idUsersImportParsing: number | null
  idAppAccount: number | null
  customParser?: {
    name: string
    columnsToImport: IMPORT_USERS_COLUMNS[]
    uiConfig: {
      columnTooltips: Record<IMPORT_USERS_COLUMNS, string>
    }
  },
  failureMessage?: string
}

export enum DATE_FORMATS_OPTIONS {
  DAYS_PRIOR_MONTHS = 'daysPriorMonths',
  MONTHS_PRIOR_DAYS = 'monthsPriorDays',
}
