import View from './view'
import { connect } from 'react-redux'
import { deprecatedGetAppsByIds } from '@selectors/apps'
import { getAppInactiveLicenses, getUserDetailsFields, getAppUsersFieldValues } from '@actions/'
import { getCurrentOrg } from '@selectors/org'
import {
  isLoadingAppInactiveLicences,
  getAppInactiveLicenses as getAppInactiveLicensesSelector
} from '@selectors/licenses'
import { EMPTY_ARRAY, EMPTY_OBJECT, LICENSES_CUSTOM_SELECT_OPTIONS, SCOPES, TABLES } from '../../../constants'
import { getInactiveUserConfigurableColumnsOptions } from '@selectors/userDetails'
import { getUserTablesInfo } from '@selectors/tables'
import { getInactiveAppLicensesState } from '../../../lenses/licenses'
import { getUserPreferences } from '@selectors/ui'
import AccessControl from '../../../lenses/accessControl'
import { INACTIVE_USERS_TABLE_COLUMNS } from '@root/tableColumnsMappingConst'

const mapStateToProps = (state, ownProps) => {
  const { idApp, idLicense } = ownProps
  const { id: idOrg, appNotInUsePeriod } = getCurrentOrg(state)
  const loading = isLoadingAppInactiveLicences(state)
  const tableInfo = getUserTablesInfo(state)[TABLES.inactiveUsersListPopup.key]
  const userPreferences = getUserPreferences(state)
  const { defaultSort = EMPTY_ARRAY } = userPreferences[TABLES.inactiveUsersListPopup.key] || EMPTY_OBJECT
  const configurableColumnsOptions = getInactiveUserConfigurableColumnsOptions(state)

  const hasLicensesCostAndChargebackAccess = AccessControl.isAccessibleWithState({ state, scopes: [SCOPES.LICENSE_AND_CHARGEBACK_READ] })
  const columnsThatRequiresLicensesCostAccess = [INACTIVE_USERS_TABLE_COLUMNS.PRICE_PER_USER]
  const columns = hasLicensesCostAndChargebackAccess ? configurableColumnsOptions : configurableColumnsOptions.filter(field => !columnsThatRequiresLicensesCostAccess.includes(field.value))

  const app = deprecatedGetAppsByIds(state)[idApp] || EMPTY_OBJECT

  const view = getUserPreferences(state)[TABLES.licensesTable.key].defaultCustomSelectOption
  const isLicensesPage = window.location.pathname.endsWith('/licenses')
  const isPaidView = (view.value === LICENSES_CUSTOM_SELECT_OPTIONS.paid.value)

  const allInactiveLicenses = getAppInactiveLicensesSelector(state)
  const inactiveLicenses = allInactiveLicenses[idLicense ? `${idApp}_${idLicense}` : idApp] || []

  const appState = getInactiveAppLicensesState(state, idApp, idLicense)
  const { loadingMore, totalLicenses: totalLicensesWithFilter } = appState

  return ({
    app,
    idApp,
    idOrg,
    licenses: inactiveLicenses,
    shouldFilterPaidLicenses: isLicensesPage && isPaidView,
    appNotInUsePeriod,
    loading,
    tableInfo,
    configurableColumnsOptions: columns,
    loadingMore,
    defaultSort,
    totalLicensesWithFilter
  })
}

const mapDispatchToProps = {
  getAppInactiveLicenses,
  getUserDetailsFields,
  getAppUsersFieldValues
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
