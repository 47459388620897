import View from './view'
import { connect } from 'react-redux'
import { APP_USER_STATUS } from '@shared/types'
import {
  APP_USERS_CUSTOM_SELECT_OPTIONS_FOR_NON_CONNECTED_APPS,
  APP_USERS_CUSTOM_SELECT_OPTIONS_FOR_CONNECTED_APPS,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  SCOPES,
  TABLES,
  INTEGRATION_CATEGORY
} from '../../constants'
import { deprecatedGetAppsByIds, isAppsLoading } from '@selectors/apps'
import { getIsSmallScreen, getUserPreferences } from '@selectors/ui'
import { withRouter } from 'react-router-dom'
import pluralize from 'pluralize'
import React from 'react'
import { isLoadingLicensesTypes } from '@selectors/licenses'
import {
  isLoadingAppUsers,
  getFieldValues,
  getImportAppUsersStatus
} from '@selectors/appUsers'
import { findAppUsers, getAppUsersState } from '../../lenses/appUsers'
import {
  getAppUsers,
  getAppUsersFieldValues,
  getUserDetailsFields,
  toggleConfigureExecuteActionOnUsers,
  getServicesConfig,
  toggleImportUsers,
  toggleShareReportPopup
} from '@actions/'
import { getUserTablesInfo } from '@selectors/tables'
import {
  getAppConnectedIntegration,
  getConstantServicesByIdApp,
  isServicesLoading as getIsServicesLoading,
  getSyncStatus
} from '@selectors/services'
import { getAppUserConfigurableColumnsOptions } from '@selectors/userDetails'
import accessControl from '@root/lenses/accessControl'
import { getScopeByIdOrgAndIdApp } from '@root/lenses/scopes'
import moment from 'moment'
import { getCompanyName } from '@selectors/org'

export const getCustomSelectOptions = (isConnected, integrationCategory) => {
  if (!isConnected || [INTEGRATION_CATEGORY.EXPENSE_SOFTWARE, INTEGRATION_CATEGORY.CONTRACT_MANAGEMENT_SOFTWARE].includes(integrationCategory)) {
    return Object.values(APP_USERS_CUSTOM_SELECT_OPTIONS_FOR_NON_CONNECTED_APPS)
  }

  return Object.values(APP_USERS_CUSTOM_SELECT_OPTIONS_FOR_CONNECTED_APPS)
}

const mapStateToProps = (state, ownProps) => {
  const { idApp, idOrg } = ownProps
  const companyName = getCompanyName(state)
  const loadingApps = isAppsLoading(state)
  const loadingLicenses = isLoadingLicensesTypes(state)
  const loadingAppUsers = isLoadingAppUsers(state)
  const app = deprecatedGetAppsByIds(state)[idApp] || EMPTY_OBJECT
  const { activeUsersCount: totalActiveUsersCount } = app
  const users = findAppUsers(state, idApp) || EMPTY_ARRAY
  const appState = getAppUsersState(state, idApp) || EMPTY_OBJECT
  const { loadingMore, totalUsers } = appState
  const syncStatuses = getSyncStatus(state)
  const appSyncStatus = syncStatuses?.find(syncStatus => syncStatus.idApp === idApp) || EMPTY_OBJECT
  const { lastUsersAndLicensesFileLastSyncTime, licensesManagedManually } = appSyncStatus
  const addedUsersSinceLastLicensesUpload = users.filter(user => lastUsersAndLicensesFileLastSyncTime && moment(user.creationTime).isAfter(lastUsersAndLicensesFileLastSyncTime))
  const addedUsersSinceLastLicensesUploadAmount = addedUsersSinceLastLicensesUpload.length

  const values = getFieldValues(state)[idApp]

  const deletedUsersMessage = getAllUsersWereDeletedText(app, users)

  const userPreferences = getUserPreferences(state)
  const userTablePreferences = userPreferences[TABLES.appUsersTable.key] || EMPTY_OBJECT
  const { defaultSort = EMPTY_ARRAY } = userTablePreferences
  const tableInfo = getUserTablesInfo(state)[TABLES.appUsersTable.key]

  const configurableColumnsOptions = getAppUserConfigurableColumnsOptions(state)

  const serviceConfig = getConstantServicesByIdApp(state)[idApp] || EMPTY_OBJECT
  const isSupportsWritePermission = Boolean(serviceConfig.supportsWritePermission)
  const canSelectRows = accessControl.isAccessibleWithState({ scopes: [SCOPES.AUTOMATION_READ, SCOPES.APP_OWNER_WRITE, getScopeByIdOrgAndIdApp(SCOPES.AUTOMATION_READ, idOrg, idApp)], state })
  const importAppUsersStatus = getImportAppUsersStatus(state)
  const isConnected = getAppConnectedIntegration(state, { idApp })
  const customSelectOptions = getCustomSelectOptions(isConnected, serviceConfig.integrationCategory)
  const isServicesLoading = getIsServicesLoading(state)
  const isLoading = loadingAppUsers || isServicesLoading || loadingApps || loadingLicenses

  return {
    idApp,
    idOrg,
    companyName,
    appName: app.name,
    users,
    loading: isLoading,
    hasData: () => users.length && Object.keys(serviceConfig).length && Object.keys(app).length,
    isLoadingViews: isServicesLoading,
    isSmallScreen: getIsSmallScreen(state),
    deletedUsersMessage,
    defaultSort,
    loadingMore,
    totalUsers,
    totalActiveUsersCount,
    tableInfo,
    filterValues: values,
    configurableColumnsOptions,
    isSupportsWritePermission,
    userTablePreferences,
    defaultCustomSelectOption: customSelectOptions[0],
    canSelectRows,
    importAppUsersStatus,
    customSelectOptions,
    licensesManagedManually,
    addedUsersSinceLastLicensesUpload,
    addedUsersSinceLastLicensesUploadAmount
  }
}

const getAllUsersWereDeletedText = (app, users) => {
  const { name, activeUsersCount } = app

  if (activeUsersCount > 0) {
    return null
  }

  const deletedUserNames = users
    .filter(user => user.status === APP_USER_STATUS.DELETED)
    .map(user => [user.firstName, user.lastName].join(' ').trim())

  if (deletedUserNames.length > 0) {
    const numberOfUsersToShow = 3
    const usersToShow = deletedUserNames.slice(0, numberOfUsersToShow).join(', ')
    const notShownUsers = (deletedUserNames.length - numberOfUsersToShow)
    const suffix = (deletedUserNames.length > numberOfUsersToShow) ? `and ${pluralize('other', notShownUsers, true)}` : ''
    return <div>{name} currently have no users.<br /> {name} had {pluralize('user', deletedUserNames.length, true)} in the past: {usersToShow} {suffix}</div>
  }
}

const mapDispatchToProps = {
  getAppUsers,
  getAppUsersFieldValues,
  getUserDetailsFields,
  toggleConfigureExecuteActionOnUsers,
  getServicesConfig,
  toggleImportUsers,
  toggleShareReportPopup
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
