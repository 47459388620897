import React from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import Table from '../table'
import {
  Tooltip,
  ButtonSize,
  SelectComponents,
  AlertBox,
  AlertBoxType,
  Stack,
  Link
} from '@toriihq/design-system'
import ImportUsersAnalytics from '@components/popups/importUsersPopup/analytics'
import {
  TABLES,
  ITEMS_PER_PAGE,
  fieldTypes,
  SCOPES,
  NO_APP_ACCOUNT_ID,
  MANUAL_WORKFLOW_EXECUTION_FLOW
} from '../../constants'
import { IMPORT_APP_USERS_STATUS } from '@reducers/appUsers/types'
import UsersImportPopover from '@components/appUsers/usersImportPopover'
import colors from '../../shared/style/colors'
import { getDisplayName } from '@lenses/users'
import UsersEmptyState from './usersEmptyState'
import config from '../../config'
import EnableFor from '../enableFor'
import uniq from 'lodash/uniq'
import isEqual from 'lodash/isEqual'
import { sortColumns } from '../table/view'
import VisibleFor from '../visibleFor'
import { getScopeByIdOrgAndIdApp } from '@root/lenses/scopes'
import * as Style from './styles'
import { FEATURES } from '@root/shared/features'
import ButtonOfFeature from '@components/buttonOfFeature'
import RelativeTeamLink from '../relativeTeamLink'
import { getColumns } from '@components/appUsers/columns'
import pluralize from 'pluralize'
import { REPORT_KEY } from '@actions/reports/reports.types'

const getNoDataProps = () => {
  return {
    className: 'rt-noData',
    style: {
      pointerEvents: 'all',
      top: '40%'
    }
  }
}

class AppUsers extends React.Component {
  state = {
    columns: getColumns({ ...this.props }),
    selectedUsers: [],
    view: this.props.defaultCustomSelectOption,
    isLoadingViews: this.props.isLoadingViews
  }

  componentDidMount () {
    const { deletedUsersMessage, idOrg, idApp, getServicesConfig, getAppUsersFieldValues } = this.props
    getServicesConfig({ idOrg })
    getAppUsersFieldValues({ idOrg, idApp, fields: ['role', 'externalStatus', 'licenses.name', 'licenses.source', 'appAccountName', 'idAppAccount'] })
    if (!this.props.isLoadingViews) {
      this.fetchUsersData(true)
    }
    this.renderNoUsersComponent = this.renderNoUsers(deletedUsersMessage)
  }

  componentDidUpdate (prevProps, prevState) {
    const columnsChanged = ['appName', 'tableInfo'].some(prop => !isEqual(prevProps[prop], this.props[prop]))
    if (columnsChanged) {
      this.setState({ columns: getColumns({ ...this.props }) })
    }

    if (prevState.view.value !== this.state.view.value) {
      this.getAppUsers({ reset: true })
    }

    if (this.state.isLoadingViews && !this.props.isLoadingViews) {
      this.getAppUsers({ reset: true })
      this.setState({ isLoadingViews: this.props.isLoadingViews })
    }

    if (prevProps.defaultCustomSelectOption.label !== this.props.defaultCustomSelectOption.label) {
      this.setState({ view: this.props.defaultCustomSelectOption })
    }

    const { importAppUsersStatus, idOrg, idApp, getAppUsersFieldValues } = this.props
    if (importAppUsersStatus !== prevProps.importAppUsersStatus &&
        importAppUsersStatus === IMPORT_APP_USERS_STATUS.SYNC_PRESUMABLY_ENDED) {
      this.getAppUsers({ reset: true })
      getAppUsersFieldValues({ idOrg, idApp, fields: ['licenses.name', 'licenses.source'] })
    }
  }

  renderNoUsers = deletedUsersMessage =>
    deletedUsersMessage || <UsersEmptyState />

  prepareExportParams = ({ sort, query, filters }) => {
    const { userTablePreferences, idApp } = this.props
    const { columns, view } = this.state

    sortColumns(columns, userTablePreferences)

    return {
      sort: sort.map(s => `${s.id}:${s.desc ? 'desc' : 'asc'}`),
      q: query,
      fields: columns.filter(col => ((col.show === undefined || col.show) && !col.hideFromCSV)).map(col => col.id || col.accessor).flatMap(field => field === 'fullName' ? ['firstName', 'lastName'] : field),
      filters: filters.concat(view.filters),
      idApp
    }
  }

  onShareReport = ({ sort: rawSort, query: rawQuery, filters: rawFilters }) => {
    const { toggleShareReportPopup, companyName, appName } = this.props
    const { sort, q, fields, filters, idApp } = this.prepareExportParams({ sort: rawSort, query: rawQuery, filters: rawFilters })

    toggleShareReportPopup({ isOpen: true, pageName: 'Applications - Users', companyName, report: { config: { sort, q, fields, filters, idApp }, reportName: `Applications - ${appName} - Users`, reportKey: REPORT_KEY.APP_USERS } })
  }

  exportToCsv = ({ sort: rawSort, query: rawQuery, filters: rawFilters }) => {
    const { idOrg } = this.props
    const { sort, q, fields, filters, idApp } = this.prepareExportParams({ sort: rawSort, query: rawQuery, filters: rawFilters })

    const sortParam = `sort=${sort.join(',')}`
    const queryParam = `q=${q}`
    const fieldsParam = `fields=${fields.join(',')}`
    const filtersParam = `filters=${encodeURIComponent(JSON.stringify(filters))}`

    const url = `${config.apiBaseUrl}/api/orgs/${idOrg}/apps/${idApp}/users/csv?${sortParam}&${queryParam}&${filtersParam}&${fieldsParam}`
    const newWindow = window.open(url, '_blank')
    newWindow.opener = null
  }

  sortStringFromArray = sortArray => {
    return sortArray.map(s => `${s.id}:${s.desc ? 'desc' : 'asc'}`).join(',')
  }

  fetchUsersData = debounce((reset = false) => {
    const { users } = this.props

    this.getAppUsers({ offset: reset ? 0 : users.length, reset })
  }, 500, { leading: true, trailing: false })

  onSortedChange = (sort) => {
    this.getAppUsers({ sort, reset: true })
  }

  onFilterChange = debounce((filters) => {
    this.getAppUsers({ filters, reset: true })
  }, 250)

  onStatusChange = (view) => {
    this.setState({ view })
  }

  fetchFieldValues = (field) => {
    const { idOrg, idApp, getAppUsersFieldValues, tableInfo } = this.props
    const options = this.getFilterOptions(tableInfo)
    const selectedFilterOption = options.find(f => f.value === field) || {}
    const isFieldSupportValues = [fieldTypes.name, fieldTypes.text, fieldTypes.dropdownMulti, fieldTypes.user].includes(selectedFilterOption.type)
    if (isFieldSupportValues) {
      getAppUsersFieldValues({ idOrg, idApp, fields: [field] })
    }
  }

  fetchFields () {
    const { getUserDetailsFields, idOrg } = this.props
    getUserDetailsFields({ idOrg })
  }

  getAppUsers = ({ limit = ITEMS_PER_PAGE, offset = 0, sort = this.props.defaultSort, q = this.query, reset = false, filters = this.props.tableInfo.filters }) => {
    const { view } = this.state
    const { idOrg, idApp, getAppUsers } = this.props
    if (reset) {
      this.fetchFields()
    }

    getAppUsers({ idOrg, idApp, limit, offset, sort: this.sortStringFromArray(sort), q, reset, filters: view.filters.concat(filters).filter(Boolean) })
  }

  onSearch = debounce((q) => {
    this.query = q
    this.getAppUsers({ reset: true, q })
  }, 250)

  onSelectChange = selection => {
    this.setState({ selectedUsers: selection })
  }

  customHeaderOnSelect = () => {
    const { users, toggleConfigureExecuteActionOnUsers, appName, idApp, isSupportsWritePermission, idOrg } = this.props
    const { selectedUsers } = this.state
    const selectedUsersInfo = users.filter(u => selectedUsers.includes(u.docId))
    const idAppAccounts = uniq(selectedUsersInfo.map(user => user.idAppAccount))
    const isValidAccountSelection = idAppAccounts.length === 1 && idAppAccounts[0] !== NO_APP_ACCOUNT_ID

    const isActionButtonDisabled = !isSupportsWritePermission || !isValidAccountSelection
    const actionButtonDisabledTooltipLabel = <Style.ActionButtonDisabledTooltipLabel>
      {!isSupportsWritePermission
        ? `Actions are not supported for ${appName}`
        : `All users must belong to the same ${appName} account`
      }
    </Style.ActionButtonDisabledTooltipLabel>

    return <Style.TableHeader>
      <Tooltip
        placement='top'
        hide={!isActionButtonDisabled}
        label={actionButtonDisabledTooltipLabel}>
        <VisibleFor scopes={[SCOPES.AUTOMATION_READ, getScopeByIdOrgAndIdApp(SCOPES.AUTOMATION_READ, idOrg, idApp)]}>
          <EnableFor scopes={[SCOPES.AUTOMATION_WRITE, getScopeByIdOrgAndIdApp(SCOPES.AUTOMATION_WRITE, idOrg, idApp)]}>
            <ButtonOfFeature
              tooltipPlacement={isActionButtonDisabled ? 'bottom' : 'top'}
              feature={FEATURES.WORKFLOWS.PAGE}
              onClick={async () => {
                await toggleConfigureExecuteActionOnUsers({ isOpen: true, idApp, users: selectedUsersInfo, onConfig: () => this.setState({ selectedUsers: [] }), executionFlow: MANUAL_WORKFLOW_EXECUTION_FLOW.appUsers, flowType: 'App user' })
              }}
              disabled={isActionButtonDisabled}
              label='Run action'
              size={ButtonSize.small}
            />
          </EnableFor>
        </VisibleFor>
      </Tooltip>
    </Style.TableHeader>
  }

  getFilterOptions = (tableInfo) => {
    const filterOptions = tableInfo.filtersOptions
    return filterOptions.concat({
      label: 'User type',
      value: 'userType',
      type: fieldTypes.text
    })
  }

  usersWereAddedSinceLastLicensesUpload = () => {
    const { appName, addedUsersSinceLastLicensesUploadAmount, addedUsersSinceLastLicensesUpload, licensesManagedManually, idApp } = this.props
    if (addedUsersSinceLastLicensesUploadAmount === 0 || !licensesManagedManually) {
      return null
    }

    const usersNames = addedUsersSinceLastLicensesUpload.map(user => getDisplayName({ firstName: user.firstName, lastName: user.lastName, email: user.email }))
    const filters = { 'key': 'fullName', 'op': 'anyOf', 'value': usersNames.map(name => ({ value: name, label: name })) }
    const viewUsersWithoutLicense = <>
      {addedUsersSinceLastLicensesUploadAmount} new {appName} {pluralize('user', addedUsersSinceLastLicensesUploadAmount)} have been added since the last license import.
      <RelativeTeamLink key={`/app/${idApp}/users`} to={`/app/${idApp}/users`} search={`filters=[${JSON.stringify(filters)}]`} onClick={() => this.onFilterChange(filters)}>
          &nbsp;
        <Link>view users</Link>
      </RelativeTeamLink>
    </>
    return <AlertBox
      type={AlertBoxType.NOTICE}
      title={viewUsersWithoutLicense}
      description={`To ensure data completeness, please upload an updated user list including license information.`}
    />
  }

  render () {
    const {
      appName,
      users,
      loading,
      loadingMore,
      hasData,
      totalUsers,
      tableInfo,
      configurableColumnsOptions,
      filterValues,
      canSelectRows,
      customSelectOptions
    } = this.props
    const { columns, selectedUsers } = this.state
    return (
      <Stack direction='column' gap='space-400'>
        {this.usersWereAddedSinceLastLicensesUpload()}
        <Table
          getNoDataProps={getNoDataProps}
          getTdProps={setTdStyle}
          tableKey={TABLES.appUsersTable.key}
          data={users}
          columns={columns}
          emptyStateMessage={this.renderNoUsersComponent}
          loading={loading}
          hasData={hasData}
          loadingMore={loadingMore}
          exportable
          showImportCSV
          importCSVPopoverComponent={UsersImportPopover}
          onCSVUsersImport={ImportUsersAnalytics.onImportUsersButtonClick}
          exportFunction={this.exportToCsv}
          onShareReport={this.onShareReport}
          searchable
          selectedItems={selectedUsers}
          selectId='docId'
          onSelectChange={this.onSelectChange}
          customHeaderOnSelect={this.customHeaderOnSelect}
          manual
          filterable
          selectable={canSelectRows}
          configurableColumnsOptions={configurableColumnsOptions}
          configurableColumns
          filtersOptions={this.getFilterOptions(tableInfo)}
          fetchData={this.fetchUsersData}
          forceShowSearch
          onSearch={this.onSearch}
          onSortedChangeCB={this.onSortedChange}
          onFilterChange={this.onFilterChange}
          filterOptionsValuesPerKey={filterValues}
          fetchFieldValues={this.fetchFieldValues}
          totalCount={totalUsers}
          customSelect
          customSelectOptions={customSelectOptions}
          customSelectSelectedValue={this.state.view}
          customSelectOnChange={this.onStatusChange}
          customSelectOptionRenderer={{ Option: SelectComponents.OptionWithDescription }}
          appName={appName}
        />
      </Stack>
    )
  }
}

const setTdStyle = () => {
  return {
    style: {
      border: 0,
      padding: '3px 10px',
      color: colors.black
    }
  }
}

AppUsers.propTypes = {
  title: PropTypes.string,
  hideExternal: PropTypes.bool,
  hideNative: PropTypes.bool,
  appName: PropTypes.string,
  apps: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    lastUsed: PropTypes.string.isRequired,
    isCore: PropTypes.bool.isRequired,
    normalizedScore: PropTypes.number.isRequired,
    users: PropTypes.number.isRequired,
    category: PropTypes.string.isRequired
  })),
  isSmallScreen: PropTypes.bool,
  isSupportsWritePermission: PropTypes.bool,
  deletedUsersMessage: PropTypes.node,
  importAppUsersStatus: PropTypes.oneOf(Object.values(IMPORT_APP_USERS_STATUS)),
  hasData: PropTypes.func
}

AppUsers.defaultProps = {
  title: 'users',
  hideExternal: false,
  hideNative: false,
  isSmallScreen: false
}

export default AppUsers
