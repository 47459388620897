export const CONNECT_STAGES = {
  CONNECT_FORM: 'CONNECT_FORM',
  IN_PROGRESS: 'IN_PROGRESS',
  RESULTS: 'RESULTS',
  ERROR: 'ERROR'
}
export const TEST_PHASES = {
  IN_PROGRESS: 'in_progress',
  RESULTS: 'results'
}
export const TEST_RESULTS = {
  SUCCESS: 'SUCCESS',
  PARTIAL_SUCCESS: 'PARTIAL_SUCCESS',
  FAILED: 'FAILED',
  IN_PROGRESS: 'IN_PROGRESS',
  INTERNAL_ERROR_SCREEN: 'ERROR_SCREEN'
}

export const CAPABILITY_TO_NAME = {
  users: 'Users',
  licenses: 'Licenses',
  usage: 'Usage',
  thirdPartyApps: '3rd party apps',
  contracts: 'Contracts',
  expenses: 'Expenses',
  realTimeEvents: 'Events'
}

export const CAPABILITY_TYPES = {
  USERS: 'users',
  LICENSES: 'licenses',
  USAGE: 'usage',
  THIRD_PARTY_APPS: 'thirdPartyApps',
  CONTRACTS: 'contracts',
  EXPENSES: 'expenses'
}
