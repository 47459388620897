import React from 'react'
import { Body2, Card, Col, Grid, H2, Stack } from '@toriihq/design-system'
import { FullWidth, RowWithMargin } from '@pages/renewalForecast/recommendation/style'
import Currency, { CurrencyFormat } from '@components/currency'
import { ColoredCell } from '@components/renewalsSummary/renewalsListTable/columns'
import { WidgetCardGridProps } from '@pages/renewalForecast/recommendation/types'

const WidgetCardGrid = ({
  licensesDetails
}: WidgetCardGridProps): JSX.Element => {
  const { totalLicenseCost: currentLicenseCost, forecastedLicenseCost, savings: forecastedChange } = licensesDetails
  const percentage = (forecastedChange / currentLicenseCost)
  const showPercentage = percentage !== 0

  const WidgetCard = ({ value, description }) => (
    <Card>
      <FullWidth>
        <Stack gap={'space-0'} direction={'column'} justifyContent={'space-between'}>
          <H2>
            {value}
          </H2>
          <Body2 color={'secondary'}>
            {description}
          </Body2>
        </Stack>
      </FullWidth>
    </Card>
  )

  return <FullWidth>
    <Stack gap={'space-200'} direction={'column'}>
      <FullWidth>
        <Grid>
          <RowWithMargin marginBottom={0}>
            <Col sm={4}>
              <WidgetCard description={'Current license cost'} value={<Currency value={currentLicenseCost} />} />
            </Col>
            <Col sm={4}>
              <WidgetCard description={'Forecasted license cost'} value={<Currency value={forecastedLicenseCost} />} />
            </Col>
            <Col sm={4}>
              <WidgetCard description={'Forecasted change'} value={
                <Stack gap={'space-100'} direction={'row'}>
                  <Currency value={forecastedChange} format={forecastedChange > 0 ? CurrencyFormat.POSITIVE : CurrencyFormat.DEFAULT} />
                  {showPercentage ? <Body2 >
                    <ColoredCell value={percentage} type='percentage' />
                  </Body2> : null}
                </Stack>
              } />
            </Col>
          </RowWithMargin>
        </Grid>
      </FullWidth>
    </Stack>
  </FullWidth>
}

export default WidgetCardGrid
