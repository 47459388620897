import styled from 'styled-components'

export const Wrapper = styled.div`
  min-height: 500px;
`

export const Image = styled.img`
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
  width: 40%;
  min-width: 520px;
`

export const ExpandableSectionWrapper = styled.div`
  margin: 16px 0;
`

export const ToggleRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`

export const FeatureDescription = styled.span`
  flex: 1;
`

export const EmptyStateContainer = styled.div`
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
`
