import styled from 'styled-components'

const insideTableRowsClass = '.rt-tr:hover &'

export const StopWorkflowButtonContainer = styled.div`
  opacity: 0;
  transition: opacity .2s;
  ${insideTableRowsClass} {
    opacity: 1;
  }
`
