import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@toriihq/design-system'
import * as Style from './style'

const OverflowTooltip = ({ children, ...rest }) => {
  const [isOverflown, setIsOverflown] = useState(false)
  const textElementRef = useRef(undefined)

  const checkOverflow = () => {
    const element = textElementRef.current
    if (element) {
      setIsOverflown(element.scrollWidth > element.offsetWidth)
    }
  }

  useEffect(() => {
    const element = textElementRef.current
    if (!element) return

    checkOverflow()

    const resizeObserver = new ResizeObserver(() => {
      checkOverflow()
    })

    resizeObserver.observe(element)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  return <Tooltip hide={!isOverflown} fullWidth {...rest}>
    <div ref={textElementRef} {...Style.TextContainer}>{children}</div>
  </Tooltip>
}

OverflowTooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired
}

export default OverflowTooltip
