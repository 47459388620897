import analytics from '@root/helpers/analytics'
import { IMPORT_TYPE } from '@root/store/selectors/importUsers/types'

const trackEventWithFlowType = (eventName, importMode, additionalProps = {}) => {
  const props = {
    'Flow type': importMode === IMPORT_TYPE.USERS ? 'Import users' : 'Adjust licenses',
    ...additionalProps
  }
  analytics.track(eventName, props)
}

const Analytics = {
  onOpenImportPopup: (importMode, buttonLabel, appName) => trackEventWithFlowType('Open import-users-dialog', importMode, { 'Button label': buttonLabel, 'App name': appName }),
  onImportUsersButtonClick: () => analytics.track('Open import-users-button-popover'),
  onOpenDeletePopup: (importMode) => trackEventWithFlowType('Open delete-imported-users-dialog', importMode),
  onCloseDeletePopup: (importMode, buttonLabel: 'X' | 'Cancel') => trackEventWithFlowType('Close delete-imported-users-dialog', importMode, { 'Button label': buttonLabel }),
  onDeleteImportedUsers: (importMode, usersCount) => trackEventWithFlowType('Delete imported-users', importMode, { '# Entities': usersCount }),
  onCloseImportPopup: (importMode, stepName, buttonLabel) => trackEventWithFlowType('Close import-users-dialog', importMode, { 'Step name': stepName, 'Button label': buttonLabel }),
  onClickImportUsers: (importMode) => trackEventWithFlowType('Click on import-users-button-in-dialog', importMode),
  onClickPreviewButton: (importMode) => trackEventWithFlowType('Click on preview-button', importMode),
  onClickSkipUsersPanel: (importMode) => trackEventWithFlowType('Click on skipped-users-panel', importMode),
  onClickMissingUsersPanel: (importMode) => trackEventWithFlowType('Click on missing-users-panel', importMode),
  onBackAction: (importMode, stepName) => trackEventWithFlowType('Click on Back', importMode, { 'Step name': stepName }),
  onLearnMoreClick: (importMode) => trackEventWithFlowType('Click on learn-more-about-importing-users-link', importMode),
  onDateFormatRadioButton: (importMode, selectionToText) => trackEventWithFlowType('Select Date-format-radio-btn', importMode, { 'radio button selection': selectionToText })
}

export default Analytics
