import View from './view'
import { connect } from 'react-redux'
import {
  getContractsConfigurableColumnsOptions,
  getContractsResources,
  getContractStatusOptions,
  getContractStatusOptionsOrder,
  getContracts as getContractsSelector,
  getUnmatchedContracts as getUnmatchedContractsSelector,
  getEditableContractDetailsFields,
  getContractFilterOptionsValuesPerKey,
  getContractsTablesInfo,
  getContractsFields,
  getContractsDoneAppMatching
} from '@selectors/contracts'
import { EMPTY_ARRAY, SCOPES, TABLES } from '../../constants'
import {
  getContracts,
  getUnmatchedContracts,
  getAppContracts,
  setContractsDetails,
  deleteContracts,
  toggleContractPopup,
  setDoneContractsAppMatching, toggleShareReportPopup
} from '@actions/'
import { getUserPreferences } from '@selectors/ui'
import AccessControl from '@lenses/accessControl'
import { getScopeByIdOrgAndIdApp } from '@root/lenses/scopes'
import { getCompanyName, getCurrency, getCurrentOrg } from '@selectors/org'
import { withRouter } from 'react-router-dom'

const getDefaultSort = ({ userPreferences, tableKey }) => {
  if (userPreferences[tableKey].defaultSort && userPreferences[tableKey].defaultSort.length) {
    return userPreferences[tableKey].defaultSort
  }

  return TABLES[tableKey].defaultSort || EMPTY_ARRAY
}

const mapStateToProps = (state, ownProps) => {
  const { tableKey, idApp, idContractToOpenPopup } = ownProps
  const { id: idOrg } = getCurrentOrg(state)
  const companyName = getCompanyName(state)
  const contracts = getContractsSelector(state)
  const unmatchedContracts = getUnmatchedContractsSelector(state)
  const tableInfo = getContractsTablesInfo(state)[tableKey]
  const contractStatusOptions = getContractStatusOptions(state)
  const contractStatusOptionsOrder = getContractStatusOptionsOrder(state)
  const { users: usersById, apps: appsById, contracts: contractsById } = getContractsResources(state)
  const configurableColumnsOptions = getContractsConfigurableColumnsOptions(state)
  const editableContractsFields = getEditableContractDetailsFields(state)
  const contractsFields = getContractsFields(state)
  const orgDefaultCurrency = getCurrency(state)
  const isDoneAppMatching = getContractsDoneAppMatching(state)

  const userPreferences = getUserPreferences(state)
  const defaultSort = getDefaultSort({ userPreferences, tableKey })
  const filterOptionsValuesPerKey = getContractFilterOptionsValuesPerKey(state)
  const isMultiSelectAllowed = AccessControl.isAccessibleWithState({ scopes: [SCOPES.CONTRACTS_WRITE, getScopeByIdOrgAndIdApp(SCOPES.CONTRACTS_WRITE, idOrg, idApp)], state })

  return {
    idOrg,
    companyName,
    idContractToOpenPopup,
    usersById,
    appsById,
    contractsById,
    contracts,
    unmatchedContracts,
    unmatchedContractsCount: unmatchedContracts.length,
    tableInfo,
    contractStatusOptions,
    contractStatusOptionsOrder,
    configurableColumnsOptions,
    editableContractsFields,
    contractsFields,
    defaultSort,
    filterOptionsValuesPerKey,
    isMultiSelectAllowed,
    orgDefaultCurrency,
    isDoneAppMatching,
    userPreferences: userPreferences[tableKey]
  }
}

const mapDispatchToProps = {
  getContracts,
  getUnmatchedContracts,
  getAppContracts,
  toggleContractPopup,
  setContractsDetails,
  deleteContracts,
  setDoneContractsAppMatching,
  toggleShareReportPopup
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onAddContract () {
    dispatchProps.toggleContractPopup({ isDuplicated: false, isOpen: true, idApp: ownProps.idApp })
  },
  onEditContract (idContract) {
    dispatchProps.toggleContractPopup({ isDuplicated: false, idContract, isOpen: true, ...(ownProps.idApp && { idApp: ownProps.idApp }) })
  },
  onDuplicateContract (contractToDuplicate) {
    dispatchProps.toggleContractPopup({ isDuplicated: true, isOpen: true, initialValues: contractToDuplicate, buttonLabel: 'Duplicate', ...(ownProps.idApp && { idApp: ownProps.idApp }) })
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(View))
