import React, { JSX } from 'react'
import Linkify from 'linkify-react'
import { Link } from '@toriihq/design-system'
import { IntermediateRepresentation } from 'linkifyjs'

const InternalLink = ({ attributes, content }: Pick<IntermediateRepresentation, 'attributes' | 'content'>): JSX.Element => {
  return (
    <Link {...attributes} target='_blank' onClick={(e) => { e.stopPropagation() }}>{content}</Link>
  )
}

const ToriiLinkify = ({ value }: { value: string }): JSX.Element => (
  <Linkify options={{
    render: {
      url: InternalLink,
      email: InternalLink
    }
  }}>
    {value}
  </Linkify>
)

export default ToriiLinkify
