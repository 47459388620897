import React, { useState } from 'react'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import LicenseEdit, { LicenseEditSelectedColumn } from '@components/licenseEdit'
import {
  bulkUpdateLicensesTypes,
  updateLicenseCurrencyAndDate,
  getLicensesTypes,
  toggleLicenseEditPopup
} from '@actions/'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrency, getIdOrg } from '@selectors/org'
import get from 'lodash/get'
import difference from 'lodash/difference'
import differenceBy from 'lodash/differenceBy'
import some from 'lodash/some'
import isNumber from 'lodash/isNumber'
import moment from 'moment'
import { SCOPES } from '@root/constants'
import Analytics from '@helpers/analytics'
import { getLicenseEditPopup } from '@selectors/ui'
import { useHistory } from 'react-router-dom'
import { toast, ToastType } from '@toriihq/design-system'

const LicenseEditPopup = (): JSX.Element => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { isOpen, appName, licenses, idApp, idLicense, selectedColumn = LicenseEditSelectedColumn.PricePerUser, conversionDateFromContract, currencyFromContract, openedFromContractFlow } = useSelector(getLicenseEditPopup)
  const initialCurrencyCode = get(licenses, [0, 'currency'], '')
  const licenseConversionDate = get(licenses, [0, 'conversionDate'], '')
  const conversionDate = conversionDateFromContract || licenseConversionDate
  const doesLicensesHavePrice = some(licenses, license => license.pricePerUser)
  const initialLicenseConversionDate = conversionDate ? moment(conversionDate).utc().format('MM/DD/YYYY').toString() : moment().utc().subtract(1, 'day').format('MM/DD/YYYY').toString()
  const [currency, setCurrency] = useState(doesLicensesHavePrice ? initialCurrencyCode : currencyFromContract)
  const [date, setDate] = useState(initialLicenseConversionDate)
  const [updatedLicenses, setLicenses] = useState(licenses)
  const orgDefaultCurrency = useSelector(getCurrency)
  const idOrg = useSelector(getIdOrg)

  const onCloseAction = () => dispatch(toggleLicenseEditPopup({ isOpen: false }))

  const onDateChange = (date: string | undefined) => {
    setDate(moment(date).format('MM/DD/YYYY').toString())
  }

  const onCurrencyChange = (currency: { label: string, value: string }) => {
    Analytics.track(`Select contract-currency`, {
      'App name': appName,
      Currency: currency.value,
      'Dialog-name': 'edit-licenses'
    })
    setCurrency(currency.value)
  }

  const onSave = async () => {
    const updatedLicensesProps = updatedLicenses.map(license => ({
      idLicense: license.id,
      pricePerUser: license.pricePerUser,
      totalAmountEnteredByUser: license.totalAmountEnteredByUser
    }))

    const isCurrencyChanged = currency !== initialCurrencyCode
    const isConversionDateChanged = date !== initialLicenseConversionDate
    const shouldUpdateLicenseCurrencyAndDate = isCurrencyChanged || isConversionDateChanged
    const shouldUpdateLicensesTypes = difference(updatedLicenses, licenses).length > 0

    if (shouldUpdateLicenseCurrencyAndDate) {
      await dispatch(updateLicenseCurrencyAndDate({
        idOrg,
        idApp,
        currency: currency || orgDefaultCurrency,
        conversionDate: date
      }))
    }
    if (shouldUpdateLicensesTypes) {
      await dispatch(bulkUpdateLicensesTypes({
        idOrg,
        idApp,
        licenses: updatedLicensesProps,
        LicensesFullDetails: updatedLicenses,
        waitForES: false
      }))
    }

    if (shouldUpdateLicenseCurrencyAndDate || shouldUpdateLicensesTypes) {
      const isLicenseCostChanged = differenceBy(updatedLicenses, licenses, 'pricePerUser').length > 0
      const isTotalAmountChanged = differenceBy(updatedLicenses, licenses, 'totalAmountEnteredByUser').length > 0

      const analyticsChanges: string[] = []
      isCurrencyChanged && analyticsChanges.push('Currency')
      isConversionDateChanged && analyticsChanges.push('Conversion date')
      isLicenseCostChanged && analyticsChanges.push('License cost')
      isTotalAmountChanged && analyticsChanges.push('Total licenses')

      Analytics.track(`Update licenses`, {
        'App name': appName,
        Currency: currency,
        'Updated fields': analyticsChanges.join(', ')
      })
    }

    await dispatch(getLicensesTypes({ idOrg, idApp }))
    if (openedFromContractFlow) {
      toast({
        message: `${appName} licenses cost updated successfully`,
        type: ToastType.SUCCESS,
        action: { label: 'View', onClick: () => history.push(`/team/${idOrg}/licenses`) }
      })
    }
    onCloseAction()
  }

  const isSaveButtonDisabled = some(updatedLicenses, license => isNumber(license.totalAmount) && license.isTotalAmountEnteredByUser && license.totalAmount < license.assignedAmount)

  return (
    <ToriiPopup isOpen={isOpen} onCloseAction={onCloseAction}
      styles={{ modal: { width: '1036px' } }}>
      <ToriiPopup.Header header={`Edit ${appName} licenses`} />
      <ToriiPopup.Content contentAreaStyle={{ padding: '31px' }}>
        <LicenseEdit
          idOrg={idOrg}
          idApp={idApp}
          onCurrencyChange={onCurrencyChange}
          onDateChange={onDateChange}
          onLicensesChange={setLicenses}
          currency={currency}
          date={date}
          licenses={updatedLicenses}
          idLicense={idLicense}
          selectedColumn={selectedColumn}
        />
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        mainButtonAction={onSave}
        mainButtonText='Save'
        mainButtonVisibilityScopes={[SCOPES.LICENSE_AND_CHARGEBACK_WRITE]}
        scopes={[SCOPES.LICENSE_AND_CHARGEBACK_WRITE]}
        cancelButtonAction={onCloseAction}
        cancelButtonText='Cancel'
        isMainButtonDisabled={isSaveButtonDisabled}
      />
    </ToriiPopup>
  )
}

export default LicenseEditPopup
