import { createSelector } from 'reselect'
import get from 'lodash/get'
import identity from 'lodash/identity'
import { Dashboard, DashboardData } from '@reducers/dashboards/types'
import { State } from '@reducers/types'

export const getDashboards = createSelector<State, Dashboard[], Dashboard[]>(
  state => get(state, ['dashboards', 'dashboardsConfig']),
  identity
)

export const getIsLoadingDashboards = createSelector<State, Dashboard[], Dashboard[]>(
  state => get(state, ['dashboards', 'isLoading']),
  identity
)

export const getDashboardsData = createSelector<State, DashboardData, DashboardData>(
  state => get(state, ['dashboards', 'dashboardsData']),
  identity
)
