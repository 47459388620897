import send from '@shared/redux-fetch'
import { GET_SCHEDULED_REPORTS, DELETE_SCHEDULED_REPORT, UPDATE_SCHEDULED_REPORT } from '@root/constants'
import { DeleteScheduledReportParams, GetScheduledReportsParams, UpdateScheduledReportParams } from '@actions/scheduledReports/types'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'

export const getOrgScheduledReports = ({ idOrg }: GetScheduledReportsParams) => {
  return send(GET_SCHEDULED_REPORTS, {
    url: `/api/orgs/${idOrg}/reports`
  })
}

export const deleteScheduledReport = ({ idOrg, idReport }: DeleteScheduledReportParams) => {
  return send(DELETE_SCHEDULED_REPORT, {
    url: `/api/orgs/${idOrg}/reports/${idReport}`,
    method: 'DELETE',
    meta: {
      idReport
    }
  })
}

export const updateReport = ({ idOrg, idReport, reportName, messageDetails, scheduleDetails, isEnabled }: UpdateScheduledReportParams) => {
  const dataToUpdate = omitBy({
    reportName,
    messageDetails,
    scheduleDetails,
    isEnabled
  }, isNil)

  return send(UPDATE_SCHEDULED_REPORT, {
    url: `/api/orgs/${idOrg}/reports/${idReport}`,
    method: 'PUT',
    body: JSON.stringify(dataToUpdate),
    meta: {
      idReport,
      isEnabled,
      timestamp: Date.now()
    }
  })
}
