import { Reducer } from 'redux'
import { CalendarsState, CalendarsAction } from './types'

const initialState: CalendarsState = {
  loading: false,
  calendars: []
}

const calendarsReducer: Reducer<CalendarsState, CalendarsAction> = (
  state = initialState,
  action
): CalendarsState => {
  switch (action.type) {
    case 'GET_CALENDARS_PENDING': {
      return {
        ...state,
        loading: true
      }
    }
    case 'GET_CALENDARS_FAILED': {
      return {
        ...state,
        loading: false
      }
    }
    case 'GET_CALENDARS_RESPONSE': {
      const { calendars } = action.payload

      return {
        ...state,
        loading: false,
        calendars
      }
    }
    case 'CREATE_CALENDAR_PENDING': {
      return {
        ...state,
        loading: true
      }
    }
    case 'CREATE_CALENDAR_FAILED': {
      return {
        ...state,
        loading: false
      }
    }
    case 'CREATE_CALENDAR_RESPONSE': {
      const { calendar } = action.payload

      return {
        ...state,
        loading: false,
        calendars: state.calendars.concat(calendar)
      }
    }
    default: {
      return state
    }
  }
}

export default calendarsReducer
