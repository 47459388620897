import React from 'react'
import { ButtonType, Button, Tooltip, Menu, Stack } from '@toriihq/design-system'
import Analytics from '../../helpers/analytics'

class HelpButton extends React.Component {
  state = { isOpen: false }

  onNavigateToHelpCenter = () => {
    Analytics.track('Clicked to open help center')
  }

  onToggle = ({ isOpen }) => {
    this.setState({ isOpen })
  }

  render () {
    const { helpCenterURL } = this.props
    const { isOpen } = this.state
    const submitRequestURL = 'https://support.toriihq.com/hc/en-us/requests/new'
    const myRequestsURL = 'https://support.toriihq.com/hc/en-us/requests'

    return (
      <Menu
        onToggle={this.onToggle}
        align='end'
        items={[
          {
            type: 'item',
            element: <Menu.Item><Stack gap='space-0'><a href={helpCenterURL} target='_blank' rel='noreferrer' onClick={this.onNavigateToHelpCenter}>Help center</a></Stack></Menu.Item>
          },
          { type: 'divider' },
          {
            type: 'item',
            element: <Menu.Item><Stack gap='space-0'><a href={submitRequestURL} target='_blank' rel='noreferrer'>Submit a support request</a></Stack></Menu.Item>
          },
          {
            type: 'item',
            element: <Menu.Item><Stack gap='space-0'><a href={myRequestsURL} target='_blank' rel='noreferrer'>My requests</a></Stack></Menu.Item>
          }
        ]}
      >
        <Tooltip
          hide={isOpen}
          placement='bottom'
          label='Help'>
          <Button type={ButtonType.tertiary} icon='Help' dataTestId='help-button' onClick={this.onClick} />
        </Tooltip>
      </Menu>
    )
  }
}

export default HelpButton
