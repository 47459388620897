import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getWorkflowActionExecutionsById } from '@lenses/workflows'
import {
  getActionsConfigByType,
  getAppCatalogPoliciesByIdSelector,
  getWorkflowActionExecutionsLoading,
  getDisplayedWorkflowExecution,
  getWorkflowsById,
  isLoadingAppCatalogPoliciesSelector,
  isLoadingDisplayedWorkflowExecution,
  isLoadingWorkflows,
  isAppsTriggersLoaded
} from '@selectors/workflows'
import { getAppDetailsFieldsByIdField } from '@selectors/apps'
import { EMPTY_OBJECT, WORKFLOW_TYPES } from '@root/constants'
import { getWorkflowActionExecutions, getWorkflowsActionsConfig, getContractsFields } from '@actions/'
import { getContractsFieldsById } from '@selectors/contracts'
import { getWorkflow, getWorkflowExecution } from '@actions/workflows'
import { getWorkflowsTriggersConfig } from '@store/actions'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const idWorkflow = parseInt(ownProps.match.params.idWorkflow, 10)
  const idWorkflowExecution = parseInt(ownProps.match.params.idWorkflowExecution, 10)

  const workflow = (
    ownProps.isAppCatalogPoliciesView ? getAppCatalogPoliciesByIdSelector(state)[idWorkflow] : getWorkflowsById(state)[idWorkflow]
  ) || EMPTY_OBJECT

  const loadingPolicies = isLoadingAppCatalogPoliciesSelector(state)
  const loadingWorkflows = isLoadingWorkflows(state)
  const loadingActionExecutions = getWorkflowActionExecutionsLoading(state)

  const actions = getWorkflowActionExecutionsById(state, idWorkflowExecution)
  const actionsByType = getActionsConfigByType(state) || EMPTY_OBJECT

  const appFields = getAppDetailsFieldsByIdField(state) || EMPTY_OBJECT
  const contractsFields = getContractsFieldsById(state) || EMPTY_OBJECT

  const loadingWorkflowExecution = isLoadingDisplayedWorkflowExecution(state)
  const workflowExecution = getDisplayedWorkflowExecution(state)

  const loadingTriggerInfo = !isAppsTriggersLoaded(state)

  const loading = (loadingWorkflows || loadingPolicies || loadingActionExecutions) && (actions.length === 0)

  return {
    idOrg,
    idWorkflow,
    idWorkflowExecution,
    workflow,
    actionsByType,
    appFields,
    actions,
    loading,
    showTriggeredBy: Boolean(workflow && workflow.type === WORKFLOW_TYPES.manual),
    contractsFields,
    workflowExecution,
    loadingWorkflowExecution,
    loadingTriggerInfo
  }
}

const mapDispatchToProps = {
  getWorkflow,
  getWorkflowActionExecutions,
  getWorkflowsActionsConfig,
  getContractsFields,
  getWorkflowExecution,
  getWorkflowsTriggersConfig
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
