import React, { ReactElement, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, ButtonType, Spacer, Stack, Tooltip, toast, ToastType } from '@toriihq/design-system'
import { useWorkflowEditorContext } from '../context'
import { useSelectedWorkflow } from '../../hooks/useSelectedWorkflow'
import { getWorkflowsEditHistoryById } from '@selectors/workflows'
import { ToolbarTitle } from './styles'
import { getSpecificWorkflowPath, getWorkflowPathPrefix } from '@root/lenses/workflows'
import { getDisplayName } from '@lenses/users'
import { getFormattedDate } from '@lenses/utils'
import { ACTION_DESCRIPTION_BY_EDIT_HISTORY_TYPE } from '@lenses/workflows.t'
import { isEmpty } from 'lodash'
import BackAndForwardNavigator from '@components/BackAndForwardNavigator'
import Confirmation from '@components/confirmation'
import EnableFor from '@components/enableFor'
import { SCOPES } from '@root/constants'
import { restoreWorkflowVersion } from '@actions/workflows'
import { getCurrentOrg } from '@selectors/org'
import { useHistory } from 'react-router-dom'
import { analytics } from '@shared/services/workflows/analytics'

export const WorkflowAuditToolbar = (): ReactElement => {
  const dispatch = useDispatch()
  const history = useHistory()

  const workflowEditHistoryById = useSelector(getWorkflowsEditHistoryById)
  const { id: idOrg } = useSelector(getCurrentOrg)
  const [isRestoreOpen, setIsRestoreOpen] = useState(false)
  const { isPreviewMode, idAudit } = useWorkflowEditorContext()
  const workflow = useSelectedWorkflow()
  const {
    id: idWorkflow,
    type: workflowType,
    name: workflowName
  } = workflow
  const triggerType = workflow.triggerType!

  const workflowEditHistoryInfo = (idAudit && workflowEditHistoryById[idAudit]) ?? {}
  const isViewingLatestEditHistoryVersion = Boolean(idAudit) && workflowEditHistoryInfo?.nextIdWorkflowEditHistory === null
  const shouldRender = Boolean(isPreviewMode && !isEmpty(workflowEditHistoryInfo))
  const performedBy = workflowEditHistoryInfo?.editedBy && getDisplayName(workflowEditHistoryInfo?.editedBy)
  const date = getFormattedDate({ date: workflowEditHistoryInfo.editTime, includeTime: true })
  const label = ACTION_DESCRIPTION_BY_EDIT_HISTORY_TYPE[workflowEditHistoryInfo.editType]?.(workflowEditHistoryInfo.metadata) || ''

  const handleRestoreClick = (): void => {
    setIsRestoreOpen(true)
    analytics.restoreWorkflow.openDialog({ idWorkflow, triggerType, workflowName })
  }

  const restoreWorkflow = async (): Promise<void> => {
    await dispatch(restoreWorkflowVersion({ idWorkflow, idVersion: idAudit as number, idOrg }))
    setIsRestoreOpen(false)
    analytics.restoreWorkflow.restoreVersion({ idWorkflow, triggerType, workflowName })
    const workflowPath = getSpecificWorkflowPath({ workflowType, triggerType, idWorkflow })
    history.push(`/team/${idOrg}/${workflowPath}`)

    toast({
      message: `Restored from ${date}`,
      type: ToastType.SUCCESS,
      position: 'bottom-right'
    })
  }

  const restoreWorkflowConfirmation = <Confirmation
    isOpen={isRestoreOpen}
    header='Restore edit history'
    text={<>Restoring this version will update the trigger and action configurations to match those in the restored version.<br />The workflow will also be set to inactive.</>}
    confirmText='Restore'
    declineText='Cancel'
    decline={() => setIsRestoreOpen(false)}
    confirm={restoreWorkflow}
    close={() => setIsRestoreOpen(false)}
    ignoreViewOnlyMode
    mainButtonIcon='Clock'
  />

  return shouldRender ? <Spacer top={'space-250'}>
    <Stack direction='row' gap='space-200'>
      <ToolbarTitle>Viewing workflow edit history</ToolbarTitle>
      <BackAndForwardNavigator
        analyticsEvent='Navigate workflow edit history'
        searchParams='?previewId='
        label={`${label} by ${performedBy} on ${date}`}
        linkPrefix={`/${getWorkflowPathPrefix({ workflowType, triggerType })}/${idWorkflow}`}
        backLink={workflowEditHistoryInfo?.previousIdWorkflowEditHistory}
        nextLink={workflowEditHistoryInfo?.nextIdWorkflowEditHistory}
      />
      <Tooltip label='This is the latest workflow version' hide={!isViewingLatestEditHistoryVersion}>
        <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]} ignoreViewOnlyMode>
          <Button type={ButtonType.primary} label='Restore' onClick={handleRestoreClick} icon='Clock' disabled={isViewingLatestEditHistoryVersion} />
        </EnableFor>
      </Tooltip>
    </Stack>
    {restoreWorkflowConfirmation}
  </Spacer> : <></>
}
