import React, { ReactElement } from 'react'
import { formFieldTypes, SCOPES, USER_FIELD_SEARCH_TYPES, WORKFLOW_ACTION_TYPES, WORKFLOW_TRIGGER_TYPES } from '@root/constants'
import ToriiSelect from '@components/select'
import Input from '@components/form/input'
import { css } from 'glamor'
import DatePicker from '@components/datePicker'
import moment from 'moment'
import SelectState from '@components/selectState'
import SelectAccount from '@components/workflows/selectAccount'
import SingleUser from '@components/workflows/singleUser'
import MultipleUsers from '@components/workflows/multipleUsers'
import MentionsTextarea from '@components/form/mentionsTextarea'
import { controlStyle } from '@components/form/formStyle'
import Threshold from '@components/workflows/threshold'
import AdvancedFilters from '@components/advancedFilters'
import UserMeetsCriteriaVerticalFilters from '@components/filters/userMeetsCriteriaVerticalFilters'
import AppAndAccountSelect from '@components/appAndAccountSelect'
import compact from 'lodash/compact'
import FormFieldSelectUser from '@components/formFieldSelectUser'
import UpdateFieldV2 from '@components/workflowsV2/updateField'
import EmailSetupBox from '@components/workflows/emailSetup/emailSetupBox'
import EmailPreviewButton from '@components/workflows/emailSetup/emailPreviewButton'
import SelectFormFields from '@components/workflows/selectFormFields'
import LicensesSelectV2 from '@components/workflowsV2/licensesSelect'
import SelectApps from '@components/workflows/selectApps'
import get from 'lodash/get'
import KeyValueTable from '@components/keyValueTable'
import lowerCase from 'lodash/lowerCase'
import pluralize from 'pluralize'
import EnableFor from '@components/enableFor'
import { formatDateAsUTC } from '@shared/utils'
import SelectUsers from '@components/selectUsers'
import { dateValidator, emailValidator, numberValidator } from './validators'
import { CSS } from './styles'
import { ADVANCED_FILTERS_DISPLAY_TYPE } from '@components/advancedFilters/consts'
import IfElseBranchFilters from '@components/filters/ifElseBranchFilters'
import { InputComponent } from '@components/workflows/formField/components/InputComponent'
import { Dropdown } from '@components/workflows/formField/components/dropdowns/dropdown'
import { DropdownMulti } from '@components/workflows/formField/components/dropdowns/dropdownMulti'
import { OverridableDropdown } from '@components/workflows/formField/components/dropdowns/overridableDropdown'
import ContractImg from '@media/contract.svg'
import isUndefined from 'lodash/isUndefined'
import omitBy from 'lodash/omitBy'
import { UserDetailChange } from './components/userDetailChange'
import { TextArea } from '@toriihq/design-system'
import { CustomWebhookPayloadConfig } from './components/customWebhookPayloadConfig'
import { ReadonlyCopyField } from '@components/readonlyCopyField'
import { CurrencyInput } from '@components/form/currencyInput'

const numberInputComponent = ({ input }) => <Input {...input} type='number' onChange={(e) => input.onChange(e, true)} />
const textareaComponent = ({ input }) => <TextArea {...input} onChange={(e) => input.onChange(e, true)} />
const allNonNumbersRegex = /([^0-9]*)/g

const adjustLabel = (word) => {
  return word ? lowerCase(pluralize.singular(word)) : ''
}

const getKeyValueStylesAndProps = ({ className, personalization, disabledPlaceholders, valuePlaceholder }: { className: string, personalization?: [], disabledPlaceholders: boolean, valuePlaceholder?: string }): { valueContainerCSS: Record<string, any>, valueProps: Record<string, any> } => {
  const valueContainerCSS = css({
    ' input': {
      ...controlStyle
    }
  })

  const valueProps = omitBy({
    singleLine: true,
    placeholder: valuePlaceholder || (disabledPlaceholders ? '' : 'Use @ to customize'),
    className,
    mentions: personalization,
    overrideStyle: {
      border: 'none',
      '&singleLine': {
        input: {
          width: '100%',
          padding: '6px 12px'
        }
      }
    }
  }, isUndefined

  )
  return { valueContainerCSS, valueProps }
}

interface FormFieldConfig {
  component: (props: any) => ReactElement
  validators?: (vale: any) => string | undefined
  format?: (value: any) => any
  parse?: (value: any) => any
}

export const formFieldTypeToConfigMap: Record<string, FormFieldConfig> = {
  [formFieldTypes.number]: {
    component: numberInputComponent
  },
  [formFieldTypes.singleLine]: {
    component: InputComponent
  },
  [formFieldTypes.multiLine]: {
    component: textareaComponent
  },
  [formFieldTypes.email]: {
    component: InputComponent,
    validators: emailValidator
  },
  [formFieldTypes.currency]: {
    component: ({ input }) => <CurrencyInput
      {...input}
      onChange={value => input.onChange(value, true)}
      onBlur={() => input.onBlur(input.value)}
    />,
    validators: numberValidator
  },
  [formFieldTypes.cardNumber]: {
    component: InputComponent,
    format: value => 'XXXX-XXXX-XXXX-' + (value || '').replace(allNonNumbersRegex, '').substr(0, 4),
    parse: value => (value || '').replace(allNonNumbersRegex, '')
  },
  [formFieldTypes.datePicker]: {
    component: ({ input }) => (
      <DatePicker
        value={input.value ? new Date(formatDateAsUTC(input.value)) : input.value}
        onDayChange={(date) => {
          input.onChange(date && moment.utc(date, 'YYYY-MM-DD').toDate())
        }}
        dayPickerProps={{
          onBlur: input.onBlur,
          onDayMouseDown: (date) => {
            input.onChange(date && moment.utc(date, 'YYYY-MM-DD').toDate())
          }
        }}
        disabled={input.disabled}
      />
    ),
    validators: dateValidator
  },
  [formFieldTypes.dropdownMulti]: {
    component: DropdownMulti
  },
  [formFieldTypes.overridableDropdownMulti]: {
    component: props => <OverridableDropdown {...props} dropdownType='multi' />
  },
  [formFieldTypes.dropdown]: {
    component: Dropdown
  },
  [formFieldTypes.overridableDropdown]: {
    component: props => <OverridableDropdown {...props} dropdownType='single' />
  },
  [formFieldTypes.usersDropdown]: {
    component: ({ input, idActionExe, id, secret }) =>
      <FormFieldSelectUser
        idActionExe={idActionExe}
        id={id}
        secret={secret}
        onChange={(selectedOption) => {
          input.onChange(selectedOption)
        }}
        value={input.value}
      />
  },
  [formFieldTypes.usersDropdownMulti]: {
    component: ({ input }) => {
      return <SelectUsers
        className={css({ width: '100%' })}
        placeholder='Search users'
        openOnFocus
        excludePastUsers
        multi
        onChange={(users) => {
          const idUsers = users?.map(user => user.id)
          input.onChange(idUsers)
        }}
        value={input.value}
        closeOnSelect={false}
      />
    }
  },
  [formFieldTypes.account]: {
    component: ({ field, input, action, refreshDynamicFieldsOptions, fieldParams }) => {
      const options = (field.options || []).map(option => ({ ...option, value: option.id }))
      const idApp = field.idApp || (action || {}).idApp || (fieldParams || {}).idApp

      return <SelectAccount
        options={options}
        selectedOptionValue={input.value}
        onChange={(selectedOption, locallyOnly) => input.onChange(selectedOption, locallyOnly)}
        idApp={idApp}
        disabled={input.disabled}
        refreshDynamicFieldsOptions={refreshDynamicFieldsOptions}
      />
    }
  },
  [formFieldTypes.user]: {
    component: ({ field, input, triggerType }) => {
      const userSearchType = field.userSearchType
      const isExternal = userSearchType === USER_FIELD_SEARCH_TYPES.INTERNAL_USERS ? false : undefined

      return <SingleUser
        includeCustomUserFields
        value={input.value}
        triggerType={triggerType}
        onChange={input.onChange}
        adminsOnly={Boolean(field.adminsOnly)}
        disabled={input.disabled}
        isExternal={isExternal}
        clearable={Boolean(field.clearable)}
      />
    }
  },
  [formFieldTypes.users]: {
    component: ({ field, input, triggerType }) => {
      return <MultipleUsers
        includeCustomUserFields={Boolean(!field.excludeCustomUserFields)}
        value={input.value}
        triggerType={triggerType}
        onChange={input.onChange}
        adminsOnly={Boolean(field.adminsOnly)}
        disabled={input.disabled}
        allowedScopes={[SCOPES.AUTOMATION_WRITE]}
        creatable={Boolean(field.showAddNewUserOption)}
        field={field}
      />
    }
  },
  [formFieldTypes.mentionsSingleLine]: {
    component: ({ input, personalization, field }) => {
      const style = {
        border: 'none',
        '&singleLine': {
          input: {
            width: '100%',
            padding: '6px 12px'
          }
        }
      }

      const containerCSS = css({
        ' input': {
          ...controlStyle
        }
      })

      return <div {...containerCSS}>
        <MentionsTextarea
          disabled={input.disabled}
          singleLine
          value={input.value}
          placeholder={field.placeholder || 'Use @ to customize'}
          mentions={personalization}
          overrideStyle={style}
          onChange={(e) => input.onChange(e, true)}
          onBlur={(e, clickedSuggestion) => {
            if (clickedSuggestion) {
              return
            }
            input.onBlur({ target: { value: input.value } })
          }}
          className={undefined}
          trigger={undefined} />
      </div>
    }
  },
  [formFieldTypes.mentionsMultiLine]: {
    component: ({ input, personalization, field, className }) => {
      const style = {
        '&multiLine': {
          input: {
            padding: '9px 12px',
            outline: 0,
            width: '100%',
            height: '100%',
            minHeight: 120
          }
        }
      }

      const containerCSS = css({
        ' textarea': {
          ...controlStyle
        }
      })

      return <div {...containerCSS}>
        <MentionsTextarea
          className={className}
          disabled={input.disabled}
          value={input.value}
          placeholder={field.placeholder || 'Use @ to customize'}
          mentions={personalization}
          overrideStyle={style}
          onChange={(e) => input.onChange(e, true)}
          onBlur={(e, clickedSuggestion) => {
            if (clickedSuggestion) {
              return
            }
            input.onBlur({ target: { value: input.value } })
          }}
          trigger={undefined} />
      </div>
    }
  },
  [formFieldTypes.threshold]: {
    component: ({ field, input }) => {
      return <Threshold
        options={field.options}
        threshold={input.value}
        onChange={(selectedOption) => input.onChange(selectedOption)}
        disabled={input.disabled}
      />
    }
  },
  [formFieldTypes.filters]: {
    component: ({ field, input, fetchTriggerValues, getTriggerPreview }) => {
      return (
        <AdvancedFilters
          optionsKey={field.options}
          optionsValuesPerKey={field.values}
          filters={input.value}
          onChange={value => {
            input.onChange(value)
            fetchTriggerValues({ filters: value })
          }}
          getTriggerPreview={getTriggerPreview}
          disabled={input.disabled}
          displayType={ADVANCED_FILTERS_DISPLAY_TYPE.VERTICAL}
          overrideStyle={css({ display: 'flex', flexDirection: 'column', gap: '16px', padding: 0 })}
          allowCustomValuesInMultiDropdown
        />
      )
    }
  },
  [formFieldTypes.userMeetsCriteriaFilters]: {
    component: ({ field, input, fetchTriggerValues, getTriggerPreview }) => {
      return (
        <UserMeetsCriteriaVerticalFilters
          getTriggerPreview={getTriggerPreview}
          optionsKey={field.options}
          optionsValuesPerKey={field.values}
          criteria={input.value || []}
          onChange={value => {
            input.onChange(value)
            const filters = compact((value || []).map(criterion => criterion.filters))
            fetchTriggerValues({ filters })
          }}
          disabled={input.disabled}
        />
      )
    }
  },
  [formFieldTypes.userDetailChange]: {
    component: UserDetailChange
  },
  [formFieldTypes.customWebhookPayloadConfig]: {
    component: CustomWebhookPayloadConfig
  },
  [formFieldTypes.ifElseBranchFilters]: {
    component: ({ input, triggerType }) => {
      return (
        <IfElseBranchFilters
          branches={input.value}
          triggerType={triggerType}
          onChange={value => input.onChange(value)}
          disabled={input.disabled}
        />
      )
    }
  },
  [formFieldTypes.appAndAccount]: {
    component: ({ input, field, triggerType, action }) => {
      const disabled = triggerType === WORKFLOW_TRIGGER_TYPES.USER_OFFBOARDING &&
          action?.type === WORKFLOW_ACTION_TYPES.REMOVE_USER_TASK

      return <AppAndAccountSelect
        selectedApp={input.value}
        options={field.options}
        onChange={value => {
          input.onChange(value)
        }}
        allowedScopes={[SCOPES.AUTOMATION_WRITE]}
        disabled={disabled}
      />
    }
  },
  [formFieldTypes.appState]: {
    component: ({ input, field }) => {
      return <div>
        <SelectState
          onChange={input.onChange}
          options={field.options || []}
          selectedValue={input.value}
          allowHideApp={false}
          allowedScopes={[SCOPES.AUTOMATION_WRITE]}
        />
      </div>
    }
  },
  [formFieldTypes.app]: {
    component: ({ input, field }) => {
      return <AppAndAccountSelect
        selectedApp={input.value}
        options={field.options}
        onChange={value => {
          input.onChange(value)
        }}
        allowedScopes={[SCOPES.AUTOMATION_WRITE]}
      />
    }
  },
  [formFieldTypes.setFieldValue]: {
    component: ({ input, field }) => {
      return <UpdateFieldV2 input={input} field={field} />
    }
  },
  [formFieldTypes.bool]: {
    component: ({ input, field }) => {
      const options = [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' }
      ]
      return <ToriiSelect
        options={options}
        value={input.value}
        clearable={Boolean(field.clearable)}
        blurInputOnSelect={false}
        onBlur={() => input.onBlur(input.value)}
        onChange={(selectedOption) => selectedOption || field.clearable ? input.onChange(selectedOption) : null}
        disabled={input.disabled}
      />
    }
  },
  [formFieldTypes.emailSetup]: {
    component: ({ input, field, triggerType, personalization, action }) => {
      const fields = field.fields.map(subField => ({ ...subField, value: input.value[subField.id] || subField.value }))
      const onChange = (subField) => {
        return input.onChange({ ...input.value, [subField.fieldId]: subField.selectedValue })
      }

      return <div {...CSS.emailSetupContainer}>
        <EmailPreviewButton overrideStyle={CSS.emailSetupPreview} actionType={action.type} />
        <EmailSetupBox fields={fields} onChange={onChange} triggerType={triggerType} personalization={personalization} action={action} allowedScopes={[SCOPES.AUTOMATION_WRITE]} />
      </div>
    }
  },
  [formFieldTypes.selectFormFields]: {
    component: ({ input, action }) => {
      return <SelectFormFields value={input.value} onChange={input.onChange} action={action} />
    }
  },
  [formFieldTypes.selectLicenses]: {
    component: ({ input }) => {
      return <LicensesSelectV2 value={input.value} onChange={input.onChange} />
    }
  },
  [formFieldTypes.includeAndExcludeApps]: {
    component: ({ input, field }) => {
      return <SelectApps value={input.value} onChange={input.onChange} field={field} />
    }
  },
  [formFieldTypes.contract]: {
    component: ({ field, input }) => {
      const renderOption = (props) => {
        const option = props.data
        return <div {...CSS.contractOptionContainer}>
          <img alt='contract' src={ContractImg} width={24} height={24} />
          {option.label}
        </div>
      }

      const isLoading = !input.disabled && field.loading && (!field.options || !field.options.length)
      return <ToriiSelect
        options={field.options}
        value={input.value}
        onBlur={() => input.onBlur(input.value)}
        blurInputOnSelect={false}
        onChange={(selectedOption) => (selectedOption || field.clearable) ? input.onChange(selectedOption) : null}
        clearable={Boolean(field.clearable)}
        disabled={input.disabled}
        isLoading={isLoading}
        optionRenderer={renderOption}
      />
    }
  },
  [formFieldTypes.mentionsKeyValueTable]: {
    component: ({ input, personalization, field, className }) => {
      const valuePlaceholder = field?.valuePlaceholder
      const { valueContainerCSS, valueProps } = getKeyValueStylesAndProps({ className, personalization, valuePlaceholder, disabledPlaceholders: false })

      const keyProps = {
        placeholder: get(field, 'keyPlaceholder')
      }

      const allowedScopes = [SCOPES.AUTOMATION_WRITE]

      return (
        <EnableFor scopes={allowedScopes}>
          <KeyValueTable
            keyValueArray={input.value}
            keyValueType={adjustLabel(field.label)}
            ValueComponent={MentionsTextarea}
            keyComponentProps={keyProps}
            valueComponentProps={valueProps}
            valueContainerCSS={valueContainerCSS}
            onBlur={() => input.onBlur(input.value)}
            onChange={(e, saveLocallyOnly = true) => {
              input.onChange(e, saveLocallyOnly)
            }}
            disallowedCharacters={field.disallowedCharacters}
          />
        </EnableFor>
      )
    }
  },
  [formFieldTypes.keyValueTable]: {
    component: ({ input, field, className }) => {
      const valuePlaceholder = field?.valuePlaceholder
      const { valueProps } = getKeyValueStylesAndProps({ className, disabledPlaceholders: true, valuePlaceholder })

      const keyProps = {
        placeholder: get(field, 'keyPlaceholder')
      }

      const allowedScopes = [SCOPES.AUTOMATION_WRITE]

      return (
        <EnableFor scopes={allowedScopes}>
          <KeyValueTable
            keyValueArray={input.value}
            keyValueType={adjustLabel(field.label)}
            keyComponentProps={keyProps}
            valueComponentProps={valueProps}
            onBlur={() => input.onBlur(input.value)}
            onChange={(e, saveLocallyOnly = true) => {
              input.onChange(e, saveLocallyOnly)
            }}
            disallowedCharacters={field.disallowedCharacters}
          />
        </EnableFor>
      )
    }
  },
  [formFieldTypes.readonlyCopy]: {
    component: ({ input, field }) => {
      return <ReadonlyCopyField
        value={input.value}
        sensitive={field.sensitive}
      />
    }
  }
}
