import React from 'react'
import ToriiSelect from '@components/select'
import { FormFieldV2 } from '@pages/workflow_v2/formField'
import identity from 'lodash/identity'
import { Field } from 'react-final-form'
import FormGroup from '@components/form/formGroup'
import { formFieldTypes } from '@root/constants'
import isUndefined from 'lodash/isUndefined'
import { formFieldTypeToConfigMap } from '@pages/workflow_v2/formField/mapping/fieldMapping'
import { UpdateDateField } from './updateDateField'
import { FormFieldInputProps } from '@pages/workflow_v2/formField/types'
import omit from 'lodash/omit'

interface Props {
  input: FormFieldInputProps
  field: any
}

class UpdateFieldV2 extends React.Component<Props> {
  renderFieldComponent = (props) => {
    const { input, field, disabled } = props
    const typeMapping = formFieldTypeToConfigMap[field.type] || {}
    const parse = typeMapping.parse || identity
    const newInput: FormFieldInputProps = {
      ...input,
      disabled,
      onBlur: e => {
        const selectedValue = e && e.target ? e.target.value : (e ?? '')
        input.onBlur(e)
        !isUndefined(e) && this.onValueChange(parse(selectedValue))
      },
      onChange: (e, locallyOnly = false) => {
        const selectedValue = e && e.target ? e.target.value : (e ?? '')
        input.onChange(e)
        this.onValueChange(parse(selectedValue), locallyOnly)
      }
    }

    return <FormFieldV2
      {...props}
      field={{
        ...field,
        isRequired: true
      }}
      overrideLabel='New value'
      input={newInput}
    />
  }

  renderField = (field) => {
    if (!field) {
      return null
    }

    const { input, field: outerField } = this.props
    const { disabled } = input
    const typeMapping = formFieldTypeToConfigMap[field.type] || {}
    const validate = typeMapping.validators

    return <Field
      addFieldIndex={false}
      index={0}
      initialValue={input.value.newValue}
      validate={validate}
      name={`${outerField.id}.newValue`}
      field={field}
      component={this.renderFieldComponent}
      format={typeMapping.format}
      parse={typeMapping.parse}
      disabled={disabled}
    />
  }

  onFieldChange = (field, local?: boolean) => {
    this.onChange({ field }, local)
  }

  onValueChange = (newValue, local?: boolean) => {
    this.onChange({ field: this.props.input.value.field, newValue }, local)
  }

  onChange = ({ field, newValue }: { field: any, newValue?: any }, local?: boolean) => {
    this.props.input.onChange({ field, newValue }, local)
  }

  render () {
    const { field, input } = this.props
    const { disabled } = input
    const { options } = field
    const editableOptions = options?.filter(f => !f.isReadonly)
    const selectedField = input.value.field
    const isDateField = selectedField && selectedField.type === formFieldTypes.datePicker

    return <>
      <FormGroup>
        <ToriiSelect
          options={editableOptions}
          placeholder='Select Field'
          valueKey='systemKey'
          labelKey='name'
          onChange={newValue => this.onFieldChange(newValue)}
          clearable={false}
          value={selectedField ? omit({ ...selectedField, label: selectedField.name, value: selectedField.systemKey }, ['options']) : undefined}
          disabled={disabled}
        />
      </FormGroup>
      {selectedField && !isDateField && this.renderField(selectedField)}
      {isDateField && <UpdateDateField
        disabled={disabled}
        field={field}
        value={input.value.newValue}
        onValueChange={this.onValueChange} />}
    </>
  }
}

export default UpdateFieldV2
