export enum DATA_COMPLETENESS_CHECK_STATES {
  NO_USERS,
  NO_USERS_NO_INTEGRATION,
  NO_LICENSE_DATA_AVAILABLE,
  NO_LICENSE_DATA_AVAILABLE_WITH_FAILED_INTEGRATION,
  MANUAL_ACCOUNT_OUTDATED,
  MANUAL_LICENSES_OUTDATED,
  MANUAL_LICENSES_OUTDATED_WITH_FAILED_INTEGRATION,
  MANUAL_LICENSES_NEW_USERS_AFTER_IMPORT,
  MANUAL_LICENSES_NEW_USERS_AFTER_IMPORT_WITH_FAILED_INTEGRATION,
  INTEGRATION_LICENSES_NON_GRANULAR,
  INTEGRATION_LICENSES_NON_GRANULAR_WITH_FAILED_INTEGRATION,
  NO_LAST_USED_DATE,
  NO_LAST_USED_DATE_WITH_FAILED_INTEGRATION,
  MANUAL_LICENSES_COMPLETE,
  MANUAL_LICENSES_COMPLETE_WITH_FAILED_INTEGRATION,
  INTEGRATION_LICENSES_COMPLETE,
  INTEGRATION_LICENSES_COMPLETE_WITH_FAILED_INTEGRATION,
}
