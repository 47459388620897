import { AGGREGATION_SORT_ORDERS, AGGREGATION_TYPES, METRIC_FUNC, Widget } from '@reducers/dashboards/types'

type getAppForGroupByReturn = { field: string, aggregationType: AGGREGATION_TYPES, options: { order:AGGREGATION_SORT_ORDERS, size: number }, aggs?: any }

export const getAggsForGroupBy = ({ widget }: { widget: Widget }) : getAppForGroupByReturn | undefined => {
  if (!widget.displayConfig.groupBy?.field) {
    return undefined
  }

  const result: getAppForGroupByReturn = {
    field: widget.displayConfig.groupBy.field.systemKey,
    aggregationType: AGGREGATION_TYPES.GROUP_BY,
    options: {
      order: AGGREGATION_SORT_ORDERS.DESC,
      size: widget.displayConfig.groupBy.size
    }
  }

  if (widget.displayConfig.groupBy.metricFunc && widget.displayConfig.groupBy.metricFunc !== METRIC_FUNC.TOTAL) {
    result.aggs = {
      field: widget.displayConfig.groupBy.metricField?.systemKey,
      aggregationType: AGGREGATION_TYPES.METRIC,
      options: {
        metricFunction: widget.displayConfig.groupBy.metricFunc
      }
    }
  }

  return result
}
