import React from 'react'
import PropTypes from 'prop-types'
import { FILTER_OPERATORS } from '@lenses/filters.t'
import { fieldTypes, formFieldTypes } from '@root/constants'
import { FIELD_TYPES } from '@root/constants.t'
import Select from '@components/select'
import DatePicker from '@components/datePicker'
import Input from '@components/form/input'
import moment from 'moment'
import { getValue } from '@shared/utils'
import RelativeDaySelect from '@components/relativeDaySelect'
import { find, isArray, get, isEqual, omit, uniqBy } from 'lodash'
import { getNewCreatedValueByModelType, optionsOpsMap } from './utils'
import { ErrorSpan, SelectWrapper } from './styles'
import { SELECTED_VALUE_NOT_EXISTS_CLASS_NAME } from '@components/select/consts'
import MultipleCheckboxSelect from '@components/multipleCheckboxSelect'
import debounce from 'lodash/debounce'

class KeyOpValueFilter extends React.Component {
  getOptionsOps (key = this.props.filter.key) {
    const { optionsKey } = this.props
    const { type } = optionsKey.find(({ value }) => (getValue(key)) === value) || {}
    return optionsOpsMap[type]
  }

  onChangeKey = (selected) => {
    const { onChange } = this.props
    const { id } = this.props.filter
    const key = selected && selected.value
    const optionsOps = this.getOptionsOps(key) || []
    const op = optionsOps[0] || {}
    const filter = { id, key: omit(selected, 'options'), op }
    onChange && onChange(filter)
  }

  onChangeOps = (selected) => {
    const { onChange } = this.props
    const { id, key, value } = this.props.filter
    const op = selected && selected.value

    const optionsOps = this.getOptionsOps()
    const { type: oldType } = optionsOps.find(({ value }) => getValue(this.props.filter.op) === value) || {}
    const { type: newType } = optionsOps.find(({ value }) => op === value) || {}

    const filter = (oldType === newType) ? { id, key, op: selected, value } : { id, key, op: selected }

    onChange && onChange(filter)
  }

  onChangeValues = (value = null) => {
    const { onChange } = this.props
    const { id, key, op } = this.props.filter
    const filter = { id, key, op, value }
    onChange && onChange(filter)
  }
  debouncedOnChangeValues = debounce(this.onChangeValues, 1000, { leading: false, trailing: true })

  renderSelectKey () {
    const { CSS, filter, optionsKey, disabled, fieldsAutoFocus } = this.props

    const hasValue = (filter.key !== undefined && filter.key !== null && filter.key !== '')
    const hasOptions = optionsKey.length > 0
    const labelFromOptions = hasValue ? (find(optionsKey, { value: filter.key.value }) || {}).label : null
    const shouldShowError = hasValue && hasOptions && !labelFromOptions

    let clonedFilter = { ...filter }

    if (filter.key && filter.key.value && !filter.key.label) {
      clonedFilter.key.label = (find(optionsKey, { value: filter.key.value }) || {}).label
    }

    return (
      <div {...CSS.selectKey}>
        <Select
          autoFocus={!hasValue && fieldsAutoFocus}
          hasError={shouldShowError}
          options={optionsKey}
          value={clonedFilter.key}
          onChange={this.onChangeKey}
          clearable={false}
          searchable
          openOnFocus
          disabled={disabled}
        />
        {shouldShowError && <ErrorSpan>The selected value no longer exists or is no longer assigned</ErrorSpan>}
      </div>
    )
  }

  renderSelectOps () {
    const { filter, disabled, fieldsAutoFocus } = this.props

    const optionsOps = this.getOptionsOps()

    if (!optionsOps) {
      return
    }

    const hasValue = (filter.op !== undefined && filter.op !== null && filter.op !== '')

    if (filter.op && filter.op.value && !filter.op.label) {
      filter.op.label = (find(optionsOps, { value: filter.op.value }) || {}).label
    }

    return (
      <Select
        autoFocus={!hasValue && fieldsAutoFocus}
        options={optionsOps}
        value={filter.op}
        onChange={this.onChangeOps}
        clearable={false}
        searchable
        openOnFocus
        disabled={disabled}
      />
    )
  }

  renderSelectValues () {
    const { CSS, filter, optionsKey, optionsValues, disabled, fieldsAutoFocus, allowCustomValuesInMultiDropdown } = this.props

    const optionsOps = this.getOptionsOps() || []
    let { type } = optionsOps.find(({ value }) => getValue(filter.op) === value) || {}

    if (!type) {
      const { type: newType } = optionsKey.find(({ value }) => (getValue(filter.key)) === value) || {}
      if (!newType || newType !== formFieldTypes.dropdownMulti) {
        return null
      }
      type = filter.op.type
    }

    const hasValue = (filter.value !== undefined && filter.value !== null && filter.value !== '')

    switch (type) {
      case formFieldTypes.bool: {
        const options = [
          { value: true, label: 'Yes' },
          { value: false, label: 'No' }
        ]

        const selectedOption = find(options, { value: get(filter, ['value', 'value'], filter.value) })

        return (
          <div {...CSS.propertyInput}>
            <Select
              autoFocus={!hasValue && fieldsAutoFocus}
              options={options}
              value={selectedOption}
              clearable={false}
              openOnFocus
              onChange={this.onChangeValues}
              disabled={disabled}
            />
          </div>
        )
      }

      case formFieldTypes.dropdown:
      case formFieldTypes.dropdownMulti: {
        const multi = (type === formFieldTypes.dropdownMulti)
        const hasNoOptions = Boolean(optionsValues && !optionsValues.length)
        const isLoadingOptions = !optionsValues

        const shouldAllowCustomOptionCreation = () => {
          if (filter?.key?.disableCustomValueCreation) {
            return false
          }

          return filter?.key?.type === fieldTypes.text && allowCustomValuesInMultiDropdown && multi
        }
        const allowCustomOptionCreation = shouldAllowCustomOptionCreation()

        let hasValuesThatNoLongerExist = false

        const getSelectedValue = (selectedValue, shouldCheckValues) => {
          const selectedValueFromCurrentOptions = (optionsValues || []).find(
            optionValue => isEqual(optionValue.value, selectedValue.value)
          )

          if (selectedValueFromCurrentOptions) {
            return selectedValueFromCurrentOptions
          }

          if (isLoadingOptions || !shouldCheckValues) {
            return selectedValue
          }

          hasValuesThatNoLongerExist = true
          return { ...selectedValue, className: SELECTED_VALUE_NOT_EXISTS_CLASS_NAME }
        }

        const getMultiSelectedValues = selectedValues =>
          selectedValues.map(selectedValue => getSelectedValue(selectedValue, false))

        const filterValue = filter.value
        const value = filterValue && (isArray(filterValue)
          ? getMultiSelectedValues(filterValue)
          : getSelectedValue(filterValue, true)
        )

        const getAllOptions = () => {
          if (isLoadingOptions) {
            return []
          }
          const options = uniqBy([...(optionsValues || []), ...(value || [])], 'value')

          return options
        }

        const selectProps = {
          autoFocus: !hasValue && fieldsAutoFocus,
          disabled: disabled || hasNoOptions,
          clearable: false,
          searchable: true,
          openOnFocus: true,
          arrowRenderer: isLoadingOptions ? null : undefined,
          isLoadingOptions,
          searchPromptText: isLoadingOptions ? 'Loading...' : '',
          placeholder: hasNoOptions ? 'No options' : 'Search...',
          closeMenuOnSelect: false
        }

        return (<SelectWrapper {...CSS.propertyInput}>
          {allowCustomOptionCreation ? <MultipleCheckboxSelect
            options={getAllOptions().map(({ value, label }) => ({ value, label, created: true }))}
            selectedValues={(value ?? []).map(({ value }) => value)}
            showValues
            cache={false}
            onChange={(selectedValues) => {
              const optionsWithValues = getAllOptions()
              const selectedValuesFromOptions = optionsWithValues
                .filter(({ value }) => selectedValues.includes(value))
                .map(({ key, label, value, count }) => ({ key, label, value, count }))

              multi ? this.debouncedOnChangeValues(selectedValuesFromOptions) : this.onChangeValues(selectedValuesFromOptions)
            }}
            creatable
            onNewOptionClick={(inputValue) => {
              if (inputValue) {
                const newValue = getNewCreatedValueByModelType({
                  modelType: filter?.key?.modelType,
                  inputValue
                })
                multi ? this.debouncedOnChangeValues([...(value ?? []), newValue]) : this.onChangeValues([...(value ?? []), newValue])
              }
            }}
            isValidNewOption={(inputValue, _, options) => Boolean(inputValue && !options.find(option => option.value === inputValue.toLowerCase()))}
            showSelectedValuesNotInOptions={false}
            formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
            {...selectProps}
          /> : <Select
            options={optionsValues}
            value={value}
            multi={multi}
            onChange={this.onChangeValues}
            {...selectProps}
          />}
          {hasValuesThatNoLongerExist && <ErrorSpan>The selected value no longer exists or is no longer assigned</ErrorSpan>}
        </SelectWrapper>)
      }

      case formFieldTypes.singleLine:
      case formFieldTypes.number: {
        return (
          <Input
            overrideStyle={CSS.propertyInput}
            autoFocus={!hasValue && fieldsAutoFocus}
            defaultValue={hasValue ? filter.value : ''}
            type={type}
            onBlur={(e) => {
              const value = (e && e.target && e.target.value)
              const hasValue = (value !== undefined && value !== null && value !== '')
              this.onChangeValues(hasValue ? (type === FIELD_TYPES.number ? parseInt(value, 10) : value) : null)
            }}
            disabled={disabled}
          />
        )
      }

      case formFieldTypes.datePicker: {
        return (
          <div {...CSS.values}>
            <DatePicker
              value={hasValue ? moment.utc(filter.value).format('MM/DD/YYYY') : null}
              onDayChange={this.onChangeValues}
              disabled={disabled}
            />
          </div>
        )
      }

      case formFieldTypes.relativeDate: {
        return (
          <RelativeDaySelect
            overrideStyle={CSS.relativeDayValue}
            value={filter.value}
            onChange={(value) => this.onChangeValues(value)}
            disabled={disabled}
          />
        )
      }

      default: {
        return null
      }
    }
  }

  render () {
    const { layout } = this.props
    return (
      <div style={{ display: 'flex', gap: 16, marginBottom: 16, flexDirection: layout === 'vertical' ? 'column' : 'row' }}>
        {this.renderSelectKey()}
        {this.renderSelectOps()}
        {this.renderSelectValues()}
      </div>
    )
  }
}

const dropdownObject = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.any
})

KeyOpValueFilter.propTypes = {
  filter: PropTypes.shape({
    id: PropTypes.any,
    key: PropTypes.oneOfType([PropTypes.string, dropdownObject]),
    op: PropTypes.oneOfType([PropTypes.oneOf(Object.keys(FILTER_OPERATORS)), dropdownObject]),
    value: PropTypes.any
  }),
  optionsKey: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    type: PropTypes.oneOf(Object.keys(FIELD_TYPES))
  })),
  optionsValues: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    label: PropTypes.string
  })),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  fieldsAutoFocus: PropTypes.bool,
  CSS: PropTypes.shape({
    propertyInput: PropTypes.object,
    values: PropTypes.object
  }),
  layout: PropTypes.oneOf(['horizontal', 'vertical']),
  allowCustomValuesInMultiDropdown: PropTypes.bool
}

KeyOpValueFilter.defaultProps = {
  optionsKey: [],
  filter: { id: 'default' },
  fieldsAutoFocus: true,
  allowCustomValuesInMultiDropdown: false
}

export default KeyOpValueFilter
