import { CREATE_CALENDAR, GET_CALENDARS } from '@root/constants.t'
import send from '@shared/redux-fetch'
import { GetCalendarsProps } from './types'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'

export const getCalendars = ({ idOrg, idUser }: GetCalendarsProps) => {
  return send(GET_CALENDARS, {
    url: `/api/orgs/${idOrg}/users/${idUser}/calendars`,
    method: 'GET'
  })
}

export const createCalendar = ({ idOrg, idUser, type }: GetCalendarsProps) => {
  return send(CREATE_CALENDAR, {
    url: `/api/orgs/${idOrg}/users/${idUser}/calendar`,
    method: 'POST',
    query: omitBy({
      type: type!
    }, isUndefined)
  })
}
