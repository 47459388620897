import React, { useEffect, useMemo, useState } from 'react'
import { Button, ButtonType, H4, Icon, Select, Spacer, Stack, Subtitle1 } from '@toriihq/design-system'
import { BodyContainer, CloseButton, DrawerContainer, FooterContainer, HeaderContainer } from './styles'
import { flatten } from 'lodash'
import { useWindowDimensions } from '@root/shared/hooks'
import Analytics from '../../analytics'

export interface ContractDrawerAttachment { [idContract: number]: { name: string, attachments: Array<{ idUpload: number, name: string, url: string }> } }
export interface ContractDrawerProps {
  isOpen: boolean,
  attachments: ContractDrawerAttachment
  onClose: () => void,
  appName: string
}

export interface SelectOption { label: string, options: { label: string, value: number }[] }

const ContractDrawer = ({ isOpen, onClose, attachments, appName }: ContractDrawerProps) => {
  const { width: windowWidth } = useWindowDimensions()
  const [selectedAttachment, setSelectedAttachment] = useState<{ label: string, value: number } | null>(null)

  const options = useMemo(() => {
    if (!attachments) return []
    return Object.keys(attachments).reduce<SelectOption[]>((acc, contractId) => {
      const contract = attachments[contractId]
      return [...acc, {
        label: contract.contractName,
        options: contract.attachments.map(attachment => ({ label: attachment.name, value: attachment.idUpload }))
      }]
    }, [])
  }, [attachments])

  useEffect(() => {
    if (options?.[0]?.options?.length > 0) {
      setSelectedAttachment({ label: options[0].options[0].label, value: options[0].options[0].value })
    }
  }, [options])

  const getSelectedAttachmentUrl = () => {
    const idUpload = selectedAttachment?.value
    if (!idUpload || !attachments) return ''
    const attachment = flatten(Object.values(attachments).map(x => x.attachments)).find(attachment => attachment.idUpload === idUpload)
    return attachment?.url || ''
  }

  const onHeaderClose = () => {
    Analytics.clickOnXPanelButton({ appName })
    onClose()
  }

  const onFooterClose = () => {
    Analytics.clickOnClosePanelButton({ appName })
    onClose()
  }

  const Header = () => <HeaderContainer>
    <Stack direction='row' gap='space-0' justifyContent='space-between'>
      <H4>Related contracts</H4>
      <CloseButton onClick={onHeaderClose}>
        <Icon color='secondary' name='Close' />
      </CloseButton>
    </Stack>
  </HeaderContainer>

  const Footer = () => <FooterContainer>
    <Button type={ButtonType.secondary} label='Close' onClick={onFooterClose} />
  </FooterContainer>

  const onSelectAttachment = (attachmentName) => {
    setSelectedAttachment(attachmentName)
    Analytics.selectContractDoc({ appName })
  }

  const optionRenderer = (props) => {
    const option = props.data
    return <div key={option.value}>
      <Stack direction='row' gap='space-050'><Icon name='File' />{option.label}</Stack>
    </div>
  }

  return (
    <DrawerContainer isOpen={isOpen} windowWidth={windowWidth}>
      <Header />
      <BodyContainer>
        <Spacer bottom='space-200'>
          <Stack direction='column' gap='space-025'>
            <Subtitle1>Contract documents</Subtitle1>
            <Select
              optionRenderer={optionRenderer}
              selectWidth={345}
              options={options}
              value={selectedAttachment}
              onChange={(newValue) => onSelectAttachment(newValue)}
            />
          </Stack>
        </Spacer>
        <iframe
          src={`${getSelectedAttachmentUrl()}`}
          style={{
            width: '100%',
            height: '90%',
            borderTop: 0,
            borderRight: 0,
            borderLeft: 0,
            borderBottom: '4px solid #0E1C28'
          }}
          title='PDF Viewer'
        />
      </BodyContainer>
      <Footer />
    </DrawerContainer>
  )
}

export default ContractDrawer
