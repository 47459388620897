import React from 'react'
import ToriiSelect from '../../select'
import FormField from '../formField'
import identity from 'lodash/identity'
import { Field } from 'react-final-form'
import { css } from 'glamor'
import Input from '../../form/input'
import FormGroup from '../../form/formGroup'
import { formFieldTypes } from '../../../constants'
import isUndefined from 'lodash/isUndefined'
import omit from 'lodash/omit'

const CSS = {
  dateNewValue: css({
    display: 'flex'
  }),
  dateFields: css({
    margin: '0 10px 0 0',
    width: 160
  }),
  dateNumber: css({
    width: 80,
    minWidth: 80
  })
}

class UpdateField extends React.Component {
  renderFieldComponent = (props) => {
    const { input, field } = props
    const typeMapping = FormField.mapping[field.type] || {}
    const parse = typeMapping.parse || identity
    const newInput = {
      ...input,
      disabled: props.disabled,
      onBlur: e => {
        const selectedValue = e && e.target ? e.target.value : (e ?? '')
        input.onBlur(e)
        !isUndefined(e) && this.onValueChange(parse(selectedValue))
      },
      onChange: (e, locallyOnly = false) => {
        const selectedValue = e && e.target ? e.target.value : (e ?? '')
        input.onChange(e)
        this.onValueChange(parse(selectedValue), locallyOnly)
      }
    }

    return <FormField
      {...props}
      field={{
        ...field,
        isRequired: true
      }}
      overrideLabel='New value'
      input={newInput}
    />
  }

  renderField = (field) => {
    if (!field) {
      return null
    }

    const { input, users, field: outerField } = this.props
    const { disabled } = input
    const typeMapping = FormField.mapping[field.type] || {}
    const validate = typeMapping.validators

    return <Field
      addFieldIndex={false}
      index={0}
      initialValue={input.value.newValue}
      validate={validate}
      name={`${outerField.id}.newValue`}
      field={field}
      users={users}
      component={this.renderFieldComponent}
      format={typeMapping.format}
      parse={typeMapping.parse}
      disabled={disabled}
    />
  }

  renderDateField = (value = {}) => {
    const { disabled } = this.props.input
    const className = `${CSS.dateFields}`
    return <FormGroup label='New Value' isRequired>
      <div {...CSS.dateNewValue}>
        <ToriiSelect
          className={className}
          options={[{ name: 'Triggered date', systemKey: 'trigger.date' }].concat((this.props.field.options || []).filter(f => f.type === formFieldTypes.datePicker))}
          placeholder='Select field'
          valueKey='systemKey'
          labelKey='name'
          onChange={(e) => this.onValueChange({ ...value, dateField: e })}
          clearable={false}
          value={value.dateField}
          disabled={disabled}
        />
        <ToriiSelect
          className={className}
          options={[{ label: 'Plus', value: 1 }, { label: 'Minus', value: -1 }]}
          value={value.sign}
          onChange={(e) => this.onValueChange({ ...value, sign: e })}
          disabled={disabled}
        />
        <Input
          className={`${CSS.dateNumber.toString()} ${className}`}
          type='number'
          value={value.number}
          onChange={(e) => this.onValueChange({ ...value, number: e.target.value }, true)}
          onBlur={(e) => this.onValueChange({ ...value, number: e.target.value })}
          disabled={disabled}
        />
        <ToriiSelect
          className={className}
          options={[{ label: 'Days', value: 'days' }, { label: 'Months', value: 'months' }, { label: 'Years', value: 'years' }]}
          value={value.unit}
          onChange={(e) => this.onValueChange({ ...value, unit: e })}
          disabled={disabled}
        />
      </div>
    </FormGroup>
  }

  onFieldChange = (field, local) => {
    this.onChange({ field }, local)
  }

  onValueChange = (newValue, local) => {
    this.onChange({ field: this.props.input.value.field, newValue }, local)
  }

  onChange = ({ field, newValue }, local) => {
    this.props.input.onChange({ field, newValue }, local)
  }
  render () {
    const { field, input } = this.props
    const { disabled } = input
    const { options } = field
    const selectedField = input.value.field
    const isDateField = selectedField && selectedField.type === formFieldTypes.datePicker
    return <>
      <FormGroup>
        <ToriiSelect
          options={options}
          placeholder='Select Field'
          valueKey='systemKey'
          labelKey='name'
          onChange={newValue => this.onFieldChange(newValue)}
          clearable={false}
          value={selectedField ? omit({ ...selectedField, label: selectedField.name, value: selectedField.systemKey }, ['options']) : undefined}
          disabled={disabled}
        />
      </FormGroup>
      {selectedField && !isDateField && this.renderField(selectedField)}
      {isDateField && this.renderDateField(input.value.newValue)}
    </>
  }
}

export default UpdateField
