import moment from 'moment'
import pluralize from 'pluralize/pluralize'

interface TimePassedProps {
  date: string
  smallestUnit: 'days' | 'hours' | 'minutes'
}

export const getTimePassedText = ({
  date,
  smallestUnit
}: TimePassedProps): string => {
  const now = moment().utc()
  const pastDate = moment(date)
  const diffMinutes = now.diff(pastDate, 'minutes', true)
  const diffHours = now.diff(pastDate, 'hours', true)
  const diffDays = now.diff(pastDate, 'days', true)

  if (
    (smallestUnit === 'minutes' && diffMinutes < 1) ||
    (smallestUnit === 'hours' && diffHours < 1) ||
    (smallestUnit === 'days' && diffDays < 1)
  ) {
    return `in the last ${pluralize.singular(smallestUnit)}`
  }

  if (diffDays >= 1) {
    const roundedDays = Math.floor(diffDays)
    return `${pluralize('day', roundedDays, true)} ago`
  }

  if (diffHours >= 1) {
    const roundedHours = Math.floor(diffHours)
    return `${pluralize('hour', roundedHours, true)} ago`
  }

  const roundedMinutes = Math.max(1, Math.floor(diffMinutes))
  return `${pluralize('minute', roundedMinutes, true)} ago`
}
