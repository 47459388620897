import React, { ReactElement } from 'react'
import { useSelectedWorkflow } from '@pages/workflow_v2/hooks/useSelectedWorkflow'
import { AlertBox, AlertBoxType, Link } from '@toriihq/design-system'
import { SUPPORT_ARTICLES } from '@root/articles'
import { ThrottledWorkflowsLearnMore } from './styles'

const ALERT_BOX_TEXT = 'Due to a spike in the number of triggers this workflow has been throttled. To ensure stability and prevent unintended actions, its execution has been temporarily limited. Review the pending executions and confirm if you’d like to resume processing.'

export const ThrottledInfo = (): ReactElement => {
  const workflow = useSelectedWorkflow()

  return workflow.isThrottled ? <AlertBox
    type={AlertBoxType.NOTICE}
    secondaryButton={{ label: 'Review Executions', onClick: () => {} }}
    title='Throttled workflow'
    description={<>
      <span>{ALERT_BOX_TEXT}</span>
      <ThrottledWorkflowsLearnMore>
        <Link href={SUPPORT_ARTICLES.WORKFLOWS_THROTTLED} target='_blank'>Learn more</Link>
      </ThrottledWorkflowsLearnMore>
    </>
    }
  /> : <></>
}
