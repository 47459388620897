import styled, { css } from 'styled-components'

export const DashboardGridContainer = styled.div`
  .react-grid-item.react-grid-placeholder {
    background-color: ${({ theme }) => theme.palette.background.brandPrimary};
  }

  .react-grid-item.cssTransforms {
    transition-property: none;
  }
  .animated .react-grid-item.cssTransforms {
    transition-property: transform;
  }

  .react-resizable-handle {
    transition: opacity 0.2s;
    opacity: 0;
    background-image: none;
  }

  .react-grid-item:hover .react-resizable-handle {
    opacity: 1;
  }

  .react-resizable-handle:after {
    content: inherit;
  }
`

export const WidgetContainer = styled.div<{ isEditMode: boolean }>`
  ${({ isEditMode }) => isEditMode && css`
      cursor: pointer;
  `}

  height: 100%;

  & > :first-child {
      box-sizing: border-box;
      height: 100%;
      overflow: auto;
      background-color: ${({ theme }) => theme.palette.background.primary};
  }
`

export const WidgetResizer = styled.div<{ $resizeHandleAxis: string }>`
  position: absolute;
  right: ${({ $resizeHandleAxis }) => $resizeHandleAxis.endsWith('e') ? '5px' : 'auto'};
  left: ${({ $resizeHandleAxis }) => $resizeHandleAxis.endsWith('w') ? '5px' : 'auto'};
  top: ${({ $resizeHandleAxis }) => $resizeHandleAxis.startsWith('n') ? '5px' : 'auto'};
  bottom: ${({ $resizeHandleAxis }) => $resizeHandleAxis.startsWith('s') ? '5px' : 'auto'};
`

export const ReminderWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing('space-125', 'space-0')};
  margin-bottom: ${({ theme }) => theme.spacing('space-200')};
`
