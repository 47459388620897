import { ops } from '@lenses/filters'

export enum INTEGRATION_TYPE {
  OAUTH = 'oauth',
  TOKENS = 'tokens',
  TORII_BOT = 'toriiBot',
  CONFIGURED_OAUTH = 'configured_oauth',
  CUSTOM = 'custom',
  SCIM = 'scim'
}

export type FilterParam = {
  key: string
  op: keyof typeof ops
  value: string
}

export enum APP_USER_STATUS {
  DELETED = 'deleted',
  ACTIVE = 'active',
  MANAGED = 'managed'
}

export enum USER_LIFECYCLE_STATUS {
  ACTIVE = 'active',
  OFFBOARDING = 'offboarding',
  OFFBOARDED = 'offboarded'
}

export interface TimezoneOption {
  value: string
  label: string,
  offset: number
}

export enum WORKFLOW_STEP_TYPE {
  SELECT = 'select',
  STEP_SETUP = 'stepSetup'
}

export enum WORKFLOW_TRIGGER_TYPE {
  NEW_APP = 'newApp',
  USER_STOP_USING_APP = 'userStoppedUsingApp',
  USER_STOP_USING_LICENSE = 'userStoppedUsingLicense',
  APP_STATE_CHANGED = 'appStateChanged',
  USER_OFFBOARDING = 'userOffboarding',
  USER_USING_CLOSED_APP = 'userUsingClosedApp',
  EXECUTE_ON_USERS = 'executeOnUsers',
  REQUEST_ACCESS = 'requestAccess',
  USER_TO_ACTIVATE_EXTENSION = 'userToActivateExtension',
  USER_MEETS_CRITERIA = 'userMeetsCriteria',
  CONTRACT_MEETS_CRITERIA = 'contractMeetsCriteria',
  USER_JOINS = 'newUser',
  USER_LEFT = 'userLeft',
  NEW_USER_IN_BAMBOOHR = 'newUserInBambooHR',
  LICENSE_THRESHOLD = 'licenseThreshold',
  APPLICATION_MEETS_CRITERIA = 'applicationMeetsCriteria',
  WORKFLOW_ERROR_OCCURRED = 'workflowErrorOccurred',
  APP_WEBHOOK_EVENT_OCCURRED = 'appWebhookEventOccurred'
}

export enum WORKFLOW_TYPE {
  regular = 'regular',
  offboarding = 'offboarding',
  manual = 'manual',
  notifyOnErrors = 'notifyOnErrors',
  appCatalog = 'appCatalog'
}

export interface UserModel {
  id: number
  email: string
  firstName: string | null
  lastName: string | null
  isSupport?: boolean
}
