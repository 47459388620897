import styled, { css } from 'styled-components'

export const DashboardChangesText = styled.div`
  color: ${({ theme }) => theme.palette.text.tertiary};
`

export const DashboardChangesContainer = styled.div<{ isEditMode: boolean }>`
  ${({ isEditMode }) => isEditMode && css`
    border: 2px dashed ${({ theme }) => theme.palette.border.focus};
    padding: ${({ theme }) => theme.spacing('space-200')};
    border-radius: ${({ theme }) => theme.corner('radius-150')};
  `}`

export const ButtonsContainer = styled.div<{ scrolledDown: boolean }>`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-bottom: ${({ theme, scrolledDown }) => (scrolledDown ? `1px solid ${theme.palette.border.secondary}` : 'none')};
  padding: ${({ theme }) => theme.spacing('space-400')};
  padding-right: 0;
`

export const DashboardContentElement = styled.div`
  height: 100%;
`

export const PageContentContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`
