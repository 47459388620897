import { PopupStepConfig } from '@root/components/popups/wizardPopup/types'
import { SharedProps, SharedState, WIZARD_STEPS } from './types'
import UserReview from '../userReview'
import LicenseReview from '../licenseReview'
import Recommendation from '../recommendation'

export const stepsConfig = (): PopupStepConfig<SharedState, SharedProps, WIZARD_STEPS>[] => {
  const steps = [
    { stepName: WIZARD_STEPS.USER_REVIEW, stepRenderer: UserReview },
    { stepName: WIZARD_STEPS.LICENSES_REVIEW, stepRenderer: LicenseReview },
    { stepName: WIZARD_STEPS.RECOMMENDATIONS, stepRenderer: Recommendation }
  ]
  return steps
}

export const STEPS_NAMES = {
  [WIZARD_STEPS.USER_REVIEW]: 'Review users',
  [WIZARD_STEPS.LICENSES_REVIEW]: 'Review licenses',
  [WIZARD_STEPS.RECOMMENDATIONS]: 'Review forecast'
}
