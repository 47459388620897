import emptyImage from '@media/dance.svg'
import { EmptyState } from '@toriihq/design-system'

const LabsEmptyState = (props): JSX.Element | null => {
  return <EmptyState
    image={<img src={emptyImage} alt='No users' />}
    title={`Coming soon...`}
    description={<>New features will be added here for you to try.<br />Check back regularly for the latest updates.</>}
    align={'center'}
  />
}

export default LabsEmptyState
