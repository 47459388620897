import { IdDashboard } from './../../types'
import send from '@shared/redux-fetch'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'
import {
  GET_DASHBOARDS,
  GET_DATA_FOR_WIDGET,
  TOGGLE_DASHBOARD_WIDGET_EDIT_CONFIG_DRAWER,
  UPDATE_DASHBOARD
} from '@root/constants'
import { GetWidgetDataProps, toggleDashboardWidgetEditConfigDrawerProps } from '@actions/dashboards/types'
import { Dashboard, ENTITY_TYPES } from '@reducers/dashboards/types'
import { IdOrg } from '@root/store/types'
import { isParentUserFilter } from '../actions'
import { pick } from 'lodash'

export const getEntityDataForWidget = ({ actionTarget = GET_DATA_FOR_WIDGET, entityType, idOrg, idDashboard, idWidget, aggs, fields = [], limit = 0, offset = 0, sort = [], q = '', filters = [], withoutContent = false }: GetWidgetDataProps) => {
  const additionalFilters = entityType === ENTITY_TYPES.USERS ? isParentUserFilter : {}

  return send(actionTarget, {
    url: `/api/orgs/${idOrg}/${entityType}`,
    query: omitBy({
      limit,
      offset,
      sort,
      q,
      fields: fields.join(','),
      filters: JSON.stringify(filters.concat([additionalFilters])),
      withoutContent,
      aggs: aggs ? JSON.stringify(aggs) : undefined
    }, isUndefined) as Record<string, string | number | boolean>,
    meta: {
      idDashboard,
      idWidget,
      entityType
    }
  })
}

export const getDashboards = ({ idOrg }) => {
  return send(GET_DASHBOARDS, {
    url: `/api/orgs/${idOrg}/dashboards`
  })
}

export const updateDashboard = ({ idOrg, idDashboard, dashboard }: { idOrg: IdOrg, idDashboard: IdDashboard, dashboard: Partial<Dashboard> }) => {
  return send(UPDATE_DASHBOARD, {
    method: 'PUT',
    url: `/api/orgs/${idOrg}/dashboards/${idDashboard}`,
    body: JSON.stringify({
      dashboard: pick(dashboard, ['title', 'description', 'widgets', 'layoutConfig'])
    }),
    meta: {
      idDashboard,
      dashboard
    }
  })
}

export const toggleDashboardWidgetEditConfigDrawer = ({ isOpen, sections, idDashboard, widget, onWidgetUpdate }: toggleDashboardWidgetEditConfigDrawerProps) =>
  ({
    type: TOGGLE_DASHBOARD_WIDGET_EDIT_CONFIG_DRAWER,
    payload: omitBy({ isOpen, idDashboard, widget, sections, onWidgetUpdate }, isUndefined),
    meta: {}
  })
