import React from 'react'
import Page from '../../components/page'
import PageHeader from '../../components/pageHeader'
import WorkflowsTable from '../../components/workflows/workflowsTable'
import { WORKFLOW_TYPES, SCOPES } from '../../constants'
import { css } from 'glamor'
import { fontSize } from '@shared/style/sizes'
import colors from '../../shared/style/colors'
import { Spacer, Button, ButtonType, ButtonSize, Menu, Link, LinkSize, AlertBox, AlertBoxType } from '@toriihq/design-system'
import { analytics } from '@shared/services/workflows/analytics'
import { getWorkflowPathPrefix } from '@lenses/workflows'
import EnableFor from '@components/enableFor'
import noop from 'lodash/noop'
import { ActionsLogButton } from '@shared/workflows/actions/components/ActionsLogButton'
import { ACTIONS_LOG_TYPE } from '@shared/workflows/actions/components/ActionsLogButton/types'
import { SUPPORT_ARTICLES } from '@root/articles'
import Analytics from '@helpers/analytics'
import * as Styles from './styles'
import { getHasHave, pluralizeWithFormat } from '@shared/utils'

const editAllowedScopes = [SCOPES.AUTOMATION_WRITE]

const CSS = {
  hiddenBadge: css({
    fontSize: fontSize.small,
    paddingLeft: '5px',
    paddingRight: '5px',
    backgroundColor: colors.lightBlue1,
    width: 'fit-content'
  }),
  buttons: css({
    display: 'flex',
    fontSize: 'initial'
  })
}

class WorkflowsPage extends React.Component {
  fetchData = () => {
    const { getWorkflows, idOrg, isHidden, getWorkflowsTriggersConfig } = this.props
    getWorkflows({ idOrg, type: WORKFLOW_TYPES.regular, isHidden, includeThrottledInfo: true })
    getWorkflowsTriggersConfig()
  }

  componentDidMount () {
    this.fetchData()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.isHidden !== this.props.isHidden) {
      this.fetchData()
    }
  }

  onWorkflowsSettingsButtonClick = () => {
    const { idOrg, history } = this.props
    analytics.settingsPage.open()
    history.push(`/team/${idOrg}/workflows/settings`)
  }

  getNewWorkflowButton = () => {
    const { isHidden, idOrg, history } = this.props
    const onToggleMenu = ({ isOpen }) => (isOpen && !isHidden) && analytics.create.click()

    const onClickFromScratch = async () => {
      return goToCreateWorkflowPage()
    }

    const goToCreateWorkflowPage = () => {
      !isHidden && analytics.create.selectCreationOption({ menuItem: 'From scratch' })
      history.push(`/team/${idOrg}/workflows/new`)
    }

    const goToWorkflowTemplatePage = () => {
      !isHidden && analytics.create.selectCreationOption({ menuItem: 'From template' })
      history.push(`/team/${idOrg}/workflows/template`)
    }

    const options = [
      {
        type: 'item',
        element: <EnableFor scopes={editAllowedScopes}>
          <Menu.Item onClick={onClickFromScratch}>
            From scratch
          </Menu.Item>
        </EnableFor>
      },
      {
        type: 'item',
        element: <EnableFor scopes={editAllowedScopes}>
          <Menu.Item onClick={goToWorkflowTemplatePage}>
            From template
          </Menu.Item>
        </EnableFor>
      },
      { type: 'divider' },
      <Link
        size={LinkSize.Medium}
        href={SUPPORT_ARTICLES.WORKFLOWS_5_TO_START_FROM}
        onClick={() => {
          Analytics.track('Open workflow-examples-documentation', {
            'Open from': 'New workflow menu'
          })
        }}
        target='_blank'>
        <Link.Icon name='Workflows' />View 5 workflow examples
      </Link>
    ]
    return (
      <Menu
        position='bottom'
        align='end'
        onToggle={onToggleMenu}
        items={options}
      >
        <EnableFor scopes={editAllowedScopes}>
          <Button
            size={ButtonSize.small}
            type={ButtonType.primary}
            label={isHidden ? 'New hidden workflow' : 'New workflow'}
            onClick={noop}
          />
        </EnableFor>
      </Menu>
    )
  }

  getThrottledWorkflowsAlertBox = () => {
    const {
      numberOfThrottledWorkflows
    } = this.props

    return numberOfThrottledWorkflows > 0 && (
      <Styles.ThrottledWorkflowsAlertBox>
        <AlertBox
          type={AlertBoxType.NOTICE}
          description={<>
            <span>
              {pluralizeWithFormat('workflow', numberOfThrottledWorkflows, true)} {getHasHave(numberOfThrottledWorkflows)} been throttled due to a spike in the number of triggers.
            </span>
            <Styles.ThrottledWorkflowsLearnMore>
              <Link href={SUPPORT_ARTICLES.WORKFLOWS_THROTTLED} target='_blank'>Learn more</Link>
            </Styles.ThrottledWorkflowsLearnMore>
          </>}
        />
      </Styles.ThrottledWorkflowsAlertBox>
    )
  }

  render () {
    const {
      title,
      workflows,
      loading,
      isHidden,
      usersMap
    } = this.props

    const pathPrefix = getWorkflowPathPrefix({ workflowType: WORKFLOW_TYPES.regular })

    return <Page title='Workflows'>
      <PageHeader
        title={title}
        allowedScopes={editAllowedScopes}
        overrideStyle={{ padding: 0 }}
        contentRight={
          <div {...CSS.buttons}>
            <Spacer right={'space-150'}><Button type={ButtonType.secondary} onClick={this.onWorkflowsSettingsButtonClick} label='Workflow Settings' /></Spacer>
            <Spacer right={'space-150'}><ActionsLogButton actionsLogType={ACTIONS_LOG_TYPE.ALL} /></Spacer>
            {this.getNewWorkflowButton()}
          </div>
        }
        contentTop={isHidden && <div {...CSS.hiddenBadge}>Hidden</div>}
        menuButtonOutline={false}
      />
      {this.getThrottledWorkflowsAlertBox()}
      <WorkflowsTable
        workflows={workflows}
        loading={loading}
        isHidden={isHidden}
        usersMap={usersMap}
        pathPrefix={pathPrefix}
      />
    </Page>
  }
}

export default WorkflowsPage
