import { FEATURES } from '@shared/features'
import { bucketDisplayName } from '@components/renewalsSummary/utils/utils'
import {
  INACTIVE_USERS_TABLE_COLUMNS,
  INACTIVE_USERS_TABLE_COLUMN_TITLES,
  USERS_TABLE_COLUMNS,
  USERS_TABLE_COLUMN_TITLES
} from './tableColumnsMappingConst'
import { WORKFLOW_EXECUTION_STATUS_LABELS, WORKFLOW_THROTTLED_STATUS } from '@root/constants.t'

export const REGISTER = 'REGISTER'
export const GET_EMAIL = 'GET_EMAIL'
export const FORGOT = 'FORGOT'
export const LOGIN = 'LOGIN'
export const LOGIN_IS_USING_SAML = 'LOGIN_IS_USING_SAML'
export const GET_SAML_URL = 'GET_SAML_URL'
export const LOGOUT = 'LOGOUT'
export const RESET_LOGOUT = 'RESET_LOGOUT'
export const RESET = 'RESET'
export const GET_ME = 'GET_ME'
export const GET_UPDATES = 'GET_UPDATES'
export const GET_SINGLE_APP = 'GET_SINGLE_APP'
export const GET_SIMILAR_APPS = 'GET_SIMILAR_APPS'
export const GET_SUGGESTED_OWNER = 'GET_SUGGESTED_OWNER'
export const GET_APPS = 'GET_APPS'
export const GET_APPS_V2 = 'GET_APPS_V2'
export const GET_EXPENSE_REPORT_APPS = 'GET_EXPENSE_REPORT_APPS'
export const GET_EXPENSE_REPORT_APPS_FIELD_VALUES = 'GET_EXPENSE_REPORT_APPS_FIELD_VALUES'
export const ADD_APP = 'ADD_APP'
export const ADD_CUSTOM_APP = 'ADD_CUSTOM_APP'
export const GET_APP_LICENSES = 'GET_APP_LICENSES'
export const GET_APP_COMPLIANCE = 'GET_APP_COMPLIANCE'
export const GET_OFFBOARDING_TODO_USERS = 'GET_OFFBOARDING_TODO_USERS'
export const GET_OFFBOARDING_TODO_USERS_COUNT = 'GET_OFFBOARDING_TODO_USERS_COUNT'
export const GET_OFFBOARDING_IN_PROGRESS_USERS = 'GET_OFFBOARDING_IN_PROGRESS_USERS'
export const GET_OFFBOARDING_IN_PROGRESS_USERS_COUNT = 'GET_OFFBOARDING_IN_PROGRESS_USERS_COUNT'
export const GET_OFFBOARDING_DONE_USERS = 'GET_OFFBOARDING_DONE_USERS'
export const GET_OFFBOARDING_DONE_USERS_COUNT = 'GET_OFFBOARDING_DONE_USERS_COUNT'
export const TOGGLE_NAVIGATION = 'TOGGLE_NAVIGATION'
export const TOGGLE_SMALL_SCREEN = 'TOGGLE_SMALL_SCREEN'
export const TOGGLE_ASSIGN_USER = 'TOGGLE_ASSIGN_USER'
export const TOGGLE_ADD_USER = 'TOGGLE_ADD_USER'
export const TOGGLE_ALL_EXPENSE_CATEGORIES = 'TOGGLE_ALL_EXPENSE_CATEGORIES'
export const TOGGLE_CONNECT_SOURCE = 'TOGGLE_CONNECT_SOURCE'
export const TOGGLE_SELECT_FIELD_TYPE_POPUP = 'TOGGLE_SELECT_FIELD_TYPE_POPUP'
export const TOGGLE_UPLOAD_EXPENSES = 'TOGGLE_UPLOAD_EXPENSES'
export const TOGGLE_IMPORT_CONTRACTS_FILE = 'TOGGLE_IMPORT_CONTRACTS_FILE'
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS'
export const GET_TRANSACTIONS_AGGS = 'GET_TRANSACTIONS_AGGS'
export const GET_LAST_12_MONTHS_TRANSACTIONS_AGGS = 'GET_LAST_12_MONTHS_TRANSACTIONS_AGGS'
export const GET_TRANSACTIONS_AGGS_BY_CATEGORY = 'GET_TRANSACTIONS_AGGS_BY_CATEGORY'
export const GET_LAST_YEAR_TOTAL_EXPENSES = 'GET_LAST_MONTH_TOTAL_EXPENSES'
export const RESET_TRANSACTIONS = 'RESET_TRANSACTIONS'
export const GET_TRANSACTIONS_FIELD_VALUES = 'GET_TRANSACTIONS_FIELD_VALUES'
export const GET_SEARCHED_TRANSACTIONS = 'GET_SEARCHED_TRANSACTIONS'
export const RESET_SEARCH_TRANSACTIONS = 'RESET_SEARCH_TRANSACTIONS'
export const GET_TRANSACTIONS_BY_SOURCE = 'GET_TRANSACTIONS_BY_SOURCE'
export const ARCHIVE_EXPENSES_TRANSACTION = 'ARCHIVE_EXPENSES_TRANSACTION'
export const UNARCHIVE_EXPENSES_TRANSACTION = 'UNARCHIVE_EXPENSES_TRANSACTION'
export const EDIT_EXPENSES_TRANSACTION = 'EDIT_EXPENSES_TRANSACTION'
export const INVITE_USER = 'INVITE_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const REVOKE_MEMBER = 'REVOKE_MEMBER'
export const TOGGLE_INVITE_USER = 'TOGGLE_INVITE_USER'
export const TOGGLE_USER_LIFECYCLE_SETTINGS = 'TOGGLE_EDIT_USER_LIFECYCLE'
export const TOGGLE_DASHBOARD_WIDGET_EDIT_CONFIG_DRAWER = 'TOGGLE_DASHBOARD_WIDGET_EDIT_CONFIG_DRAWER'
export const SEND_USER_LIFECYCLE_CHANGES_REPORT = 'SEND_USER_LIFECYCLE_CHANGES_REPORT'
export const SEND_USER_TYPE_SOURCE_CHANGES_REPORT = 'SEND_USER_TYPE_SOURCE_CHANGES_REPORT'
export const TOGGLE_ADD_APPLICATION = 'TOGGLE_ADD_APPLICATION'
export const GET_APP_INTEGRATIONS = 'GET_APP_INTEGRATIONS'
export const SORT_TABLE = 'SORT_TABLE'
export const TABLE_CUSTOM_SELECT_CHANGE = 'TABLE_CUSTOM_SELECT_CHANGE'
export const FILTER_TABLE = 'FILTER_TABLE'
export const TOGGLE_FILTER_TABLE = 'TOGGLE_FILTER_TABLE'
export const EDIT_TABLE_VIEW = 'EDIT_TABLE_VIEW'
export const ADD_TABLE_VIEW = 'ADD_TABLE_VIEW'
export const DELETE_TABLE_VIEW = 'DELETE_TABLE_VIEW'
export const UPDATE_ORG = 'UPDATE_ORG'
export const UPDATE_ORG_TRIAL_END_TIME = 'UPDATE_ORG_TRIAL_END_TIME'
export const UPDATE_IS_TORII_SUPPORT_ALLOWED_FOR_ORG = 'UPDATE_IS_TORII_SUPPORT_ALLOWED_FOR_ORG'
export const UPDATE_ORG_PAID_PLAN_END_TIME = 'UPDATE_ORG_PAID_PLAN_END_TIME'
export const UPDATE_ORG_APP_OWNER_SETTINGS = 'UPDATE_ORG_APP_OWNER_SETTINGS'
export const GET_ORG = 'GET_ORG'
export const RENEWALS_SUMMARY_BASE_YEAR_CHANGE = 'RENEWALS_SUMMARY_BASE_YEAR_CHANGE'
export const GET_ALL_PLANS = 'GET_ALL_PLANS'
export const SET_ONBOARDING_STATUS = 'SET_ONBOARDING_STATUS'
export const GET_SYNC_DATA = 'GET_SYNC_DATA'
export const GET_SYNC_DATA_FOR_APP_ACCOUNT = 'GET_SYNC_DATA_FOR_APP_ACCOUNT'
export const GET_SERVICES_CONFIG = 'GET_SERVICES_CONFIG'
export const GET_TEST_CONNECTION_INFO = 'GET_TEST_CONNECTION_INFO'
export const CREATE_TEST_CONNECTION_ENTRY = 'CREATE_TEST_CONNECTION_ENTRY'
export const UPDATE_TEST_CONNECTION_ENTRY = 'UPDATE_TEST_CONNECTION_ENTRY'
export const CREATE_SOURCE_TOKEN_AFTER_TEST_CONNECTION = 'CREATE_SOURCE_TOKEN_AFTER_TEST_CONNECTION'
export const MARK_SYNC_AS_RUN = 'MARK_SYNC_AS_RUN'
export const GET_SERVICES_IMAGE_URL = 'GET_SERVICES_IMAGE_URL'
export const GET_SERVICES_CATEGORY = 'GET_SERVICES_CATEGORY'
export const CREATE_SOURCE_TOKEN = 'CREATE_SOURCE_TOKEN'
export const CREATE_CUSTOM_INTEGRATION = 'CREATE_CUSTOM_INTEGRATION'
export const GET_INTEGRATIONS = 'GET_INTEGRATIONS'
export const DOWNLOAD_EXTENSION = 'DOWNLOAD_EXTENSION'
export const EXTENSION_INSTALLED = 'EXTENSION_INSTALLED'
export const PARSE_EXPENSE_FILE = 'PARSE_EXPENSE_FILE'
export const GET_SCORE_METRICS = 'GET_SCORE_METRICS'
export const SET_HEADER_HEIGHT = 'SET_HEADER_HEIGHT'
export const GET_AUTOMATION_RULES = 'GET_AUTOMATION_RULES'
export const UPDATE_AUTOMATION_RULE = 'UPDATE_AUTOMATION_RULE'
export const GET_USER_SETTINGS = 'GET_USER_SETTINGS'
export const UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS'
export const LOAD_USER_PREFERENCES = 'LOAD_USER_PREFERENCES'
export const UPDATE_USER_PREFERENCES = 'UPDATE_USER_PREFERENCES'
export const EXPORT_MONTHLY_EXPENSES_CSV = 'EXPORT_MONTHLY_EXPENSES_CSV'
export const EXPORT_THIRD_PARTY_CSV = 'EXPORT_THIRD_PARTY_CSV'
export const EXPORT_SSO_AUDIT_CSV = 'EXPORT_SSO_AUDIT_CSV'
export const GET_APP_DETAILS_VALUES = 'GET_APP_DETAILS_VALUES'
export const GET_APP_DETAILS_FIELDS = 'GET_APP_DETAILS_FIELDS'
export const GET_USER_FIELDS = 'GET_USER_FIELDS'
export const GET_ALL_USER_FIELDS = 'GET_USER_FIELDS'
export const UPDATE_APP_DETAILS_FIELD = 'UPDATE_APP_DETAILS_FIELD'
export const GET_APP_DETAIL_FIELD_HISTORY = 'GET_APP_DETAIL_FIELD_HISTORY'
export const GET_USER_DETAIL_FIELD_HISTORY = 'GET_USER_DETAIL_FIELD_HISTORY'
export const GET_CONTRACT_DETAIL_FIELD_HISTORY = 'GET_CONTRACT_DETAIL_FIELD_HISTORY'
export const ADD_APP_DETAILS_FIELD = 'ADD_APP_DETAILS_FIELD'
export const GET_APP_DETAILS_GROUPS = 'GET_APP_DETAILS_GROUPS'
export const ADD_APP_DETAILS_GROUPS = 'ADD_APP_DETAILS_GROUPS'
export const REORDER_APP_DETAILS_GROUPS = 'REORDER_APP_DETAILS_GROUPS'
export const DELETE_APP_DETAILS_GROUPS = 'DELETE_APP_DETAILS_GROUPS'
export const DELETE_APP_DETAILS_FIELD = 'DELETE_APP_DETAILS_FIELD'
export const REORDER_APP_DETAILS_FIELDS = 'REORDER_APP_DETAILS_FIELDS'
export const REORDER_CONTRACT_DETAILS_FIELDS = 'REORDER_CONTRACT_DETAILS_FIELDS'
export const EDIT_APP_DETAILS_GROUPS = 'EDIT_APP_DETAILS_GROUPS'
export const GET_STATE_DETAILS = 'GET_STATE_DETAILS'
export const UPDATE_APPS_DETAILS = 'UPDATE_APPS_DETAILS'
export const UPLOAD_APP_ATTACHMENT = 'UPLOAD_APP_ATTACHMENT'
export const DELETE_APP_DETAILS_FIELD_VALUE = 'DELETE_APP_DETAILS_FIELD_VALUE'
export const TOGGLE_UPLOAD_APP_DETAILS_ATTACHMENTS = 'TOGGLE_UPLOAD_APP_DETAILS_ATTACHMENTS'
export const TOGGLE_TRIGGER_PREVIEW_POPUP = 'TOGGLE_TRIGGER_PREVIEW_POPUP'
export const TOGGLE_DISCOVERED_APPS_PREVIEW_POPUP = 'TOGGLE_DISCOVERED_APPS_PREVIEW_POPUP'
export const UPDATE_DISCOVERED_APPS_PREVIEW = 'UPDATE_DISCOVERED_APPS_PREVIEW'
export const DISCONNECT_SERVICE_CONFIRMATION = 'DISCONNECT_SERVICE_CONFIRMATION'
export const DISCONNECT_SERVICE = 'DISCONNECT_SERVICE'
export const RUN_SERVICE_SYNC = 'RUN_SERVICE_SYNC'
export const EXPORT_USERS_EXTENSION_CSV = 'EXPORT_USERS_EXTENSION_CSV'
export const SET_LICENSE_TREND_FILTER = 'SET_LICENSE_TREND_FILTER'
export const GET_USAGE_TRENDS = 'GET_USAGE_TRENDS'
export const GET_ACTIVE_USERS_TRENDS = 'GET_ACTIVE_USERS_TRENDS'
export const CONFIGURE_TABLE_COLUMNS = 'CONFIGURE_TABLE_COLUMNS'
export const REPORT_TORII_USAGE = 'REPORT_TORII_USAGE'
export const UPDATE_USER_LIFECYCLE = 'UPDATE_USER_LIFECYCLE'
export const UPDATE_USERS_LIFECYCLE = 'UPDATE_USERS_LIFECYCLE'
export const GET_USER_AUDIT_LOGS = 'GET_USER_AUDIT_LOGS'
export const UPDATE_APP_USER_INFO = 'UPDATE_APP_USER_INFO'
export const GET_API_KEYS = 'GET_API_KEYS'
export const CREATE_API_KEY = 'CREATE_API_KEY'
export const DELETE_API_KEY = 'DELETE_API_KEY'
export const GET_IS_SCIM_ENABLED = 'GET_IS_SCIM_ENABLED'
export const UPDATE_IS_SCIM_ENABLED = 'UPDATE_IS_SCIM_ENABLED'
export const SIGNUP = 'SIGNUP'
export const TRIAL_SIGNUP = 'TRIAL_SIGNUP'
export const UPDATE_APP_CATALOG_SETTINGS = 'UPDATE_APP_CATALOG_SETTINGS'
export const UPDATE_APP_OFFBOARDING_SETTINGS = 'UPDATE_APP_OFFBOARDING_SETTINGS'
export const GET_APP_USERS = 'GET_APP_USERS'
export const GET_APP_ACCOUNT_LICENSES_USERS = 'GET_APP_ACCOUNT_LICENSES_USERS'
export const GET_APP_USERS_COUNT = 'GET_APP_USERS_COUNT'
export const GET_APP_USERS_FIELD_VALUES = 'GET_APP_USERS_FIELD_VALUES'
export const GET_APP_USER_LICENSES_FIELD_VALUES = 'GET_APP_USER_LICENSE_FIELD_VALUES'
export const GET_APPS_FIELD_VALUES = 'GET_APPS_FIELD_VALUES'
export const GET_CURRENT_USERS_FIELD_VALUES = 'GET_CURRENT_USERS_FIELD_VALUES'
export const GET_PAST_USERS_FIELD_VALUES = 'GET_PAST_USERS_FIELD_VALUES'
export const GET_EXTERNAL_USERS_FIELD_VALUES = 'GET_EXTERNAL_USERS_FIELD_VALUES'
export const GET_APP_INACTIVE_LICENSES = 'GET_APP_INACTIVE_LICENSES'
export const GET_APPS_OF_USER = 'GET_APPS_OF_USER'
export const TOGGLE_CONNECT_SERVICE = 'TOGGLE_CONNECT_SERVICE'
export const TOGGLE_CONNECT_CUSTOM_SERVICE = 'TOGGLE_CONNECT_CUSTOM_SERVICE'
export const TOGGLE_NAVIGATION_COLLAPSE = 'TOGGLE_NAVIGATION_COLLAPSE'
export const TOGGLE_CONNECT_SCIM_SERVICE = 'TOGGLE_CONNECT_SCIM_SERVICE'
export const GET_APPS_PERMISSIONS = 'GET_APPS_PERMISSIONS'
export const GET_WORKFLOWS = 'GET_WORKFLOWS'
export const GET_APP_CATALOG_POLICIES = 'GET_APP_CATALOG_POLICIES'
export const GET_APP_CATALOG_REQUEST_NEW_APP_POLICIES = 'GET_APP_CATALOG_REQUEST_NEW_APP_POLICIES'
export const GET_NOTIFY_ON_ERRORS_WORKFLOWS = 'GET_NOTIFY_ON_ERRORS_WORKFLOWS'
export const GET_WORKFLOWS_TEMPLATES = 'GET_WORKFLOWS_TEMPLATES'
export const GET_WORKFLOW = 'GET_WORKFLOW'
export const GET_WORKFLOW_TRIGGER_CONFIGURATION_FIELD = 'GET_WORKFLOW_TRIGGER_CONFIGURATION_FIELD'
export const GET_WORKFLOW_EXECUTIONS = 'GET_WORKFLOW_EXECUTIONS'
export const GET_WORKFLOW_ACTION_EXECUTIONS = 'GET_WORKFLOW_ACTION_EXECUTIONS'
export const UPDATE_WORKFLOW = 'UPDATE_WORKFLOW'
export const VALIDATE_WORKFLOW = 'VALIDATE_WORKFLOW'
export const DELETE_WORKFLOW = 'DELETE_WORKFLOW'
export const GET_WORKFLOWS_TRIGGERS_CONFIG = 'GET_WORKFLOWS_TRIGGERS_CONFIG'
export const GET_WORKFLOWS_ACTIONS_CONFIG = 'GET_WORKFLOWS_ACTIONS_CONFIG'
export const GET_WORKFLOWS_PERSONALIZATION_CONFIG = 'GET_WORKFLOWS_PERSONALIZATION_CONFIG'
export const GET_WORKFLOW_DYNAMIC_CONFIG = 'GET_WORKFLOW_DYNAMIC_CONFIG'
export const GET_WORKFLOWS_TRIGGERS_FIELDS = 'GET_WORKFLOWS_TRIGGERS_FIELDS'
export const GET_WORKFLOWS_TRIGGERS_FIELDS_VALUES = 'GET_WORKFLOWS_TRIGGERS_FIELDS_VALUES'
export const GET_WORKFLOWS_ACTIONS_FIELDS = 'GET_WORKFLOWS_ACTIONS_FIELDS'
export const CLEAR_WORKFLOW_ACTION_FIELDS = 'CLEAR_WORKFLOW_ACTION_FIELDS'
export const CLEAR_WORKFLOW_TRIGGER_FIELD_OPTIONS = 'CLEAR_WORKFLOW_TRIGGER_FIELD_OPTIONS'
export const GET_WORKFLOW_TRIGGER_PREVIEW = 'GET_WORKFLOW_TRIGGER_PREVIEW'
export const CREATE_WORKFLOW = 'CREATE_WORKFLOW'
export const SUBMIT_WORKFLOW_FORM_INFO = 'SUBMIT_WORKFLOW_FORM_INFO'
export const GET_WORKFLOW_FORM_INFO = 'GET_WORKFLOW_FORM_INFO'
export const SUBMIT_WORKFLOW_REQUEST_APPROVAL = 'SUBMIT_WORKFLOW_REQUEST_APPROVAL'
export const IS_LOGGED_IN = 'IS_LOGGED_IN'
export const GET_SUPPORTED_FEATURES = 'GET_SUPPORTED_FEATURES'
export const UPDATE_FEATURE_USABILITY_STATUS = 'UPDATE_FEATURE_USABILITY_STATUS'
export const GET_UPLOAD_URL = 'GET_UPLOAD_URL'
export const CREATE_UPLOAD = 'CREATE_UPLOAD'
export const GET_SSO_AUDIT_REPORT = 'GET_SSO_AUDIT_REPORT'
export const GET_SSO_AUDIT_UNMANAGED_USERS = 'GET_SSO_AUDIT_UNMANAGED_USERS'
export const CHANGE_APP_HIDDEN_STATE = 'CHANGE_APP_HIDDEN_STATE'
export const GET_HIDDEN_APPS = 'GET_HIDDEN_APPS'
export const TOGGLE_UPDATE_APP_DETAIL_FIELD_POPUP = 'TOGGLE_UPDATE_APP_DETAIL_FIELD_POPUP'
export const TOGGLE_UPDATE_CONTRACT_DETAIL_FIELD_POPUP = 'TOGGLE_UPDATE_CONTRACT_DETAIL_FIELD_POPUP'
export const GET_PARSING_STATUS = 'GET_PARSING_STATUS'
export const GET_OFFBOARDING_APPS = 'GET_OFFBOARDING_APPS'
export const GET_OFFBOARDING_APPS_OF_USERS = 'GET_OFFBOARDING_APPS_OF_USERS'
export const GET_OFFBOARDING_STATUS_OF_USER = 'GET_OFFBOARDING_STATUS_OF_USER'
export const GET_OFFBOARDING_APP = 'GET_OFFBOARDING_APP'
export const GET_OFFBOARDING_DEFAULT = 'GET_OFFBOARDING_DEFAULT'
export const TOGGLE_CONFIGURE_APP_FOR_OFFBOARDING = 'TOGGLE_CONFIGURE_APP_FOR_OFFBOARDING'
export const TOGGLE_CUSTOM_ACTION_RESPONSE = 'TOGGLE_CUSTOM_ACTION_RESPONSE'
export const TOGGLE_HIDE_APP_POPUP = 'TOGGLE_HIDE_APP_POPUP'
export const TOGGLE_OFFBOARDING_PREVIEW_POPUP = 'TOGGLE_OFFBOARDING_PREVIEW_POPUP'
export const TOGGLE_OFFBOARDING_STARTED_POPUP = 'TOGGLE_OFFBOARDING_STARTED_POPUP'
export const TOGGLE_APP_DETAIL_HISTORY_POPUP = 'TOGGLE_APP_DETAIL_HISTORY_POPUP'
export const TOGGLE_USER_DETAIL_HISTORY_POPUP = 'TOGGLE_USER_DETAIL_HISTORY_POPUP'
export const TOGGLE_CONTRACT_DETAIL_HISTORY_POPUP = 'TOGGLE_CONTRACT_DETAIL_HISTORY_POPUP'
export const GET_INACTIVE_USERS = 'GET_INACTIVE_USERS'
export const EXPORT_INACTIVE_USERS_CSV = 'EXPORT_INACTIVE_USERS_CSV'
export const UPDATE_LICENSE = 'UPDATE_LICENSE'
export const GET_LICENSES_TYPES = 'GET_LICENSES_TYPES'
export const GET_CHARGE_BACK_REPORT = 'GET_CHARGE_BACK_REPORT'
export const GET_LICENSE_TRENDS = 'GET_LICENSE_TRENDS'
export const GET_CONNECT_INTEGRATION_LINK = 'GET_CONNECT_INTEGRATION_LINK'
export const GET_CONNECT_INTEGRATION_LINK_INFO = 'GET_CONNECT_INTEGRATION_LINK_INFO'
export const GET_ALL_ORGS = 'GET_ALL_ORGS'
export const GET_USER = 'GET_USER'
export const GET_USER_DETAILS = 'GET_USER_DETAILS'
export const GET_CURRENT_USERS = 'GET_CURRENT_USERS'
export const GET_DATA_FOR_WIDGET = 'GET_DATA_FOR_WIDGET'
export const GET_DRILL_DOWN_DATA_FOR_WIDGET = 'GET_DRILL_DOWN_DATA_FOR_WIDGET'
export const GET_CURRENT_USERS_AMOUNT = 'GET_CURRENT_USERS_AMOUNT'
export const GET_CURRENT_USERS_WITH_CHILDREN_AMOUNT = 'GET_CURRENT_USERS_WITH_CHILDREN_AMOUNT'
export const GET_USER_TYPES_AMOUNT = 'GET_USER_TYPES_AMOUNT'
export const GET_PAST_USERS = 'GET_PAST_USERS'
export const GET_PAST_USERS_AMOUNT = 'GET_PAST_USERS_AMOUNT'
export const GET_EXTERNAL_USERS = 'GET_EXTERNAL_USERS'
export const GET_EXTERNAL_USERS_AMOUNT = 'GET_EXTERNAL_USERS_AMOUNT'
export const GET_APPS_V2_AMOUNT = 'GET_APPS_V2_AMOUNT'
export const GET_TEAM_MEMBERS = 'GET_TEAM_MEMBERS'
export const GET_TABLE_VIEWS = 'GET_TABLE_VIEWS'
export const SEARCH_USERS_AND_APPS = 'SEARCH_USERS_AND_APPS'
export const SEARCH_APPS = 'SEARCH_APPS'
export const SEARCH_USERS_EMAIL = 'SEARCH_USERS_EMAIL'

export const SEARCH_USERS = 'SEARCH_USERS'
export const SEARCH_USERS_RESTRICTED = 'SEARCH_USERS_RESTRICTED'
export const SEARCH_APPS_TAGS = 'SEARCH_APPS_TAGS'
export const UPDATE_APP_ACCOUNT_NAME = 'UPDATE_APP_ACCOUNT_NAME'
export const GET_CATALOG_SETTINGS = 'GET_CATALOG_SETTINGS'
export const GET_OFFBOARDING_SETTINGS = 'GET_OFFBOARDING_SETTINGS'
export const GET_UPLOAD = 'GET_UPLOAD'
export const GET_UPLOADS = 'GET_UPLOADS'
export const DELETE_UPLOAD = 'DELETE_UPLOAD'
export const GET_MOST_USED_APPS = 'GET_MOST_USED_APPS'
export const GET_INSIGHTS_STATS = 'GET_INSIGHTS_STATS'
export const GET_USER_DATA_ACCESS_BY_CATEGORY = 'GET_USER_DATA_ACCESS_BY_CATEGORY'
export const GET_USER_DATA_ACCESS_OF_CATEGORY = 'GET_USER_DATA_ACCESS_OF_CATEGORY'
export const GET_UNDERUTILIZED_LICENSES_TRENDS = 'GET_UNDERUTILIZED_LICENSES_TRENDS'
export const GET_USERS_DISTRIBUTION_TRENDS = 'GET_USERS_DISTRIBUTION_TRENDS'
export const GET_PRODUCT_UPDATES = 'GET_PRODUCT_UPDATES'
export const UPDATE_USER_LAST_SEEN_UPDATES_TIME = 'UPDATE_USER_LAST_SEEN_UPDATES_TIME'
export const GET_USERS_BY_COUNTRY = 'GET_USERS_BY_COUNTRY'
export const TOGGLE_CONFIGURE_EXECUTE_ACTION_ON_USERS = 'TOGGLE_CONFIGURE_EXECUTE_ACTION_ON_USERS'
export const TOGGLE_MANUAL_ACTION_STARTED_POPUP = 'TOGGLE_MANUAL_ACTION_STARTED_POPUP'
export const GET_USER_TASKS = 'GET_USER_TASKS'
export const UPDATE_USER_TASK_COMPLETION_STATUS_INSTANT_ACCESS = 'UPDATE_USER_TASK_COMPLETION_STATUS_INSTANT_ACCESS'
export const GET_CUSTOM_APPS = 'GET_CUSTOM_APPS'
export const EDIT_CUSTOM_APP = 'EDIT_CUSTOM_APP'
export const TOGGLE_SHOW_OFFBOARDING_MESSAGE = 'TOGGLE_SHOW_OFFBOARDING_MESSAGE'
export const DUPLICATE_WORKFLOW = 'DUPLICATE_WORKFLOW'
export const GET_WORKFLOWS_BRANCH_ACTION_FIELDS = 'GET_WORKFLOWS_BRANCH_ACTION_FIELDS'
export const GET_WORKFLOWS_BRANCHING_FILTERS_OPTION_VALUES = 'GET_WORKFLOWS_BRANCHING_FILTERS_OPTION_VALUES'
export const TRIGGER_WORKFLOW = 'TRIGGER_WORKFLOW'
export const GET_ORG_MATCHING_RULES = 'GET_ORG_MATCHING_RULES'
export const UPDATE_ORG_MATCHING_RULES = 'UPDATE_ORG_MATCHING_RULES'
export const UPDATE_ORG_MATCHING_RULES_LOCALLY = 'UPDATE_ORG_MATCHING_RULES_LOCALLY'
export const TOGGLE_MATCHING_RULES_POPUP = 'TOGGLE_MATCHING_RULES_POPUP'
export const GET_CONTRACTS = 'GET_CONTRACTS'
export const GET_UNMATCHED_CONTRACTS = 'GET_UNMATCHED_CONTRACTS'
export const GET_CONTRACTS_FIELDS = 'GET_CONTRACTS_FIELDS'
export const UPDATE_CONTRACT_DETAILS = 'UPDATE_CONTRACT_DETAILS'
export const GET_CONTRACTS_GROUPS = 'GET_CONTRACTS_GROUPS'
export const REORDER_CONTRACT_DETAILS_GROUPS = 'REORDER_CONTRACT_DETAILS_GROUPS'
export const GET_CONTRACT = 'GET_CONTRACT'
export const CREATE_CONTRACT = 'CREATE_CONTRACT'
export const TOGGLE_CONTRACT_POPUP = 'TOGGLE_CONTRACT_POPUP'
export const TOGGLE_LICENSE_EDIT_POPUP = 'TOGGLE_LICENSE_EDIT_POPUP'
export const TOGGLE_ASK_TO_UPDATE_LICENSE_COST_POPUP = 'TOGGLE_ASK_TO_UPDATE_LICENSE_COST_POPUP'
export const TOGGLE_APP_ACCOUNT_SELECT_POPUP = 'TOGGLE_APP_ACCOUNT_SELECT_POPUP'
export const TOGGLE_RENEWAL_FORECAST_NOT_SUPPORTED_POPUP = 'TOGGLE_RENEWAL_FORECAST_NOT_SUPPORTED_POPUP'
export const UPDATE_CONTRACTS_DETAILS = 'UPDATE_CONTRACTS_DETAILS'
export const DELETE_CONTRACTS = 'DELETE_CONTRACTS'
export const GET_APP_CONTRACTS = 'GET_APP_CONTRACTS'
export const EDIT_CONTRACT_DETAILS_GROUPS = 'EDIT_CONTRACT_DETAILS_GROUPS'
export const ADD_CONTRACT_DETAILS_GROUPS = 'ADD_CONTRACT_DETAILS_GROUPS'
export const DELETE_CONTRACT_DETAILS_GROUPS = 'DELETE_CONTRACT_DETAILS_GROUPS'
export const UPDATE_CONTRACT_DETAILS_FIELD = 'UPDATE_CONTRACT_DETAILS_FIELD'
export const DELETE_CONTRACT_DETAILS_FIELD = 'DELETE_CONTRACT_DETAILS_FIELD'
export const ADD_CONTRACT_DETAILS_FIELD = 'ADD_CONTRACT_DETAILS_FIELD'
export const GET_APP_CHARGEBACK = 'GET_APP_CHARGEBACK'
export const GET_USER_FIELD_DISPLAY_NAMES = 'GET_USER_FIELD_DISPLAY_NAMES'
export const UPDATE_APP_CHARGEBACK_CONFIG = 'UPDATE_APP_CHARGEBACK_CONFIG'
export const DELETE_APP_CHARGEBACK_CONFIG = 'DELETE_APP_CHARGEBACK_CONFIG'
export const GET_APP_CHARGEBACK_CONFIG = 'GET_APP_CHARGEBACK_CONFIG'
export const TOGGLE_CONFIGURE_APP_CHARGEBACK = 'TOGGLE_CONFIGURE_APP_CHARGEBACK'
export const BULK_UPDATE_LICENSES_TYPES = 'BULK_UPDATE_LICENSES_TYPES'
export const UPDATE_LICENSE_CURRENCY_AND_DATE = 'UPDATE_LICENSE_CURRENCY_AND_DATE'
export const TOGGLE_SHOW_HIDDEN_WORKFLOWS = 'TOGGLE_SHOW_HIDDEN_WORKFLOWS'
export const TOGGLE_UNSAFE_MODE = 'TOGGLE_UNSAFE_MODE'
export const TOGGLE_VIEW_ONLY_MODE = 'TOGGLE_VIEW_ONLY_MODE'
export const TOGGLE_WORKFLOW_PREVIEW_POPUP = 'TOGGLE_WORKFLOW_PREVIEW_POPUP'
export const GET_ORG_DOMAINS = 'GET_ORG_DOMAINS'
export const UPDATE_ORG_DOMAINS = 'UPDATE_ORG_DOMAINS'
export const TOGGLE_CSV_EXPORT_POPUP = 'TOGGLE_CSV_EXPORT_POPUP'
export const EXPORT_CSV_REPORT = 'EXPORT_CSV_REPORT'
export const GET_BROWSER_EXTENSION_ACTIVATION_EMAIL_USERS = 'GET_BROWSER_EXTENSION_ACTIVATION_EMAIL_USERS'
export const TOGGLE_CONFIGURE_BROWSER_ACTIVATION_EMAIL_POPUP = 'TOGGLE_CONFIGURE_BROWSER_ACTIVATION_EMAIL_POPUP'
export const TOGGLE_SHARE_REPORT_POPUP = 'TOGGLE_SHARE_REPORT_POPUP'
export const TOGGLE_SENT_EMAILS_POPUP = 'TOGGLE_SENT_EMAILS_POPUP'
export const TOGGLE_MANUALLY_RUN_WORKFLOW_POPUP = 'TOGGLE_MANUALLY_RUN_WORKFLOW_POPUP'
export const GET_ROLES = 'GET_ROLES'
export const DELETE_ROLE = 'DELETE_ROLE'
export const CREATE_ROLE = 'CREATE_ROLE'
export const UPDATE_ROLE = 'UPDATE_ROLE'
export const TOGGLE_ROLE_POPUP = 'TOGGLE_ROLE_POPUP'
export const GET_ROLE = 'GET_ROLE'
export const GET_SCOPES = 'GET_SCOPES'
export const GET_CATALOG_APPS = 'GET_CATALOG_APPS'
export const CONTRACT_PARSE = 'CONTRACT_PARSE'
export const GET_CONTRACT_PARSE = 'GET_CONTRACT_PARSE'
export const GET_LIFECYCLE_APPS = 'GET_LIFECYCLE_APPS'
export const GET_LIFECYCLE_PREVIEW = 'GET_LIFECYCLE_PREVIEW'
export const UPDATE_LIFECYCLE_SETTINGS = 'UPDATE_LIFECYCLE_SETTINGS'
export const UPDATE_ORG_IS_DISABLED = 'UPDATE_ORG_IS_DISABLED'
export const UPDATE_ORG_PLAN = 'UPDATE_ORG_PLAN'
export const UPDATE_ORG_ALLOW_ONLY_YEARLY_SUBSCRIPTION = 'UPDATE_ORG_ALLOW_ONLY_YEARLY_SUBSCRIPTION'
export const GET_LIFECYCLE_SETTINGS = 'GET_LIFECYCLE_SETTINGS'
export const GET_BROWSER_COUNT = 'GET_BROWSER_COUNT'
export const GET_EXTENSION_REPORT_USERS = 'GET_EXTENSION_REPORT_USERS'
export const GET_CATALOG_USERS = 'GET_CATALOG_USERS'
export const ADD_USER_REPORTING = 'ADD_USER_REPORTING'
export const APP_COMPARISON_GET_ACTIVE_USERS = 'APP_COMPARISON_GET_ACTIVE_USERS'
export const APP_COMPARISON_GET_USAGE = 'APP_COMPARISON_GET_USAGE'
export const APP_COMPARISON_GET_APP_ACTIVE_USERS = 'APP_COMPARISON_GET_APP_ACTIVE_USERS'
export const GET_COMPARISON_APPS = 'GET_COMPARISON_APPS'
export const APP_COMPARISON_UPDATE_ID_APPS = 'APP_COMPARISON_UPDATE_ID_APPS'
export const LICENSE_AUDIT_DATA = 'LICENSE_AUDIT_DATA'
export const APP_COMPARISON_GET_OVERLAPS = 'APP_COMPARISON_GET_OVERLAPS'
export const APP_COMPARISON_GET_OVERLAPS_USERS = 'APP_COMPARISON_GET_OVERLAPS_USERS'
export const TOGGLE_USERS_OVERLAP_POPUP = 'TOGGLE_USERS_OVERLAP_POPUP'
export const APP_COMPARISON_GET_RECOMMENDATIONS = 'APP_COMPARISON_GET_RECOMMENDATIONS'
export const APP_COMPARISON_GET_SSO_SOURCES_ACCOUNTS = 'APP_COMPARISON_GET_SSO_SOURCES_ACCOUNTS'
export const GET_SAML_CONFIGURATION = 'GET_SAML_CONFIGURATION'
export const CREATE_SAML_CONFIGURATION = 'CREATE_SAML_CONFIGURATION'
export const UPDATE_SAML_CONFIGURATION = 'UPDATE_SAML_CONFIGURATION'
export const DELETE_SAML_CONFIGURATION = 'DELETE_SAML_CONFIGURATION'
export const CREATE_SAML_CONFIGURATION_BY_SOURCE = 'CREATE_SAML_CONFIGURATION_BY_SOURCE'
export const TOGGLE_POPUP = 'TOGGLE_POPUP'
export const REMOVE_ORG_LOGO = 'REMOVE_ORG_LOGO'
export const UPDATE_ORG_LOGO = 'UPDATE_ORG_LOGO'
export const GET_SPECIFIC_APP_V2 = 'GET_SPECIFIC_APP_V2'
export const TOGGLE_ACTIVATE_WORKFLOW_POPUP = 'TOGGLE_ACTIVATE_WORKFLOW_POPUP'
export const TOGGLE_WORKFLOW_STARTED_POPUP = 'TOGGLE_WORKFLOW_STARTED_POPUP'
export const GET_APP_LICENSE_RECOMMENDATION_USERS = 'GET_APP_LICENSE_RECOMMENDATION_USERS'
export const GET_RECOMMENDATION = 'GET_RECOMMENDATION'
export const GET_RECOMMENDATIONS = 'GET_RECOMMENDATIONS'
export const GET_RECOMMENDATION_APPS_ACCOUNTS = 'GET_RECOMMENDATION_APPS_ACCOUNTS'
export const TOGGLE_ADD_APP_CATALOG_POLICY_POPUP = 'TOGGLE_ADD_APP_CATALOG_POLICY_POPUP'
export const TOGGLE_WORKFLOW_AUDIT_LOGS = 'TOGGLE_WORKFLOW_AUDIT_LOGS'
export const GET_WORKFLOW_EDIT_HISTORY_INFO = 'GET_WORKFLOW_EDIT_HISTORY_INFO'
export const GET_WORKFLOW_EDIT_HISTORY_LOGS = 'GET_WORKFLOW_EDIT_HISTORY_LOGS'
export const RESTORE_WORKFLOW_VERSION = 'RESTORE_WORKFLOW_VERSION'
export const GET_APP_OWNERS = 'GET_APP_OWNERS'
export const TEST_CONNECTION = 'TEST_CONNECTION'
export const TORII_BOT_ID_USER = -1
export const ITEMS_PER_PAGE = 50
export const DEFAULT_API_PAGE_LIMIT = 1000
export const WHITE_SPACE = '\u00A0'
export const MAX_ITEMS_TO_SHOW = 500
export const TORII_APP_ID = 1
export const DEFAULT_SEARCH_APPS_LIMIT = 20
export const TOGGLE_IMPORT_USERS_POPUP = 'TOGGLE_IMPORT_USERS_POPUP'
export const TOGGLE_SYNC_STATUS_POPUP = 'TOGGLE_SYNC_STATUS_POPUP'
export const TOGGLE_DELETE_IMPORTED_USERS_POPUP = 'TOGGLE_DELETE_IMPORTED_USERS_POPUP'
export const PARSE_USERS_IMPORT_FILE = 'PARSE_USERS_IMPORT_FILE'
export const GET_USERS_IMPORT_PARSING = 'GET_USERS_IMPORT_PARSING'
export const SAVE_MAPPING_AND_PARSE = 'SAVE_MAPPING_AND_PARSE'
export const SYNC_IMPORT_USERS = 'SYNC_IMPORT_USERS'
export const GET_CURRENCIES = 'GET_CURRENCIES'
export const TOGGLE_SUBSCRIBE_POPUP = 'TOGGLE_SUBSCRIBE_POPUP'
export const UPDATE_IMPORT_APP_USERS_STATUS = 'UPDATE_IMPORT_APP_USERS_STATUS'
export const DELETE_MANUALLY_IMPORTED_USERS = 'DELETE_MANUALLY_IMPORTED_USERS'
export const DELETE_MANUALLY_IMPORTED_LICENSES = 'DELETE_MANUALLY_IMPORTED_LICENSES'
export const GET_SECRETS = 'GET_SECRETS'
export const CREATE_SECRET = 'CREATE_SECRET'
export const DELETE_SECRET = 'DELETE_SECRET'
export const UPDATE_SECRET = 'UPDATE_SECRET'

export const GET_SUBSCRIPTION_CHECKOUT_SESSION_URL = 'GET_SUBSCRIPTION_CHECKOUT_SESSION_URL'
export const GET_SUBSCRIPTION_CUSTOMER_PORTAL_URL = 'GET_SUBSCRIPTION_CUSTOMER_PORTAL_URL'
export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS'
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION'
export const GET_CONNECTED_APP_USERS_STATUSES_BREAKDOWN = 'GET_CONNECTED_APP_USERS_STATUSES_BREAKDOWN'
export const GET_APP_USERS_STATS = 'GET_APP_USERS_STATS'
export const GET_USER_TYPE_SOURCE_PREVIEW = 'GET_USER_TYPE_SOURCE_PREVIEW'
export const CREATE_USER_TYPE_SOURCE = 'CREATE_USER_TYPE_SOURCE'
export const UPDATE_USER_TYPE_SOURCE = 'UPDATE_USER_TYPE_SOURCE'
export const GET_USER_TYPE_SOURCES = 'GET_USER_TYPE_SOURCES'
export const DELETE_USER_TYPE_SOURCE = 'DELETE_USER_TYPE_SOURCE'
export const ASK_TO_UPGRADE_PLAN = 'ASK_TO_UPGRADE_PLAN'

export const GET_MERGE_USERS_RULES = 'GET_MERGE_USERS_RULES'
export const CREATE_MERGE_USERS_RULES = 'CREATE_MERGE_USERS_RULES'
export const UPDATE_MERGE_USERS_RULE = 'UPDATE_MERGE_USERS_RULE'
export const DELETE_MERGE_USERS_RULE = 'DELETE_MERGE_USERS_RULE'
export const SEND_MERGE_USERS_PREVIEW_REPORT = 'SEND_MERGE_USERS_PREVIEW_REPORT'

export const GET_APP_DATA_FROM_AI = 'GET_APP_DATA_FROM_AI'
export const GET_CONTRACT_DETAILS_FROM_AI = 'GET_CONTRACT_DETAILS_FROM_AI'
export const SET_DONE_CONTRACTS_APP_MATCHING = 'SET_DONE_CONTRACTS_APP_MATCHING'
export const GET_CONTRACT_ATTACHMENTS = 'GET_CONTRACT_ATTACHMENTS'

export const GET_PLUGINS = 'GET_PLUGINS'
export const GET_PLUGIN = 'GET_PLUGIN'
export const INSTALL_PLUGIN = 'INSTALL_PLUGIN'
export const UNINSTALL_PLUGIN = 'UNINSTALL_PLUGIN'
export const GET_WIDGETS = 'GET_WIDGETS'
export const GET_DISCOVERED_APPS = 'GET_DISCOVERED_APPS'
export const RESET_DISCOVERED_APPS = 'RESET_DISCOVERED_APPS'
export const MODIFY_DISCOVERED_APP = 'MODIFY_DISCOVERED_APP'
export const RESET_MODIFY_DISCOVERED_APP = 'RESET_MODIFY_DISCOVERED_APP'
export const UPDATE_DISCOVERED_APPS = 'UPDATE_DISCOVERED_APPS'
export const MODIFY_DISCOVERED_APP_RESOURCES = 'MODIFY_DISCOVERED_APP_RESOURCES'

export const TOGGLE_EDIT_WORKFLOW_TAGS_POPUP = 'TOGGLE_EDIT_WORKFLOW_TAGS_POPUP'
export const TOGGLE_EDIT_WORKFLOW_TAG_POPUP = 'TOGGLE_EDIT_WORKFLOW_TAG_POPUP'
export const GET_WORKFLOW_TAGS = 'GET_WORKFLOW_TAGS'
export const UPDATE_WORKFLOW_TAGS = 'UPDATE_WORKFLOW_TAGS'
export const DELETE_WORKFLOW_TAG = 'DELETE_WORKFLOW_TAG'
export const UPDATE_WORKFLOW_TAG = 'UPDATE_WORKFLOW_TAG'

export const GET_WORKFLOWS_EXECUTION_AUDIT = 'GET_WORKFLOWS_EXECUTION_AUDIT'
export const GET_WORKFLOWS_EXECUTION_AUDIT_FIELD_VALUES = 'GET_WORKFLOWS_EXECUTION_AUDIT_FIELD_VALUES'

export const TOGGLE_CREATE_WORKFLOW_TAGS_POPUP = 'TOGGLE_CREATE_WORKFLOW_TAGS_POPUP'
export const CREATE_WORKFLOW_TAGS = 'CREATE_WORKFLOW_TAGS'

export const CREATE_REPORT = 'CREATE_REPORT'
export const GET_SCHEDULED_REPORTS = 'GET_SCHEDULED_REPORTS'
export const DELETE_SCHEDULED_REPORT = 'DELETE_SCHEDULED_REPORT'
export const UPDATE_SCHEDULED_REPORT = 'UPDATE_SCHEDULED_REPORT'

export const UPDATE_USERS_BY_ID = 'UPDATE_USERS_BY_ID'

export const GET_DASHBOARDS = 'GET_DASHBOARDS'
export const UPDATE_DASHBOARD = 'UPDATE_DASHBOARD'

export const GET_WORKFLOW_EXECUTION = 'GET_WORKFLOW_EXECUTION'
export const STOP_WORKFLOW_EXECUTION = 'STOP_WORKFLOW_EXECUTION'

export const GET_APP_USER_LAST_VISIT_TIMES = 'GET_APP_USER_LAST_VISIT_TIMES'
export const AUTH_STATE = {
  BEFORE_AUTH: 'BEFORE_AUTH',
  PENDING: 'PENDING',
  AUTHENTICATED: 'AUTHENTICATED',
  NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
  NO_LONGER_AUTHENTICATED: 'NO_LONGER_AUTHENTICATED'
}

export const DISCOVERED_APP_MAPPING_LOGIC = {
  TORII_AI: 'torii_ai',
  MANUALLY: 'manually',
  IGNORED: 'ignored'
}

export const USER_STATUS = {
  DELETED: 'deleted',
  ACTIVE: 'active',
  INVITED: 'invited',
  REPORTING: 'reporting'
}

export const SCOPES = {
  MEMBERS_AND_ROLES_READ: 'members-and-roles:read',
  MEMBERS_AND_ROLES_WRITE: 'members-and-roles:write',
  API_MANAGEMENT_READ: 'api-management:read',
  API_MANAGEMENT_WRITE: 'api-management:write',
  SETTINGS_READ: 'settings:read',
  SETTINGS_WRITE: 'settings:write',
  PUBLIC_VIEWS_READ: 'public-views:read',
  PUBLIC_VIEWS_WRITE: 'public-views:write',
  INTEGRATIONS_READ: 'integrations:read',
  INTEGRATIONS_WRITE: 'integrations:write',
  AUTOMATION_NONE: 'automation:none',
  AUTOMATION_READ: 'automation:read',
  AUTOMATION_WRITE: 'automation:write',
  APPLICATIONS_READ: 'applications:read',
  APPLICATIONS_WRITE: 'applications:write',
  CONTRACTS_NONE: 'contracts:none',
  CONTRACTS_READ: 'contracts:read',
  CONTRACTS_WRITE: 'contracts:write',
  LICENSE_AND_CHARGEBACK_NONE: 'license-and-chargeback:none',
  LICENSE_AND_CHARGEBACK_READ: 'license-and-chargeback:read',
  LICENSE_AND_CHARGEBACK_WRITE: 'license-and-chargeback:write',
  EXPENSE_NONE: 'expense:none',
  EXPENSE_READ: 'expense:read',
  EXPENSE_WRITE: 'expense:write',
  APPLICATION_CATALOG_READ: 'application-catalog:read',
  APPLICATION_CATALOG_WRITE: 'application-catalog:write',
  USERS_NONE: 'users:none',
  USERS_READ: 'users:read',
  OFFBOARDING_NONE: 'offboarding:none',
  OFFBOARDING_READ: 'offboarding:read',
  OFFBOARDING_WRITE: 'offboarding:write',
  BASIC_ADMIN: 'basic-admin',
  APP_OWNER_READ: 'app-owner:read',
  APP_OWNER_WRITE: 'app-owner:write',
  ALL_SCHEDULED_REPORTS_NONE: 'all-scheduled-reports:none',
  ALL_SCHEDULED_REPORTS_READ: 'all-scheduled-reports:read',
  ALL_SCHEDULED_REPORTS_WRITE: 'all-scheduled-reports:write',
  DASHBOARDS_READ: 'dashboards:read',
  DASHBOARDS_WRITE: 'dashboards:write'
}
export const TORII_ADMIN_SCOPES = {
  ORGS_READ: 'torii-admin:orgs:read'
}

export const SCOPES_PREFIX = {
  MEMBERS_AND_ROLES: 'members-and-roles',
  API_MANAGEMENT: 'api-management',
  SETTINGS: 'settings',
  PUBLIC_VIEWS: 'public-views',
  INTEGRATIONS: 'integrations',
  AUTOMATION: 'automation',
  APPLICATIONS: 'applications',
  CONTRACTS: 'contracts',
  LICENSE_AND_CHARGEBACK: 'license-and-chargeback',
  EXPENSE: 'expense',
  APPLICATION_CATALOG: 'application-catalog',
  USERS: 'users',
  OFFBOARDING: 'offboarding',
  BASIC_ADMIN: 'basic-admin',
  APP_OWNER: 'app-owner'
}

export const FIELDS_CONTAINER_ENTITY = {
  APPS: 'apps',
  CONTRACTS: 'contracts',
  USERS: 'users'
}

export const fieldTypes = {
  longText: 'longText',
  text: 'text',
  number: 'number',
  currency: 'currency',
  bool: 'bool',
  dropdown: 'dropdown',
  dropdownMulti: 'dropdownMulti',
  date: 'date',
  user: 'user',
  userMulti: 'userMulti',
  name: 'name',
  freeText: 'freeText',
  appUserAccount: 'appUserAccount',
  fileUpload: 'fileUpload',
  contractsDropdownMulti: 'contractsDropdownMulti'
}

export const formFieldLabelActionTypes = {
  previewSendForm: 'previewSendForm',
  licenseInfoTooltip: 'licenseInfoTooltip'
}

export const actionLabelAddonTypes = {
  offboardingRemindersConfig: 'offboardingRemindersConfig'
}

export const APP_COMPLIANCE_FIELDS_SYSTEM_KEYS = {
  SOC2_REPORT: 'soc2Report',
  SOC2_REFERENCE: 'soc2Reference',
  GDPR_COMPLIANT: 'gdprCompliant',
  GDPR_REFERENCE: 'gdprReference',
  ISO27001_CERTIFIED: 'iso27001Certified',
  ISO27001_REFERENCE: 'iso27001Reference'
}

export const formFieldTypes = {
  number: 'number',
  singleLine: 'singleLine',
  mentionsSingleLine: 'mentionsSingleLine',
  mentionsMultiLine: 'mentionsMultiLine',
  multiLine: 'multiLine',
  email: 'email',
  currency: 'currency',
  cardNumber: 'cardNumber',
  datePicker: 'datePicker',
  dropdown: 'dropdown',
  overridableDropdown: 'overridableDropdown',
  dropdownMulti: 'dropdownMulti',
  overridableDropdownMulti: 'overridableDropdownMulti',
  usersDropdown: 'usersDropdown',
  usersDropdownMulti: 'usersDropdownMulti',
  bool: 'bool',
  fileUpload: 'fileUpload',
  account: 'account',
  user: 'user',
  users: 'users',
  threshold: 'threshold',
  filters: 'filters',
  userMeetsCriteriaFilters: 'userMeetsCriteriaFilters',
  userDetailChange: 'userDetailChange',
  ifElseBranchFilters: 'ifElseBranchFilters',
  relativeDate: 'relativeDate',
  appUserAccount: 'appUserAccount',
  appAndAccount: 'appAndAccount',
  appState: 'appState',
  app: 'app',
  contract: 'contract',
  setFieldValue: 'setFieldValue',
  emailSetup: 'emailSetup',
  selectFormFields: 'selectFormFields',
  selectLicenses: 'selectLicenses',
  includeAndExcludeApps: 'includeAndExcludeApps',
  mentionsKeyValueTable: 'mentionsKeyValueTable',
  keyValueTable: 'keyValueTable',
  contractsDropdownMulti: 'contractsDropdownMulti',
  multiValueString: 'multiValueString',
  customWebhookPayloadConfig: 'customWebhookPayloadConfig',
  readonlyCopy: 'readonlyCopy'
}

export const formFieldTypesToFieldTypes = {
  [formFieldTypes.number]: fieldTypes.number,
  [formFieldTypes.singleLine]: fieldTypes.text,
  [formFieldTypes.multiLine]: fieldTypes.longText,
  [formFieldTypes.email]: fieldTypes.text,
  [formFieldTypes.currency]: fieldTypes.number,
  [formFieldTypes.cardNumber]: fieldTypes.text,
  [formFieldTypes.datePicker]: fieldTypes.date,
  [formFieldTypes.dropdown]: fieldTypes.dropdown,
  [formFieldTypes.dropdownMulti]: fieldTypes.dropdownMulti,
  [formFieldTypes.usersDropdown]: fieldTypes.user,
  [formFieldTypes.user]: fieldTypes.user,
  [formFieldTypes.users]: fieldTypes.userMulti,
  [formFieldTypes.usersDropdownMulti]: fieldTypes.userMulti,
  [formFieldTypes.fileUpload]: fieldTypes.number,
  [formFieldTypes.contractsDropdownMulti]: fieldTypes.contractsDropdownMulti,
  [formFieldTypes.multiValueString]: fieldTypes.text
}

export const formFieldTypeToName = {
  [formFieldTypes.number]: 'Number',
  [formFieldTypes.singleLine]: 'Single-line Text',
  [formFieldTypes.multiLine]: 'Multi-line Text',
  [formFieldTypes.email]: 'Email',
  [formFieldTypes.currency]: 'Currency',
  [formFieldTypes.cardNumber]: 'Card Number',
  [formFieldTypes.datePicker]: 'Date Picker',
  [formFieldTypes.dropdown]: 'Single Select',
  [formFieldTypes.dropdownMulti]: 'Multi Select',
  [formFieldTypes.usersDropdown]: 'Employee',
  [formFieldTypes.usersDropdownMulti]: 'Multiple Employees',
  [formFieldTypes.fileUpload]: 'File',
  [formFieldTypes.contractsDropdownMulti]: 'Contracts Multi Select'
}

export const scoreSpecialValues = {
  onlyExtensionLowUsage: -2,
  notCollectingUsage: -1,
  noUsageInLast30Days: 0
}

export const MONTHLY_EXPENSES_REPORT_RANGE = {
  THIS_YEAR: 'thisYear',
  LAST_12_MONTHS: 'last12Months'
}

export const TABLES = {
  appsV2Table: {
    key: 'appsV2Table',
    name: 'Applications',
    filtersOptions: [
      { label: 'App name', value: 'name', type: fieldTypes.text },
      { label: 'State', value: 'state', type: fieldTypes.text },
      { label: 'Number of users', value: 'displayActiveUsersCount', type: fieldTypes.number },
      { label: 'Category', value: 'category', type: fieldTypes.text },
      { label: 'Number of used days', value: 'score', type: fieldTypes.number },
      { label: 'Sources', value: 'sources', type: fieldTypes.dropdownMulti },
      { label: 'Active contracts', value: 'activeContractsCount', type: fieldTypes.number, scopes: [SCOPES.CONTRACTS_READ, SCOPES.APP_OWNER_READ] },
      { label: 'Active contracts value', value: 'activeContractsTotalValue', type: fieldTypes.currency, scopes: [SCOPES.CONTRACTS_READ, SCOPES.APP_OWNER_READ] },
      { label: 'Active contract start date', value: 'activeContractTerm.earliestStartDate', type: fieldTypes.date, scopes: [SCOPES.CONTRACTS_READ, SCOPES.APP_OWNER_READ] },
      { label: 'Active contract end date', value: 'activeContractTerm.latestEndDate', type: fieldTypes.date, scopes: [SCOPES.CONTRACTS_READ, SCOPES.APP_OWNER_READ] },
      { label: 'Expenses (last 12 months)', value: 'expenses', type: fieldTypes.currency, scopes: [SCOPES.EXPENSE_READ, SCOPES.APP_OWNER_READ] },
      { label: 'Expenses (active contract duration)', value: 'expensesInActiveContractTerm', type: fieldTypes.currency, scopes: [SCOPES.EXPENSE_READ, SCOPES.APP_OWNER_READ] },
      { label: 'Expenses compared to contract value (%)', value: 'expensesComparedToActiveContractValue', type: fieldTypes.number, scopes: [SCOPES.EXPENSE_READ, SCOPES.APP_OWNER_READ] },
      { label: 'New app', value: 'isNew', type: fieldTypes.bool },
      { label: 'Tags', value: 'tags', type: fieldTypes.dropdownMulti },
      { label: 'Discovery date', value: 'creationTime', type: fieldTypes.date },
      { label: 'Last usage date', value: 'lastVisitTime', type: fieldTypes.date },
      { label: 'Vendor', value: 'vendor', type: fieldTypes.text },
      { label: 'Has integration', value: 'hasIntegration', type: fieldTypes.bool },
      { label: 'Is connected', value: 'isConnected', type: fieldTypes.bool },
      { label: 'Recommended to connect', value: 'recommendedToConnect', type: fieldTypes.bool },
      { label: 'App ID', value: 'id', type: fieldTypes.number },
      { label: 'Description', value: 'description', type: fieldTypes.text },
      { label: 'Website', value: 'url', type: fieldTypes.text },
      { label: 'Google Workspace risk level', value: 'GSuiteRiskLevel', type: fieldTypes.dropdown },
      { label: 'Microsoft Entra ID risk level', value: 'azureRiskLevel', type: fieldTypes.dropdown },
      { label: 'Slack risk level', value: 'slackRiskLevel', type: fieldTypes.dropdown }
    ],
    preDefinedColumnsMapping: {
      'name': 'App name',
      'state': 'State',
      'displayActiveUsersCount': 'Users',
      'category': 'Category',
      'score': 'Usage',
      'sources': 'Sources',
      'activeContractsCount': 'Active contracts',
      'activeContractsTotalValue': 'Active contracts value',
      'activeContractTerm.earliestStartDate': 'Active contract start date',
      'activeContractTerm.latestEndDate': 'Active contract end date',
      'expenses': 'Expenses (last 12 months)',
      'expensesInActiveContractTerm': 'Expenses (active contract duration)',
      'expensesComparedToActiveContractValue': 'Expenses compared to contract value (%)',
      'tags': 'Tags',
      'creationTime': 'Discovery date',
      'lastVisitTime': 'Last usage date',
      'vendor': 'Vendor',
      'hasIntegration': 'Has integration',
      'isConnected': 'Is connected',
      'recommendedToConnect': 'Recommended to connect',
      'id': 'App ID',
      'description': 'Description',
      'url': 'Website',
      'GSuiteRiskLevel': 'Google Workspace risk level',
      'azureRiskLevel': 'Microsoft Entra ID risk level',
      'slackRiskLevel': 'Slack risk level'
    },
    disabledColumns: ['name']
  },
  usersTable: {
    key: 'usersTable',
    name: 'Users',
    filtersOptions: [
      { label: 'Name', value: 'fullName', type: fieldTypes.name },
      { label: 'Email', value: 'email', type: fieldTypes.freeText },
      { label: 'Number of apps used', value: 'activeAppsCount', type: fieldTypes.number },
      { label: 'Additional emails', value: 'additionalEmails', type: fieldTypes.dropdownMulti },
      { label: 'Number of owned apps', value: 'numberOfOwnedApps', type: fieldTypes.number },
      { label: 'Is past user', value: 'isDeletedInIdentitySources', type: fieldTypes.bool },
      { label: 'Is app owner', value: 'isAppOwner', type: fieldTypes.bool },
      { label: 'Annual Cost', value: 'annualCostConverted', type: fieldTypes.currency }
    ],
    dashboardFilterOptions: [
      { label: 'Is past user', value: 'isDeletedInIdentitySources', type: fieldTypes.bool },
      { label: 'User Type', value: 'userType', type: fieldTypes.text },
      { label: 'Is External', value: 'isExternal', type: fieldTypes.bool }
    ],
    preDefinedColumnsMapping: {
      [USERS_TABLE_COLUMNS.FULL_NAME]: USERS_TABLE_COLUMN_TITLES.FULL_NAME,
      [USERS_TABLE_COLUMNS.ACTIVE_APPS_COUNT]: USERS_TABLE_COLUMN_TITLES.ACTIVE_APPS_COUNT,
      [USERS_TABLE_COLUMNS.ANNUAL_COST_CONVERTED]: USERS_TABLE_COLUMN_TITLES.ANNUAL_COST_CONVERTED,
      [USERS_TABLE_COLUMNS.USER_TYPE]: USERS_TABLE_COLUMN_TITLES.USER_TYPE,
      [USERS_TABLE_COLUMNS.ADDITIONAL_EMAILS]: USERS_TABLE_COLUMN_TITLES.ADDITIONAL_EMAILS,
      [USERS_TABLE_COLUMNS.NUMBER_OF_OWNED_APPS]: USERS_TABLE_COLUMN_TITLES.NUMBER_OF_PRIMARY_OWNED_APPS,
      [USERS_TABLE_COLUMNS.IS_PAST_USER]: USERS_TABLE_COLUMN_TITLES.IS_PAST_USER,
      [USERS_TABLE_COLUMNS.IS_APP_OWNER]: USERS_TABLE_COLUMN_TITLES.IS_APP_OWNER
    },
    disabledColumns: ['fullName']
  },
  usersListPopup: {
    key: 'usersListPopup',
    name: 'Users'
  },
  usersOverlapsPopup: {
    key: 'usersOverlapsPopup',
    name: 'Users Overlaps',
    preDefinedColumnsMapping: {
      'firstName': 'Users',
      'app1': 'App 1',
      'app2': 'App 2'
    },
    disabledColumns: ['firstName', 'app1', 'app2'],
    hiddenColumns: ['App 1', 'App 2']
  },
  inactiveUsersListPopup: {
    key: 'inactiveUsersListPopup',
    name: 'Inactive Users',
    preDefinedColumnsMapping: {
      [INACTIVE_USERS_TABLE_COLUMNS.LICENSE_NAME]: INACTIVE_USERS_TABLE_COLUMN_TITLES.LICENSE_NAME,
      [INACTIVE_USERS_TABLE_COLUMNS.FULL_NAME]: INACTIVE_USERS_TABLE_COLUMN_TITLES.FULL_NAME,
      [INACTIVE_USERS_TABLE_COLUMNS.ORIGIN_EMAIL]: INACTIVE_USERS_TABLE_COLUMN_TITLES.ORIGIN_EMAIL,
      [INACTIVE_USERS_TABLE_COLUMNS.REASON]: INACTIVE_USERS_TABLE_COLUMN_TITLES.REASON,
      [INACTIVE_USERS_TABLE_COLUMNS.LAST_USAGE_TIME]: INACTIVE_USERS_TABLE_COLUMN_TITLES.LAST_USAGE_TIME,
      [INACTIVE_USERS_TABLE_COLUMNS.PRICE_PER_USER]: INACTIVE_USERS_TABLE_COLUMN_TITLES.PRICE_PER_USER
    },
    disabledColumns: ['licenseName', 'fullName']
  },
  catalogUsersList: {
    key: 'catalogUsersList',
    name: 'SIGNED UP EMPLOYEES'
  },
  appUsersTable: {
    key: 'appUsersTable',
    name: 'Application Users',
    filtersOptions: [
      { label: 'User', value: 'fullName', type: fieldTypes.name },
      { label: 'Email', value: 'originEmail', type: fieldTypes.freeText },
      { label: 'Account', value: 'appAccountName', type: fieldTypes.text },
      { label: 'Last used date', value: 'lastVisitTime', type: fieldTypes.date },
      { label: 'License', value: 'licenses.name', type: fieldTypes.dropdownMulti },
      { label: 'Usage (days used over past 30 days)', value: 'score', type: fieldTypes.number },
      { label: 'Status in app', value: 'externalStatus', type: fieldTypes.text },
      { label: 'Is past user', value: 'isDeletedInIdentitySources', type: fieldTypes.bool },
      { label: 'Source', value: 'sources', type: fieldTypes.dropdownMulti },
      { label: 'License source', value: 'licenses.source', type: fieldTypes.dropdownMulti },
      { label: 'Role', value: 'role', type: fieldTypes.text }
    ],
    originFiltersOptions: [
      { label: 'User', value: 'originFullName', type: fieldTypes.name }
    ],
    preDefinedColumnsMapping: {
      'fullName': 'User',
      'originEmail': 'Email',
      'appAccountName': 'Account',
      'lastVisitTime': 'Last used date',
      'licenses.name': 'Has license',
      'licenseName': 'Licenses',
      'score': 'Usage (days used over past 30 days)',
      'externalStatus': 'Status in app',
      'isDeletedInIdentitySources': 'Is past user',
      'sources': 'Source',
      'licenses.source': 'License source',
      'role': 'Role',
      'userType': 'User type'
    },
    disabledColumns: ['fullName']
  },
  appAccountUsersTable: {
    key: 'appAccountUsersTable',
    name: 'App Account Users',
    filtersOptions: [
      { label: 'User', value: 'fullName', type: fieldTypes.name },
      { label: 'Email', value: 'originEmail', type: fieldTypes.freeText },
      { label: 'Last used date', value: 'lastVisitTime', type: fieldTypes.date },
      { label: 'License', value: 'licenses.name', type: fieldTypes.dropdownMulti },
      { label: 'Usage (days used over past 30 days)', value: 'score', type: fieldTypes.number },
      { label: 'Status in app', value: 'externalStatus', type: fieldTypes.text },
      { label: 'Is past user', value: 'isDeletedInIdentitySources', type: fieldTypes.bool },
      { label: 'Source', value: 'sources', type: fieldTypes.dropdownMulti },
      { label: 'License source', value: 'licenses.source', type: fieldTypes.dropdownMulti },
      { label: 'Role', value: 'role', type: fieldTypes.text }
    ],
    originFiltersOptions: [
      { label: 'User', value: 'originFullName', type: fieldTypes.name }
    ],
    preDefinedColumnsMapping: {
      'fullName': 'User',
      'originEmail': 'Email',
      'lastVisitTime': 'Last used date',
      'licenses.name': 'Has license',
      'licenseName': 'Licenses',
      'score': 'Usage (days used over past 30 days)',
      'externalStatus': 'Status in app',
      'sources': 'Source'
    },
    disabledColumns: ['fullName']
  },
  appAccountLicenseUsersTable: {
    key: 'appAccountLicenseUsersTable',
    name: 'App Account License Users',
    preDefinedColumnsMapping: {
      'fullName': 'User',
      'originEmail': 'Email',
      'reason': 'Reason',
      'lastVisitTime': 'Last used date',
      'licenses.name': 'Has license',
      'licenseName': 'Licenses',
      'score': 'Usage (days used over past 30 days)',
      'externalStatus': 'Status in app',
      'sources': 'Source'
    },
    disabledColumns: ['fullName']
  },
  expensesTable: {
    key: 'expensesTable',
    name: 'Expenses'
  },
  allExpensesTable: {
    key: 'allExpensesTable',
    name: 'All Expenses',
    filtersOptions: [
      { label: 'Transaction date', value: 'transactionDate', type: fieldTypes.date },
      { label: 'Amount', value: 'amount', type: fieldTypes.currency },
      { label: 'Description', value: 'concatenatedDescription', type: fieldTypes.text },
      { label: 'Source', value: 'toriiSource', type: fieldTypes.text },
      { label: 'Account', value: 'appAccountName', type: fieldTypes.dropdown },
      { label: 'Reported by', value: 'reportedBy', type: fieldTypes.dropdown },
      { label: 'Matching status', value: 'mappingStatus', type: fieldTypes.dropdown },
      { label: 'Matched by', value: 'mappingLogic', type: fieldTypes.dropdown },
      { label: 'Matched app', value: 'appName', type: fieldTypes.text },
      { label: 'Source Transaction ID', value: 'idExternalTransaction', type: fieldTypes.text },
      { label: 'GL Account ID', value: 'externalAccountId', type: fieldTypes.text },
      { label: 'GL Account', value: 'externalAccountName', type: fieldTypes.text }
    ],
    preDefinedColumnsMapping: {
      'transactionDate': 'Transaction date',
      'amount': 'Amount',
      'description': 'Description',
      'toriiSource': 'Source',
      'appAccountName': 'Account',
      'reportedByFullName': 'Reported by',
      'mappingStatus': 'Matching status',
      'mappingLogic': 'Matched by',
      'appName': 'Matched app',
      'idExternalTransaction': 'Source Transaction ID',
      'externalAccountId': 'GL Account ID',
      'externalAccountName': 'GL Account'
    }
  },
  appExpensesTable: {
    key: 'appExpensesTable',
    name: 'All App Expenses',
    filtersOptions: [
      { label: 'Transaction date', value: 'transactionDate', type: fieldTypes.date },
      { label: 'Amount', value: 'amount', type: fieldTypes.currency },
      { label: 'Description', value: 'concatenatedDescription', type: fieldTypes.text },
      { label: 'Source', value: 'toriiSource', type: fieldTypes.text },
      { label: 'Account', value: 'appAccountName', type: fieldTypes.dropdown },
      { label: 'Reported by', value: 'reportedBy', type: fieldTypes.dropdown },
      { label: 'Matching status', value: 'mappingStatus', type: fieldTypes.dropdown },
      { label: 'Matched by', value: 'mappingLogic', type: fieldTypes.dropdown },
      { label: 'Source Transaction ID', value: 'idExternalTransaction', type: fieldTypes.text },
      { label: 'GL Account ID', value: 'externalAccountId', type: fieldTypes.text },
      { label: 'GL Account', value: 'externalAccountName', type: fieldTypes.text }
    ],
    preDefinedColumnsMapping: {
      'transactionDate': 'Transaction date',
      'amount': 'Amount',
      'description': 'Description',
      'toriiSource': 'Source',
      'appAccountName': 'Account',
      'reportedByFullName': 'Reported by',
      'mappingStatus': 'Matching status',
      'mappingLogic': 'Matched by',
      'idExternalTransaction': 'Source Transaction ID',
      'externalAccountId': 'GL Account ID',
      'externalAccountName': 'GL Account'
    }
  },
  appDiscoveredAppsTable: {
    key: 'appDiscoveredAppsTable',
    name: 'All App Discovered Applications',
    preDefinedColumnsMapping: {
      'name': 'Discovered App',
      'accountName': 'Account Name',
      'creationTime': 'Discovered at',
      'mappingLogic': 'Matching Logic',
      'mappedIdApp': 'Matched App'
    },
    disabledColumns: ['name', 'mappingLogic', 'mappedIdApp']
  },
  allDiscoveredAppsTable: {
    key: 'allDiscoveredAppsTable',
    name: 'All Discovered Applications',
    preDefinedColumnsMapping: {
      'name': 'Discovered App',
      'source': 'Source',
      'accountName': 'Account Name',
      'creationTime': 'Discovered at',
      'mappingLogic': 'Matching Logic',
      'mappedIdApp': 'Matched App'
    },
    disabledColumns: ['name', 'mappingLogic', 'mappedIdApp']
  },
  teamUsersTable: {
    key: 'teamUsersTable',
    name: 'Team Users'
  },
  rolesTable: {
    key: 'rolesTable',
    name: 'Roles'
  },
  monthlyExpensesReportTable: {
    key: 'monthlyExpensesReportTable',
    name: 'Monthly Expenses Report'
  },
  extensionRolloutReportTable: {
    key: 'extensionRolloutReportTable',
    name: 'Extension Rollout Status Report'
  },
  thirdPartyReportTable: {
    key: 'thirdPartyReportTable',
    name: 'Third Party Report'
  },
  inactiveUsersReportV2Table: {
    key: 'inactiveUsersReportV2Table',
    name: 'Inactive Users Report'
  },
  userAppsTable: {
    key: 'userAppsTable',
    name: 'User Applications'
  },
  deletedUsersTable: {
    key: 'deletedUsersTable',
    name: 'Deleted Users',
    filtersOptions: [
      { label: 'Name', value: 'fullName', type: fieldTypes.name },
      { label: 'Email', value: 'email', type: fieldTypes.freeText },
      { label: 'Removal date', value: 'identitySourcesDeletionTime', type: fieldTypes.date },
      { label: 'Number of apps used', value: 'activeAppsCount', type: fieldTypes.number },
      { label: 'Additional emails', value: 'additionalEmails', type: fieldTypes.dropdownMulti },
      { label: 'Number of owned apps', value: 'numberOfOwnedApps', type: fieldTypes.number },
      { label: 'Is app owner', value: 'isAppOwner', type: fieldTypes.bool }
    ],
    preDefinedColumnsMapping: {
      'fullName': 'Name',
      'identitySourcesDeletionTime': 'Removal date',
      'activeAppsCount': 'Apps used',
      'annualCostConverted': 'Annual cost',
      'userType': 'User type',
      'additionalEmails': 'Additional emails',
      [USERS_TABLE_COLUMNS.NUMBER_OF_OWNED_APPS]: USERS_TABLE_COLUMN_TITLES.NUMBER_OF_PRIMARY_OWNED_APPS,
      [USERS_TABLE_COLUMNS.IS_APP_OWNER]: USERS_TABLE_COLUMN_TITLES.IS_APP_OWNER
    },
    disabledColumns: ['fullName', 'identitySourcesDeletionTime']
  },
  externalUsersTable: {
    key: 'externalUsersTable',
    name: 'External Users',
    filtersOptions: [
      { label: 'Name', value: 'fullName', type: fieldTypes.name },
      { label: 'Email', value: 'email', type: fieldTypes.freeText },
      { label: 'Domain', value: 'domain', type: fieldTypes.text },
      { label: 'Number of apps used', value: 'activeAppsCount', type: fieldTypes.number },
      { label: 'Additional emails', value: 'additionalEmails', type: fieldTypes.dropdownMulti }
    ]
  },
  transactionsTable: {
    key: 'transactionsTable',
    name: 'Transactions'
  },
  licensesTable: {
    key: 'licensesTable',
    name: 'Licenses'
  },
  ssoAuditTable: {
    key: 'ssoAuditTable',
    name: 'SSO Audit'
  },
  workflowsTable: {
    key: 'workflowsTable',
    name: 'Workflows',
    filtersOptions: [
      { label: 'Name', value: 'name', type: fieldTypes.text },
      { label: 'Creation date', value: 'creationTime', type: fieldTypes.date },
      { label: 'Last run', value: 'lastTriggeredTime', type: fieldTypes.date },
      {
        label: 'Last status',
        value: 'lastStatus',
        type: fieldTypes.dropdown,
        options: [...Object.values(WORKFLOW_EXECUTION_STATUS_LABELS), WORKFLOW_THROTTLED_STATUS]
          .map(label => ({ value: label, label }))
      },
      { label: 'Active', value: 'isActive', type: fieldTypes.bool },
      { label: 'Created by', value: 'createdBy', type: fieldTypes.user }
    ]
  },
  hiddenAppsTable: {
    key: 'hiddenAppsTable',
    name: 'Hidden apps'
  },
  customAppsTable: {
    key: 'customAppsTable',
    name: 'Custom apps'
  },
  needsAttentionAppsTable: {
    key: 'needsAttentionAppsTable',
    name: 'Needs attention apps'
  },
  notConfiguredAppsTable: {
    key: 'notConfiguredAppsTable',
    name: 'Not configured apps'
  },
  offboardingTodoTable: {
    key: 'offboardingTodoTable',
    name: 'To-do'
  },
  offboardingInprogressTable: {
    key: 'offboardingInprogressTable',
    name: 'In progress'
  },
  offboardingDoneTable: {
    key: 'offboardingDoneTable',
    name: 'Done'
  },
  configuredAppsTable: {
    key: 'configuredAppsTable',
    name: 'Configured apps'
  },
  automaticOffboardingTable: {
    key: 'automaticOffboardingTable',
    name: 'Automatic offboarding'
  },
  delegateOffboardingTable: {
    key: 'delegateOffboardingTable',
    name: 'Delegate offboarding'
  },
  ignoredOffboardingTable: {
    key: 'ignoredOffboardingTable',
    name: 'Ignored offboarding'
  },
  workflowAppsLicensesTable: {
    key: 'workflowAppsLicensesTable',
    name: 'Workflow Apps Licenses Table'
  },
  chargeBackReportTableV2: {
    key: 'chargeBackReportTableV2',
    name: 'Chargeback Report Table V2'
  },
  appsListPopupTable: {
    key: 'appsListPopupTable',
    name: 'Apps List Popup Table'
  },
  appDetailHistoryPopupTable: {
    key: 'appDetailHistoryPopupTable',
    name: 'Apps Detail History Popup Table'
  },
  userDetailHistoryPopupTable: {
    key: 'userDetailHistoryPopupTable',
    name: 'Users Detail History Popup Table'
  },
  contractDetailHistoryPopupTable: {
    key: 'contractDetailHistoryPopupTable',
    name: 'Contracts Detail History Popup Table'
  },
  userTasksTable: {
    key: 'userTasksTable',
    name: 'User tasks Table'
  },
  contractsTable: {
    key: 'contractsTable',
    name: 'Contracts',
    filtersOptions: [
      { label: 'Contract Source', value: 'source', type: fieldTypes.text },
      { label: 'Application', value: 'application', type: fieldTypes.text },
      { label: 'Creation Date', value: 'creationTime', type: fieldTypes.date },
      { label: 'Created By', value: 'createdBy', type: fieldTypes.user }
    ],
    preDefinedColumnsMapping: {
      'idApp': 'Application',
      'source': 'Contract Source',
      'id': 'Contract Id',
      'creationTime': 'Creation Date',
      'createdBy': 'Created By'
    },
    specialColumns: ['status', 'owner', 'name'],
    disabledColumns: ['idApp', 'name']
  },
  appContractsTable: {
    key: 'appContractsTable',
    name: 'App Contracts',
    preDefinedColumnsMapping: {
      'idApp': 'Application',
      'source': 'Contract Source'
    },
    filtersOptions: [
      { label: 'Contract Source', value: 'source', type: fieldTypes.text },
      { label: 'Creation Date', value: 'creationTime', type: fieldTypes.date },
      { label: 'Created By', value: 'createdBy', type: fieldTypes.user }
    ],
    specialColumns: ['status', 'owner', 'name'],
    disabledColumns: ['idApp', 'name'],
    defaultSort: [{ id: 'idApp', desc: true }]
  },
  unmatchedContractsTable: {
    key: 'unmatchedContractsTable',
    name: 'Unmatched Contracts',
    preDefinedColumnsMapping: {
      'name': 'Contract name',
      'source': 'Contract Source',
      'supplier': 'Vendor',
      'documents': 'Documents',
      'creationTime': 'Creation Date',
      'createdBy': 'Created By'
    },
    specialColumns: ['name']
  },
  renewalsSummaryTable: {
    key: 'renewalsSummaryTable',
    name: 'Renewals Summary Table',
    preDefinedColumnsMapping: {
      'idApp': 'Application',
      'diff': 'Difference',
      'bucket': 'Bucket'
    }
  },
  appChargebackTable: {
    key: 'appChargebackTable',
    name: 'App Chargeback'
  },
  workflowsActionExecutionsTable: {
    key: 'workflowsActionExecutionsTable',
    name: 'Workflows Action Executions'
  },
  apiKeysTable: {
    key: 'apiKeysTable',
    name: 'API Keys'
  },
  licenseCostTable: {
    key: 'licenseCostTable',
    name: 'App Licenses Costs'
  },
  detailsGroupTable: {
    key: 'detailsGroupTable',
    name: 'Details Group'
  },
  expensesUploadActivityTable: {
    key: 'expensesUploadActivityTable',
    name: 'Expenses Upload Activity'
  },
  triggerPreviewPopup: {
    key: 'triggerPreviewPopup',
    name: 'Trigger Preview Popup'
  },
  mapUsersTable: {
    key: 'mapUsersTable',
    name: 'Map Users'
  },
  uploadsTable: {
    key: 'uploadsTable',
    name: 'Uploads'
  },
  workflowsExecutionsTable: {
    key: 'workflowsExecutionsTable',
    name: 'Workflow Executions'
  },
  catalogAppsPopup: {
    key: 'catalogAppsPopup',
    name: 'Catalog Apps Popup',
    filtersOptions: [
      { label: 'Name', value: 'name', type: fieldTypes.text },
      { label: 'Category', value: 'category', type: fieldTypes.text },
      { label: 'Owner', value: 'owner', type: fieldTypes.user },
      { label: 'Number of users', value: 'activeUsersCount', type: fieldTypes.number },
      { label: 'Number of used days', value: 'score', type: fieldTypes.number },
      { label: 'Expenses', value: 'expenses', type: fieldTypes.number },
      { label: 'Sources', value: 'sources', type: fieldTypes.dropdownMulti },
      { label: 'New app', value: 'isNew', type: fieldTypes.bool },
      { label: 'Tags', value: 'tags', type: fieldTypes.dropdownMulti },
      { label: 'Discovery date', value: 'creationTime', type: fieldTypes.date },
      { label: 'Last usage date', value: 'lastVisitTime', type: fieldTypes.date },
      { label: 'Vendor', value: 'vendor', type: fieldTypes.text }
    ]
  },
  appActiveUsersListPopup: {
    key: 'appActiveUsersListPopup',
    name: 'Application Active Users Popup',
    filtersOptions: [
      { label: 'User', value: 'fullName', type: fieldTypes.name },
      { label: 'Has license', value: 'licenses.name', type: fieldTypes.text },
      { label: 'Last used date', value: 'lastVisitTime', type: fieldTypes.date },
      { label: 'Source', value: 'sources', type: fieldTypes.dropdownMulti }
    ],
    preDefinedColumnsMapping: {
      'fullName': 'User',
      'licenses.name': 'Has license',
      'score': 'Usage',
      'lastVisitTime': 'Last used date',
      'sources': 'Source'
    },
    disabledColumns: ['fullName']
  },
  comparisonRecommendationsTable: {
    key: 'comparisonRecommendationsTable',
    name: 'Comparison Recommendations Table'
  },
  integrationsTable: {
    key: 'integrationsTable',
    name: 'Integrations'
  },
  licenseRecommendationsTable: {
    key: 'licenseRecommendationsTable',
    name: 'License Recommendations Table',
    preDefinedColumnsMapping: {
      'fullName': 'User',
      'originEmail': 'Email',
      'license.lastTimeLicenseFeatureUsed': 'Last date of license full utilization',
      'license.calculatedLastUsageTime': 'App last used date',
      'role': 'Role',
      'externalStatus': 'Status in app',
      'allLicenseNames': 'Licenses'
    },
    disabledColumns: ['license.lastTimeLicenseFeatureUsed', 'license.calculatedLastUsageTime', 'fullName'],
    filtersOptions: [
      { label: 'User', value: 'fullName', type: fieldTypes.name },
      { label: 'Email', value: 'originEmail', type: fieldTypes.freeText },
      { label: 'Last date of license full utilization', value: 'license.lastTimeLicenseFeatureUsed', type: fieldTypes.date },
      { label: 'App last used date', value: 'license.calculatedLastUsageTime', type: fieldTypes.date },
      { label: 'Role', value: 'role', type: fieldTypes.text },
      { label: 'Status in app', value: 'externalStatus', type: fieldTypes.text }
    ]
  },
  monthlyExpensesReport: {
    key: 'monthlyExpensesReport',
    name: 'Monthly expenses report',
    filtersOptions: [
      { label: 'App name', value: 'name', type: fieldTypes.text },
      { label: 'Category', value: 'category', type: fieldTypes.text },
      { label: 'App owner', value: 'owner', type: fieldTypes.user },
      { label: 'Number of users', value: 'activeUsersCount', type: fieldTypes.number },
      { label: 'Number of used days', value: 'score', type: fieldTypes.number },
      { label: 'Expenses', value: 'expenses', type: fieldTypes.currency },
      { label: 'Active contracts value', value: 'activeContractsTotalValue', type: fieldTypes.currency },
      { label: 'Active contracts', value: 'activeContractsCount', type: fieldTypes.number },
      { label: 'Sources', value: 'sources', type: fieldTypes.dropdownMulti },
      { label: 'New app', value: 'isNew', type: fieldTypes.bool },
      { label: 'State', value: 'state', type: fieldTypes.text },
      { label: 'Tags', value: 'tags', type: fieldTypes.dropdownMulti },
      { label: 'Discovery date', value: 'creationTime', type: fieldTypes.date },
      { label: 'Last usage date', value: 'lastVisitTime', type: fieldTypes.date },
      { label: 'Vendor', value: 'vendor', type: fieldTypes.text },
      { label: 'Google Workspace risk level', value: 'GSuiteRiskLevel', type: fieldTypes.dropdown },
      { label: 'Microsoft Entra ID risk level', value: 'azureRiskLevel', type: fieldTypes.dropdown },
      { label: 'Slack risk level', value: 'slackRiskLevel', type: fieldTypes.dropdown }
    ],
    preDefinedColumnsMapping: {
      'name': 'App name',
      'owner': 'App owner',
      'total': 'Total',
      '0': '0',
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
      '6': '6',
      '7': '7',
      '8': '8',
      '9': '9',
      '10': '10',
      '11': '11'
    },
    customFilterOptions: [
      { label: 'Last 12 Months', value: MONTHLY_EXPENSES_REPORT_RANGE.LAST_12_MONTHS },
      { label: 'This Year', value: MONTHLY_EXPENSES_REPORT_RANGE.THIS_YEAR }
    ]
  },
  secretsTable: {
    key: 'secretsTable',
    name: 'Secrets'
  },
  workflowsExecutionsAudit: {
    key: 'workflowsExecutionsAudit',
    name: 'Workflows Executions Audit Table',
    filtersOptions: [
      { label: 'Triggered at', value: 'runTime', type: fieldTypes.date },
      { label: 'Action type', value: 'actionLabel', type: fieldTypes.dropdown },
      { label: 'User from action', value: 'actionUser', type: fieldTypes.user },
      { label: 'Status', value: 'status', type: fieldTypes.dropdown },
      { label: 'App name', value: 'appName', type: fieldTypes.text },
      { label: 'Action trigger', value: 'triggerReason', type: fieldTypes.text },
      { label: 'Workflow / policy name', value: 'workflowOrPolicyName', type: fieldTypes.text },
      { label: 'User from trigger', value: 'triggerUser', type: fieldTypes.user },
      { label: 'App from trigger', value: 'triggerApp', type: fieldTypes.text },
      { label: 'License from trigger', value: 'triggerLicense', type: fieldTypes.text },
      { label: 'Contract from trigger', value: 'triggerContract', type: fieldTypes.text },
      { label: 'Manually triggered by', value: 'calculatedTriggeredBy', type: fieldTypes.user },
      { label: 'Completed at', value: 'completionTime', type: fieldTypes.date }
    ]
  },
  importUsersColumnMapping: {
    key: 'importUsersColumnMapping',
    name: 'Import Users Column Mapping'
  },
  workflowEditHistoryLogs: {
    key: 'workflowEditHistoryLogs'
  },
  scheduledReportsTable: {
    key: 'scheduledReportsTable',
    name: 'Scheduled Reports',
    preDefinedColumnsMapping: {
      'reportName': 'Report Name',
      'scheduleDetails.scheduleFrequency': 'Frequency',
      'status': 'Last status',
      'nextScheduledTime': 'Next schedule (UTC)',
      'createdBy': 'Created by',
      'isEnabled': 'Active',
      'creationTime': 'Created on',
      'lastUpdatedBy': 'Last updated by',
      'lastUpdatedOn': 'Last updated on',
      'lastTimeSent': 'Last run'
    },
    disabledColumns: ['reportName'],
    filtersOptions: [
      { label: 'Report name', value: 'reportName', type: fieldTypes.text },
      { label: 'Frequency', value: 'scheduleDetails.scheduleFrequency', type: fieldTypes.text },
      { label: 'Last status', value: 'status', type: fieldTypes.dropdown, options: [{ value: 'finished_successfully', label: 'Completed' }, { value: 'finished_failed', label: 'Error' }] },
      { label: 'Next schedule (UTC)', value: 'nextScheduledTime', type: fieldTypes.date },
      { label: 'Created by', value: 'createdBy', type: fieldTypes.user },
      { label: 'Active', value: 'isEnabled', type: fieldTypes.bool },
      { label: 'Created on', value: 'creationTime', type: fieldTypes.date },
      { label: 'Last updated by', value: 'lastUpdatedBy', type: fieldTypes.user },
      { label: 'Last updated on', value: 'lastUpdatedOn', type: fieldTypes.date },
      { label: 'Last run', value: 'lastTimeSent', type: fieldTypes.date }
    ]
  }
}

export const numberOfDaysToConsiderAsNew = 4

export const SYNC_STATUS = {
  IN_PROGRESS: 'in_progress',
  FINISHED_SUCCESSFULLY: 'finished_successfully',
  FINISHED_PARTIALLY: 'finished_partially',
  FINISHED_FAILED: 'finished_failed'
}

export const AUDIT_LOG_TYPES = {
  APP_REMOVAL: 'remove_app',
  REMOVED_DIRECTLY_BY_TORII: 'removed_directly_by_torii',
  SEND_EMAIL_ASK_REVOKE_USER_FROM_APP: 'send_email_ask_to_revoke_user_from_apps',
  UPDATE_USER_LIFECYCLE_STATUS: 'update_user_lifecycle_status'
}

export const TOOLTIP_ALERT = {
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
  NONE: 'none'
}

export const EMAIL_NOTIFICATION_FREQUENCY = {
  DISABLED: 'disabled',
  DAILY: 'daily',
  WEEKLY: 'weekly',
  ONLY_WEEKLY: 'onlyWeekly'
}

export const USAGE_LEVEL = {
  USAGE_NOT_PROVIDED: 'Usage not provided',
  NO_USAGE: 'No usage',
  LOW: 'Low usage',
  MID: 'Medium usage',
  HIGH: 'High usage',
  CORE_APP: 'Core application'
}

export const INACTIVE_LICENSE_REASON = {
  NO_ACCESS: 'noAccess',
  LEFT_ORG: 'leftOrg',
  NO_USAGE: 'noUsage'
}

export const INACTIVE_REASON = {
  [INACTIVE_LICENSE_REASON.NO_USAGE]: (days) => `No usage in the last ${days || 30} days`,
  [INACTIVE_LICENSE_REASON.NO_ACCESS]: () => 'No access to account',
  [INACTIVE_LICENSE_REASON.LEFT_ORG]: () => 'User has left the org'
}

export const LICENSE_STATUS = {
  inactive: {
    id: 'inactive',
    readable: 'Inactive'
  },
  active: {
    id: 'active',
    readable: 'Active'
  },
  noLicense: {
    id: 'noLicense',
    readable: 'No license'
  }
}

export const CALCULATED_LICENSE_STATUS = {
  ACTIVE: 'active',
  DELETED: 'deleted',
  INACTIVE: 'inactive'
}

export const WORKFLOW_TRIGGER_TYPES = {
  SELECT: 'select',
  NEW_APP: 'newApp',
  USER_STOP_USING_APP: 'userStoppedUsingApp',
  USER_STOP_USING_LICENSE: 'userStoppedUsingLicense',
  APP_STATE_CHANGED: 'appStateChanged',
  USER_OFFBOARDING: 'userOffboarding',
  USER_USING_CLOSED_APP: 'userUsingClosedApp',
  EXECUTE_ON_USERS: 'executeOnUsers',
  REQUEST_ACCESS: 'requestAccess',
  REQUEST_NEW_APP: 'requestNewApp',
  USER_TO_ACTIVATE_EXTENSION: 'userToActivateExtension',
  USER_MEETS_CRITERIA: 'userMeetsCriteria',
  CONTRACT_MEETS_CRITERIA: 'contractMeetsCriteria',
  USER_JOINS: 'newUser',
  USER_LEFT: 'userLeft',
  NEW_USER_IN_BAMBOOHR: 'newUserInBambooHR',
  LICENSE_THRESHOLD: 'licenseThreshold',
  APPLICATION_MEETS_CRITERIA: 'applicationMeetsCriteria',
  WORKFLOW_ERROR_OCCURRED: 'workflowErrorOccurred',
  APP_WEBHOOK_EVENT_OCCURRED: 'appWebhookEventOccurred',
  USER_DETAIL_CHANGED: 'userDetailChanged',
  CUSTOM_WEBHOOK_RECEIVED: 'customWebhookReceived'
}

export const WORKFLOW_ACTION_TYPES = {
  SELECT: 'select',
  SEND_EMAIL: 'sendEmail',
  SEND_FORM: 'sendForm',
  SEND_CONTRACT_FORM: 'sendContractForm',
  REQUEST_APPROVAL: 'requestApproval',
  REMOVE_USER: 'removeUser',
  REMOVE_USER_TASK: 'removeUserTask',
  SLACK_NOTIFICATION: 'slackNotification',
  IGNORE: 'ignore',
  AUTO_REMOVE_USER: 'autoRemoveUser',
  SLACK_INVITE_USER: 'slackInviteUser',
  START_EMPLOYEE_OFFBOARDING: 'startEmployeeOffboarding',
  SEND_BROWSER_ACTIVATION_EMAIL: 'sendBrowserActivationEmail',
  SEND_HTTP_REQUEST: 'sendHttpRequest',
  JIRA_CLOUD_CREATE_ISSUE: 'jiraCloudCreateIssue',
  SERVICENOW_CREATE_TICKET: 'servicenowCreateTicket',
  ASANA_CREATE_TASK: 'asanaCreateTask',
  FRESHSERVICE_CREATE_TICKET: 'freshserviceCreateTicket',
  MONDAY_CREATE_ITEM: 'mondayCreateItem',
  ZENDESK_CREATE_TICKET: 'zendeskCreateTicket',
  SHARE_REPORT: 'shareReport'
}

export const WORKFLOW_EXECUTION_TYPES = {
  AUTOMATICALLY: 'automatically',
  ON_ACTIVATION: 'onActivation',
  MANUAL_WORKFLOW: 'manualWorkflow',
  MANUAL_ACTION: 'manualAction',
  RERUN: 'rerun',
  MANUAL_API_WORKFLOW: 'manualAPIWorkflow'
}

export const WORKFLOW_STEP_TYPES = {
  SELECT: 'select',
  STEP_SETUP: 'stepSetup'
}

export const WORKFLOW_OUTPUT_FIELD_TYPES = {
  USER: 'user',
  APP: 'app',
  LICENSE: 'license',
  TEXT: 'text',
  CONTRACT: 'contract',
  TUPLE: 'tuple',
  USER_DETAIL_CHANGE: 'userDetailChange',
  OBJECT: 'object'
}

export const WORKFLOW_VALIDATION_TYPES = {
  REQUIRED: 'required',
  NUMBER: 'number',
  SELECTED_USER: 'selectedUser',
  NOT_EMPTY_ARRAY: 'notEmptyArray',
  FORM_FIELD: 'formField',
  USER: 'user',
  USERS: 'users',
  APPS_SELECTION: 'appsSelection',
  LICENSES_SELECTION: 'licensesSelection',
  EMAIL: 'email',
  DATE: 'date',
  BOOL: 'bool'
}

export const INTEGRATION_CATEGORY = {
  IDENTITY_PROVIDER: 'identityProvider',
  HUMAN_RESOURCE: 'humanResource',
  EXPENSE_SOFTWARE: 'expenseSoftware',
  CONTRACT_MANAGEMENT_SOFTWARE: 'contractManagementSoftware',
  OTHER: 'other'
}

export const SYNC_TYPE = {
  API: 'API',
  TORII_BOT: 'toriiBot',
  CUSTOM: 'custom',
  MANUAL: 'manual'
}

export const INTEGRATION_CATEGORY_CONFIG = {
  [INTEGRATION_CATEGORY.IDENTITY_PROVIDER]: { type: 'identityProvider', title: 'Identity Providers', index: 1 },
  [INTEGRATION_CATEGORY.HUMAN_RESOURCE]: { type: 'humanResource', title: 'Human Resource Management Systems', index: 2 },
  [INTEGRATION_CATEGORY.EXPENSE_SOFTWARE]: { type: 'expenseSoftware', title: 'Expense Software', index: 3, feature: FEATURES.INTEGRATIONS.TYPES.EXPENSES },
  [INTEGRATION_CATEGORY.CONTRACT_MANAGEMENT_SOFTWARE]: { type: 'contractManagementSoftware', title: 'Contract Management Software', index: 4, feature: FEATURES.INTEGRATIONS.TYPES.CONTRACTS },
  [INTEGRATION_CATEGORY.OTHER]: { type: 'other', title: 'Popular Direct Integrations', index: 5 }
}

export const PARSING_STATUS = {
  parsingInProgress: 'parsing_in_progress',
  parsedSuccessfully: 'parsed_successfully',
  parsingFaied: 'parsing_failed'
}

export const APP_PERMISSIONS = {
  read: 'read',
  write: 'write',
  read_write: 'read_write'
}
export const APP_PERMISSIONS_LABEL = {
  [APP_PERMISSIONS.read]: 'read',
  [APP_PERMISSIONS.read_write]: 'read and take action'
}

export const WORKFLOW_TYPES = {
  regular: 'regular',
  offboarding: 'offboarding',
  manual: 'manual',
  notifyOnErrors: 'notifyOnErrors',
  appCatalog: 'appCatalog'
}

export const OFFBOARDING_METHOD_TYPE = {
  AUTOMATIC: 'automatic',
  DELEGATE: 'delegate',
  IGNORE: 'ignore'
}

export const WORKFLOW_TYPE_TO_ITEM_NAME = {
  regular: 'workflow',
  appCatalog: 'policy'
}

export const OFFBOARDING_APPS_STATUS = {
  needsAttention: 'needsAttention',
  nonConfigured: 'nonConfigured',
  configured: 'configured'
}

export const WORKFLOW_EXECUTION_ERROR_TYPES = {
  noWritePermissions: 'noWritePermissions',
  serviceNotConnected: 'serviceNotConnected',
  disabledService: 'disabledService',
  notSupportedInPlan: 'notSupportedInPlan',
  cannotDeactivateDefaultWorkflowUser: 'cannotDeactivateDefaultWorkflowUser',
  userNotFound: 'userNotFound',
  userNotFoundInAccount: 'userNotFoundInAccount',
  userNotToriiUser: 'userNotToriiUser',
  managerNotFoundInAccount: 'managerNotFoundInAccount',
  groupNotFound: 'groupNotFound',
  memberAlreadyExists: 'memberAlreadyExists',
  noArchivedLicenses: 'noArchivedLicenses',
  invalidOrgUnit: 'invalidOrgUnit',
  invalidUserGroup: 'invalidUserGroup',
  invalidWebhookUrl: 'invalidWebhookUrl',
  memberNotFound: 'memberNotFound',
  transferFailed: 'transferFailed',
  sourceUserNotFound: 'sourceUserNotFound',
  destinationUserNotFound: 'destinationUserNotFound',
  usernameAlreadyInUse: 'usernameAlreadyInUse',
  adminPermissionsAreRequired: 'adminPermissionsAreRequired',
  customHttpRequestFailed: 'customHttpRequestFailed'
}

export const TRANSACTION_MAPPING_STATUS = {
  IGNORED: 'ignored',
  RECOMMEND_TO_IGNORE: 'recommend_ignore',
  RECOMMEND_TO_MAP: 'recommend_map',
  UNKNOWN: 'unknown',
  MAPPED: 'mapped',
  ARCHIVED: 'archived'
}

export const EXPENSES_RANGE_OPTIONS = {
  THIS_YEAR: 'thisYear',
  LAST_12_MONTHS: 'last12Months',
  ACTIVE_CONTRACT_TERM: 'activeContractTerm',
  ALL: 'all'
}

export const TRANSACTION_MAPPING_STATUS_OPEN_FOR_EXPENSES_WRITE = [
  TRANSACTION_MAPPING_STATUS.RECOMMEND_TO_MAP,
  TRANSACTION_MAPPING_STATUS.UNKNOWN,
  TRANSACTION_MAPPING_STATUS.IGNORED,
  TRANSACTION_MAPPING_STATUS.RECOMMEND_TO_IGNORE
]

export const TRANSACTION_MAPPING_STATUS_DISPLAY = {
  [TRANSACTION_MAPPING_STATUS.RECOMMEND_TO_MAP]: 'Potential match',
  [TRANSACTION_MAPPING_STATUS.UNKNOWN]: 'No match found',
  [TRANSACTION_MAPPING_STATUS.MAPPED]: 'Matched',
  [TRANSACTION_MAPPING_STATUS.ARCHIVED]: 'Archived',
  [TRANSACTION_MAPPING_STATUS.IGNORED]: 'Ignored',
  [TRANSACTION_MAPPING_STATUS.RECOMMEND_TO_IGNORE]: 'Suggested ignore'
}

export const TRANSACTION_MAPPING_STATUS_ORDER = {
  [TRANSACTION_MAPPING_STATUS.MAPPED]: 1,
  [TRANSACTION_MAPPING_STATUS.RECOMMEND_TO_MAP]: 2,
  [TRANSACTION_MAPPING_STATUS.UNKNOWN]: 3,
  [TRANSACTION_MAPPING_STATUS.IGNORED]: 4,
  [TRANSACTION_MAPPING_STATUS.RECOMMEND_TO_IGNORE]: 5,
  [TRANSACTION_MAPPING_STATUS.ARCHIVED]: 6
}

export const TRANSACTION_MAPPING_LOGIC_DISPLAY = {
  toriilogic: 'Torii AI',
  manually: 'Manual',
  orgrules: 'Matching rule'
}

export const EMPTY_ARRAY = []
export const EMPTY_OBJECT = {}

export const UPLOAD_TYPES = {
  ATTACHMENT: 'attachment',
  EXPENSE_REPORT: 'expenseReport',
  CONTRACT: 'contract',
  TORII_BOT: 'toriiBot',
  SAML_CONFIGURATION: 'samlConfiguration',
  APP_USERS_IMPORT: 'appUsersImport'
}

export const NO_APP_ACCOUNT_ID = 1

export const SHARE_STATUS = {
  notShared: { type: 'notShared', label: 'Private' },
  sharedReadOnly: { type: 'sharedReadOnly', label: 'Shared (read only)' },
  shared: { type: 'shared', label: 'Shared (full access)' }
}
export const SHARE_STATUS_OPTIONS = Object.values(SHARE_STATUS).map(status => ({ label: status.label, value: status.type }))

export const USER_FIELDS_TYPES = {
  datePicker: 'datePicker',
  singleLine: 'singleLine',
  multiValueString: 'multiValueString',
  user: 'user',
  email: 'email'
}

export const CHARGEBACK_ALLOCATION_STRATEGY = {
  topDown: 'topDown',
  bottomUp: 'bottomUp',
  notConfigured: 'notConfigured'
}

export const CHARGEBACK_ALLOCATION_COST = {
  license: 'license',
  user: 'user',
  contract: 'contract',
  expenses: 'expenses',
  manual: 'manual'
}

export const SCOPES_PERMISSIONS = {
  NONE: 'none',
  READ: 'read',
  WRITE: 'write'
}

export const syncDisabledString = 'Sync disabled - '

export const SYNC_ERRORS = {
  'invalid_credentials': 'Invalid credentials',
  'multi_factor_auth': 'Multi-Factor authentication not supported',
  'sso_not_supported': 'SSO authentication not supported',
  'insufficient_permissions': 'Insufficient permissions',
  'not_supported': 'Data is not supplied by vendor',
  'incorrect_url': 'The provided URL is missing or incorrect',
  'user_review_needed': 'User needs to login manually and approve new conditions'
}

export const simpleEmailRegex = /^\S+@\S+\.\S+$/

export const EXPENSES_SOURCES = {
  netsuite: 'NetSuite',
  expensify: 'Expensify',
  billcom: 'Bill.com',
  quickbooks: 'QuickBooks',
  oracleFinancialsCloud: 'Oracle Financials Cloud',
  concur: 'Concur',
  xero: 'Xero',
  sageIntacct: 'SageIntacct',
  demo: 'Expenses'
}

export const CONTRACT_FIELDS_TO_REMOVE_FROM_DUPLICATE = ['id', 'idOrg', 'source', 'createdBy', 'externalId', 'documents', 'status', 'nextContracts', 'previousContracts']

export const CONTRACT_STATUS = {
  active: 'active',
  closed: 'closed',
  pipeline: 'pipeline',
  inReview: 'inReview'
}

export const CONTRACT_TABLE_HEADERS = {
  id: 'Contract Id',
  source: 'Contract Source',
  name: 'Contract name',
  appName: 'Application name',
  status: 'Status',
  currency: 'Contract currency',
  createdBy: 'Created By',
  creationTime: 'Creation Date'
}

export const READ_ONLY_CONTRACT_FIELDS = [
  'cancellationDeadline',
  'creationTime'
]

export const APP_USERS_CUSTOM_SELECT_OPTIONS = {
  active: {
    value: 'active',
    label: 'Only show relevant users',
    description: 'Show only users with access to the app',
    filters: [
      {
        op: { label: 'is', value: 'equals', type: formFieldTypes.dropdown },
        key: { label: 'status', value: 'status', type: 'text' },
        value: { label: 'active', value: 'active' }
      }
    ]
  },
  all: { value: 'all', label: 'Show all users', description: 'Include users who are deleted or suspended', filters: [] }
}

export const APP_USERS_CUSTOM_SELECT_OPTIONS_FOR_NON_CONNECTED_APPS = {
  active: {
    value: 'active',
    label: 'Discovered Users',
    description: 'Users Torii discovered for this app',
    filters: [
      { op: { label: 'is', value: 'equals', type: formFieldTypes.dropdown }, key: { label: 'status', value: 'status', type: 'text' }, value: { label: 'active', value: 'active' } }
    ]
  },
  all: {
    value: 'all',
    label: 'All users',
    description: 'Also includes users who are deleted or suspended',
    filters: []
  }
}
export const APP_USERS_CUSTOM_SELECT_OPTIONS_FOR_CONNECTED_APPS = {
  integration_active: {
    value: 'integration_active',
    label: 'Users with access',
    description: 'Per the integration',
    filters: [
      { op: { label: 'is not', value: 'notEquals', type: formFieldTypes.number }, key: { label: 'App Account', value: 'idAppAccount', type: formFieldTypes.number }, value: 1 },
      { op: { label: 'is', value: 'equals', type: formFieldTypes.dropdown }, key: { label: 'status', value: 'status', type: 'text' }, value: { label: 'active', value: 'active' } }
    ]
  },
  integration_all: {
    value: 'integration_all',
    label: 'Users with and without access',
    description: 'Per the integration',
    filters: [
      { op: { label: 'is not', value: 'notEquals', type: formFieldTypes.number }, key: { label: 'App Account', value: 'idAppAccount', type: formFieldTypes.number }, value: 1 }
    ]
  },
  all: {
    value: 'all',
    label: 'All users from all sources',
    description: 'Also includes users discovered outside the integration',
    filters: []
  }
}

export const LICENSES_CUSTOM_SELECT_OPTIONS = {
  paid: {
    value: 'paid',
    label: 'Paid Licenses'
  },
  all: {
    value: 'all',
    label: 'All Licenses'
  }
}

export const DISCOVERED_APPS_CUSTOM_SELECT_OPTIONS = {
  all: {
    value: 'all',
    label: 'All apps'
  },
  matched: {
    value: 'matched',
    label: 'Matched apps'
  },
  unmatched: {
    value: 'unmatched',
    label: 'Unmatched apps'
  },
  ignored: {
    value: 'ignored',
    label: 'Ignored apps'
  }
}

export const INTEGRATIONS_TABLE_CUSTOM_SELECT_OPTIONS = {
  all: {
    value: 'all',
    label: 'All integrations'
  },
  connected: {
    value: 'connected',
    label: 'Connected integrations'
  },
  recommended: {
    value: 'recommended',
    label: 'Recommended to connect'
  }
}

export const INACTIVE_USERS_REPORT_TABLE_CUSTOM_SELECT_OPTIONS = {
  all: {
    value: 'all',
    label: 'All users',
    filters: []
  },
  pastUsers: {
    value: 'pastUsers',
    label: 'Past users',
    filters: [
      { key: 'isDeletedInIdentitySources', op: 'equals', value: true }
    ]
  },
  currentUsers: {
    value: 'currentUsers',
    label: 'Current users',
    filters: [
      { key: 'isDeletedInIdentitySources', op: 'equals', value: false }
    ]
  }
}

export const ALL_SOURCES_CUSTOM_SELECT_OPTION = { value: 'allSources', label: 'All sources' }

export const EXPENSES_TABLE_CUSTOM_SELECT_OPTIONS = {
  matched: {
    value: [TRANSACTION_MAPPING_STATUS.MAPPED],
    label: 'Matched expenses'
  },
  unmatched: {
    value: [TRANSACTION_MAPPING_STATUS.RECOMMEND_TO_MAP, TRANSACTION_MAPPING_STATUS.UNKNOWN],
    label: 'Unmatched expenses'
  },
  archived: {
    value: [TRANSACTION_MAPPING_STATUS.ARCHIVED],
    label: 'Archived expenses'
  },
  all: {
    value: [TRANSACTION_MAPPING_STATUS.MAPPED, TRANSACTION_MAPPING_STATUS.ARCHIVED, TRANSACTION_MAPPING_STATUS.UNKNOWN, TRANSACTION_MAPPING_STATUS.RECOMMEND_TO_MAP, TRANSACTION_MAPPING_STATUS.IGNORED, TRANSACTION_MAPPING_STATUS.RECOMMEND_TO_IGNORE],
    label: 'All expenses',
    isMainOption: true
  }
}

export const EXPENSES_TABLE_SECONDARY_CUSTOM_SELECT_OPTIONS = {
  [EXPENSES_RANGE_OPTIONS.LAST_12_MONTHS]: {
    value: EXPENSES_RANGE_OPTIONS.LAST_12_MONTHS,
    label: 'Last 12 months'
  },
  [EXPENSES_RANGE_OPTIONS.ACTIVE_CONTRACT_TERM]: {
    value: EXPENSES_RANGE_OPTIONS.ACTIVE_CONTRACT_TERM,
    label: 'Active contract duration'
  },
  [EXPENSES_RANGE_OPTIONS.THIS_YEAR]: {
    value: EXPENSES_RANGE_OPTIONS.THIS_YEAR,
    label: 'This year'
  },
  [EXPENSES_RANGE_OPTIONS.ALL]: {
    value: EXPENSES_RANGE_OPTIONS.ALL,
    label: 'All time'
  }
}

export const RENEWALS_SUMMARY_CUSTOM_SELECT_OPTIONS = Object.keys(bucketDisplayName).reduce((acc, bucket) => {
  acc[bucket] = {
    value: bucket,
    label: bucketDisplayName[bucket]
  }
  return acc
}, { all: {
  value: 'all',
  label: 'All buckets',
  isMainOption: true
} })

export const TRIGGER_TYPE_TO_ITEM = {
  [WORKFLOW_TRIGGER_TYPES.USER_MEETS_CRITERIA]: 'User',
  [WORKFLOW_TRIGGER_TYPES.CONTRACT_MEETS_CRITERIA]: 'Contract',
  [WORKFLOW_TRIGGER_TYPES.APPLICATION_MEETS_CRITERIA]: 'Application',
  [WORKFLOW_TRIGGER_TYPES.EXECUTE_ON_USERS]: 'User',
  [WORKFLOW_TRIGGER_TYPES.USER_STOP_USING_LICENSE]: 'License',
  default: 'Item'
}

export const LICENSES_RECOMMENDATIONS_TYPES = {
  DOWNGRADE: 'licenseDowngrade',
  RECLAIM: 'licenseReclaim',
  RECLAIM_UNUSED: 'licenseReclaimUnused'
}

export const MANUAL_WORKFLOW_EXECUTION_FLOW = {
  appUsers: 'appUsers',
  inactiveUsers: 'inactiveUsers',
  recommendations: 'recommendations'
}

export const INACCESSIBLE_PAGE = {
  TITLE: 'You don\'t have the appropriate permission to access this page.',
  DESCRIPTION: 'Please contact your Torii Administrator to obtain the required permission.'
}

export const USER_FIELD_SEARCH_TYPES = {
  ALL_USERS: 'users',
  INTERNAL_USERS: 'internalUsers'
}

export const APP_CATALOG_REQUEST_ACCESS_BUTTON_ACTIONS = {
  COMPOSE_EMAIL: 'composeEmail',
  OPEN_LINK: 'openLink',
  TRIGGER_WORKFLOW: 'triggerWorkflow'
}

export const ANALYTICS_EVENT = {
  ADD_NEW_CUSTOM_POLICY: 'Add add-new-custom-policy-dialog'
}

export const DATE_FORMAT = 'MMMM DD, YYYY'
export const DATE_FORMAT_WITH_TIME = 'MMMM DD, YYYY LT'

export const ICON_NAMES = {
  CALANDER_DAY: 'CalendarDay',
  CURRENCY: 'Currency',
  EMPLOYEE: 'Employee',
  HASH: 'Hash',
  MULTI_LINE_TEXT: 'MultiLineText',
  MULTI_SELECT: 'MultiSelect',
  SINGLE_LINE_TEXT: 'SingleLineText',
  SINGLE_SELECT: 'SingleSelect',
  USERS: 'Users'
}

export const AUTH_TYPES = {
  BASIC: 'basic',
  BEARER: 'bearer',
  TOKEN: 'token'
}

export const APP_OWNERS_FIELDS = {
  primary: 'primaryOwner',
  appOwners: 'appOwners'
}

export const USER_TYPE = {
  EMPLOYEE: 'employee'
}

export const FEATURE_FLAGS = {
  HIDE_UNMATCHED_EXPENSES: 'hideUnmatchedExpenses'
}

export const MAX_BRANCHES_PER_IF_ELSE_ACTION = 20
export const WORKFLOW_TAG_NAME_MAX_LENGTH = 32

export const DATE_FORMATS_OPTIONS = ['DD/MM/YYYY', 'MM/DD/YYYY', 'DD/MM/YY', 'MM/DD/YY', 'YYYY/MM/DD', 'DD.MM.YYYY', 'MM.DD.YYYY', 'DD.MM.YY', 'MM.DD.YY', 'YYYY.MM.DD', 'DD-MM-YYYY', 'MM-DD-YYYY', 'DD-MM-YY', 'MM-DD-YY', 'YYYY-MM-DD', 'YYYYMMDD']
  .map(format => ({ label: format, value: format }))

export const MIN_MISSING = Number.MIN_SAFE_INTEGER
