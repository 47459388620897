import { BAR_ORIENTATION, Dashboard, ENTITY_TYPES, METRIC_FUNC, Widget, WIDGET_TYPES } from '@reducers/dashboards/types'
import { formFieldTypes } from '@root/constants'

const saasInventoryWidgets: Widget[] = [
  {
    id: 1736343285,
    type: WIDGET_TYPES.METRIC_WIDGET,
    entityType: ENTITY_TYPES.APPS,
    dataConfig: {
      filters: [{
        'key': { 'label': 'State', 'value': 'state', 'type': 'text' },
        'op': { 'label': 'is', 'value': 'equals', 'type': 'dropdown' },
        'value': { 'value': 'sanctioned', 'label': 'sanctioned' }
      }]
    },
    drillDownConfig: {
      fields: ['name', 'primaryOwner', 'displayActiveUsersCount', 'expenses', 'score'],
      sort: ['name:asc']
    },
    displayConfig: {
      label: 'Sanctioned apps',
      metricFunc: METRIC_FUNC.TOTAL
    }
  },
  {
    id: 1736343286,
    type: WIDGET_TYPES.METRIC_WIDGET,
    entityType: ENTITY_TYPES.APPS,
    dataConfig: {
      filters: [ {
        'key': { 'type': 'number', 'label': 'Number of users', 'value': 'displayActiveUsersCount' },
        'op': { 'type': 'number', 'label': 'greater than', 'value': 'gt' },
        'value': 10
      }, {
        'key': { 'label': 'State', 'value': 'state', 'type': 'text' },
        'op': { 'label': 'is', 'value': 'equals', 'type': 'dropdown' },
        'value': { 'value': 'sanctioned', 'label': 'sanctioned' }
      }]
    },
    drillDownConfig: {
      fields: ['name', 'primaryOwner', 'displayActiveUsersCount', 'expenses', 'score'],
      sort: ['name:asc']
    },
    displayConfig: {
      label: 'Commonly used apps',
      metricFunc: METRIC_FUNC.TOTAL
    }
  },
  {
    id: 1736343287,
    type: WIDGET_TYPES.METRIC_WIDGET,
    entityType: ENTITY_TYPES.APPS,
    dataConfig: {
      filters: [{
        'key': { 'type': 'date', 'label': 'Discovery date', 'value': 'creationTime' },
        'op': { 'type': 'relativeDate', 'label': 'is less than', 'value': 'relativeDateLess' },
        'value': { 'type': { 'label': 'days ago', 'value': 'ago' }, 'number': '30' }
      }]
    },
    drillDownConfig: {
      fields: ['name', 'creationTime', 'displayActiveUsersCount', 'score', 'state', 'primaryOwner'],
      sort: ['name:asc']
    },
    displayConfig: {
      label: 'Newly discovered apps',
      metricFunc: METRIC_FUNC.TOTAL
    }
  },
  {
    id: 1736343288,
    type: WIDGET_TYPES.METRIC_WIDGET,
    entityType: ENTITY_TYPES.USERS,
    dataConfig: {
      filters: [
        {
          'key': { 'type': 'number', 'label': 'Number of apps used', 'value': 'activeAppsCount' },
          'op': { 'type': 'number', 'label': 'greater than', 'value': 'gt' },
          'value': 0
        },
        {
          'key': { 'type': 'bool', 'label': 'Is External', 'value': 'isExternal' },
          'op': { 'type': 'bool', 'label': 'is', 'value': 'equals' },
          'value': { 'label': 'No', 'value': false }
        },
        {
          'id': 1737626703880,
          'key': { 'type': 'bool', 'label': 'Is past user', 'value': 'isDeletedInIdentitySources' },
          'op': { 'type': 'bool', 'label': 'is', 'value': 'equals' },
          'value': { 'label': 'No', 'value': false }
        }
      ]
    },
    drillDownConfig: {
      fields: [ 'fullName', 'additionalEmails', 'activeAppsCount', 'annualCostConverted' ],
      sort: ['fullName:asc']
    },
    displayConfig: {
      label: 'Avg. apps per user',
      metricFunc: METRIC_FUNC.AVG,
      metricField: { type: 'number', label: 'Number of apps used', systemKey: 'activeAppsCount' }
    }
  },
  {
    id: 1736343289,
    type: WIDGET_TYPES.PIE_CHART_WIDGET,
    entityType: ENTITY_TYPES.APPS,
    dataConfig: {
      filters: [
        {
          'id': 1737649754498,
          'key': { 'type': 'text', 'label': 'State', 'value': 'state' },
          'op': { 'type': 'dropdown', 'label': 'is not', 'value': 'notEquals' },
          'value': { 'label': 'closed', 'value': 'closed' }
        }
      ]
    },
    drillDownConfig: {
      fields: ['name', 'state', 'sources', 'displayActiveUsersCount', 'score', 'expenses'],
      sort: ['name:asc']
    },
    displayConfig: {
      label: 'Apps by source',
      groupBy: {
        metricFunc: METRIC_FUNC.TOTAL,
        field: { systemKey: 'sources', type: formFieldTypes.dropdownMulti, label: 'Sources' },
        size: 5
      }
    }
  },
  {
    id: 1736343290,
    type: WIDGET_TYPES.PIE_CHART_WIDGET,
    entityType: ENTITY_TYPES.APPS,
    dataConfig: {
      filters: []
    },
    drillDownConfig: {
      fields: ['name', 'state', 'displayActiveUsersCount', 'score', 'expenses'],
      sort: ['name:asc']
    },
    displayConfig: {
      label: 'Apps by state',
      groupBy: {
        metricFunc: METRIC_FUNC.TOTAL,
        field: { systemKey: 'state', type: formFieldTypes.singleLine, label: 'State' },
        size: 5
      }
    }
  },
  {
    id: 1736343291,
    type: WIDGET_TYPES.METRIC_WIDGET,
    entityType: ENTITY_TYPES.APPS,
    dataConfig: {
      filters: [
        {
          'key': { 'type': 'text', 'label': 'State', 'value': 'state' },
          'op': { 'type': 'dropdown', 'label': 'is', 'value': 'equals' },
          'value': { 'label': 'sanctioned', 'value': 'sanctioned' }
        },
        {
          'id': 1737905370804,
          'key': { 'type': 'dropdownMulti', 'label': 'Certifications & Compliance', 'value': 'certificationsAndCompliance' },
          'op': { 'type': 'dropdownMulti', 'label': 'has none of', 'value': 'noneOf' },
          'value': [
            { 'label': 'soc 2', 'value': 'soc 2' },
            { 'label': 'iso 27001', 'value': 'iso 27001'
            }
          ]
        }
      ]
    },
    drillDownConfig: {
      fields: [ 'name', 'primaryOwner', 'certificationsAndCompliance', 'displayActiveUsersCount', 'score' ],
      sort: ['name:asc']
    },
    displayConfig: {
      label: 'Apps with security risk',
      metricFunc: METRIC_FUNC.TOTAL
    }
  },
  {
    id: 1736343292,
    type: WIDGET_TYPES.METRIC_WIDGET,
    entityType: ENTITY_TYPES.APPS,
    dataConfig: {
      filters: [
        {
          'key': { 'type': 'text', 'label': 'State', 'value': 'state' },
          'op': { 'type': 'dropdown', 'label': 'is', 'value': 'equals' },
          'value': { 'label': 'sanctioned', 'value': 'sanctioned' }
        },
        {
          'id': 1737905669950,
          'key': { 'type': 'dropdownMulti', 'label': 'Certifications & Compliance', 'value': 'certificationsAndCompliance' },
          'op': { 'type': 'dropdownMulti', 'label': 'has none of', 'value': 'noneOf' },
          'value': [
            { 'label': 'gdpr', 'value': 'gdpr' }
          ]
        }
      ]
    },
    drillDownConfig: {
      fields: [ 'name', 'primaryOwner', 'certificationsAndCompliance', 'displayActiveUsersCount', 'score' ],
      sort: ['name:asc']
    },
    displayConfig: {
      label: 'Apps with privacy risk',
      metricFunc: METRIC_FUNC.TOTAL
    }
  },
  {
    id: 1736343293,
    type: WIDGET_TYPES.METRIC_WIDGET,
    entityType: ENTITY_TYPES.APPS,
    dataConfig: {
      filters: [
        {
          'key': { 'type': 'text', 'label': 'State', 'value': 'state' },
          'op': { 'type': 'dropdown', 'label': 'is', 'value': 'equals' },
          'value': { 'label': 'sanctioned', 'value': 'sanctioned' }
        },
        {
          'id': 1737905812210,
          'key': { 'type': 'user', 'label': 'Primary App Owner', 'value': 'primaryOwner' },
          'op': { 'label': 'is not set', 'value': 'isNotSet' }
        }
      ]
    },
    drillDownConfig: {
      fields: [ 'name', 'creationTime', 'displayActiveUsersCount', 'score', 'expenses' ],
      sort: ['name:asc']
    },
    displayConfig: {
      label: 'Apps without owners',
      metricFunc: METRIC_FUNC.TOTAL
    }
  },
  {
    id: 1736343294,
    type: WIDGET_TYPES.METRIC_WIDGET,
    entityType: ENTITY_TYPES.APPS,
    dataConfig: {
      filters: [
        {
          'id': 1737628364676,
          'key': { 'type': 'number', 'label': 'Number of used days', 'value': 'score' },
          'op': { 'type': 'number', 'label': 'is', 'value': 'equals' },
          'value': 0
        },
        {
          'key': { 'type': 'text', 'label': 'State', 'value': 'state' },
          'op': { 'type': 'dropdown', 'label': 'is', 'value': 'equals' },
          'value': { 'label': 'sanctioned', 'value': 'sanctioned' }
        }
      ]
    },
    drillDownConfig: {
      fields: [ 'name', 'primaryOwner', 'displayActiveUsersCount', 'expenses' ],
      sort: ['name:asc']
    },
    displayConfig: {
      label: 'Unused apps',
      metricFunc: METRIC_FUNC.TOTAL
    }
  },
  {
    id: 1736343295,
    type: WIDGET_TYPES.PIE_CHART_WIDGET,
    entityType: ENTITY_TYPES.APPS,
    dataConfig: {
      filters: [
        {
          'id': 1737649754498,
          'op': { 'type': 'dropdown', 'label': 'is not', 'value': 'notEquals' },
          'key': { 'type': 'text', 'label': 'State', 'value': 'state' },
          'value': { 'label': 'closed', 'value': 'closed' }
        },
        {
          'id': 1737650028499,
          'op': { 'type': 'bool', 'label': 'is', 'value': 'equals' },
          'key': { 'type': 'bool', 'label': 'Has integration', 'value': 'hasIntegration' },
          'value': { 'label': 'Yes', 'value': true }
        }
      ]

    },
    drillDownConfig: {
      fields: [ 'name', 'primaryOwner', 'displayActiveUsersCount', 'expenses', 'score' ],
      sort: ['name:asc']
    },
    displayConfig: {
      label: 'Potential Integrations connected',
      groupBy: {
        metricFunc: METRIC_FUNC.TOTAL,
        field: { systemKey: 'isConnected', type: formFieldTypes.bool, label: 'Is connected' },
        size: 5
      }
    }
  }
]

const demoDashboard: Omit<Dashboard, 'id'>[] = [{
  title: 'SaaS Inventory',
  description: 'Overview of your SaaS portfolio, app breakdown and usage.',
  widgets: [
    ...saasInventoryWidgets,
    {
      id: 1736343296,
      type: WIDGET_TYPES.METRIC_WIDGET,
      entityType: ENTITY_TYPES.USERS,
      dataConfig: {
        filters: [
          {
            'key': { 'label': 'Is Past User', 'value': 'isDeletedInIdentitySources', 'type': 'bool' },
            'op': { 'label': 'is', 'value': 'equals', 'type': 'dropdown' },
            'value': { 'label': 'No', 'value': false }
          },
          {
            'key': { 'label': 'User Type', 'value': 'userType', 'type': 'text' },
            'op': { 'label': 'is', 'value': 'equals', 'type': 'dropdown' },
            'value': { 'label': 'employee', 'value': 'employee' }
          }
        ]
      },
      drillDownConfig: {
        fields: ['fullName', 'activeAppsCount', 'annualCostConverted', 'additionalEmails'],
        sort: ['fullName:asc']
      },
      displayConfig: {
        label: 'Active employees (Not in MVP)',
        metricFunc: METRIC_FUNC.TOTAL
      }
    },
    {
      id: 1736343297,
      type: WIDGET_TYPES.METRIC_WIDGET,
      entityType: ENTITY_TYPES.USERS,
      dataConfig: {
        filters: [
          {
            'key': { 'label': 'Is External', 'value': 'isExternal', 'type': 'bool' },
            'op': { 'label': 'is', 'value': 'equals', 'type': 'dropdown' },
            'value': { 'label': 'No', 'value': false }
          },
          {
            'key': { 'label': 'Is Past User', 'value': 'isDeletedInIdentitySources', 'type': 'bool' },
            'op': { 'label': 'is', 'value': 'equals', 'type': 'dropdown' },
            'value': { 'label': 'Yes', 'value': true }
          }
        ]
      },
      drillDownConfig: {
        fields: ['firstName', 'lastName', 'email', 'isExternal', 'creationTime', 'idRole', 'idOrg', 'status', 'lifecycleStatus', 'isDeletedInIdentitySources', 'identitySourcesDeletionTime', 'activeAppsCount', 'photoUrl', 'annualCostConverted', 'additionalEmails'],
        sort: ['fullName:asc']
      },
      displayConfig: {
        label: 'Past users with app access (Not in MVP)',
        metricFunc: METRIC_FUNC.TOTAL
      }
    },
    {
      id: 1736343298,
      type: WIDGET_TYPES.METRIC_WIDGET,
      entityType: ENTITY_TYPES.USERS,
      dataConfig: {
        filters: [
          {
            'key': { 'label': 'Is past user', 'value': 'isDeletedInIdentitySources', 'type': 'bool' },
            'op': { 'label': 'is', 'value': 'equals', 'type': 'dropdown' },
            'value': { 'label': 'No', 'value': false }
          },
          {
            'key': { 'label': 'Is external', 'value': 'isExternal', 'type': 'bool' },
            'op': { 'label': 'is', 'value': 'equals', 'type': 'dropdown' },
            'value': { 'label': 'No', 'value': false }
          },
          {
            'key': { 'label': 'Number of apps used', 'value': 'activeAppsCount', 'type': 'number' },
            'op': { 'label': 'greater than', 'value': 'gt', 'type': 'number' },
            'value': 0
          },
          {
            'id': 1733221913007,
            'key': { 'label': 'Hibob Start Date', 'value': 'hibob_startDate', 'type': 'date', 'idApp': 3506 },
            'op': { 'label': 'is less than', 'value': 'relativeDateLess', 'type': 'relativeDate' },
            'value': { 'number': '90', 'type': { 'label': 'days ago', 'value': 'ago' } }
          }
        ]
      },
      drillDownConfig: {
        fields: ['firstName', 'lastName', 'email', 'isExternal', 'creationTime', 'idRole', 'idOrg', 'status', 'lifecycleStatus', 'isDeletedInIdentitySources', 'identitySourcesDeletionTime', 'activeAppsCount', 'photoUrl', 'annualCostConverted', 'additionalEmails'],
        sort: ['fullName:asc']
      },
      displayConfig: {
        label: 'Recent hires (Not in MVP)',
        metricFunc: METRIC_FUNC.TOTAL
      }
    },
    {
      id: 1736343299,
      type: WIDGET_TYPES.METRIC_WIDGET,
      entityType: ENTITY_TYPES.CONTRACTS,
      dataConfig: {
        filters: [{
          'key': { 'label': 'End date', 'value': 'endDate', 'type': 'date', 'idApp': null },
          'op': { 'label': 'is less than', 'value': 'relativeDateLess', 'type': 'relativeDate' },
          'value': { 'number': '30', 'type': { 'label': 'days from now', 'value': 'from' } }
        }]
      },
      drillDownConfig: {
        fields: ['name', 'createdBy', 'startDate', 'endDate'],
        sort: ['name:asc']
      },
      displayConfig: {
        label: 'Upcoming contract renewals (Not in MVP)',
        metricFunc: METRIC_FUNC.TOTAL
      }
    },
    {
      id: 17363432100,
      type: WIDGET_TYPES.METRIC_WIDGET,
      entityType: ENTITY_TYPES.APPS,
      dataConfig: {
        filters: []
      },
      drillDownConfig: {
        fields: ['name', 'category'],
        sort: ['name:asc']
      },
      displayConfig: {
        label: 'Applications last 12 months expenses Sum (Not in MVP)',
        metricFunc: METRIC_FUNC.SUM,
        metricField: { systemKey: 'expenses', type: formFieldTypes.currency, label: 'Expenses (last 12 months)' }
      }
    },
    {
      id: 17363432101,
      type: WIDGET_TYPES.PIE_CHART_WIDGET,
      entityType: ENTITY_TYPES.USERS,
      dataConfig: {
        filters: [
          {
            'key': { 'label': 'Is Past User', 'value': 'isDeletedInIdentitySources', 'type': 'bool' },
            'op': { 'label': 'is', 'value': 'equals', 'type': 'dropdown' },
            'value': { 'label': 'No', 'value': false }
          },
          {
            'key': { 'label': 'User Type', 'value': 'userType', 'type': 'text' },
            'op': { 'label': 'is', 'value': 'equals', 'type': 'dropdown' },
            'value': { 'label': 'employee', 'value': 'employee' }
          }
        ]
      },
      drillDownConfig: {
        fields: ['fullName', 'activeAppsCount', 'annualCostConverted', 'additionalEmails'],
        sort: ['fullName:asc']
      },
      displayConfig: {
        label: 'Active users by department (Not in MVP)',
        groupBy: {
          metricFunc: METRIC_FUNC.TOTAL,
          field: { systemKey: 'gsuite_department', type: formFieldTypes.singleLine, label: 'Google Workspace Department' },
          size: 5
        }
      }
    },
    {
      id: 17363432102,
      type: WIDGET_TYPES.PIE_CHART_WIDGET,
      entityType: ENTITY_TYPES.CONTRACTS,
      dataConfig: {
        filters: []
      },
      drillDownConfig: {
        fields: ['name', 'createdBy', 'startDate', 'endDate'],
        sort: ['name:asc']
      },
      displayConfig: {
        label: 'Contracts grouped by Status (Not in MVP)',
        groupBy: {
          metricFunc: METRIC_FUNC.TOTAL,
          field: { systemKey: 'status', type: formFieldTypes.dropdown, label: 'Status' },
          size: 5
        }
      }
    },
    {
      id: 17363432103,
      type: WIDGET_TYPES.BAR_CHART_WIDGET,
      entityType: ENTITY_TYPES.APPS,
      dataConfig: {
        filters: [
          {
            'key': { type: 'text', label: 'State', value: 'state' },
            'op': { type: 'dropdown', label: 'is not', value: 'notEquals' },
            'value': { label: 'closed', value: 'closed' }

          }
        ]
      },
      drillDownConfig: {
        fields: [ 'name', 'state', 'tags', 'displayActiveUsersCount', 'score', 'expenses', 'primaryOwner' ],
        sort: ['name:asc']
      },
      displayConfig: {
        label: 'Apps grouped by Tag (Not in MVP)',
        metricFunc: METRIC_FUNC.TOTAL,
        barOrientation: BAR_ORIENTATION.VERTICAL,
        groupBy: {
          field: { systemKey: 'tags', type: formFieldTypes.dropdownMulti, label: 'Tags' },
          size: 5
        }
      }
    },
    {
      id: 17363432104,
      type: WIDGET_TYPES.BAR_CHART_WIDGET,
      entityType: ENTITY_TYPES.APPS,
      dataConfig: {
        filters: [
          {
            'key': { type: 'text', label: 'State', value: 'state' },
            'op': { type: 'dropdown', label: 'is not', value: 'notEquals' },
            'value': { label: 'closed', value: 'closed' }

          }
        ]
      },
      drillDownConfig: {
        fields: [ 'name', 'state', 'category', 'displayActiveUsersCount', 'score', 'expenses', 'primaryOwner' ],
        sort: ['name:asc']
      },
      displayConfig: {
        label: 'Apps by category (Not in MVP)',
        metricFunc: METRIC_FUNC.TOTAL,
        barOrientation: BAR_ORIENTATION.HORIZONTAL,
        groupBy: {
          field: { systemKey: 'category', type: formFieldTypes.dropdown, label: 'Category' },
          size: 5
        }
      }
    },
    {
      id: 17363432105,
      type: WIDGET_TYPES.PIE_CHART_WIDGET,
      entityType: ENTITY_TYPES.APPS,
      dataConfig: {
        filters: []
      },
      drillDownConfig: {
        fields: ['name', 'category'],
        sort: ['name:asc']
      },
      displayConfig: {
        label: 'Applications group by Category total expenses (Not in MVP)',
        groupBy: {
          field: { systemKey: 'category', type: formFieldTypes.dropdown, label: 'Category' },
          size: 5,
          metricFunc: METRIC_FUNC.SUM,
          metricField: { systemKey: 'expenses', type: 'currency', label: 'Expenses (last 12 months)' }
        }
      }
    },
    {
      id: 17363432106,
      type: WIDGET_TYPES.PIE_CHART_WIDGET,
      entityType: ENTITY_TYPES.USERS,
      dataConfig: {
        filters: [
          {
            'key': { 'label': 'Is Past User', 'value': 'isDeletedInIdentitySources', 'type': 'bool' },
            'op': { 'label': 'is', 'value': 'equals', 'type': 'dropdown' },
            'value': { 'label': 'No', 'value': false }
          },
          {
            'key': { 'label': 'User Type', 'value': 'userType', 'type': 'text' },
            'op': { 'label': 'is', 'value': 'equals', 'type': 'dropdown' },
            'value': { 'label': 'employee', 'value': 'employee' }
          }
        ]
      },
      drillDownConfig: {
        fields: ['fullName', 'activeAppsCount', 'annualCostConverted', 'additionalEmails'],
        sort: ['fullName:asc']
      },
      displayConfig: {
        label: 'The total cost of active users by department (Not in MVP)',
        groupBy: {
          field: { systemKey: 'gsuite_department', type: formFieldTypes.singleLine, label: 'Google Workspace Department' },
          size: 5,
          metricFunc: METRIC_FUNC.SUM,
          metricField: { systemKey: 'annualCostConverted', type: 'currency', label: 'Annual cost' }
        }
      }
    },

    {
      id: 17363432107,
      type: WIDGET_TYPES.PIE_CHART_WIDGET,
      entityType: ENTITY_TYPES.CONTRACTS,
      dataConfig: {
        filters: []
      },
      drillDownConfig: {
        fields: ['name', 'createdBy', 'startDate', 'endDate'],
        sort: ['name:asc']
      },
      displayConfig: {
        label: 'The total amount of contracts grouped by Status (Not in MVP)',
        groupBy: {
          field: { systemKey: 'status', type: formFieldTypes.dropdown, label: 'Status' },
          size: 5,
          metricFunc: METRIC_FUNC.SUM,
          metricField: { systemKey: 'amount', type: 'currency', label: 'Contract value' }
        }
      }
    }
  ],
  layoutConfig: [
    {
      'h': 1,
      'i': 1736343285,
      'w': 3,
      'x': 0,
      'y': 0
    },
    {
      'h': 1,
      'i': 1736343286,
      'w': 3,
      'x': 3,
      'y': 0
    },
    {
      'h': 1,
      'i': 1736343287,
      'w': 3,
      'x': 6,
      'y': 0
    },
    {
      'h': 1,
      'i': 1736343288,
      'w': 3,
      'x': 9,
      'y': 0
    },
    {
      'h': 3,
      'i': 1736343289,
      'w': 6,
      'x': 0,
      'y': 1
    },
    {
      'h': 3,
      'i': 1736343290,
      'w': 6,
      'x': 6,
      'y': 1
    },
    {
      'h': 1,
      'i': 1736343291,
      'w': 3,
      'x': 6,
      'y': 4
    },
    {
      'h': 1,
      'i': 1736343292,
      'w': 3,
      'x': 9,
      'y': 5
    },
    {
      'h': 1,
      'i': 1736343293,
      'w': 3,
      'x': 6,
      'y': 5
    },
    {
      'h': 1,
      'i': 1736343294,
      'w': 3,
      'x': 9,
      'y': 4
    },
    {
      'h': 3,
      'i': 1736343295,
      'w': 6,
      'x': 0,
      'y': 4
    },
    {
      'h': 1,
      'i': 1736343296,
      'w': 3,
      'x': 6,
      'y': 12
    },
    {
      'h': 1,
      'i': 1736343297,
      'w': 3,
      'x': 9,
      'y': 11
    },
    {
      'h': 1,
      'i': 1736343298,
      'w': 3,
      'x': 9,
      'y': 12
    },
    {
      'h': 1,
      'i': 1736343299,
      'w': 3,
      'x': 6,
      'y': 11
    },
    {
      'h': 1,
      'i': 17363432100,
      'w': 6,
      'x': 6,
      'y': 10
    },
    {
      'h': 3,
      'i': 17363432101,
      'w': 6,
      'x': 0,
      'y': 16
    },
    {
      'h': 3,
      'i': 17363432102,
      'w': 6,
      'x': 0,
      'y': 10
    },
    {
      'h': 3,
      'i': 17363432103,
      'w': 6,
      'x': 6,
      'y': 16
    },
    {
      'h': 3,
      'i': 17363432104,
      'w': 12,
      'x': 0,
      'y': 7
    },
    {
      'h': 3,
      'i': 17363432105,
      'w': 6,
      'x': 0,
      'y': 13
    },
    {
      'h': 3,
      'i': 17363432106,
      'w': 6,
      'x': 6,
      'y': 13
    },
    {
      'h': 3,
      'i': 17363432107,
      'w': 12,
      'x': 0,
      'y': 19
    }
  ]
}]

export default demoDashboard
