import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import * as Style from './style'
import moment from 'moment'
import ReportHeader from '../../components/reportHeader'
import Table from '../table'
import { EMPTY_ARRAY, EMPTY_OBJECT, ITEMS_PER_PAGE, SCOPES, TABLES,
  INACTIVE_USERS_REPORT_TABLE_CUSTOM_SELECT_OPTIONS, EXPORT_INACTIVE_USERS_CSV } from '../../constants'
import UserDetails from '../userDetails'
import AppDetails from '../../components/appDetails'
import pluralize from 'pluralize'
import { sortStringFromArray } from '@shared/utils'
import { debounce, isNil } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentOrg } from '@selectors/org'
import { getUserPreferences } from '@selectors/ui'
import { getInactiveUsersInfo, getInactiveUsersReportData } from '@selectors/users'
import { getInactiveUsers } from '@actions/'
import InaccessiblePage from '@pages/inaccessiblePage/inaccessiblePage'
import VisibleFor from '@components/visibleFor'
import { getFormattedDate } from '@lenses/utils'
import Currency from '../currency'
import useEffectOnce from '@shared/hooks/useEffectOnce'
import AccessControl from '@lenses/accessControl'
import config from '@root/config'

const InactiveUsersReport = withRouter(({ match }) => {
  const dispatch = useDispatch()

  const { id: idOrg = parseInt(match.params.idOrg, 10) } = useSelector(getCurrentOrg)
  const userPreferences = useSelector(getUserPreferences)
  const { defaultSort = EMPTY_ARRAY, defaultCustomSelectOption } = userPreferences[TABLES.inactiveUsersReportV2Table.key] || EMPTY_OBJECT
  const users = useSelector(getInactiveUsersReportData)
  const { loading: isLoading, loadingMore, isLoaded, total } = useSelector(getInactiveUsersInfo)
  const loading = !isLoaded || isLoading

  const hasLicenseCostAndChargebackAccess = AccessControl.useIsAccessible({ scopes: [SCOPES.LICENSE_AND_CHARGEBACK_READ] })

  const [searchQuery, setSearchQuery] = useState('')
  const [customSelectOption, setCustomSelectOption] = useState(defaultCustomSelectOption)

  const getUsers = async ({ limit = ITEMS_PER_PAGE, offset = 0, sort = defaultSort, q = searchQuery, reset = false, filters = customSelectOption.filters }) => {
    await dispatch(getInactiveUsers({ idOrg, limit, offset, sort: sortStringFromArray(sort), q, reset, filters }))
  }

  useEffectOnce(() => {
    getUsers({ offset: 0, reset: true })
  })

  const onSortedChange = debounce((sort) => {
    getUsers({ sort, reset: true })
  }, 250)

  const onSearch = debounce((q) => {
    setSearchQuery(q)
    getUsers({ reset: true, q })
  }, 500)

  const fetchData = () => {
    getUsers({ offset: users.length, reset: false })
  }

  const onCustomSelectOptionChange = async (option) => {
    setCustomSelectOption(option)
    getUsers({ reset: true, filters: option.filters })
  }

  const columns = [
    {
      Header: 'Name',
      id: 'fullName',
      accessor: ({ firstName, lastName, email }) => [firstName, lastName, email].join(' ').trim().toLowerCase(),
      Cell: ({ row: { firstName, lastName, idUser, email, isExternal, photoUrl, isDeletedInIdentitySources } }) => {
        return <UserDetails
          idUser={idUser}
          firstName={firstName}
          lastName={lastName}
          email={email}
          isExternal={isExternal}
          photoUrl={photoUrl}
          isDeletedInIdentitySources={isDeletedInIdentitySources}
          showPastUserBadge
        />
      },
      minWidth: 220,
      maxWidth: 300
    },
    {
      Header: 'Is past user',
      accessor: 'isDeletedInIdentitySources',
      Cell: ({ value: isDeletedInIdentitySources }) => isDeletedInIdentitySources ? 'Yes' : 'No'
    },
    {
      Header: 'Email',
      accessor: 'originEmail',
      show: true,
      Cell: ({ original: { originEmail } }) => originEmail
    },
    {
      Header: 'App',
      id: 'appName',
      accessor: 'name',
      minWidth: 125,
      Cell: ({ value: name, row: { idApp, category, imageUrl } }) => (
        <AppDetails
          id={idApp}
          name={name}
          category={category}
          imageUrl={imageUrl}
          component='Inactive users report'
        />
      )
    },
    {
      Header: 'Licenses',
      id: 'licenses',
      accessor: 'licenses',
      Cell: ({ row: { licenses = [] } }) => {
        if (!licenses.length) {
          return '-'
        }
        return licenses.map(license => <div key={license.type}>{license.name}</div>)
      },
      width: 200,
      sortable: false
    },
    {
      Header: 'Annual Cost',
      id: 'annualCostConverted',
      accessor: 'annualCostConverted',
      Cell: ({ value }) => {
        return isNil(value) ? '-' : <Currency value={value} />
      },
      width: 180,
      textValue: ({ value }) => isNil(value) ? '' : value,
      sortMethod: Table.sortMethods.currency,
      show: hasLicenseCostAndChargebackAccess
    },
    {
      Header: 'Last used',
      accessor: 'lastVisitTime',
      Cell: ({ value: lastVisitTime }) => getFormattedDate({ date: lastVisitTime }),
      minWidth: 220,
      maxWidth: 300
    },
    {
      Header: 'Days since last used',
      accessor: 'lastVisitTime',
      id: 'days',
      sortable: false,
      Cell: ({ value: lastVisitTime }) => {
        if (!lastVisitTime) {
          return '-'
        }

        const days = moment().diff(lastVisitTime, 'days')
        return `${pluralize('day', days, true)} ago`
      },
      minWidth: 220,
      maxWidth: 300
    },
    {
      accessor: 'firstName',
      show: false
    },
    {
      accessor: 'lastName',
      show: false
    },
    {
      accessor: 'email',
      show: false
    },
    {
      accessor: 'idUser',
      show: false
    },
    {
      accessor: 'idApp',
      show: false
    },
    {
      accessor: 'category',
      show: false
    },
    {
      accessor: 'imageUrl',
      show: false
    },
    {
      accessor: 'isExternal',
      show: false
    },
    {
      accessor: 'photoUrl',
      show: false
    },
    {
      accessor: 'isDeletedInIdentitySources',
      show: false
    }
  ]

  const searchFilterMethod = (row, search) => {
    const values = [
      [row.firstName, row.lastName].join(' '),
      row.email
    ]
    return values.some(value => value && value.toLowerCase().includes(search))
  }

  const subtitle = <div>View users who did not engage with their apps in the defined inactivity period.<br />This report is taking into account all usage sources including direct integration, SSO logins and Browser extension usage.<br />When a user has multiple licenses for the same app he will be considered inactive only if he didn’t use any of them.</div>

  const exportToCsv = ({ sort, query }) => {
    dispatch({ type: EXPORT_INACTIVE_USERS_CSV })

    const sortParam = 'sort=' + sort.map(s => `${s.id}:${s.desc ? 'desc' : 'asc'}`).join(',')
    const queryParam = `q=${query}`
    const filtersParam = `filters=${encodeURIComponent(JSON.stringify(customSelectOption.filters))}`

    const url = `${config.apiBaseUrl}/api/orgs/${idOrg}/users/inactive/csv?${sortParam}&${queryParam}&${filtersParam}`
    const newWindow = window.open(url, '_blank')
    newWindow.opener = null
  }

  return (
    <VisibleFor scopes={[SCOPES.USERS_READ]} fallback={<InaccessiblePage />}>
      <div {...Style.Wrapper}>
        <ReportHeader title='Inactive users' subtitle={subtitle} />
        <Table
          tableKey={TABLES.inactiveUsersReportV2Table.key}
          data={users}
          columns={columns}
          loading={loading}
          loadingMore={loadingMore}
          exportable
          exportFunction={exportToCsv}
          fetchData={fetchData}
          manual
          onSortedChangeCB={onSortedChange}
          onSearch={onSearch}
          searchable
          searchFilterMethod={searchFilterMethod}
          forceShowSearch
          totalCount={total}
          forceShowNumberOfResults
          customSelect
          customSelectOptions={Object.values(INACTIVE_USERS_REPORT_TABLE_CUSTOM_SELECT_OPTIONS)}
          customSelectSelectedValue={customSelectOption}
          customSelectOnChange={onCustomSelectOptionChange}
        />
      </div>
    </VisibleFor>
  )
})

export default InactiveUsersReport
