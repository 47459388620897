import styled from 'styled-components'
import colors from '@shared/style/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`

export const HeaderLine = styled.div`
  height: 32px;
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
`

export const ActionsLine = styled.div`
  height: 32px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const WorkflowNameHeaderContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`

export const WorkflowNameContainer = styled.div`
  flex: 1;
  max-width: 600px;
  display: flex;
  align-items: center;
  input {
    text-align: center;
    font: ${({ theme }) => theme.typography.font.header04};
    color: ${({ theme }) => theme.palette.text.primary};
  }
`

export const HeaderSide = styled.div`
  flex: 0 0 150px;
`

export const ActivationToggleContainer = styled.div`
  font: ${({ theme }) => theme.typography.font.body02};
  color: ${({ theme }) => theme.palette.text.primary};
  display: flex;
  align-items: center;
  gap: 12px;
`

export const HiddenWorkflowTitle = styled.div`
  font-size: 14px;
  background-color: ${colors.lightBlue1};
  padding: 1px 5px;
`

export const NavigationToWorkflowExecutions = styled.div`
  margin-right: auto;
`

export const ThrottledWorkflowsLearnMore = styled.span`
  margin-left: ${({ theme }) => theme.spacing('space-050')}; 
`
