import { theme } from '@toriihq/design-system'

export const Popup = {
  modal: {
    width: '100%',
    maxHeight: '756px'
  }
}

export const PopupContent = {
  padding: theme.spacing('space-300')
}

export const Thead = {
  color: theme.palette.text.secondary
}
