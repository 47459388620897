import { type Config } from '../types'

const config: Config = {
  theme: 'light',
  fullstory: {
    enabled: true,
    fullstoryOrg: '2X085',
    exclude: ['16404', '16405']
  },
  segment: {
    enabled: true,
    token: 'n2wZhQK7CpKmNPfYnh8WbUxjTbhWJGJr',
    orgsOverrideIntegrationConfig: {
      16404: {
        Slack: false,
        'Google Analytics': false,
        Mixpanel: false,
        Snowflake: false
      },
      16405: {
        Slack: false,
        'Google Analytics': false,
        Mixpanel: false,
        Snowflake: false
      }
    }
  },
  googleAnalytics: {
    enabled: true,
    measurementId: 'G-VVQLGK39RG'
  },
  proxyApiRequests: true,
  webBaseUrl: `https://${process.env.REACT_APP_ROOT_DOMAIN || 'toriihq.com'}`,
  apiBaseUrl: `https://${process.env.REACT_APP_API_DOMAIN || 'api.toriihq.com'}`,
  appBaseUrl: `https://${process.env.REACT_APP_TORII_DOMAIN_APP || 'app.toriihq.com'}`,
  catalogBaseUrl: `https://${process.env.REACT_APP_TORII_DOMAIN_CATALOG || 'catalog.toriihq.com'}`,
  features: {},
  azure: {
    clientId: '162a033b-7435-4ba7-ba7b-b4db768d34c6'
  },
  hubSpot: {
    trialForm: {
      portalId: '4265482',
      formId: '5db0b537-cf9e-404d-ab78-ca128fb640e2'
    }
  },
  facebook: {
    pixelId: '1913188995595949'
  },
  recaptcha: {
    sitekey: '6LcJ_bEZAAAAAJdsFKwFsiNTWh2osDCfQ9rM5Iy_'
  },
  churnzero: {
    enabled: true
  },
  zendesk: {
    enabled: false
  }
}

export default config
