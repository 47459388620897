import {
  GET_DASHBOARDS,
  GET_DATA_FOR_WIDGET,
  GET_DRILL_DOWN_DATA_FOR_WIDGET,
  UPDATE_DASHBOARD
} from '@root/constants'
import { Action, ActionTypeStatus } from '@reducers/types'
import { IdDashboard } from '@store/types'

export enum ENTITY_TYPES {
    USERS= 'users',
    APPS='apps',
    CONTRACTS= 'contracts'
}

export enum WIDGET_TYPES {
    METRIC_WIDGET= 'metricWidget',
    PIE_CHART_WIDGET= 'pieChartWidget',
    BAR_CHART_WIDGET= 'barChartWidget'
}

export enum AGGREGATION_TYPES {
    METRIC = 'metric',
    GROUP_BY = 'groupBy'
}

export enum METRIC_FUNC {
    TOTAL= 'total',
    MIN= 'min',
    MAX ='max',
    AVG = 'avg',
    SUM = 'sum'
}

export type FIELD = {
    systemKey: string
    type: string
    label: string
}

export enum BAR_ORIENTATION {
    VERTICAL = 'vertical',
    HORIZONTAL = 'horizontal'
}

export enum AGGREGATION_SORT_ORDERS {
    DESC = 'desc',
    ASC = 'asc'
}

export type Widget = {
    id: number
    entityType: ENTITY_TYPES
    type: WIDGET_TYPES
    dataConfig: {
      filters: object[]
    }
    drillDownConfig: {
      fields: string[]
      sort: string[]
    }
    displayConfig: {
        label: string
        groupBy?: {
          field: FIELD,
          size: number,
          metricFunc?: METRIC_FUNC,
          metricField?: FIELD
          groupBy?: Widget['displayConfig']['groupBy']
        }
        metricFunc?: METRIC_FUNC
        metricField?: FIELD
        barOrientation?: BAR_ORIENTATION
    }
}

type LayoutConfig = {
  i: number
  x: number
  y: number
  w: number
  h: number
}

export type Dashboard = {
  title: string
  description: string
  id: IdDashboard
  widgets: Widget[]
  layoutConfig: LayoutConfig[]
}

export type WidgetData = {
    data: {
        loading: boolean,
        total: number
        resources: { users?: object[], apps?: object[], contracts?: object[], fields?: object[] }
        aggregations: Array<{ key: string, value: number }> | number
    }
    drillDownData?: {
        loading: boolean
        total?: number
        rows?: object[]
        resources?: { fields?: object[] }
    }
}

export type DashboardData = {
    widgetsData: Record<Widget['id'], WidgetData>
}

export type DashboardsState = {
    isLoading: boolean,
    dashboardsConfig: Dashboard[],
    dashboardsData: Record<Dashboard['id'], DashboardData>
}

type GetDashboards = Action<
    `${typeof GET_DASHBOARDS}_${ActionTypeStatus}`,
    { dashboards: DashboardsState['dashboardsConfig'] }
>

type UpdateDashboard = Action<
    `${typeof UPDATE_DASHBOARD}_${ActionTypeStatus}`,
    { dashboard: Dashboard },
    { idDashboard: IdDashboard, dashboard: Dashboard }
>

type GetDataForWidget = Action<
    `${typeof GET_DATA_FOR_WIDGET}_${ActionTypeStatus}`,
    { total: number, aggregations: any, resources: any },
    { idDashboard: IdDashboard, idWidget: number, entityType: string }
>

type GetDrillDownDataForWidget = Action<
    `${typeof GET_DRILL_DOWN_DATA_FOR_WIDGET}_${ActionTypeStatus}`,
    { rows: any, total: number, resources: any },
    { idDashboard: IdDashboard, idWidget: number, entityType: string }
>

export type WidgetsActions =
    | GetDataForWidget
    | GetDashboards
    | UpdateDashboard
    | GetDrillDownDataForWidget
