import React from 'react'
import { NewCurrencyCell } from '@components/table/columnFieldTypes'
import AppDetails from '@components/appDetails'
import { bucketDisplayName, bucketSummarySortOrder, RenewalBuckets } from '@components/renewalsSummary/utils/utils'
import { Button, ButtonSize, ButtonType, Icon, Spacer, theme, Tooltip, StatusDot } from '@toriihq/design-system'
import Table from '@components/table'
import numeral from 'numeral'
import { ContractYearColumnHeaderContainer } from '@components/renewalsSummary/renewalsListTable/styles'
import Analytics from '@components/renewalsSummary/analytics'

const BUCKETS_NOT_SHOW_DIFFERENCE = [RenewalBuckets.notRenewedYet, RenewalBuckets.missingContract]
const showDiff = (bucket) => !BUCKETS_NOT_SHOW_DIFFERENCE.includes(bucket)

const BucketCell = ({ value: bucket }) => (
  <div>{bucketDisplayName[bucket]}</div>
)

export const ColoredCell = ({ value, type = 'currency', valueInCents = false }) => {
  let color = theme.palette.text.primary
  if (value < 0) {
    color = theme.palette.text.success
  }

  let content
  if (type === 'currency') {
    content = <NewCurrencyCell value={value} format={`${value > 0 ? '+' : ''}$0,`} />
  } else if (type === 'percentage') {
    content = numeral(value).format(`${value > 0 ? '+' : ''}0,0%`)
  }

  return (
    <div style={{ color: color }}>
      {content}
    </div>
  )
}

const AppDetailsCell = ({ original: { idApp } }, appsById) => {
  const app = appsById[idApp] || {}
  return <AppDetails
    id={app.id}
    name={app.name}
    category={app.category}
    imageUrl={app.imageUrl}
    component='Contracts list'
  />
}

const RelatedContractsCell = ({ value, contractsById, openContractPopup }) => {
  const contractsButtons = value.map(idContract => {
    const contract = contractsById[idContract]
    return (
      <Button
        type={ButtonType.compact}
        size={ButtonSize.small}
        onClick={() => {
          Analytics.openContract()
          openContractPopup(idContract)
        }}
        label={contract?.name} />
    )
  })
  return (
    <div>
      {contractsButtons.reduce((prev, curr, index) => {
        return index === 0 ? [curr] : [...prev, ', ', curr]
      }, [])}
    </div>
  )
}

const YearContractValueHeader = ({ year }) => (
  <ContractYearColumnHeaderContainer >
    <span>{year}</span>
    <Spacer left={'space-050'}>
      <Tooltip
        label={`${year} Annualized contract value`}
        maxWidth={300}>
        <Spacer left='space-025' right='space-025'>
          <Icon name='Info' />
        </Spacer>
      </Tooltip>
    </Spacer>
  </ContractYearColumnHeaderContainer>
)

const getColumns = ({ baseYear, appsById, stateInfo, contractsById, openContractPopup }) =>
  [
    {
      Header: 'Application',
      accessor: 'appName',
      minWidth: 200,
      maxWidth: 300,
      textHeader: 'Application Name',
      Cell: (props) => AppDetailsCell(props, appsById),
      style: {
        paddingLeft: '5px'
      },
      sortMethod: (apNameA, appNameB) => {
        return apNameA.localeCompare(appNameB)
      }
    },
    {
      Header: 'App state',
      accessor: 'appState',
      width: 200,
      textHeader: 'App State',
      Cell: ({ value }) => {
        const state = stateInfo && stateInfo.options.find(o => o.value === value)
        return state ? (
          <div style={{ marginLeft: '-8px' }}>
            <StatusDot {...state} />
          </div>
        ) : null
      },
      sortMethod: (a, b) => a.localeCompare(b)
    },
    {
      Header: 'Bucket',
      accessor: 'bucket',
      width: 150,
      Cell: BucketCell,
      sortMethod: (a, b) => bucketSummarySortOrder[a.bucket] - bucketSummarySortOrder[b.bucket],
      textValue: ({ value }) => bucketDisplayName[value]
    },
    {
      Header: YearContractValueHeader({ year: baseYear - 1 }),
      textHeader: `${baseYear - 1}`,
      accessor: 'previousYearAnnualValue',
      width: 100,
      Cell: ({ value }) => NewCurrencyCell({ value }),
      textValue: ({ value }) => (value / 100).toFixed(2),
      ...Table.numericFieldProps
    },
    {
      Header: YearContractValueHeader({ year: baseYear }),
      textHeader: `${baseYear}`,
      accessor: 'baseYearAnnualValue',
      width: 100,
      Cell: ({ value }) => NewCurrencyCell({ value }),
      textValue: ({ value }) => (value / 100).toFixed(2),
      ...Table.numericFieldProps
    },
    {
      Header: 'Value difference',
      accessor: 'diffWithPreviousYear',
      width: 150,
      Cell: ({ value, original }) => showDiff(original.bucket) ? ColoredCell({ value, type: 'currency', valueInCents: true }) : '-',
      textValue: ({ value, bucket }) => showDiff(bucket) ? Number((value / 100).toFixed(2)) : '-',
      ...Table.numericFieldProps
    },
    {
      Header: 'Value difference %',
      width: 150,
      accessor: 'diffWithPreviousYearPercentage',
      Cell: ({ value, original }) => showDiff(original.bucket) ? ColoredCell({ value, type: 'percentage' }) : '-',
      textValue: ({ value, bucket }) => showDiff(bucket) ? Number((value * 100).toFixed(2)) : '-',
      ...Table.numericFieldProps
    },
    {
      Header: 'Related contracts',
      accessor: 'idContracts',
      width: 400,
      Cell: ({ value }) => RelatedContractsCell({ value, contractsById, openContractPopup })
    }
  ]

export default getColumns
