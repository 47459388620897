import React from 'react'
import { Body2, Card, Divider, Stack, Subtitle2 } from '@toriihq/design-system'
import { FullWidth } from '@pages/renewalForecast/recommendation/style'
import { LicenseToPurchaseCardProps } from '@pages/renewalForecast/recommendation/types'

const LicenseToPurchaseCard = ({
  activeAmount,
  bufferAmount,
  bufferValue
}: LicenseToPurchaseCardProps): JSX.Element => {
  return <Card>
    <FullWidth>
      <Stack gap={'space-600'} direction={'column'}>
        <Stack gap={'space-150'} direction={'column'}>
          <Subtitle2>
            Licenses to purchase
          </Subtitle2>
          <Stack gap={'space-200'} direction={'row'} justifyContent={'space-between'}>
            <Body2 color='secondary'>
              Active
            </Body2>
            <Body2>
              {activeAmount}
            </Body2>
          </Stack>
          <Stack gap={'space-200'} direction={'row'} justifyContent={'space-between'}>
            <Body2 color='secondary'>
              {`+ Buffer (${bufferValue}%)`}
            </Body2>
            <Body2>
              {bufferAmount}
            </Body2>
          </Stack>
        </Stack>
        <Stack gap={'space-200'} direction={'column'}>
          <FullWidth>
            <Divider orientation={'Vertical'} />
          </FullWidth>
          <Stack gap={'space-200'} direction={'row'} justifyContent={'space-between'}>
            <Subtitle2>
              Total
            </Subtitle2>
            <Subtitle2>
              {bufferAmount + activeAmount}
            </Subtitle2>
          </Stack>
        </Stack>
      </Stack>
    </FullWidth>
  </Card>
}

export default LicenseToPurchaseCard
