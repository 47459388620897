export enum INACTIVE_USERS_TABLE_COLUMNS {
  LICENSE_NAME = 'licenseName',
  FULL_NAME = 'fullName',
  ORIGIN_EMAIL = 'originEmail',
  REASON = 'reason',
  LAST_USAGE_TIME = 'lastUsageTime',
  PRICE_PER_USER = 'pricePerUser'
}

export enum INACTIVE_USERS_TABLE_COLUMN_TITLES {
  LICENSE_NAME = 'License',
  FULL_NAME = 'Assigned To',
  ORIGIN_EMAIL = 'Email',
  REASON = 'Reason',
  LAST_USAGE_TIME = 'Last used',
  PRICE_PER_USER = 'Annual cost per license'
}

export enum USERS_TABLE_COLUMNS {
  FULL_NAME = 'fullName',
  ACTIVE_APPS_COUNT = 'activeAppsCount',
  ANNUAL_COST_CONVERTED = 'annualCostConverted',
  USER_TYPE = 'userType',
  ADDITIONAL_EMAILS = 'additionalEmails',
  NUMBER_OF_OWNED_APPS = 'numberOfOwnedApps',
  IS_PAST_USER = 'isDeletedInIdentitySources',
  IS_APP_OWNER = 'isAppOwner'
}

export enum USERS_TABLE_COLUMN_TITLES {
  FULL_NAME = 'Name',
  ACTIVE_APPS_COUNT = 'Apps used',
  ANNUAL_COST_CONVERTED = 'Annual cost',
  USER_TYPE = 'User type',
  ADDITIONAL_EMAILS = 'Additional emails',
  NUMBER_OF_PRIMARY_OWNED_APPS = 'Number of owned apps',
  IS_PAST_USER = 'Is past user',
  IS_APP_OWNER = 'Is app owner'
}
