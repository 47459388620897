import React from 'react'
import { Stack } from '@toriihq/design-system'
import { useSelector } from 'react-redux'
import { getDisplayedWorkflowExecutionUsersById } from '@store/selectors/workflows'
import { TriggerDetailsWrapper, Wrapper } from './styles'
import { WORKFLOW_EXECUTION_STATUS } from '@root/constants.t'
import { WorkflowExecution } from './types'
import StoppedInfo from './stoppedInfo'
import { IdUser } from '@store/types'
import TriggerDetails from './triggerDetails'

interface Props {
  workflowExecution: WorkflowExecution
}

const ExecutionInfo = ({
  workflowExecution
}: Props) => {
  const executionUsersById = useSelector(getDisplayedWorkflowExecutionUsersById)

  const stoppedByIdUser: IdUser | null = workflowExecution?.metadata?.stoppedBy
  const stoppedByUser = stoppedByIdUser && executionUsersById[stoppedByIdUser]
  const isStopped = workflowExecution.status === WORKFLOW_EXECUTION_STATUS.STOPPED

  return (
    <Stack direction='column' gap={'space-0'}>
      <Wrapper>
        <TriggerDetailsWrapper>
          <TriggerDetails
            workflowExecution={workflowExecution}
            idTriggerUsersToParents={executionUsersById} />
        </TriggerDetailsWrapper>
        {isStopped && stoppedByUser &&
        <StoppedInfo
          stoppedByIdUser={stoppedByIdUser}
          stoppedByUser={stoppedByUser}
          stoppedTime={workflowExecution.stoppedTime}
        />}
      </Wrapper>
    </Stack>
  )
}

export default ExecutionInfo
