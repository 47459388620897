import { WorkflowEditorState as State } from './types'
import noop from 'lodash/noop'

export const defaultState: State = {
  idNewNode: null,
  setIdNewNode: noop,
  isAppCatalogPoliciesView: false,
  isRequestNewAppPoliciesView: false,
  isPreviewMode: false,
  idAudit: null,
  idWorkflow: null
}
