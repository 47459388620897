import React from 'react'
import ToriiToggle from '@components/toriiToggle'
import moment from 'moment'
import capitalize from 'lodash/capitalize'
import { TextBadge, Tooltip } from '@toriihq/design-system'
import { EMPTY_OBJECT, SYNC_STATUS } from '@root/constants'
import LinkableAvatar from '@components/_shared/linkableAvatar'
import ScheduledReportsActions from '@components/scheduledReportsActions'
import { hoverActions } from '@shared/style/mixins'
import { css } from 'glamor'
import { getFormattedDate } from '@lenses/utils'
import { Time } from '@components/scheduledReportsTable/styles'

export interface Column {
  Header?: string
  accessor?: string | any
  minWidth?: number
  Cell?: ({ value, original, row }: { value: any, original: any, row: any }) => any
  sortMethod?: (a: any, b: any) => any
  show?: boolean
  maxWidth?: number
  id?: string
  sortable?: boolean
  className?: string
}

const dateTimeColumn = {
  Cell: ({ value }) => value ? (
    <div>
      <div>{getFormattedDate({ date: value })}</div>
      <Time>{moment.utc(value).format('h:mm A')}</Time>
    </div>
  ) : '-',
  sortable: true
}

export const getColumns = ({ usersMap = EMPTY_OBJECT, handleEnableToggle, handleDownloadAction, handleEditAction, handleDeleteAction, columnsConfiguration }) => [
  {
    Header: 'Report name',
    accessor: 'reportName',
    minWidth: 213
  },
  {
    Header: 'Frequency',
    id: 'scheduleFrequency',
    idForColumnsOrder: 'scheduleDetails.scheduleFrequency',
    accessor: ({ scheduleDetails }) => scheduleDetails?.scheduleFrequency ?? null,
    Cell: ({ value }) => {
      return (
        <div>
          {capitalize(value)}
        </div>
      )
    },
    sortMethod: (a, b) => a.localeCompare(b),
    show: Boolean(columnsConfiguration['scheduleDetails.scheduleFrequency'])
  },
  {
    Header: 'Last run',
    id: 'lastTimeSent',
    accessor: ({ lastTimeSent }) => lastTimeSent ? moment.utc(lastTimeSent).toDate().getTime() : null,
    Cell: ({ value: date }) => date && moment.utc(date).fromNow(),
    show: Boolean(columnsConfiguration['lastTimeSent'])
  },
  {
    Header: 'Last status',
    accessor: 'status',
    Cell: ({ value: status, original }) => {
      if (!status) {
        return '-'
      }

      const { errorMessage } = original
      const statusMap: Record<string, { color: 'success' | 'error', text: string }> = {
        [SYNC_STATUS.FINISHED_SUCCESSFULLY]: { color: 'success', text: 'Completed' },
        [SYNC_STATUS.FINISHED_FAILED]: { color: 'error', text: 'Error' }
      }

      const { color = 'gray', text = 'In progress' } = statusMap[status] || {}

      return (
        errorMessage
          ? <Tooltip label={errorMessage ?? null}>
            <TextBadge color={color} size='Small'>{text}</TextBadge>
          </Tooltip>
          : <TextBadge color={color} size='Small'>{text}</TextBadge>
      )
    },
    show: Boolean(columnsConfiguration['status'])
  },
  {
    Header: 'Next schedule (UTC)',
    id: 'nextScheduledTime',
    accessor: ({ nextScheduledTime }) => nextScheduledTime ? moment.utc(nextScheduledTime).toDate().getTime() : null,
    ...dateTimeColumn,
    show: Boolean(columnsConfiguration['nextScheduledTime'])
  },
  {
    Header: 'Created by',
    accessor: 'createdBy',
    Cell: ({ value: createdBy }) => {
      const user = usersMap[createdBy] || {}
      return <LinkableAvatar user={user} />
    },
    maxWidth: 130,
    show: Boolean(columnsConfiguration['createdBy'])
  },
  {
    Header: 'Created on (UTC)',
    id: 'creationTime',
    accessor: ({ creationTime }) => creationTime ? moment.utc(creationTime).toDate().getTime() : null,
    ...dateTimeColumn,
    maxWidth: 250,
    show: Boolean(columnsConfiguration['creationTime'])
  },
  {
    Header: 'Last updated by',
    accessor: 'lastUpdatedBy',
    Cell: ({ value: lastUpdatedBy }) => {
      const user = usersMap[lastUpdatedBy] || {}
      return <LinkableAvatar user={user} />
    },
    maxWidth: 130,
    show: Boolean(columnsConfiguration['lastUpdatedBy'])
  },
  {
    Header: 'Last updated on (UTC)',
    id: 'lastUpdatedOn',
    accessor: ({ lastUpdatedOn }) => lastUpdatedOn ? moment.utc(lastUpdatedOn).toDate().getTime() : null,
    ...dateTimeColumn,
    maxWidth: 250,
    show: Boolean(columnsConfiguration['lastUpdatedOn'])
  },
  {
    Header: 'Active',
    id: 'isEnabled',
    accessor: ({ isEnabled }) => Boolean(isEnabled),
    Cell: ({ value, original }) => {
      const { isEnabled, isAbleToEditReport } = original
      return (
        <ToriiToggle
          id='isEnabledScheduledReport'
          checked={Boolean(value)}
          disabled={!isAbleToEditReport}
          onToggle={() => handleEnableToggle({ scheduledReport: original, isEnabled: !isEnabled })}
        />
      )
    },
    maxWidth: 80,
    show: Boolean(columnsConfiguration['isEnabled'])
  },
  {
    accessor: 'id',
    show: false
  },
  {
    id: 'actions',
    Header: '',
    sortable: false,
    className: css(hoverActions).toString(),
    Cell: ({ original }) => {
      const { id: idReport, isAbleToEditReport } = original
      return (
        <ScheduledReportsActions
          handleDownloadAction={() => handleDownloadAction({ idReport })}
          handleEditAction={() => handleEditAction({ scheduledReport: original })}
          handleDeleteAction={() => handleDeleteAction({ scheduledReport: original })}
          hideActions={!isAbleToEditReport}
        />
      )
    }
  }
]
