import { getTriggersByType } from '@selectors/workflows'
import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import RelativeTeamLink from '@components/relativeTeamLink'
import { getDisplayName } from '@lenses/users'
import SafeTemplate from '../safeTemplate'
import { WORKFLOW_EXECUTION_TYPES, WORKFLOW_OUTPUT_FIELD_TYPES } from '@root/constants'
import reduce from 'lodash/reduce'
import isNil from 'lodash/isNil'
import RelativeTeamUserLink from '@components/relativeTeamUserLink'
import { Link } from '@toriihq/design-system'
import ReactJson from 'react-json-view'
import { UserModel } from '@shared/types'
import { TriggerByWrapper } from './styles'

interface Props {
  triggerType: string,
  triggerOutput: any,
  triggeredBy: UserModel | null,
  executionType: keyof typeof WORKFLOW_EXECUTION_TYPES | null,
  idTriggerUsersToParents: Record<number, UserModel>
  hideTriggerPayload?: boolean
}

const WORKFLOW_EXECUTION_TYPE_TO_TEXT: Record<keyof typeof WORKFLOW_EXECUTION_TYPES, string> = {
  [WORKFLOW_EXECUTION_TYPES.ON_ACTIVATION]: 'Run during workflow activation by ',
  [WORKFLOW_EXECUTION_TYPES.MANUAL_API_WORKFLOW]: 'Triggered via API call',
  AUTOMATICALLY: '',
  ON_ACTIVATION: '',
  MANUAL_WORKFLOW: '',
  MANUAL_ACTION: '',
  RERUN: '',
  MANUAL_API_WORKFLOW: ''
}

const TriggerInfo = ({
  triggerType,
  triggerOutput,
  triggeredBy,
  executionType,
  idTriggerUsersToParents,
  hideTriggerPayload = false
}: Props): ReactElement => {
  const triggerTypes = useSelector(getTriggersByType)

  const renderTriggerPayload = (triggerPayload: any, hideTriggerPayload: boolean): ReactElement | '' => {
    if (isNil(triggerPayload) || hideTriggerPayload) {
      return ''
    }

    return (
      <div>
        <b>Payload:</b>
        <br />
        <ReactJson
          name={false}
          displayDataTypes={false}
          displayObjectSize={false}
          collapsed
          src={triggerPayload}
        />
      </div>
    )
  }

  const renderTriggerBy = (triggeredBy: UserModel | null, executionType: keyof typeof WORKFLOW_EXECUTION_TYPES | null): ReactElement | null => {
    if (!triggeredBy || !executionType) {
      return null
    }

    return (
      <TriggerByWrapper>
        <span>{`(${WORKFLOW_EXECUTION_TYPE_TO_TEXT[executionType] || 'Manually triggered by '}`}</span>
        {executionType !== WORKFLOW_EXECUTION_TYPES.MANUAL_API_WORKFLOW &&
        <RelativeTeamUserLink
          idUser={triggeredBy.id}
          isSupportTeamUser={triggeredBy.isSupport}>
          <Link>
            {getDisplayName({
              firstName: triggeredBy.firstName,
              lastName: triggeredBy.lastName,
              email: triggeredBy.email
            })}
          </Link>
        </RelativeTeamUserLink>
        }
        <span>)</span>
      </TriggerByWrapper>
    )
  }

  const mapUserFields = (outputSchema: any, triggerOutput: any) =>
    reduce(
      outputSchema,
      (result, field) => {
        if (field.type === WORKFLOW_OUTPUT_FIELD_TYPES.USER && triggerOutput[field.id]) {
          const user = triggerOutput[field.id]
          result[field.id] = (
            <RelativeTeamUserLink idUser={user.id}>
              <Link>{getDisplayName(user)}</Link>
            </RelativeTeamUserLink>
          )
        }
        return result
      },
      {}
    )

  const template = triggerTypes[triggerType]?.uiConfig?.audit ?? ''
  const outputSchema = triggerTypes[triggerType]?.outputSchema ?? {}
  const userFields = mapUserFields(outputSchema, triggerOutput)
  const user = triggerOutput.triggerUser
  const parent = idTriggerUsersToParents[user?.id]
  const app = triggerOutput.app || {}
  const triggerApp = triggerOutput.triggerApp || {}
  const license = triggerOutput.triggerLicense || {}
  const triggerPayload = triggerOutput.triggerPayload || {}

  const data = {
    ...triggerOutput,
    ...userFields,
    triggerUser: user
      ? <span>
        <RelativeTeamUserLink idUser={user.id}>
          <Link>{
            getDisplayName({
              firstName: (parent ?? user).firstName,
              lastName: (parent ?? user).lastName,
              email: (parent ?? user).email
            })}</Link>
        </RelativeTeamUserLink>{` (${user.email})`}
      </span>
      : 'a contract or an expense',
    app: <RelativeTeamLink to={`/app/${app.id}`}>
      <Link>{app.name}</Link>
    </RelativeTeamLink>,
    triggerApp: <RelativeTeamLink to={`/app/${triggerApp.id}`}>
      <Link>{triggerApp.name}</Link>
    </RelativeTeamLink>,
    triggerLicense: <span>{license.name}</span>,
    triggerPayload: renderTriggerPayload(triggerPayload, hideTriggerPayload)
  }

  const triggerBy = renderTriggerBy(triggeredBy, executionType)

  return (<>
    <SafeTemplate template={template} data={data} highlightData />
    {triggerBy}
  </>)
}

export default TriggerInfo
