import React from 'react'
import { ToastType, toast } from '@toriihq/design-system'
import Confirmation from '../confirmation'
import { useDispatch, useSelector } from 'react-redux'
import { stopWorkflowExecution } from '@actions/workflows'
import { getCurrentOrg } from '@selectors/org'
import Analytics from '@helpers/analytics'
import { getWorkflowsById } from '@selectors/workflows'
import { Workflow } from '@shared/workflows/types'
import { getWorkflowExecutionsByIdWorkflow } from '@lenses/workflows.t'

interface Props {
  isShowConfirmation: boolean,
  onCloseConfirmation: () => void
  idWorkflowExecution: number
  idWorkflow: number
  sourcePageName: string
}

const StopWorkflowConfirmation = ({
  isShowConfirmation,
  onCloseConfirmation,
  idWorkflowExecution,
  idWorkflow,
  sourcePageName
}: Props) => {
  const dispatch = useDispatch()

  const { id: idOrg } = useSelector(getCurrentOrg)
  const workflow: Workflow = useSelector(getWorkflowsById)[idWorkflow] ?? {}
  const currentExecution = useSelector(state =>
    getWorkflowExecutionsByIdWorkflow(
      state,
      idWorkflow,
      idWorkflowExecution
    ))

  const handleConfirm = async () => {
    await dispatch(stopWorkflowExecution({
      idOrg,
      idWorkflow,
      idWorkflowExecution: idWorkflowExecution
    }))

    Analytics.track('Click on stop-workflow-button', {
      'Trigger name': workflow.triggerType,
      'Action name': currentExecution.latestExecutedActionType,
      'Workflow ID': idWorkflow,
      'Workflow name': workflow.name,
      'Page name': sourcePageName
    })

    toast({
      type: ToastType.SUCCESS,
      message: 'The workflow was stopped successfully.',
      position: 'bottom-right'
    })
    onCloseConfirmation()
  }

  const handleDecline = () => {
    onCloseConfirmation()
  }

  return (
    <Confirmation
      isOpen={isShowConfirmation}
      confirm={handleConfirm}
      decline={handleDecline}
      close={handleDecline}
      header='Stop workflow'
      text='All remaining actions will be stopped. This cannot be undone. Are you sure you want to stop the workflow?'
      confirmText='Stop workflow'
      declineText='Cancel'
    />
  )
}

export default StopWorkflowConfirmation
