import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import UploadExpenses from '../../uploadExpenses'
import { DROP_ZONE_MANUAL_MATCH, DROP_ZONE_SUCCESS, DROP_ZONE_PARSING_ERROR } from '../../uploadExpenses/constants'
import ToriiPopup from '../ToriiPopupV2'
import { UploadExpensesPopupContext, UploadExpensesPopupProvider } from './uploadExpensesPopupContext'
import { Link } from '@toriihq/design-system'

const DROP_ZONE_FINISH_STATES = [DROP_ZONE_SUCCESS, DROP_ZONE_MANUAL_MATCH, DROP_ZONE_PARSING_ERROR]

const UploadExpensesWithContext = ({ cancel, isOpen, close, idOrg, ...rest }) => {
  const { state, action, refreshContext } = useContext(UploadExpensesPopupContext)

  useEffect(() => {
    if (!isOpen) {
      refreshContext()
    }
  }, [isOpen, refreshContext])

  const renderHeader = () => {
    if (state === DROP_ZONE_MANUAL_MATCH) {
      return 'Map Attributes'
    }

    return 'Upload expenses report'
  }

  const renderSubHeader = () => {
    return state === DROP_ZONE_MANUAL_MATCH ? (<div>
      Match each data type with the correct column
    </div>) : (<div>
      Upload a XLSX/CSV report from any financial system, or fill out and upload
      this <Link href='https://s3-us-west-2.amazonaws.com/torii-static/templates/torii_template_v5.xlsx'>template</Link>.
      <br />
      Files uploaded cannot exceed 10MB.
    </div>)
  }

  const renderMainButtonText = () => {
    if (state === DROP_ZONE_SUCCESS) {
      return 'Review mapping'
    } else if (state === DROP_ZONE_MANUAL_MATCH) {
      return 'Finish'
    } else if (state === DROP_ZONE_PARSING_ERROR) {
      return 'Try again'
    }
  }

  return (
    <ToriiPopup
      isOpen={isOpen}
      onCloseAction={cancel}
    >
      <ToriiPopup.Header header={renderHeader()} subHeader={renderSubHeader()} />
      <ToriiPopup.Content>
        <UploadExpenses {...rest} isInsidePopup />
      </ToriiPopup.Content>
      { DROP_ZONE_FINISH_STATES.includes(state) && <ToriiPopup.Footer mainButtonAction={action.current} mainButtonText={renderMainButtonText()} cancelButtonText={'Skip'} showCancelButton={state === DROP_ZONE_SUCCESS} />}
    </ToriiPopup>)
}

const uploadExpensesPopup = ({ cancel, isOpen, close, idOrg, ...rest }) => {
  if (!isOpen) {
    return null
  }

  return (<UploadExpensesPopupProvider>
    <UploadExpensesWithContext isOpen={isOpen} cancel={cancel} close={close} idOrg={idOrg} {...rest} />
  </UploadExpensesPopupProvider>)
}

uploadExpensesPopup.propTypes = {
  cancel: PropTypes.func,
  isOpen: PropTypes.bool,
  close: PropTypes.func
}

export default uploadExpensesPopup
