import React, { useState } from 'react'
import UploadFileArea, { UPLOAD_FILE_POST_PROCESS_STATUS } from '@root/components/uploadFileArea'
import { UPLOAD_TYPES } from '@root/constants'
import { Body2, Link, Stack, AlertBox, AlertBoxType, Spacer } from '@toriihq/design-system'
import { PARSING_STATUS, UPLOAD_ERROR_TEXTS } from '../../constants'
import Analytics from '../../analytics'
import ToriiPopup from '@root/components/popups/ToriiPopupV2'
import { RendererProps } from '@root/components/popups/wizardPopup/types'
import { IMPORT_USERS_COLUMNS, WIZARD_STEPS, SharedProps, SharedState, ImportUserSubmitParams } from '../types'
import { useDispatch } from 'react-redux'
import { createUsersImportParsing, getUsersImportParsing, getUsersImportPreviewChanges } from '@actions/'
import { getSelectedDateFormat } from '../../shared'
import { UsePolling } from '../usePolling'
import { IMPORT_TYPE } from '@selectors/importUsers/types'

const HELP_CENTER_ARTICLE_URL = 'https://support.toriihq.com/hc/en-us/articles/12256710253211-Add-Users-Manually'

const UploadFile = ({
  sharedState, sharedProps, setState, navigateToStep
}: RendererProps<SharedState, SharedProps, WIZARD_STEPS, ImportUserSubmitParams>): JSX.Element => {
  const [errorType, setErrorType] = useState<string>('')
  const dispatch = useDispatch()
  const { importMode } = sharedProps

  const onPollFailure = (parsingResult: { errorCode?: string }) => {
    const { errorCode } = parsingResult
    clearPolling()
    setIsError(true)
    errorCode && setErrorType(errorCode)
    throw new Error(errorCode)
  }

  const { startPolling, isError, setIsError, clearPolling } = UsePolling({
    onPollFailure
  })

  const pollLogic = async (idOrg, idApp, idUsersImportParsing, idAppAccount) => {
    const response = await dispatch(getUsersImportParsing({ idOrg, idApp, idUsersImportParsing }))
    const { parsingStatus, parsingPreviewData, parsingResult } = response
    if (parsingStatus === PARSING_STATUS.parsingSucceeded) {
      const { filePreview, suggestedMapping, suggestedDateFormat } = parsingPreviewData
      const columnsKeys = Object.keys(suggestedMapping) as IMPORT_USERS_COLUMNS[]
      const selectedDateFormat = getSelectedDateFormat(suggestedDateFormat)

      clearPolling()
      setIsError(false)

      const newState = {
        idUsersImportParsing,
        idAppAccount,
        filePreview,
        previewDataMapping: suggestedMapping,
        selectedColumnsToImport: columnsKeys,
        suggestedDateFormat,
        selectedDateFormat,
        originalSuggestions: { suggestedMapping, suggestedDateFormat }
      }

      setState({ ...sharedState, ...newState })

      if (parsingResult?.customParserName) {
        const previewChangesResponse = await dispatch(getUsersImportPreviewChanges({ idOrg, idApp, idUsersImportParsing, idAppAccount }))
        const { users: { validUsers, invalidUsers, missingUsers, newUsersWithLicenses }, uiConfig } = previewChangesResponse
        const { customParserName, importedColumns } = parsingResult

        setState({
          ...sharedState,
          ...newState,
          customParser: {
            name: customParserName,
            columnsToImport: importedColumns,
            uiConfig
          },
          parsingPreviewChanges: { validUsers, invalidUsers, missingUsers, newUsersWithLicenses }
        })

        navigateToStep(WIZARD_STEPS.REVIEW_IMPORT_CHANGES)
      } else {
        navigateToStep(WIZARD_STEPS.MAP_COLUMNS)
      }
    } else if (parsingStatus === PARSING_STATUS.parsingFailed) {
      const { parsingResult } = response
      onPollFailure(parsingResult)
    }
  }

  const onSuccessfulUpload = async (idOrg: number, idUpload: number): Promise<{ status: string }> => {
    try {
      const { idUsersImportParsing, idAppAccount } = await dispatch(createUsersImportParsing({ idOrg, idApp: sharedProps.idApp, idUpload, mode: sharedProps.importMode, idAppAccount: sharedProps.idAppAccount }))
      await startPolling(async () => pollLogic(idOrg, sharedProps.idApp, idUsersImportParsing, idAppAccount))
      return { status: UPLOAD_FILE_POST_PROCESS_STATUS.SUCCESS }
    } catch (error) {
      setIsError(true)
      return { status: UPLOAD_FILE_POST_PROCESS_STATUS.ERROR }
    }
  }

  const importUsersInstructionText = isError ? <></> : (
    <Stack gap='space-300'>
      <Body2 color='primary'>
        Upload a CSV file to add and modify users, licenses and last used date information. You can either export the user list from the application's admin console or create your own spreadsheet. The file must include user emails.
      </Body2>
      <Body2 color='primary'>
        You will be able to preview the changes before finalizing the import.
      </Body2>
      <Body2 color='secondary'>Learn more about importing users <Link color='primary' target={'_blank'} href={HELP_CENTER_ARTICLE_URL} rel={'noopener noreferrer'} onClick={() => Analytics.onLearnMoreClick(importMode)}>{'here'}</Link></Body2>
    </Stack>
  )

  const importLicensesInstructionText = isError ? <></> : (
    <Stack gap='space-300'>
      <Body2 color='primary'>
        Upload a CSV file to modify user licenses and last used date information for users synced from the integration. You can either export the user list from the application's admin console or create your own spreadsheet. The file must include user emails and licenses.
      </Body2>
      <Body2 color='primary'>
        You will be able to preview the changes before finalizing the import.
      </Body2>
      <AlertBox type={AlertBoxType.NOTICE} description={'Please note: Manually adjusting license data will stop the synchronization of license details from the integration. However, user information and last used dates will continue to sync. You can reset these manual changes at any time to restore the integration\'s license data.'} />
      <Body2 color='secondary'>Learn more about adjusting licenses <Link color='primary' target={'_blank'} href={HELP_CENTER_ARTICLE_URL} rel={'noopener noreferrer'} onClick={() => Analytics.onLearnMoreClick(importMode)}>{'here'}</Link></Body2>
    </Stack>
  )

  const header = sharedProps.importMode === IMPORT_TYPE.USERS ? 'Import users' : 'Adjust user licenses'
  const popupInstructions = <Spacer bottom='space-300'>{sharedProps.importMode === IMPORT_TYPE.USERS ? importUsersInstructionText : importLicensesInstructionText}</Spacer>
  const uploadEventType = sharedProps.importMode === IMPORT_TYPE.USERS ? 'App users list' : 'App users licenses list'

  return (
    <>
      <ToriiPopup.Header header={header} subHeader={sharedProps.appAccountName} />
      <ToriiPopup.Content>
        <UploadFileArea
          popupInstructions={popupInstructions}
          validFileTypes={['text/csv', 'application/vnd.ms-excel']}
          uploadedFileType={UPLOAD_TYPES.APP_USERS_IMPORT}
          successMessage={<>File uploaded</>}
          isError={isError}
          fileDescription={'CSV'}
          onShowFooter={() => {}}
          hideFooterAfterSuccess
          postUploadAction={onSuccessfulUpload}
          errorInfo={UPLOAD_ERROR_TEXTS[errorType]}
          idApp={sharedProps.idApp}
          uploadEventType={uploadEventType}
        />
      </ToriiPopup.Content>
    </>
  )
}

export default UploadFile
