import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDashboardWidgetEditConfigDrawer } from '@selectors/ui'
import { filterTable } from '@store/actions'
import { METRIC_FUNC } from '@reducers/dashboards/types'
import { fieldTypes, formFieldTypes } from '@root/constants'
import EditWidgetForm from './editWidgetForm'
import { getWidgetTableKey, useGetWidgetFieldsDataByEntityType } from '../widgets/shared'
import { isNil, omitBy } from 'lodash'
import Analytics, { ENTITY_TYPE } from '@components/dashboard/analytics'
import { DashboardEditWidgetProps } from './types'

const DashboardEditWidget = (props: DashboardEditWidgetProps): ReactElement | null => {
  const dispatch = useDispatch()

  const { dashboardName } = props

  const { sections, widget, onWidgetUpdate } = useSelector(getDashboardWidgetEditConfigDrawer)

  const [updatedWidget, setUpdatedWidget] = useState(widget)

  useEffect(() => {
    if (widget) {
      setUpdatedWidget(widget)
    }
  }, [widget])

  const widgetTableKey = getWidgetTableKey(widget)

  const onFieldChange = async (fieldKey: string, fieldValue: string) => {
    Analytics.updateWidgetConfiguration({ dashboardName, widgetTitle: updatedWidget?.displayConfig.label, fieldName: fieldKey, entityType: ENTITY_TYPE.DRAWER })
    const newDisplayConfig: Record<string, any> = { ...updatedWidget.displayConfig }

    let filters = updatedWidget.dataConfig.filters
    if (fieldKey === 'filters') {
      filters = fieldValue
      await dispatch(filterTable({ tableKey: widgetTableKey, filters: fieldValue, supportViews: false, shareViewPreferences: false }))
    }

    if (fieldKey === 'label') {
      newDisplayConfig.label = fieldValue
    }

    if (fieldKey === 'barOrientation' && fieldValue) {
      newDisplayConfig.barOrientation = fieldValue
    }

    if (fieldKey === 'groupBy' && fieldValue) {
      const field = fieldsOptions.find((field) => field.value === fieldValue)
      const newField = { systemKey: fieldValue, type: field.type, label: field.label }
      newDisplayConfig.groupBy = { ...newDisplayConfig.groupBy, field: newField, size: updatedWidget.displayConfig?.groupBy?.size ?? 5, metricFunc: updatedWidget.displayConfig?.groupBy?.metricFunc }
    }

    if (fieldKey === 'metricFunc') {
      const existingMetricFunc = updatedWidget.displayConfig?.groupBy?.metricFunc ?? updatedWidget.displayConfig?.metricFunc
      const existingMetricField = updatedWidget.displayConfig?.groupBy?.metricField ?? updatedWidget.displayConfig?.metricField
      const newMetricConfig = { metricFunc: fieldValue, metricField: existingMetricField } as any

      if (fieldValue !== METRIC_FUNC.TOTAL && existingMetricFunc !== fieldValue) {
        if (!existingMetricField?.systemKey) {
          const field = fieldsOptions.find((field) => field.value === fieldsDefaultValue?.metricField)
          newMetricConfig.metricField = { systemKey: fieldsDefaultValue?.metricField, type: field.type, label: field.label }
        }
      }

      if (fieldValue === METRIC_FUNC.TOTAL) {
        newMetricConfig.metricField = null
      }

      if (updatedWidget.displayConfig.groupBy) {
        newDisplayConfig.groupBy = { ...updatedWidget.displayConfig.groupBy, ...newMetricConfig }
      } else {
        newDisplayConfig.metricField = newMetricConfig.metricField
        newDisplayConfig.metricFunc = newMetricConfig.metricFunc
      }
    }

    if (fieldKey === 'metricField' && fieldValue) {
      const field = fieldsOptions.find((field) => field.value === fieldValue)
      const newMetricField = { systemKey: fieldValue, type: field.type, label: field.label }

      if (updatedWidget.displayConfig.groupBy?.metricFunc && updatedWidget.displayConfig.groupBy?.metricFunc !== METRIC_FUNC.TOTAL) {
        newDisplayConfig.metricField = null
        newDisplayConfig.groupBy.metricField = newMetricField
      } else if (updatedWidget.displayConfig.metricFunc && updatedWidget.displayConfig.metricFunc !== METRIC_FUNC.TOTAL) {
        newDisplayConfig.metricField = newMetricField
      }
    }

    const newWidget = {
      ...updatedWidget,
      displayConfig: omitBy(newDisplayConfig, isNil),
      dataConfig: {
        ...updatedWidget.dataConfig,
        filters
      }
    }

    setUpdatedWidget(newWidget)
    onWidgetUpdate({
      updatedWidget: newWidget
    })
  }

  const { fieldsOptions, filtersOptions, fieldOptionsValuesPerKey, fetchFieldValues } = useGetWidgetFieldsDataByEntityType(widget.entityType)

  const supportedFieldsOptions = fieldsOptions?.filter(field => ![fieldTypes.date, formFieldTypes.datePicker].includes(field.type))

  const filterOnlyNumeric = (fields) => fields.filter((field) => [fieldTypes.number, fieldTypes.currency].includes(field.type))

  const sectionWithFieldOptions = sections.map((section) => {
    return {
      ...section,
      fields: section.fields.map((field) => {
        let options
        if (field.options) {
          options = field.options
        } else if (field.type === fieldTypes.dropdown) {
          options = (field.numericFieldsOnly ? filterOnlyNumeric(supportedFieldsOptions) : supportedFieldsOptions)
        }

        return {
          ...field,
          options
        }
      })
    }
  })

  const fieldsDefaultValue = sectionWithFieldOptions.reduce((acc, section) => {
    section.fields.forEach((field) => {
      acc[field.key] = field.options?.[0]?.value
    })
    return acc
  }, {})

  if (!widget.displayConfig) {
    return null
  } else {
    const initialFormValues = {
      label: widget.displayConfig.label,
      groupBy: widget.displayConfig.groupBy?.field,
      barOrientation: widget.displayConfig?.barOrientation,
      metricFunc: widget.displayConfig?.groupBy?.metricFunc ?? widget.displayConfig?.metricFunc ?? METRIC_FUNC.TOTAL,
      metricField: widget.displayConfig?.groupBy?.metricField?.systemKey ?? widget.displayConfig?.metricField?.systemKey,
      filters: widget.dataConfig?.filters
    }

    return <EditWidgetForm
      sections={sectionWithFieldOptions}
      onFieldChange={onFieldChange}
      initialValues={initialFormValues}
      filtersOptions={filtersOptions}
      fieldOptionsValuesPerKey={fieldOptionsValuesPerKey}
      fetchFieldValues={fetchFieldValues}
    />
  }
}

export default DashboardEditWidget
