import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Stack, Body2, H4, Spacer, Spinner, SpinnerColor, Popover } from '@toriihq/design-system'
import { toggleImportUsers, getAppUsersStats } from '@store/actions'
import ImportUsersAnalytics from '@components/popups/importUsersPopup/analytics'
import { getAppV2 } from '@actions/apps'
import { getServicesSyncData } from '@actions/'
import { getIdOrg } from '@selectors/org'
import AccessControl from '@lenses/accessControl'
import AppAccountUsers from '@pages/renewalForecast/appAccountUsers'
import Footer from '../wizardSteps/footer'
import Header from '../wizardSteps/header'
import { SharedProps, SharedState, WIZARD_STEPS } from '../wizardSteps/types'
import { RendererProps } from '@root/components/popups/wizardPopup/types'
import { IMPORT_TYPE } from '@root/store/selectors/importUsers/types'
import { AppAccountUsersWrapper, FullHeight, FullWidth, PopupContent } from './styles'
import { isLoadingLicensesTypes } from '@root/store/selectors/licenses'
import { getAppUsersStats as getAppUsersStatsSelector } from '@selectors/app'
import { APP_ACCOUNT_TYPE } from '@root/constants.t'
import { DATE_FORMAT, SCOPES, SCOPES_PREFIX } from '@root/constants'
import Analytics from '../analytics'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import reportSVG from '@media/report.svg'
import DataCompletenessCheckAlertBox from './dataCompletenessCheckAlertBox'
import { get, isNumber, isEmpty } from 'lodash'
import { getDisplayName } from '@lenses/users'
import { getServicesResources } from '@selectors/services'
import moment from 'moment/moment'

const UserReview = ({
  sharedProps, sharedState, setState, navigateToStep, onCancel
}: RendererProps<SharedState, SharedProps, WIZARD_STEPS>): JSX.Element => {
  const { app, appName, idAppAccount, idApp, appAccount, licenses } = sharedProps
  const { isFirstTimeInUserReviewStep } = sharedState
  const dispatch = useDispatch()
  const idOrg = useSelector(getIdOrg)
  const { users } = useSelector(getServicesResources)
  const [totalUsers, setTotalUsers] = useState(0)
  const [hasLicenseData, setHasLicenseData] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isFakeLoading, setIsFakeLoading] = useState(Boolean(isFirstTimeInUserReviewStep))
  const isLoadingLicenses = useSelector(isLoadingLicensesTypes)
  const history = useHistory()
  const hasApplicationsScope = AccessControl.useIsAccessible({ scopes: [SCOPES.APPLICATIONS_WRITE] })
  const isAppOwner = AccessControl.useIsAccessible({ scopes: [`${SCOPES_PREFIX.APP_OWNER}-${idOrg}-app-${idApp}`] })
  const hasAccessToImportUsers = hasApplicationsScope || isAppOwner
  const appUsersStats = useSelector(getAppUsersStatsSelector)
  const { isLoading: isLoadingAppUsersStats } = appUsersStats
  const { lastSyncTime, appAccountName, appAccountType: accountType, licensesManagedManually: isLicensesManagedManually, lastSyncBy, lastUsersAndLicensesFileLastSyncTime, connectedAt } = appAccount || {}
  const accountStatistics = isNumber(idAppAccount)
    ? get(appUsersStats, ['statisticsByAccount', idAppAccount as number])
    : null

  useEffect(() => {
    const secondsToWait = 2
    const timer = setTimeout(() => {
      setIsFakeLoading(false)
      setState(prevState => ({ ...prevState, isFirstTimeInUserReviewStep: false }))
    }, secondsToWait * 1000)

    return () => clearTimeout(timer)
  }, [setState])

  useEffect(() => {
    setState(prevState => ({ ...prevState, appAccountLicenses: licenses }))
  }, [licenses, setState])

  useEffect(() => {
    setIsLoading(isFakeLoading || isLoadingAppUsersStats)
  }, [isLoadingAppUsersStats, isFakeLoading])

  const lastSyncByUserName = useMemo(() => {
    const lastSyncByUser = lastSyncBy && users[lastSyncBy]
    return (lastSyncTime || lastUsersAndLicensesFileLastSyncTime) && lastSyncByUser ? getDisplayName({ firstName: lastSyncByUser.firstName, lastName: lastSyncByUser.lastName, email: lastSyncByUser.email }) : ''
  }, [lastSyncBy, users, lastSyncTime, lastUsersAndLicensesFileLastSyncTime])

  const onImportUsersSuccess = ({ idAppAccount }) => {
    if (!sharedProps.idAppAccount) {
      history.push(`/team/${idOrg}/app/${idApp}/account/${idAppAccount}/renewalForecast/${WIZARD_STEPS.USER_REVIEW}`)
    }
    dispatch(getAppV2({ idOrg, idApp }))
    dispatch(getAppUsersStats({ idOrg, idApp }))
    dispatch(getServicesSyncData({ idOrg }))
  }

  const onImportUserListClick = () => {
    const importMode = (!accountType || accountType === APP_ACCOUNT_TYPE.MANUAL) ? IMPORT_TYPE.USERS : IMPORT_TYPE.LICENSES
    dispatch(toggleImportUsers({
      isOpen: true,
      idApp,
      appName,
      importMode,
      isFirstTimeImport: !isLicensesManagedManually,
      lastSyncTime,
      idAppAccount,
      appAccountName,
      onSubmitSuccess: onImportUsersSuccess
    }))
    ImportUsersAnalytics.onOpenImportPopup(importMode, 'Import', appName)
  }

  const onNextClick = () => {
    Analytics.clickOnNextButton({ appName, stepName: WIZARD_STEPS.USER_REVIEW })
    navigateToStep(WIZARD_STEPS.LICENSES_REVIEW)
    window.history.pushState({}, '', `/team/${idOrg}/app/${idApp}/account/${idAppAccount}/renewalForecast/${WIZARD_STEPS.LICENSES_REVIEW}`)
  }

  const onClose = () => {
    Analytics.clickOnCloseButton({ appName, stepName: WIZARD_STEPS.USER_REVIEW })
    onCancel()
  }

  const renderDataCompletenessCheckLoadingPopup = () => {
    return isFirstTimeInUserReviewStep ? <ToriiPopup isOpen={isLoading} width={'518px'} showCloseIcon={false}>
      <ToriiPopup.Content>
        <PopupContent>
          <Spacer bottom='space-300' >
            <img style={{ height: 120 }} src={reportSVG} alt='checking-for-data-completeness' />
          </Spacer>
          <Spacer bottom='space-100' >
            <Spinner color={SpinnerColor.active} />
          </Spacer>
          <Stack gap={'space-050'} alignItems='center'>
            <H4>Checking for data completeness...</H4>
            <Body2>Before starting, let’s ensure your user and license data is up-to-date</Body2>
          </Stack>
        </PopupContent>
      </ToriiPopup.Content>
    </ToriiPopup> : null
  }

  const lastDataSyncText = useMemo(() => {
    const integrationLastSyncTime = moment(lastSyncTime || connectedAt).format(DATE_FORMAT)
    const importLastSyncTimeForDisplay = moment(lastUsersAndLicensesFileLastSyncTime).format(DATE_FORMAT)
    if (!lastUsersAndLicensesFileLastSyncTime) {
      return <Body2 color={'secondary'}>{`Updated via integration on ${integrationLastSyncTime}`}</Body2>
    } else if (isLicensesManagedManually) {
      return (
        <Stack gap={'space-100'} direction={'row'} justifyContent={'start'}>
          <Body2 color={'secondary'}>{`Users updated via integration on ${integrationLastSyncTime}`}</Body2>
          <Popover
            showArrow
            content={
              <Body2 color={'secondary'}>
                Licenses updated via file import on <br />
                {importLastSyncTimeForDisplay} by {lastSyncByUserName}
              </Body2>
            }
          >
            <Body2 color={'interactive'}>+1</Body2>
          </Popover>
        </Stack>
      )
    } else {
      return <Body2 color={'secondary'}>{`Updated via file import on ${importLastSyncTimeForDisplay} by ${lastSyncByUserName}`}</Body2>
    }
  }, [lastUsersAndLicensesFileLastSyncTime, isLicensesManagedManually, lastSyncTime, connectedAt, lastSyncByUserName])

  return (
    <FullHeight>
      {renderDataCompletenessCheckLoadingPopup()}
      <Stack direction={'column'} gap={'space-400'} alignItems={'start'}>
        <Header accountName={appAccount.appAccountName} app={app} currentStep={WIZARD_STEPS.USER_REVIEW} onBackClick={onCancel} />
        <FullWidth>
          <Stack direction={'column'} gap={'space-400'} alignItems={'start'} >
            <div style={{ width: '100%' }}>
              <Stack gap='space-100' direction={'column'} justifyContent={'start'}>
                <Stack gap={'space-100'}>
                  <H4>Review {appName} users</H4>
                  <Stack direction='column' gap='space-400'>
                    <Body2>Review {appName} users and make sure their licenses are updated before proceeding.</Body2>
                    {DataCompletenessCheckAlertBox({
                      appAccount,
                      accountStatistics,
                      isLoading,
                      totalUsers,
                      setHasLicenseData,
                      onImportUserListClick,
                      hasAccessToImportUsers
                    })}
                  </Stack>
                </Stack>
              </Stack>
            </div>
            <FullWidth>
              <Stack direction='column' gap={'space-150'} justifyContent={'space-between'}>
                {isEmpty(appAccount) ? null : lastDataSyncText}
                <AppAccountUsersWrapper>
                  <AppAccountUsers
                    idApp={idApp}
                    idAppAccount={idAppAccount || undefined}
                    idOrg={idOrg}
                    isLoadingLicenses={isLoadingLicenses}
                    setTotalUsers={setTotalUsers}
                  />
                </AppAccountUsersWrapper>
              </Stack>
            </FullWidth>
          </Stack>
        </FullWidth>
        <Spacer bottom='space-050' ><div /></Spacer>
      </Stack>
      <Footer
        shouldShowNextButton
        isNextButtonDisabled={!hasLicenseData || !totalUsers}
        onNextClick={onNextClick}
        onCloseClick={onClose}
      />
    </FullHeight>
  )
}

export default UserReview
