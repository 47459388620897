import React, { Fragment } from 'react'
import get from 'lodash/get'
import omitBy from 'lodash/omitBy'
import Table from '../table'
import { css } from 'glamor'
import RelativeTeamLink from '../relativeTeamLink'
import { getDisplayName, getUserLinkOrDisplayName } from '@lenses/users'
import SafeTemplate from '../safeTemplate'
import { formFieldTypes, TABLES, WORKFLOW_ACTION_TYPES } from '../../constants'
import { dateTimeColumn, wrapColumn } from '../workflowExecutions/columns'
import pluralize from 'pluralize'
import reduce from 'lodash/reduce'
import ErrorMessage from '../offboarding/errorMessage'
import { getLabel } from '@shared/utils'
import ActionExecutionStatus from '../workflowExecutions/actionExecutionStatus'
import DetailsDisplay from '../workflows/detailsDisplay'
import AppDetails from '../appDetails'
import { Tooltip, Avatar, Link } from '@toriihq/design-system'
import { Wrapper } from './styles'
import PropTypes from 'prop-types'
import RelativeTeamUserLink from '@components/relativeTeamUserLink'
import TicketLink from '@components/ticketLink'
import isNil from 'lodash/isNil'
import { WORKFLOW_ACTION_TYPE } from '@shared/workflows/types'
import { DEFAULT_BRANCH_LABEL } from '@root/pages/workflow_v2/workflowEditor/consts'
import { getResumingInText } from '@components/workflowActionExecutions/utils/getResumingInText'
import { getRecipientName } from '@lenses/workflows'
import isUndefined from 'lodash/isUndefined'
import { JSONTryParse } from '@lenses/utils'

const CSS = {
  result: css({
    textTransform: 'capitalize'
  })
}

const formatValueByType = (value, type) => {
  switch (type) {
    case (formFieldTypes.currency):
      return parseInt(value || 0) / 100

    case (formFieldTypes.cardNumber):
      return 'XXXX-XXXX-XXXX-' + (value || '')

    default:
      return value
  }
}

export const renderDetailsObjectColumn = (details, key) => {
  if (!details || details[key] === null || details[key] === '' || details[key] === undefined) {
    return
  }
  const colValue = typeof details[key] === 'string' ? details[key] : JSON.stringify(details[key])

  return (
    <p key={key}>
      <strong {...CSS.result}>{key}</strong>: {colValue}
    </p>
  )
}

class WorkflowActionExecutions extends React.Component {
  constructor (props) {
    super(props)
    this.columns = this.getColumns()
  }

  getColumns = () => {
    return [
      {
        Header: 'App / Action type',
        accessor: 'app',
        minWidth: 130,
        Cell: ({ value: app, row: { actionType } }) => {
          const { globalActionTypes } = this.props

          let actionDetails

          if (app) {
            actionDetails = app
          } else if (globalActionTypes.includes(actionType)) {
            const { actionsByType = {} } = this.props
            const { uiConfig } = actionsByType[actionType] ?? {}
            const { label: name, imageUrl } = uiConfig ?? {}

            actionDetails = { name, imageUrl }
          }

          return (actionDetails
            ? <AppDetails {...actionDetails} component='workflow actions audit' />
            : <></>
          )
        },
        sortable: false
      },
      {
        Header: 'Action',
        id: 'action',
        accessor: 'runtimeInfo',
        Cell: ({ value: runtimeInfo, row: { actionType } }) => {
          const { actionsByType = {}, idRuntimeInfoUsersToParents = {} } = this.props
          const template = get(actionsByType[actionType], ['uiConfig', 'audit'], '')
          const inputSchema = get(actionsByType[actionType], ['inputSchema'], {})
          const userFields = reduce(inputSchema, (result, field) => {
            if (field.type === formFieldTypes.user && runtimeInfo[field.id]) {
              const user = runtimeInfo[field.id]
              const parent = idRuntimeInfoUsersToParents[user.id]
              result[field.id] = getUserLinkOrDisplayName(user, getDisplayName(parent || user))
            }
            return result
          }, {})
          const recipients = [].concat(runtimeInfo.to || get(runtimeInfo, ['emailSetup', 'to']))
          const recipient = recipients[0] || (runtimeInfo.submissionsTo && runtimeInfo.submissionsTo[0]) || runtimeInfo.from || get(runtimeInfo, ['emailSetup', 'from'])
          const moreRecipientsAmount = recipients.length - 1
          const moreRecipientsText = moreRecipientsAmount > 0 ? ` and ${moreRecipientsAmount} more ${pluralize('user', moreRecipientsAmount)}` : ''
          const triggerUser = runtimeInfo.triggerUser
          const app = runtimeInfo.app || {}
          const triggerApp = runtimeInfo.triggerApp || {}

          const reason = runtimeInfo.reason || '(no reason entered)'
          return (
            <SafeTemplate
              template={template}
              data={{
                ...runtimeInfo,
                ...userFields,
                triggerUser: triggerUser ? getUserLinkOrDisplayName(triggerUser, getDisplayName(triggerUser)) : 'N/A',
                to: recipient ? <span>{getUserLinkOrDisplayName(recipient, getRecipientName(recipient))}{moreRecipientsText}</span> : 'fallback recipient',
                app: <RelativeTeamLink to={`/app/${app.id}`}><Link>{app.name}</Link></RelativeTeamLink>,
                triggerApp: <RelativeTeamLink to={`/app/${triggerApp.id}`}><Link>{triggerApp.name}</Link></RelativeTeamLink>,
                reason
              }}
              highlightData
            />
          )
        },
        maxWidth: 400,
        sortable: false,
        ...wrapColumn
      },
      {
        Header: 'Triggered by',
        accessor: 'triggeredBy',
        Cell: ({ value: user = {} }) => {
          const { firstName, lastName, photoUrl, email } = user || {}
          const tooltipText =
            <Wrapper>
              <div>{getDisplayName(user)}</div>
              <div>{email}</div>
            </Wrapper>
          return (
            <Tooltip label={tooltipText}>
              <Avatar
                firstName={firstName}
                lastName={lastName}
                imageUrl={photoUrl}
              />
            </Tooltip>
          )
        },
        showFunc: () => !!this.props.showTriggeredBy,
        width: 120,
        sortable: false
      },
      {
        Header: 'Triggered at (UTC)',
        accessor: 'runTime',
        ...dateTimeColumn,
        width: 150
      },
      {
        Header: 'Completed at (UTC)',
        accessor: 'completionTime',
        ...dateTimeColumn,
        width: 150
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: 'completionTime',
        Cell: ({ value: completionTime, row: { executionErrorType } }) =>
          <ActionExecutionStatus
            completionTime={completionTime}
            hasError={Boolean(executionErrorType)}
          />,
        width: 110,
        sortable: false
      },
      {
        Header: 'Details',
        accessor: 'details',
        Cell: ({
          value: details,
          row: { app, actionType, executionErrorType, action, completionTime }
        }) => {
          const { appFields, contractsFields } = this.props
          return getActionDetails({ app, details, actionType, executionErrorType, action, appFields, contractsFields, completionTime })
        },
        sortable: false,
        minWidth: 150,
        ...wrapColumn
      },
      {
        accessor: 'actionType',
        show: false
      },
      {
        accessor: 'executionErrorType',
        show: false
      }
    ]
  }

  render () {
    const { header, actions, loading, actionsByType, total, fetchData, loadingMore, clientPaging = true, idRuntimeInfoUsersToParents } = this.props
    const pagingInfo = clientPaging ? { clientPaging: true, pageSize: 50 } : { loadingMore: loadingMore, totalCount: total, fetchData: fetchData, manual: true }

    return (
      <Table
        tableKey={TABLES.workflowsActionExecutionsTable.key}
        data={actions}
        columns={this.columns}
        header={header}
        emptyStateMessage='No actions triggered yet'
        loading={loading}
        actionTypes={actionsByType}
        idRuntimeInfoUsersToParents={idRuntimeInfoUsersToParents}
        {...pagingInfo}
      />
    )
  }
}

export const getActionDetails = ({
  app,
  details,
  actionType,
  appFields,
  contractsFields,
  executionErrorType,
  action,
  completionTime
}) => {
  if (actionType === WORKFLOW_ACTION_TYPES.IGNORE) {
    const reason = action?.reason
    return reason ? <p><strong>Reason:</strong><br />{reason}</p> : ''
  }

  if (!details && !executionErrorType) {
    return '-'
  }

  if (executionErrorType) {
    return <ErrorMessage errorType={executionErrorType} appName={app?.name} showTooltip={false} errorDetails={details} />
  }

  let linkToUser
  if (details.performedBy) {
    linkToUser = details.performedBy.id
      ? <RelativeTeamUserLink idUser={details.performedBy.id}> <Link>{getDisplayName(details.performedBy)}</Link> </RelativeTeamUserLink>
      : getDisplayName(details.performedBy)
  }

  switch (actionType) {
    case WORKFLOW_ACTION_TYPES.SEND_FORM: {
      if (details.rejected) {
        return <div><strong {...CSS.result}>Form submission</strong>: I can not help you with filling the details</div>
      }

      return <div>
        <p>Form filled by {linkToUser}</p>
        {(details.fields || []).map(item => {
          const field = appFields[item.idField] || {}
          const text = getLabel(item.value || item.values)
          const convertedText = formatValueByType(text, field.type)

          return <p key={item.idField}><strong {...CSS.result}>{field.name}</strong><br />{convertedText}</p>
        })
        }
      </div>
    }
    case WORKFLOW_ACTION_TYPES.SEND_CONTRACT_FORM: {
      if (details.rejected) {
        return <div><strong {...CSS.result}>Form submission</strong>: I can not help you with filling the details</div>
      }

      return <div>
        <p>Form filled by {linkToUser}</p>
        {(details.fields || []).map(item => {
          const field = contractsFields[item.id] || {}
          const text = getLabel(item.value || item.values)
          const convertedText = formatValueByType(text, field.type)

          return <p key={item.id}><strong {...CSS.result}>{field.name}</strong><br />{convertedText}</p>
        })
        }
      </div>
    }
    case WORKFLOW_ACTION_TYPES.REQUEST_APPROVAL: {
      const sendViaSlack = action.sendRequestMethod?.value === 'Slack'
      const { destinations, approved } = details

      return <>
        {destinations?.length &&
          <div>
            <span>Sent to:</span>
            <br />
            {
              destinations.map((destination, index) => <Fragment key={index}><span><strong>{destination.label}{sendViaSlack ? ': ' : ''}</strong>{sendViaSlack ? destination.success ? 'Success' : 'Failure' : ''}</span><br /></Fragment>)
            }
          </div>
        }
        {!isNil(approved)
          ? <div>User {linkToUser} <strong>{approved ? 'Approved' : 'Declined'}</strong></div>
          : null}
      </>
    }
    case WORKFLOW_ACTION_TYPES.REMOVE_USER_TASK:
    case WORKFLOW_ACTION_TYPES.REMOVE_USER: {
      if (details.performedBy) {
        return <div>Removed By {linkToUser}</div>
      }

      if (details.createdBy) {
        const linkToCreatedByUser = <RelativeTeamUserLink idUser={details.createdBy.id}> <Link>{getDisplayName(details.createdBy)}</Link> </RelativeTeamUserLink>
        return <div>The task couldn't be assigned because the specified recipients were not found. Instead, it was assigned to {linkToCreatedByUser}</div>
      }
      return ''
    }
    case WORKFLOW_ACTION_TYPES.START_EMPLOYEE_OFFBOARDING: {
      return details.userLifecycleStatus ? <div>The user status was already {details.userLifecycleStatus} when the workflow was triggered</div> : ''
    }
    case WORKFLOW_ACTION_TYPES.SEND_HTTP_REQUEST: {
      const orderedDetails = {}
      orderedDetails['status code'] = details.statusCode
      orderedDetails['response headers'] = details.headers
      orderedDetails['response body'] = JSONTryParse(details.body)
      orderedDetails['response mapping'] = details.response

      return <DetailsDisplay details={omitBy(orderedDetails, isUndefined)} displayDetailsInSeparateLinesKeys={['response mapping']} forceShowKeys={['status code', 'response headers', 'response body']} />
    }
    case WORKFLOW_ACTION_TYPES.JIRA_CLOUD_CREATE_ISSUE: {
      const { projectName, ticketUrl, ticketKey, note, customErrorMessages } = details || {}
      if (!ticketKey) {
        return ''
      }

      const boldIssueKey = <TicketLink url={ticketUrl} content={ticketKey} actionType={WORKFLOW_ACTION_TYPES.JIRA_CLOUD_CREATE_ISSUE} />

      const boldProjectName = <strong>{projectName}</strong>
      const inProject = projectName ? <span> in {boldProjectName}</span> : ''

      const suffix = note ? `${note}` : ''

      return <div>
        {boldIssueKey} issue was created{inProject}.
        <div>{suffix}</div>
        {customErrorMessages?.customFieldsNotSuportedTypeNote && <div>{customErrorMessages.customFieldsNotSuportedTypeNote}</div>}
        {customErrorMessages?.customFieldsInvalidValueNote && <div>{customErrorMessages.customFieldsInvalidValueNote}</div>}
        {customErrorMessages?.dueTimeErrorNote && <div>{customErrorMessages.dueTimeErrorNote}</div>}
      </div>
    }
    case WORKFLOW_ACTION_TYPES.SERVICENOW_CREATE_TICKET: {
      const { ticketNumber, ticketUrl, isUserFound } = details || {}
      if (!ticketNumber) {
        return ''
      }

      const boldTicket = <TicketLink url={ticketUrl} content={ticketNumber} actionType={WORKFLOW_ACTION_TYPES.SERVICENOW_CREATE_TICKET} />

      const userNotFoundSuffix = isUserFound ? '' : `. The ticket was not assigned to selected user because the user was not found in ${app.name} account.`
      return <div>{boldTicket} ticket was created{userNotFoundSuffix}</div>
    }
    case WORKFLOW_ACTION_TYPES.ASANA_CREATE_TASK: {
      const { taskName, projectName, workspaceName, taskUrl, isUserFound } = details || {}
      if (!taskName) {
        return ''
      }

      const boldTaskName = <TicketLink url={taskUrl} content={taskName} actionType={WORKFLOW_ACTION_TYPES.ASANA_CREATE_TASK} />

      const boldProjectName = <strong>{projectName}</strong>
      const suffix = projectName ? <span> in {boldProjectName}</span> : ''
      const userNotFoundSuffix = isUserFound ? '' : `. The task was not assigned to selected user because the user was not found in ${workspaceName} workspace.`
      return <div>{boldTaskName} task was created{suffix}{userNotFoundSuffix}</div>
    }
    case WORKFLOW_ACTION_TYPES.FRESHSERVICE_CREATE_TICKET: {
      const { ticketUrl, ticketId } = details || {}
      if (!ticketId) {
        return ''
      }

      return <div>Ticket <TicketLink url={ticketUrl} content={`#${ticketId}`} actionType={WORKFLOW_ACTION_TYPES.FRESHSERVICE_CREATE_TICKET} /> was created</div>
    }
    case WORKFLOW_ACTION_TYPES.ZENDESK_CREATE_TICKET: {
      const { ticketUrl, ticketId, Note: note } = details || {}
      if (!ticketId) {
        return ''
      }
      const noteComponent = note ? renderDetailsObjectColumn(details, 'Note') : <></>

      return <div>Ticket <TicketLink url={ticketUrl} content={`#${ticketId}`} actionType={WORKFLOW_ACTION_TYPES.ZENDESK_CREATE_TICKET} /> was created {noteComponent}</div>
    }
    case WORKFLOW_ACTION_TYPES.MONDAY_CREATE_ITEM: {
      const { projectName, ticketName, groupName } = details || {}
      const boldBoardName = <strong>{projectName}</strong>
      const boldGroupName = <strong>{groupName}</strong>
      const boldItemName = <strong>{ticketName}</strong>
      return <div>Item {boldItemName} was created in {boldGroupName}, {boldBoardName}</div>
    }
    case WORKFLOW_ACTION_TYPE.IF_ELSE_BRANCH: {
      const { selectedBranchLabel } = details

      return <>Continuing on the <strong>{selectedBranchLabel === 'Default' ? DEFAULT_BRANCH_LABEL : selectedBranchLabel}</strong> branch</>
    }
    case WORKFLOW_ACTION_TYPE.WAIT: {
      if (completionTime) {
        return ''
      }

      const { continueExecutionAt } = details
      const { durationUnit } = action
      const resumingIn = getResumingInText({
        continueExecutionAt,
        durationUnit: durationUnit.value
      })
      return <>Resuming in <strong>{resumingIn}</strong></>
    }
    default: {
      if (details.isUserFound === false) {
        return <div>User not found</div>
      }

      if (details.isUserCreated === false) {
        return <div>The user was not created</div>
      }

      if (details.userAlreadySuspended === true) {
        return <div>The user was already suspended</div>
      }

      return Object
        .keys(details || {})
        .map(key => renderDetailsObjectColumn(details, key))
    }
  }
}

WorkflowActionExecutions.propTypes = {
  header: PropTypes.string,
  actions: PropTypes.array,
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  actionsByType: PropTypes.object,
  total: PropTypes.number,
  fetchData: PropTypes.func,
  idOrg: PropTypes.number,
  clientPaging: PropTypes.bool,
  idRuntimeInfoUsersToParents: PropTypes.object
}

export default WorkflowActionExecutions
