import { fieldTypes } from '@root/constants'

interface Option {
  label: string
  value: string
  type: string
}

interface IsFieldSupportAutocompleteValuesParams {
  fieldOptions: Option[]
  fieldOption: string
}

const FIELDS_WITH_SUPPORT_AUTOCOMPLETE_VALUES = [
  fieldTypes.text,
  fieldTypes.user,
  fieldTypes.dropdownMulti,
  fieldTypes.name
]

export const isFieldSupportAutocompleteValues = ({
  fieldOptions,
  fieldOption
}: IsFieldSupportAutocompleteValuesParams): boolean => {
  const optionType = fieldOptions.find(option => option.value === fieldOption)?.type
  return FIELDS_WITH_SUPPORT_AUTOCOMPLETE_VALUES.includes(optionType ?? '')
}
