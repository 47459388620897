import View from './view'
import { connect } from 'react-redux'
import { getIsSmallScreen } from '@selectors/ui'
import range from 'lodash/range'
import groupBy from 'lodash/groupBy'
import keyBy from 'lodash/keyBy'
import moment from 'moment'
import { getContractsResources, getContractStatusOptions, getContractsWithRenewalDate } from '@selectors/contracts'
import { getCurrentOrg, getRenewalCalendarSettings } from '@selectors/org'
import { createCalendar } from '@actions/calendars'
import { getSelf } from '@selectors/me'
import { getCalendars, getIsLoadingCalendars } from '@selectors/calendars'
import { CALENDAR_TYPE } from '@reducers/calendars/types'

const mapStateToProps = (state, ownProps) => {
  const MONTHS_IN_ROW = 3
  const { isEmptyState } = ownProps
  const { id: idOrg } = getCurrentOrg(state)
  const { id: idUser } = getSelf(state)
  const { renewals, maxRenewalDate } = getContractsWithRenewalDate(state)
  const renewalsWithoutNextContracts = renewals.filter(renewal => !renewal.nextContracts || renewal.nextContracts.length === 0)
  const { apps } = getContractsResources(state)
  const isLoadingCalendars = getIsLoadingCalendars(state)
  const calendars = getCalendars(state)
  const renewalCalendar = calendars.find(calendar => calendar.type === CALENDAR_TYPE.RENEWAL) || {}
  const contractStatusOptions = getContractStatusOptions(state)
  const contractStatusOptionsByValue = keyBy(contractStatusOptions, 'value')
  const isRenewalCalendarAllowed = getRenewalCalendarSettings(state)
  const now = moment()
  let maxYear = now.year()
  let startMonth = 1
  if (!isEmptyState) {
    const currMonth = now.month() + 1
    const monthIndexPerRow = currMonth % MONTHS_IN_ROW
    startMonth = monthIndexPerRow === 1 ? currMonth : monthIndexPerRow === 2 ? currMonth - 1 : currMonth - 2
    maxYear = (maxRenewalDate && maxRenewalDate.year()) || maxYear
  }

  const currYear = now.year()
  const years = {
    [currYear]: range(startMonth, 13)
  }
  for (let year = currYear + 1; year <= maxYear; year++) {
    years[year] = range(1, 13)
  }

  return {
    idOrg,
    idUser,
    contractStatusOptionsByValue,
    isSmallScreen: getIsSmallScreen(state),
    renewals: groupBy(renewalsWithoutNextContracts, 'key'),
    appsInfo: apps,
    years,
    monthsInRow: MONTHS_IN_ROW,
    isRenewalCalendarAllowed,
    renewalCalendar,
    isLoadingCalendars
  }
}

const mapDispatchToProps = {
  createCalendar
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
