import { ops } from '@lenses/filters'
import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import Table from '../table'
import { TABLES, SCOPES } from '../../constants'
import TeamUserActions from './teamUserActions'
import UserDetails from '../userDetails'
import Select from '../select'
import { getDisplayName } from '../../lenses/users'
import EnableFor from '../enableFor'
import { getBadgeByStatus } from '@root/shared/customUserBadges'
import moment from 'moment'
import exportCSV from '@helpers/exportCSV'

const DEFAULT_FIELDS_TO_FETCH = ['firstName', 'lastName', 'email', 'originEmail', 'isExternal', 'idRole', 'idOrg', 'photoUrl', 'role', 'status', 'isAdmin']
const DEFAULT_FILTERS = [{ key: 'isAdmin', op: ops.equals, value: true }, { key: 'status', op: ops.anyOf, value: ['active', 'invited'] }]

class TeamUsers extends React.Component {
  componentDidUpdate (prevProps) {
    if (prevProps.idOrg !== this.props.idOrg) {
      this.fetchData()
    }
  }

  fetchData () {
    const { idOrg, getRoles, getTeamMembers } = this.props
    getTeamMembers({ idOrg, fields: DEFAULT_FIELDS_TO_FETCH, reset: true, filters: DEFAULT_FILTERS })
    getRoles({ idOrg })
  }

  getColumns () {
    const { isSmallScreen, idOrg, trackRoleChange, me } = this.props
    return [
      {
        Header: 'Name',
        id: 'name',
        accessor: ({ firstName, lastName, email }) => [firstName, lastName, email].join(' ').trim().toLowerCase(),
        Cell: ({ row: { id, firstName, lastName, email, status, isExternal, photoUrl } }) => (
          <UserDetails
            firstName={firstName}
            lastName={lastName}
            email={email}
            idUser={id}
            isExternal={isExternal}
            badge={getBadgeByStatus(status)}
            photoUrl={photoUrl}
          />
        ),
        minWidth: 150
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ row: { originEmail } }) => originEmail,
        minWidth: 150
      },
      {
        Header: 'Role',
        accessor: 'idRole',
        Cell: ({ value: idRole, row: { id } }) => {
          const { adminRoleOptions } = this.props
          if (adminRoleOptions.length === 0) {
            return null
          }

          if (me.id === id) {
            return adminRoleOptions.find(r => r.value === me.idRole).label
          }

          return <EnableFor scopes={[SCOPES.MEMBERS_AND_ROLES_WRITE]}>
            <Select
              options={adminRoleOptions}
              value={idRole}
              clearable={false}
              openOnFocus
              onChange={(selected) => this.userRoleChanged({ idUser: id, idRole: selected.value, prevIdRole: idRole })}
            />
          </EnableFor>
        },
        minWidth: 150
      },
      {
        id: 'action',
        Header: '',
        sortable: false,
        className: css(CSS.actions, !isSmallScreen && CSS.hoverActions).toString(),
        Cell: ({ row: { role, id, firstName, lastName, email } }) => <TeamUserActions key={id} id={id} idOrg={idOrg} displayName={getDisplayName({ firstName, lastName, email })} role={role || ''} trackRoleChange={trackRoleChange} />,
        maxWidth: 80
      },
      {
        accessor: 'firstName',
        show: false
      },
      {
        accessor: 'originEmail',
        show: false
      },
      {
        accessor: 'lastName',
        show: false
      },
      {
        accessor: 'imageUrl',
        show: false
      },
      {
        accessor: 'status',
        show: false
      },
      {
        accessor: 'id',
        show: false
      },
      {
        accessor: 'isExternal',
        show: false
      },
      {
        accessor: 'photoUrl',
        show: false
      }
    ]
  }

  userRoleChanged = async ({ idUser, idRole, prevIdRole }) => {
    const { idOrg, updateUser, trackRoleChange } = this.props
    const payload = await updateUser({ idOrg, idUser, idRole, prevIdRole })
    trackRoleChange(payload.user, prevIdRole)
  }

  exportToCsv = async () => {
    const { users } = this.props
    const columnNames = ['First name', 'Last name', 'Email', 'Role']
    const dataForCSV = users.map(user => {
      return {
        'First name': user.firstName,
        'Last name': user.lastName,
        'Email': user.email,
        'Role': user.role
      }
    })

    exportCSV(`members_${moment().format('DD_MMMM_YYYY')}.csv`, dataForCSV, columnNames)
  }

  render () {
    const { users, toggleInviteUser, loading } = this.props

    const customButton = {
      onClick: () => toggleInviteUser(true),
      text: 'Invite members',
      scopes: [SCOPES.MEMBERS_AND_ROLES_WRITE]
    }
    return (
      <Table
        tableKey={TABLES.teamUsersTable.key}
        sortable={false}
        getTrGroupProps={setTrGroupStyle}
        data={users}
        columns={this.getColumns()}
        loading={loading}
        customButton={customButton}
        exportable
        exportFunction={this.exportToCsv}
      />
    )
  }
}

TeamUsers.propTypes = {
  isSmallScreen: PropTypes.bool,
  users: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    imageUrl: PropTypes.string
  })),
  idOrg: PropTypes.number.isRequired,
  getTeamMembers: PropTypes.func.isRequired,
  getRoles: PropTypes.func.isRequired
}

const CSS = {
  row: css({}),
  admin: css({
    color: colors.black,
    fontWeight: '600'
  }),
  actions: css({
    textAlign: 'right'
  }),
  nameAndIcon: css({
    ':hover': {
      color: colors.blue
    }
  })
}

CSS.icon = css({
  width: '30px',
  marginRight: '15px',
  borderRadius: '4px',
  border: '1px solid transparent',
  transition: 'border-color .2s',
  [`.${CSS.nameAndIcon}:hover &, [data-${CSS.nameAndIcon}]:hover &`]: {
    borderColor: colors.darkBorder
  }
})

CSS.hoverActions = css({
  opacity: 0,
  [`.${CSS.row}:hover &, [data-${CSS.row}]:hover &`]: {
    opacity: 1
  }
})

const setTrGroupStyle = () =>
  ({
    className: CSS.row.toString()
  })

export default TeamUsers
