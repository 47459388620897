import { APP_ACCOUNT_TYPE } from '@root/constants.t'
import { SYNC_STATUS } from '@root/constants'

export type SyncStatus = typeof SYNC_STATUS[keyof typeof SYNC_STATUS]

export interface License {
  id: number
  name: string
  totalAmount: number
  validTotalAmount: number
  assignedAmount: number
  unassignedAmount: number
  activeAmount: number
  inactiveAmount: number
  currency: string
  pricePerUser: number
  pricePerUserConverted: number
  totalAmountEnteredByUser: number
  isTotalAmountEnteredByUser: boolean
  isPaid: boolean
  savingsConverted: number
  totalCostConverted: number
}

export interface SharedState {
  initialAppAccountLicenses: License[]
  appAccountLicenses: License[]
  isFirstTimeInUserReviewStep: boolean
}

export interface SharedProps {
  idOrg: number,
  idApp: number
  idAppAccount: number | null
  appName: string
  licenses: License[]
  appAccount: {
    lastSyncTime: string
    connectedAt: string
    appAccountName: string
    licensesManagedManually: boolean
    appAccountType: APP_ACCOUNT_TYPE
    syncStatus: SyncStatus
    lastUsersAndLicensesFileLastSyncTime: string
    source: string
    lastSyncBy: number
  }
  app: {
    name: string
    imageUrl: string
    isCustom: boolean
   }
}

export enum WIZARD_STEPS {
  USER_REVIEW = 'userReview',
  LICENSES_REVIEW = 'licensesReview',
  RECOMMENDATIONS = 'reviewForecast',
}
