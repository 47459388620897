import React, { Fragment } from 'react'
import { css } from 'glamor'
import { Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import colors from '../../shared/style/colors'
import texts from '../../shared/style/texts'
import Input from '../form/input'
import FormGroup from '../form/formGroup'
import Placeholder from '../placeholder'
import FieldByType from '@components/details/fieldByType'
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  SCOPES,
  formFieldTypes,
  READ_ONLY_CONTRACT_FIELDS,
  FIELDS_CONTAINER_ENTITY
} from '../../constants'
import SelectContractStatus from '../selectContractStatus'
import SelectApps from '../selectApps'
import partition from 'lodash/partition'
import groupBy from 'lodash/groupBy'
import keyBy from 'lodash/keyBy'
import omitBy from 'lodash/omitBy'
import get from 'lodash/get'
import isUndefined from 'lodash/isUndefined'
import isEmpty from 'lodash/isEmpty'
import EditableUploadFileBox from '../uploadAppFiles/editableUploadFileBox'
import Analytics from '../../helpers/analytics'
import { ToriiPopupContext } from '@components/popups/ToriiPopupV2'
import forOwn from 'lodash/forOwn'
import isObject from 'lodash/isObject'
import isArray from 'lodash/isArray'
import isDate from 'lodash/isDate'
import moment from 'moment'
import VisibleFor from '@components/visibleFor'
import EnableFor from '@components/enableFor'
import { getContractCurrencyValue, saveContractCurrencyValue } from '../contracts/utils'
import { CurrencySelector } from '../currencySelector'
import { isEqual, pickBy } from 'lodash'
import { Icon, Tooltip, Button, ButtonType, ButtonSize } from '@toriihq/design-system'
import { getScopeByIdOrgAndIdApp } from '@lenses/scopes'

const customApp = 'customApp'
const specialFieldsSystemKeys = ['name', 'status', 'idApp']
const formId = 'contractForm'
const dateFormat = 'YYYY-MM-DD'
const mandatoryFieldValidator = value => {
  return !value ? 'Mandatory field' : undefined
}
const renderFieldLoading = (index) => {
  return (
    <Placeholder key={index} loading rows={1} style={{ maxWidth: '95%', margin: '20px' }}>
      <div />
    </Placeholder>
  )
}

const CSS = {
  main: css({
    padding: '30px 30px 0 30px'
  }),
  groupContainer: css({
    border: `1px solid ${colors.border}`,
    borderRadius: '4px',
    ' .ef-field': {
      borderBottom: '0'
    },
    ' .ef-input': {
      display: 'inline-block',
      width: '90%'
    },
    ' textarea.ef-input': {
      height: '100px'
    },
    marginBottom: '28px'
  }),
  groupHeader: css(texts.heading, {
    padding: '20px',
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'space-between'
  }),
  buttons: css({
    bottom: '-10px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    backgroundColor: colors.white,
    padding: '0 30px'
  }),
  buttonsWrapper: css({
    position: 'sticky',
    bottom: '-10px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    backgroundColor: colors.white,
    padding: '20px 0',
    borderTop: '1px solid #E6EBF4'
  }),
  errorMessage: css(texts.body, {
    color: colors.red,
    marginTop: '5px'
  }),
  addFieldContainer: css({
    padding: '0 20px'
  }),
  field: css({
    borderTop: `1px solid ${colors.border}`,
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  })
}

class ContractDetails extends React.Component {
  state = {
    selectedIdApp: null,
    nextContractsList: [],
    previousContractsList: [],
    selectedNextContracts: [],
    selectedPreviousContracts: [],
    selectedStartDate: null,
    selectedEndDate: null,
    contractCurrency: null
  }

  componentDidMount () {
    const { getContract, idContract, idOrg, allContracts, initialValues, idApp } = this.props
    let details = (idContract && allContracts[idContract]) || (idApp && { ...initialValues, idApp }) || initialValues || EMPTY_OBJECT
    this.context.submitAction.current = this.onSubmit
    idOrg && idContract && getContract({ idOrg, idContract, idApp })
    this.context.setInitialValues(details)
    this.initialContractsOptions({ contractDetails: details, idApp: details?.idApp })
  }

  componentDidUpdate (prevProps) {
    if (this.props.idOrg !== prevProps.idOrg) {
      const { getContract, idContract, idOrg, idApp } = this.props

      idOrg && idContract && getContract({ idOrg, idContract, idApp })
    }

    const { idContract, allContracts, orgDefaultCurrency } = this.props
    const { idContract: prevIdContract, allContracts: prevAllContract, orgDefaultCurrency: prevOrgDefaultCurrency } = prevProps
    if (!isEqual(allContracts[idContract], prevAllContract[prevIdContract]) || orgDefaultCurrency !== prevOrgDefaultCurrency) {
      const details = allContracts[idContract]
      if (details) {
        this.context.setInitialValues(details)
        this.initialContractsOptions({ contractDetails: details, idApp: details?.idApp })
      }
    }
  }

  componentWillUnmount () {
    this.context.setInitialValues(EMPTY_OBJECT)
  }

  getContractCurrency = (contractDetails) => {
    const { orgDefaultCurrency } = this.props
    const fieldWithCurrencyKey = Object.keys(contractDetails).find(contractField => contractDetails[contractField]?.currency)
    return fieldWithCurrencyKey ? contractDetails[fieldWithCurrencyKey].currency : orgDefaultCurrency
  }

  initialContractsOptions = ({ contractDetails, idApp }) => {
    const { initialValues } = this.props
    if (contractDetails) {
      const { startDate, endDate, nextContracts, previousContracts } = contractDetails
      const contractCurrency = this.getContractCurrency(contractDetails)
      initialValues['contractCurrency'] = contractCurrency
      this.setState({
        selectedStartDate: startDate,
        selectedEndDate: endDate,
        contractCurrency
      })
      this.calculateContractsOptionsByIdApp({ idApp, nextContracts, previousContracts })
    } else {
      this.calculateContractsOptionsByIdApp({ idApp })
    }
  }

  calculateContractsOptionsByIdApp = ({ idApp = this.state.selectedIdApp, nextContracts = this.props.values.nextContracts, previousContracts = this.props.values.previousContracts }) => {
    const { idContract = null, allContracts } = this.props
    const nextAndPreviousContractsList = Object.values(allContracts)
      .filter(contract => contract.idApp === idApp && contract.id !== idContract)
      .map(contract => ({ label: contract.name, value: contract.id }))
    this.setState({
      selectedIdApp: idApp,
      nextContractsList: nextAndPreviousContractsList.filter(contract => !previousContracts?.includes(contract.value)),
      previousContractsList: nextAndPreviousContractsList.filter(contract => !nextContracts?.includes(contract.value))
    })
  }

  getComponentByType = ({ input, fieldProps }) => {
    const { allContracts, idContract } = this.props
    const details = idContract ? allContracts[idContract] : EMPTY_OBJECT
    const hideHistoryFieldsList = ['previousContracts']
    const getRemoveValue = (field) => {
      switch (field) {
        case 'previousContracts':
          return []
        case 'nextContracts':
          return []
        default:
          return null
      }
    }

    const handleDatesInput = (value) => {
      const { systemKey } = fieldProps.field
      const { form } = this.props
      if (['startDate', 'endDate'].includes(systemKey)) {
        let stateField
        if (systemKey === 'startDate') {
          const startDateMoment = moment(value)
          if (startDateMoment.isValid()) {
            const oneYearFromStartDate = startDateMoment.clone().add(1, 'year').subtract('1', 'day').format(dateFormat)
            stateField = { selectedStartDate: startDateMoment.format(dateFormat), selectedEndDate: oneYearFromStartDate }
            form.change('endDate', oneYearFromStartDate)
          } else {
            stateField = { selectedStartDate: null }
          }
        } else {
          stateField = { selectedEndDate: value }
        }
        this.setState((prevState) => ({ ...prevState, ...stateField }))
      }
    }

    const getValidValue = (input) => {
      const { form } = this.props
      const { systemKey, type } = fieldProps.field
      let value
      switch (systemKey) {
        case 'endDate':
          return this.state.selectedEndDate
        case 'startDate':
          return this.state.selectedStartDate
        case 'cancellationDeadline':
          const { cancellationNoticePeriodDays, endDate } = form.getState().values || {}
          return !isNaN(parseInt(cancellationNoticePeriodDays)) && endDate ? moment(endDate).subtract(cancellationNoticePeriodDays, 'days').format(dateFormat) : null
        default:
          value = null
          break
      }
      if (type === formFieldTypes.currency) {
        value = getContractCurrencyValue(input.value).toString()
      }
      return value || input.value
    }

    const handleChange = (value) => {
      input.onChange(value)
      handleDatesInput(value)
      this.calculateContractsOptionsByIdApp({ [input.name]: value })
    }

    const onSave = (value) => {
      const { type } = fieldProps.field
      if (type === formFieldTypes.currency) {
        value = saveContractCurrencyValue(value)
      }
      if (type === formFieldTypes.datePicker) {
        value = value ? moment(value).format(dateFormat) : null
      }
      handleChange(value)
    }

    const onRemove = () => {
      const inputValue = getRemoveValue(fieldProps.field.systemKey)
      handleChange(inputValue)
    }

    return <FieldByType
      {...input}
      {...fieldProps}
      value={getValidValue(input)}
      onSave={onSave}
      onRemove={onRemove}
      onHistory={() => {
        const { idContract, toggleContractDetailHistoryPopup, idApp } = this.props
        toggleContractDetailHistoryPopup({ isOpen: true, idContract, detail: fieldProps.field, idApp })
      }}
      showHistoryButton={!isUndefined(details[input.name]) && !hideHistoryFieldsList.includes(fieldProps.field.systemKey)}
    />
  }

  onAddFieldClicked = (group) => {
    const { toggleSelectFieldTypePopup, addContractDetailsField, groupsForSelectGroup } = this.props
    return toggleSelectFieldTypePopup(true, group.id, null, addContractDetailsField, groupsForSelectGroup, false, FIELDS_CONTAINER_ENTITY.CONTRACTS)
  }

  renderContractDetailsGroup ({ group, fields, currencySymbol }) {
    const { loading, readonly, disabled, usersById } = this.props

    return (
      <div key={group.id} {...CSS.groupContainer}>
        <div {...CSS.groupHeader}>
          {group.label}
          <div {...CSS.addFieldContainer}>
            <VisibleFor scopes={[SCOPES.CONTRACTS_WRITE]}>
              <Button type={ButtonType.compact} size={ButtonSize.small} onClick={() => this.onAddFieldClicked(group)} label='+ Add field' />
            </VisibleFor>
          </div>
        </div>
        <div>
          {fields.map((field, index) => {
            if (loading) {
              return renderFieldLoading(index)
            }

            const props = {
              currencySymbol,
              loading,
              readonly: Boolean(readonly || field.isReadonly),
              disabled: Boolean(disabled || field.isReadonly),
              identifier: field.id,
              label: field.name,
              usersById,
              field
            }

            return <Field
              form={formId}
              key={field.systemKey}
              name={field.systemKey}
              component={this.getComponentByType}
              fieldProps={props}
            />
          })}
        </div>
      </div>
    )
  }

  getNameField = ({ input, meta, disabled, label }) => {
    const { loading } = this.props

    if (loading) {
      return renderFieldLoading('name')
    }

    return <FormGroup label={label} error={meta.touched && meta.error} isRequired>
      <Input label='' disabled={disabled} {...input} />
    </FormGroup>
  }

  getStatusField = ({ input, meta, disabled, label }) => {
    const { loading, idApp } = this.props

    if (loading) {
      return renderFieldLoading('status')
    }

    return <FormGroup label={label} error={meta.touched && meta.error} isRequired>
      <SelectContractStatus
        {...input}
        selectedValue={input.value}
        disabled={disabled}
        idApp={idApp}
      />
    </FormGroup>
  }

  onCurrencySelection = (selectedCurrency) => {
    this.setState({ contractCurrency: selectedCurrency })
  }
  getCurrencyField = ({ label, input }) => {
    const { idOrg, idApp } = this.props
    return <FormGroup
      label={label}
      tooltip={
        <Tooltip
          label='The contract currency will apply to all currency fields in this contract. The values will be converted to your selected display currency based on the contract start date’s exchange rate.'>
          <Icon name='Info' />
        </Tooltip>
      }
      isRequired
    >
      <EnableFor scopes={[SCOPES.CONTRACTS_WRITE, getScopeByIdOrgAndIdApp(SCOPES.CONTRACTS_WRITE, idOrg, idApp)]}>
        <CurrencySelector
          {...input}
          onChange={(selectedCurrency) => {
            if (selectedCurrency) {
              input.onChange(selectedCurrency.value)
              this.onCurrencySelection(selectedCurrency.value)
            }
          }}
          selectedCurrency={this.state.contractCurrency}
        />
      </EnableFor>
    </FormGroup>
  }

  getAppField = ({ input, meta, disabled, label }) => {
    const { loading, toggleAddApplication, idContract, allContracts, idApp, contractApps, isAppMatchingMode } = this.props

    if (loading) {
      return renderFieldLoading('app')
    }
    return <FormGroup label={label} error={meta.touched && meta.error} isRequired>
      <EnableFor scopes={[SCOPES.CONTRACTS_WRITE]}>
        <SelectApps
          placeholder='Select...'
          clearable={false}
          disableHiddenApps
          searchable
          {...input}
          value={contractApps[input.value] || input.value || null}
          onChange={(app, searchValue) => {
            if (app.value === customApp) {
              toggleAddApplication({
                isAddApplicationOpen: true,
                customApp: true,
                app: { name: searchValue },
                onSuccessCB: (idApp) => {
                  input.onChange(idApp)
                  this.initialContractsOptions({ contractDetails: allContracts[idContract], idApp })
                },
                openFrom: 'Add Contract'
              })
            } else {
              input.onChange(app.id)
              this.initialContractsOptions({ contractDetails: allContracts[idContract], idApp: app.id })
            }
          }}
          openOnFocus
          disabled={disabled || idApp}
          autoFocus={isAppMatchingMode}
          specialOption={{
            value: customApp,
            render: (searchValue) => {
              return <div style={{ color: colors.blue }}>Add a custom app "{searchValue}"</div>
            }
          }}
        />
      </EnableFor>
    </FormGroup>
  }

  onSubmit = async (formFields, form) => {
    const { onSuccess, toggleAskToUpdateLicenseCostPopup, getLicensesTypes, idOrg, createContract, updateContractDetails, idContract, isDuplicated, idApp, getAppContracts, getContracts, fields: shownFields, hiddenFields, filledByAI, idAuditLog, hasLicenseAndChargebackWriteScope } = this.props
    const { contractCurrency, selectedIdApp } = this.state
    const contractUpdatedName = formFields.name

    const hasCurrencyFieldChanged = (value, initialValue) => {
      if (value === initialValue) {
        return false
      } else if ((!value && initialValue) || (value && !initialValue)) {
        return true
      } else {
        return value.value !== initialValue.value || value.currency !== initialValue.currency
      }
    }

    const allFields = [...shownFields, ...hiddenFields]
    const fieldsListBySystemKey = keyBy(allFields, 'systemKey')
    const fields = {
      ...omitBy(formFields, (value, key) => key === 'contractCurrency' || key === 'application' || READ_ONLY_CONTRACT_FIELDS.includes(key)),
      startDate: this.state.selectedStartDate,
      endDate: this.state.selectedEndDate
    }

    const currencyFields = pickBy(fields, (value, key) => fieldsListBySystemKey[key]?.type === formFieldTypes.currency)
    const notCurrencyFields = pickBy(fields, (value, key) => fieldsListBySystemKey[key]?.type !== formFieldTypes.currency)

    try {
      if (idContract) {
        const initialValues = form.getState().initialValues
        const updatedFields = omitBy(notCurrencyFields, (value, key) => initialValues[key] === value)
        Object.keys(currencyFields).forEach(systemKey => {
          currencyFields[systemKey] = currencyFields[systemKey] ? { ...saveContractCurrencyValue(currencyFields[systemKey].value), currency: this.state.contractCurrency } : null
        })
        const updatedCurrencyFields = pickBy(currencyFields, (value, key) => hasCurrencyFieldChanged(value, initialValues[key]))
        const hasFieldsUpdate = (!isEmpty(updatedFields) || !isEmpty(updatedCurrencyFields))
        hasFieldsUpdate && await updateContractDetails({ idOrg, idContract, details: { ...updatedFields, ...updatedCurrencyFields }, belongsToIdApp: idApp })
      } else {
        const contractToUpdateStore = {}
        forOwn(currencyFields, (value, key) => {
          const currencyField = value ? { ...saveContractCurrencyValue(value.value), currency: contractCurrency } : null
          currencyFields[key] = currencyField
          contractToUpdateStore[key] = currencyField
        })
        forOwn(notCurrencyFields, (value, key) => { contractToUpdateStore[key] = isArray(value) || !isObject(value) || isDate(value) ? value : value.value })
        const [createdContract, licensesTypes] = await Promise.all([
          createContract({ idOrg, details: { ...currencyFields, ...notCurrencyFields }, contractToUpdateStore, isDuplicatedContract: isDuplicated, flow: filledByAI, idAuditLog }),
          getLicensesTypes({ idOrg, idApp: selectedIdApp })
        ])
        const licenses = get(licensesTypes, ['licenses', selectedIdApp], [])
        const appName = get(createdContract, ['resources', 'apps', selectedIdApp, 'name'], '')
        if (!isEmpty(licenses)) {
          const { startDate } = fields
          hasLicenseAndChargebackWriteScope && setTimeout(() => {
            toggleAskToUpdateLicenseCostPopup({ isOpen: true, idApp: selectedIdApp, appName, licenses, conversionDate: startDate ? moment.utc(startDate).format() : null, currency: contractCurrency })
          }, 1000)
        }
      }
      idApp ? getAppContracts({ idOrg, idApp }) : getContracts({ idOrg })
    } catch (e) {
      return { [FORM_ERROR]: 'Error updating contract, please try again' }
    }

    setTimeout(() => {
      onSuccess({ contractUpdatedName })
    }, 1000)
  }

  renderFilesField = ({ input, disabled, loading, group }) => {
    const { idOrg, idApp } = this.props
    const values = input.value || EMPTY_ARRAY

    return <EditableUploadFileBox
      supportMultiOnUploadCallback
      uploadViaPopup={false}
      uploadDetails={{ ...group, idField: group.id, values }}
      idOrg={idOrg}
      disabled={disabled}
      readOnly={false}
      loading={loading}
      idApp={idApp}
      {...input}
      onRemove={({ idFile }) => {
        Analytics.track('Deleted contract file')
        const updatedValue = input.value.filter(file => file.id !== idFile)
        input.onChange(updatedValue)
      }}
      onUpload={(uploads) => {
        Analytics.track('Uploaded contract file')
        const updatedValue = (input.value || []).concat(uploads)
        input.onChange(updatedValue)
      }}
    />
  }
  renderRelatedContracts ({ fields }) {
    const { nextContractsList, previousContractsList } = this.state
    const { loading, readonly, disabled, currencySymbol, usersById } = this.props

    return fields.map((field, index) => {
      const options = field.systemKey === 'nextContracts' ? nextContractsList : (field.systemKey === 'previousContracts' ? previousContractsList : [])
      if (loading) {
        return renderFieldLoading(index)
      }

      const searchPromptText = 'No matching contracts found'

      const props = {
        currencySymbol,
        loading,
        readonly,
        disabled,
        identifier: field.id,
        label: field.name,
        usersById,
        field: { ...field, options, searchPromptText }
      }

      return <Field
        form={formId}
        key={field.systemKey}
        name={field.systemKey}
        component={this.getComponentByType}
        fieldProps={props}
      />
    })
  }

  render () {
    const { fields, groups, disabled, loading, currencySymbols } = this.props

    const currencySymbol = currencySymbols[this.state.contractCurrency]

    const [fileFields, nonFileFields] = partition(fields, field => field.type === 'fileUpload')
    const [relatedContractsFields, nonRelatedContractsFields] = partition(nonFileFields, field => ['nextContracts', 'previousContracts'].includes(field.systemKey))

    const [specialFields, groupFields] = partition(nonRelatedContractsFields, field => specialFieldsSystemKeys.includes(field.systemKey))

    const specialFieldsBySystemKey = keyBy(specialFields, 'systemKey')
    const fieldsByGroups = groupBy(groupFields, 'idGroup')

    return (
      <Fragment>
        <Field
          label='Application'
          name='idApp'
          component={this.getAppField}
          disabled={disabled}
          validate={mandatoryFieldValidator}
        />
        <Field
          label={get(specialFieldsBySystemKey, ['name', 'name'], '')}
          name='name'
          component={this.getNameField}
          disabled={disabled}
          validate={mandatoryFieldValidator}
        />
        <Field
          label={get(specialFieldsBySystemKey, ['status', 'name'], '')}
          name='status'
          component={this.getStatusField}
          disabled={disabled}
          validate={mandatoryFieldValidator}
        />
        <Field
          label='Contract currency'
          name='contractCurrency'
          disabled={disabled}
          component={this.getCurrencyField}
        />
        {groups
          .filter(group => Boolean(fieldsByGroups[group.id]))
          .map(group => this.renderContractDetailsGroup({ group, fields: fieldsByGroups[group.id], currencySymbol }))
        }
        {relatedContractsFields.length > 0 &&
          <div {...CSS.groupContainer}>
            <div {...CSS.groupHeader}>RELATED CONTRACTS</div>
            {this.renderRelatedContracts({ fields: relatedContractsFields })}
          </div>}
        {fileFields
          .map(group => {
            return <Field
              key={group.systemKey}
              label=''
              name={group.systemKey}
              component={this.renderFilesField}
              disabled={disabled}
              loading={loading}
              group={group}
              defaultValue={EMPTY_ARRAY}
            />
          })
        }
      </Fragment>
    )
  }
}

ContractDetails.contextType = ToriiPopupContext

export default ContractDetails
