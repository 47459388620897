import styled from 'styled-components'

export const Wrapper = styled.div`
  font: ${({ theme }) => theme.typography.font.body02};
  color: ${({ theme }) => theme.palette.text.primary};
`
export const TriggerDetailsWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing('space-075')};
  padding-bottom: ${({ theme }) => theme.spacing('space-075')};
`
