import React from 'react'
import Page from '../../components/page'
import PageHeader from '../../components/pageHeader'
import WorkflowActionExecutions from '../../components/workflowActionExecutions'
import BackLink from '@components/backLink'
import PropTypes from 'prop-types'
import { getWorkflowPathPrefix } from '@lenses/workflows'
import { SCOPES, WORKFLOW_TYPE_TO_ITEM_NAME, WORKFLOW_TYPES } from '@root/constants'
import { Button, ButtonSize, ButtonType, Stack } from '@toriihq/design-system'
import StopWorkflowConfirmation from '@components/stopWorkflowConfirmation'
import ExecutionInfo from '@components/executionInfo'
import { ExecutionInfoWrapper } from './styles'
import Placeholder from '@components/placeholder'
import isEmpty from 'lodash/isEmpty'
import EnableFor from '@components/enableFor'

class WorkflowActionExecutionsPage extends React.Component {
  state = {
    showStopWorkflowConfirmation: false
  }

  componentDidMount () {
    this.fetchData()
  }

  fetchData () {
    const {
      idOrg,
      idWorkflow,
      idWorkflowExecution,
      getWorkflow,
      getWorkflowActionExecutions,
      getWorkflowsActionsConfig,
      getContractsFields,
      getWorkflowExecution,
      getWorkflowsTriggersConfig
    } = this.props

    getWorkflow({ idOrg, idWorkflow })
    getWorkflowActionExecutions({ idOrg, idWorkflow, idWorkflowExecution })
    getWorkflowsActionsConfig(idOrg)
    getContractsFields({ idOrg })
    getWorkflowExecution({ idOrg, idWorkflow, idWorkflowExecution })
    getWorkflowsTriggersConfig()
  }

  render () {
    const {
      idWorkflow,
      workflow,
      actions,
      loading,
      actionsByType,
      appFields,
      showTriggeredBy,
      contractsFields,
      backlinkText,
      backLinkPathPrefix,
      itemName,
      idWorkflowExecution,
      workflowExecution,
      loadingWorkflowExecution,
      loadingTriggerInfo
    } = this.props

    const title = workflow.name && `${workflow.name}${workflow.isDeleted ? ' (deleted)' : ''}`
    const actionsByExecutionOrder = [...actions].reverse()

    return (
      <Page title={title}>
        <div style={{ marginTop: '20px' }}>
          <BackLink navigateTo={`/${backLinkPathPrefix}/${idWorkflow}/executions`} linkText={backlinkText} />
        </div>
        <PageHeader title={title} />
        <ExecutionInfoWrapper>
          <Stack direction='row' justifyContent='space-between'>
            <Placeholder loading={loadingWorkflowExecution || loading || loadingTriggerInfo} rows={1} style={{ maxWidth: 'none', width: '20%' }}>
              {!isEmpty(workflowExecution) && <ExecutionInfo workflowExecution={workflowExecution} />}
              {!workflowExecution.isUnstoppableExecution &&
                <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
                  <Button
                    label='Stop workflow'
                    type={ButtonType.secondary}
                    size={ButtonSize.small}
                    onClick={() => this.setState({ showStopWorkflowConfirmation: true })}
                  />
                </EnableFor>
              }
            </Placeholder>
          </Stack>
          <StopWorkflowConfirmation
            isShowConfirmation={this.state.showStopWorkflowConfirmation}
            onCloseConfirmation={() => this.setState({ showStopWorkflowConfirmation: false })}
            idWorkflowExecution={idWorkflowExecution}
            idWorkflow={idWorkflow}
            sourcePageName='Workflow Execution'
          />
        </ExecutionInfoWrapper>
        <WorkflowActionExecutions header={`${itemName.toUpperCase()} ACTIONS`} actions={actionsByExecutionOrder} loading={loading} actionsByType={actionsByType} appFields={appFields} contractsFields={contractsFields} showTriggeredBy={showTriggeredBy} />
      </Page>
    )
  }
}

WorkflowActionExecutionsPage.propTypes = {
  backLinkPathPrefix: PropTypes.string,
  backlinkText: PropTypes.string,
  itemName: PropTypes.string
}

WorkflowActionExecutionsPage.defaultProps = {
  itemName: WORKFLOW_TYPE_TO_ITEM_NAME[WORKFLOW_TYPES.regular],
  backLinkPathPrefix: getWorkflowPathPrefix(),
  backlinkText: 'Back to workflow runs'
}

export default WorkflowActionExecutionsPage
