import React from 'react'
import TodoUsers from '../../components/offboardingTabs/todoUsers'
import InProgressTabsContainer from '@components/offboardingTabs/inProgressTabsContainer'
import Done from '../../components/offboardingTabs/done'
import TabsPage from '../tabs'
import { ITEMS_PER_PAGE } from '../../constants'
import { sortStringFromArray } from '@shared/utils'
import { Button, ButtonType } from '@toriihq/design-system'
import { HeaderButtonsContainer } from '@pages/offboarding/styles'
import { ACTIONS_LOG_TYPE } from '@shared/workflows/actions/components/ActionsLogButton/types'
import { ActionsLogButton } from '@shared/workflows/actions/components/ActionsLogButton'

class OffboardingPage extends React.Component {
  componentDidMount () {
    if (this.props.idOrg) {
      this.fetchData()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.idOrg && (prevProps.idOrg !== this.props.idOrg)) {
      this.fetchData()
    }
  }

  fetchData = () => {
    const {
      idOrg,
      getOffboardingTodoUsers,
      getOffboardingInProgressUsers,
      getOffboardingDoneUsers,
      getOffboardingTodoUsersCount,
      getOffboardingInProgressUsersCount,
      getOffboardingDoneUsersCount,
      todoDefaultSort,
      inprogressDefaultSort,
      doneDefaultSort
    } = this.props
    getOffboardingTodoUsers({ idOrg, limit: ITEMS_PER_PAGE, reset: true, sort: sortStringFromArray(todoDefaultSort) })
    getOffboardingInProgressUsers({ idOrg, limit: ITEMS_PER_PAGE, reset: true, sort: sortStringFromArray(inprogressDefaultSort) })
    getOffboardingDoneUsers({ idOrg, limit: ITEMS_PER_PAGE, reset: true, sort: sortStringFromArray(doneDefaultSort) })
    getOffboardingTodoUsersCount({ idOrg })
    getOffboardingInProgressUsersCount({ idOrg })
    getOffboardingDoneUsersCount({ idOrg })
  }

  configureAppsOnClick = () => {
    const { history, idOrg } = this.props
    history.push(`/team/${idOrg}/offboarding/settings`)
  }

  render () {
    const {
      todoUsers,
      doneUsers,
      idOrg,
      loading,
      todoCount,
      inProgressCount,
      doneCount
    } = this.props

    const tabsName = ['todo', 'progress', 'done']

    const tabsHeader = [
      { header: 'To-do', counter: todoCount, isLoading: loading && todoCount === 0 },
      { header: 'In progress', counter: inProgressCount, isLoading: loading && inProgressCount === 0 },
      { header: 'Done', counter: doneCount, isLoading: loading && doneCount === 0 }
    ]

    const tabsContent = [
      <TodoUsers loading={loading} loadingMore={todoUsers.loadingMore} users={todoUsers.users} total={todoUsers.total} refreshAllOffboardingData={this.fetchData} title='To-do' />,
      <InProgressTabsContainer loading={loading} refreshAllOffboardingData={this.fetchData} title='In Progress' />,
      <Done loading={loading} loadingMore={doneUsers.loadingMore} users={doneUsers.users} total={doneUsers.total} title='Done' />]

    return (
      <TabsPage
        overrideHeaderStyle={{ padding: 0, gap: '8px' }}
        headerButtonAllowForToriiAdmin
        contentRight={
          <HeaderButtonsContainer>
            <Button
              label='Offboarding settings'
              onClick={this.configureAppsOnClick}
              type={ButtonType.secondary}
            />
            <ActionsLogButton actionsLogType={ACTIONS_LOG_TYPE.OFFBOARDING} />
          </HeaderButtonsContainer>
        }
        pageHeader='Offboarding'
        pageTitle='Offboarding'
        pageName='Offboarding'
        path={`/team/${idOrg}/offboarding`}
        tabsName={tabsName}
        tabsHeader={tabsHeader}
        tabsContent={tabsContent}
        vertical
      />
    )
  }
}

export default OffboardingPage
