import React, { useEffect } from 'react'
import { AdvancedCard } from '@toriihq/design-system'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentOrg } from '@selectors/org'
import Placeholder from '@components/placeholder'
import { getDashboardsData } from '@selectors/dashboards'
import capitalize from 'lodash/capitalize'
import { WidgetProps } from '../types'
import * as Style from './style'
import { getAggsForGroupBy } from '@components/dashboard/widgets/utils'
import { getEntityDataForWidget } from '@actions/dashboards'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@root/constants'
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'
import { axisLineStyle } from './style'
import { BAR_ORIENTATION } from '@reducers/dashboards/types'

const BarChartWidget = (props: WidgetProps) => {
  const { idDashboard, widget, onViewDataButtonClick, isSelected } = props

  const dispatch = useDispatch()

  const { id: idOrg } = useSelector(getCurrentOrg)
  const dashboardsData = useSelector(getDashboardsData)
  const dashboardData = dashboardsData[idDashboard]
  const widgetsDataById = dashboardData?.widgetsData || EMPTY_OBJECT
  const widgetData = widgetsDataById[widget.id]

  const { loading, aggregations = EMPTY_ARRAY } = widgetData?.data ?? EMPTY_OBJECT

  const data = aggregations
    .map((entry) => ({ value: entry.value, name: capitalize(entry.key) }))
    .filter(entry => entry.name !== 'Other')

  const loadingData = loading && data.length === 0

  const isVertical = widget?.displayConfig?.barOrientation === BAR_ORIENTATION.VERTICAL

  useEffect(() => {
    if (idOrg) {
      dispatch(getEntityDataForWidget({
        entityType: widget.entityType,
        idOrg,
        idDashboard,
        idWidget: widget.id,
        filters: widget.dataConfig.filters,
        aggs: getAggsForGroupBy({ widget })
      }))
    }
  }, [dispatch, idOrg, widget, idDashboard])

  return (
    <AdvancedCard
      title={widget?.displayConfig?.label}
      hoverable
      selected={isSelected}
      primaryButton={{
        icon: 'ViewTable',
        onClick: () => onViewDataButtonClick(widget.id)
      }}>
      <Placeholder loading={loadingData} type='rect' style={Style.Placeholder}>
        <div style={{ width: '100%' }}>
          <ResponsiveContainer width='100%' height={250}>
            <BarChart
              data={data}
              layout={widget.displayConfig.barOrientation}
              margin={{ top: 32, right: 32, left: 32, bottom: 32 }}
            >
              <CartesianGrid
                stroke='#ccc'
                horizontal={!isVertical}
                vertical={isVertical}
              />
              <XAxis
                type={isVertical ? 'number' : 'category'}
                {...(isVertical ? {} : { dataKey: 'name' })}
                {...axisLineStyle}
              />
              <YAxis
                type={isVertical ? 'category' : 'number'}
                dataKey={isVertical ? 'name' : undefined}
                {...axisLineStyle}
                width={isVertical ? 150 : undefined}
              />
              <Tooltip />
              <Bar
                dataKey='value'
                fill='#3366FF'
                barSize={20}
                radius={isVertical ? [0, 4, 4, 0] : [4, 4, 0, 0]}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Placeholder>
    </AdvancedCard>
  )
}

export default BarChartWidget
