import { TriggerInputSchemaFieldValue } from '@selectors/workflows/types'
import { IdScheduledReport } from '@store/types'
import { REPORT_KEY } from '@actions/reports/reports.types'

export type InputSchemaFieldWithValue = TriggerInputSchemaFieldValue & { value: any, isValid?: boolean }

export type ActionShareReport = {
  type: string
  fields: InputSchemaFieldWithValue[]
  idApp: number
}

export enum SHARE_OPTION {
  ONCE = 'once',
  SCHEDULED = 'scheduled'
}

export interface ShareReportPopupParams {
  isOpen: boolean,
  pageName: string,
  report: {
    reportName: string,
    config: Record<string, any>,
    reportKey: REPORT_KEY,
    scheduleDetails: Record<string, any>,
    messageDetails: Record<string, any>
    id?: IdScheduledReport
  },
  isEditMode: boolean
}

export enum SHARE_METHOD {
  EMAIL = 'email',
  SLACK = 'slack'
}
