import styled, { keyframes } from 'styled-components'

const loadingKeyframe = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`

export const TableLoadingLine = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  overflow: hidden;
  background: linear-gradient(to right,
  #fff 0%,
  #0054FF 25%,
  #003DCC 50%,
  #0054FF 75%,
  #fff 100%);
  background-size: 200% 100%;
  animation: ${loadingKeyframe} 5s linear infinite;
`
