import React, { Fragment } from 'react'
import debounce from 'lodash/debounce'
import Table from '../table'
import { css } from 'glamor'
import RelativeTeamLink from '../relativeTeamLink'
import texts from '../../shared/style/texts'
import pluralize from 'pluralize'
import { dateTimeColumn, wrapColumn } from './columns'
import { SCOPES, TABLES } from '@root/constants'
import { hoverActions, insideTableRowsClass } from '@shared/style/mixins'
import Analytics from '@helpers/analytics'
import { Link, ProgressBar, AlertBox, AlertBoxType, Button, Tooltip, ButtonType } from '@toriihq/design-system'
import EnableFor from '../enableFor'
import StopWorkflowConfirmation from '../stopWorkflowConfirmation'
import { StopWorkflowButtonContainer } from './styles'
import ExecutionStatus from './executionStatus'
import TriggerInfo from '../TriggerInfo'

const CSS = {
  progress: css(texts.caption, hoverActions, {
    display: 'flex'
  }),
  progressBar: css({
    display: 'flex',
    width: '100%',
    margin: '5px 0',
    transform: 'translateY(100%)',
    transition: 'transform .2s',
    [insideTableRowsClass]: {
      transform: 'translateY(0)'
    }
  }),
  alertBoxWrapper: css({
    marginBottom: 12
  })
}

const POLL_INTERVAL_IN_SECONDS = 7

class WorkflowExecutions extends React.Component {
  state = {
    refreshButtonClicks: 0,
    stopWorkflowConfirmation: {
      showConfirmation: false,
      idWorkflowExecution: null
    }
  }

  constructor (props) {
    super(props)
    this.columns = this.getColumns()
  }

  componentDidMount () {
    this.fetchWorkflowData()
    this.fetchExecutionsData(true)
  }

  fetchWorkflowData () {
    const { idOrg, idWorkflow, getWorkflow, getWorkflowsTriggersConfig } = this.props
    getWorkflow({ idOrg, idWorkflow })
    getWorkflowsTriggersConfig()
  }

  fetchExecutionsData = debounce(async (reset = false) => {
    const { idOrg, idWorkflow, executions, getWorkflowExecutions } = this.props

    const { total } = await getWorkflowExecutions({ idOrg, idWorkflow, offset: reset ? 0 : executions.length, reset }) || {}
    if (total === 0) {
      this.updatePolling(false)
    }
  }, 500, { leading: true, trailing: false })

  updatePolling (hasData) {
    if (!hasData) {
      if (!this.interval) {
        this.interval = setInterval(() => { this.fetchExecutionsData(true) }, POLL_INTERVAL_IN_SECONDS * 1000)
        setTimeout(() => clearInterval(this.interval), 60 * 1000)
      }
    } else {
      clearInterval(this.interval)
      this.interval = null
    }
  }

  componentDidUpdate (prevProps) {
    const { total } = this.props
    const hasData = total > 0
    const hadData = prevProps.total > 0

    if (!hadData && hasData) {
      this.updatePolling(true)
    }
  }

  getHeader = () => this.props.total ? `Triggered ${pluralize('time', this.props.total, true)}`.toUpperCase() : ''

  getColumns = () => {
    return [
      {
        Header: 'Trigger',
        accessor: 'triggerOutput',
        Cell: ({ value: triggerOutput, row: { triggerType, triggeredBy, type } }) => {
          const { idTriggerUsersToParents = {} } = this.props
          return <TriggerInfo
            triggerType={triggerType}
            triggerOutput={triggerOutput}
            idTriggerUsersToParents={idTriggerUsersToParents}
            triggeredBy={triggeredBy}
            executionType={type}
          />
        },
        maxWidth: 400,
        sortable: false,
        ...wrapColumn
      },
      {
        Header: 'Triggered at (UTC)',
        accessor: 'creationTime',
        ...dateTimeColumn
      },
      {
        Header: 'Completed at (UTC)',
        accessor: 'completionTime',
        ...dateTimeColumn
      },
      {
        Header: 'Progress',
        accessor: 'completedActions',
        Cell: ({
          value: completedActions,
          row: {
            maxPossibleRemainingActionsCount = 0,
            completionTime,
            totalActionExecutions
          }
        }) => {
          const isWorkflowCompleted = Boolean(completionTime)
          let total = completedActions
          if (!isWorkflowCompleted) {
            total = totalActionExecutions + maxPossibleRemainingActionsCount
          }

          const progress = completedActions / total
          return (
            <>
              <div {...CSS.progressBar}>
                <ProgressBar value={progress} max={1} size='Large' />
              </div>
              <div {...CSS.progress}>{completedActions}/{pluralize('action', total, true)}</div>
            </>
          )
        },
        width: 230,
        sortable: false
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: 'status',
        Cell: ({
          value: status
        }) =>
          <ExecutionStatus
            status={status}
          />,
        width: 200,
        sortable: false
      },
      {
        accessor: 'id',
        Cell: ({ value: id }) => (
          <span {...CSS.hoverActions}>
            <RelativeTeamLink to={`/${this.props.executionsPathPrefix}/${this.props.idWorkflow}/executions/${id}`}><Link>View details</Link></RelativeTeamLink>
          </span>
        ),
        width: 200,
        sortable: false
      },
      {
        accessor: 'stopWorkflow',
        Cell: ({
          row: {
            id: idWorkflowExecution,
            isUnstoppableExecution
          }
        }) => {
          return (
            <StopWorkflowButtonContainer>
              <Tooltip label='Stop workflow' position='top'>
                <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
                  <Button disabled={isUnstoppableExecution} icon='Stop' type={ButtonType.tertiary} onClick={() => {
                    this.setState({
                      stopWorkflowConfirmation: {
                        showConfirmation: true,
                        idWorkflowExecution
                      }
                    })
                  }} />
                </EnableFor>
              </Tooltip>
            </StopWorkflowButtonContainer>
          )
        },
        width: 50
      },
      {
        accessor: 'triggerType',
        show: false
      },
      {
        accessor: 'triggeredBy',
        show: false
      },
      {
        accessor: 'maxPossibleRemainingActionsCount',
        show: false
      },
      {
        accessor: 'hasError',
        show: false
      },
      {
        accessor: 'type',
        show: false
      },
      {
        accessor: 'latestExecutedIdAction',
        show: false
      },
      {
        accessor: 'totalActionExecutions',
        show: false
      },
      {
        accessor: 'latestExecutedActionHasError',
        show: false
      },
      {
        accessor: 'isUnstoppableExecution',
        show: false
      }
    ]
  }

  onRefreshButtonClick = () => {
    const { refreshButtonClicks } = this.state
    const { entitiesAmount, entityType, triggerType } = this.props

    Analytics.track('Click on refresh-to-view-more-results-link', {
      'Entities': entitiesAmount,
      'Entity type': entityType,
      'Trigger name': triggerType,
      'Refresh clicks': refreshButtonClicks + 1
    })

    this.setState({ refreshButtonClicks: refreshButtonClicks + 1 })
    this.fetchExecutionsData(true)
  }

  closeStopWorkflowConfirmation = () => {
    this.setState({
      stopWorkflowConfirmation: {
        showConfirmation: false,
        idWorkflowExecution: null
      }
    })
  }

  render () {
    const {
      executions,
      loading,
      total,
      triggerTypes,
      loadingMore,
      entities,
      isManualActionRun,
      showRefreshAlertBox,
      idWorkflow
    } = this.props

    return (
      <Fragment>
        {showRefreshAlertBox ? (
          <div {...CSS.alertBoxWrapper}>
            <AlertBox
              type={AlertBoxType.INFORMATIVE}
              description={`You asked to run the ${isManualActionRun ? 'action' : 'workflow'} on many ${entities} at once. It might take several minutes to see the full log.`}
              secondaryButton={{
                label: 'Refresh',
                onClick: this.onRefreshButtonClick
              }}
            />
          </div>
        ) : null}
        <Table
          tableKey={TABLES.workflowsExecutionsTable.key}
          data={executions}
          columns={this.getColumns()}
          header={this.getHeader}
          emptyStateMessage='Not triggered yet'
          loading={loading}
          loadingMore={loadingMore}
          manual
          fetchData={this.fetchExecutionsData}
          triggerTypes={triggerTypes}
          totalCount={total}
        />
        {this.state.stopWorkflowConfirmation.idWorkflowExecution && <StopWorkflowConfirmation
          isShowConfirmation={this.state.stopWorkflowConfirmation.showConfirmation}
          onCloseConfirmation={this.closeStopWorkflowConfirmation}
          idWorkflowExecution={this.state.stopWorkflowConfirmation.idWorkflowExecution}
          idWorkflow={idWorkflow}
          sourcePageName='Workflow Executions'
        />}
      </Fragment>
    )
  }
}

export default WorkflowExecutions
