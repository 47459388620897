import styled from 'styled-components'
import { ELEVATION_WEIGHT } from '@toriihq/design-system'

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

export const LeftContent = styled.div`
  transition: width 0.3s ease;
  height: 100%;
  width: 100%;
`

export const Drawer = styled.div<{ isOpen: boolean; drawerWidth: number }>`
  position: absolute;
  width: ${({ drawerWidth }) => `${drawerWidth}px`};
  right: 0;
  top: 65px;
  bottom: 0;
  opacity: ${({ isOpen }) => isOpen ? 1 : 1};
  transition: transform 0.3s ease, opacity 0.3s ease;
  transform:  ${({ isOpen, drawerWidth }) => `translateX(${isOpen ? 0 : drawerWidth}px)`};
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-left: 1px solid ${({ theme }) => theme.palette.border.primary};
  box-shadow: ${({ theme }) => theme.elevation(ELEVATION_WEIGHT.SHADOW_500)};
`

export const Header = styled.div`
  height: 80px;
  width: 100%;
  padding: ${({ theme }) => theme.spacing('space-300')};
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.primary};
  border-top: 1px solid ${({ theme }) => theme.palette.border.primary};
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`

export const DrawerContent = styled.div`
  overflow: auto;
  height: calc(100% - 80px);
  padding: ${({ theme }) => theme.spacing('space-300')};
`

export const CloseButtonContainer = styled.span`
  [aria-label='Close'] {
    color: ${({ theme }) => theme.palette.icon.primary};
  }
`
