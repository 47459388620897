import React from 'react'
import { css } from 'glamor'
import ReportHeader from '../../components/reportHeader'
import Table from '../table'
import { SCOPES, TABLES } from '../../constants'
import AppDetails from '../appDetails'
import texts from '../../shared/style/texts'
import colors from '../../shared/style/colors'
import SSOAuditUsersPopup from './ssoAuditUsersPopup'
import Analytics from '../../helpers/analytics'
import SSOAuditBar from './ssoAuditBar'
import { getSourceByType, ssoProviders } from '../../sourcesConfig'
import Select from '../select'
import EnableFor from '@components/enableFor'

const MAX_BAR_WIDTH = 350
const MIN_BAR_SIZE = 50

const CSS = {
  wrapper: css({
    height: '100%'
  }),
  unmanaged: css(texts.body, {
    color: colors.error,
    '[disabled]': {
      color: colors.black
    }
  }),
  unmanagedMoreThenZero: css({
    textDecoration: 'underline',
    ':hover': { cursor: 'pointer' },
    '[disabled]': {
      textDecoration: 'none',
      ':hover': { cursor: 'text' }
    }
  }),
  selectProvider: css({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
    maxWidth: '200px'
  }),
  providerTitle: css(texts.heading, {
    paddingBottom: '10px'
  })
}

const PROVIDERS = ssoProviders.map(source => ({ value: source.id, label: source.name }))

class SSOAuditReport extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false,
      idApp: null,
      selectedProvider: 'okta'
    }
  }

  componentDidMount () {
    if (this.props.idOrg) {
      this.fetchData(this.state.selectedProvider)
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.idOrg && (prevProps.idOrg !== this.props.idOrg)) {
      this.fetchData(this.state.selectedProvider)
    }
  }

  ssoProviderChanged = (selected) => {
    const selectedProvider = selected.value
    this.setState({ selectedProvider })
    this.fetchData(selectedProvider)
  }

  fetchData (provider) {
    const { idOrg, getSSOAuditReport } = this.props
    getSSOAuditReport({ idOrg, source: getSourceByType(provider).source })
  }

  getColumns () {
    const { apps } = this.props
    return [
      {
        Header: 'Application Name',
        id: 'name',
        accessor: ({ idApp }) => (apps[idApp] || {}).name || '',
        minWidth: 200,
        maxWidth: 300,
        sortMethod: (appNameA, appNameB) => appNameA.localeCompare(appNameB),
        Cell: ({ row: { idApp } }) => <AppDetails id={idApp} />
      },
      {
        Header: 'Managed Users',
        accessor: 'managedUsers',
        Cell: ({ value }) => value,
        width: 200
      },
      {
        Header: 'Unmanaged Users',
        accessor: 'unmanagedUsers',
        Cell: ({ value, row: { idApp, name: appName } }) => {
          const hasUnmanagedUsers = value > 0
          return (
            <EnableFor scopes={[SCOPES.USERS_READ]} allowForToriiAdmin showAsDisabled={false}>
              <div {...css(CSS.unmanaged, hasUnmanagedUsers && CSS.unmanagedMoreThenZero)} onClick={() => (hasUnmanagedUsers && this.togglePopup({ idApp, appName }))}>{value}</div>
            </EnableFor>
          )
        },
        width: 200
      },
      {
        Header: '',
        sortable: false,
        hideFromCSV: () => true,
        id: 'managedStatus',
        Cell: ({ row: { managedUsers, unmanagedUsers } }) => {
          const { data } = this.props
          const total = managedUsers + unmanagedUsers
          const totals = data.map(({ managedUsers, unmanagedUsers }) => managedUsers + unmanagedUsers)
          const maxTotal = Math.max(...totals) || 1

          return (
            <SSOAuditBar
              managed={managedUsers}
              unmanaged={unmanagedUsers}
              barWidth={Math.max(((total * MAX_BAR_WIDTH) / maxTotal), MIN_BAR_SIZE)}
            />
          )
        },
        width: 400
      },
      {
        accessor: 'idApp',
        show: false
      }
    ]
  }

  closePopup = () => {
    const { apps } = this.props
    const { idApp } = this.state
    const appName = (apps[idApp] || {}).name || ''
    this.togglePopup({ idApp, appName })
  }

  togglePopup = ({ idApp, appName }) => {
    const isOpen = !this.state.isOpen
    this.setState({ isOpen, idApp })
    Analytics.track(`Clicked ${isOpen ? 'show' : 'hide'} application wasted users`, {
      'App name': appName,
      'App id': idApp
    })
  }

  searchFilterMethod = (row, search) => {
    const { apps } = this.props
    const app = apps[row.idApp] || {}
    const values = [
      app.name,
      app.category
    ]
    return values.some(value => value.toLowerCase().includes(search))
  }

  render () {
    const { loading, data, apps } = this.props
    const { isOpen, idApp } = this.state

    return (
      <div {...CSS.wrapper}>
        <ReportHeader title='SSO Audit' />
        <div {...CSS.selectProvider}>
          <span {...CSS.providerTitle}>Provider</span>
          <Select
            options={PROVIDERS}
            value={this.state.selectedProvider}
            clearable={false}
            openOnFocus
            onChange={this.ssoProviderChanged}
          />
        </div>
        <div>
          <Table
            tableKey={TABLES.ssoAuditTable.key}
            data={data}
            hasData={() => data.length && Object.keys(apps).length}
            columns={this.getColumns()}
            header='YOUR APPLICATIONS'
            emptyStateMessage={`${getSourceByType(this.state.selectedProvider).name} provider has no applications`}
            loading={loading}
            searchable
            searchFilterMethod={this.searchFilterMethod}
            clientPaging
            exportable
          />
        </div>
        <SSOAuditUsersPopup isOpen={isOpen} idApp={idApp} cancel={this.closePopup} source={this.state.selectedProvider} loading={loading} />
      </div>
    )
  }
}

export default SSOAuditReport
