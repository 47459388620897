import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import Table from '@components/table'
import {
  TABLES,
  ITEMS_PER_PAGE,
  DATE_FORMAT
} from '@root/constants'
import { IMPORT_APP_USERS_STATUS } from '@reducers/appUsers/types'
import colors from '@shared/style/colors'
import config from '@root/config'
import getColumnByFieldType from '@components/table/columnFieldTypes'
import isEqual from 'lodash/isEqual'
import { mapSortArray, sortStringFromArray } from '@shared/utils'
import { getNameColumn } from '@components/usersTabs/columns'
import emptyImage from '@media/dance.svg'
import Analytics from '@helpers/analytics'
import moment from 'moment'
import { isNil } from 'lodash'
import Currency from '@root/components/currency'
import { HeaderWithInfo } from '../users/styles'
import { Icon, Tooltip, EmptyState, Button, ButtonType } from '@toriihq/design-system'
import { isFieldSupportAutocompleteValues } from '@shared/fieldUtils'
import { REPORT_KEY } from '@actions/reports/reports.types'

const DEFAULT_FIELDS_TO_FETCH = ['firstName', 'lastName', 'email', 'isExternal', 'creationTime', 'idRole', 'idOrg', 'status', 'lifecycleStatus', 'isDeletedInIdentitySources', 'identitySourcesDeletionTime', 'activeAppsCount', 'photoUrl', 'annualCostConverted', 'additionalEmails', 'numberOfOwnedApps', 'isAppOwner']

const getNoDataProps = () => {
  return {
    className: 'rt-noData',
    style: {
      pointerEvents: 'all',
      top: '40%'
    }
  }
}

const getPreDefinedColumns = ({ preDefinedColumnsMapping, columnsConfiguration, isPastUser = false, columnsRestrictions }) => {
  return [
    {
      ...getNameColumn({ showExternalUserBadge: true }),
      id: 'fullName',
      show: Boolean(columnsConfiguration.fullName)
    },
    {
      Header: preDefinedColumnsMapping.additionalEmails,
      id: 'additionalEmails',
      accessor: 'additionalEmails',
      width: 160,
      Cell: ({ row: { additionalEmails } }) => additionalEmails?.join(', '),
      show: Boolean(columnsConfiguration.additionalEmails)
    },
    {
      Header: preDefinedColumnsMapping.numberOfOwnedApps,
      id: 'numberOfOwnedApps',
      accessor: 'numberOfOwnedApps',
      show: Boolean(columnsConfiguration.numberOfOwnedApps),
      ...Table.numericFieldProps
    },
    {
      Header: preDefinedColumnsMapping.isAppOwner,
      id: 'isAppOwner',
      accessor: 'isAppOwner',
      show: Boolean(columnsConfiguration.isAppOwner),
      Cell: ({ value: isAppOwner }) => isAppOwner ? 'Yes' : 'No'
    },
    {
      Header: preDefinedColumnsMapping.activeAppsCount,
      id: 'activeAppsCount',
      accessor: 'activeAppsCount',
      width: 100,
      show: Boolean(columnsConfiguration.activeAppsCount),
      ...Table.numericFieldProps
    },
    {
      accessor: 'email',
      show: false,
      textValue: ({ email }) => email,
      textHeader: 'Email'
    },
    {
      Header: 'Removal date',
      id: 'identitySourcesDeletionTime',
      accessor: ({ identitySourcesDeletionTime }) => moment.utc(identitySourcesDeletionTime).valueOf(),
      textValue: ({ value }) => (value && moment.utc(value).format(DATE_FORMAT)),
      Cell: ({ value }) => moment.utc(value).format(DATE_FORMAT),
      width: 250,
      show: isPastUser
    },
    {
      Header: (
        <HeaderWithInfo>
          {preDefinedColumnsMapping.annualCostConverted}
          <Tooltip
            label={'Annual cost is the sum of user license costs for integrated apps and cost per user for non-integrated apps, entered via the chargeback module.'}
          >
            <Icon name='Info' />
          </Tooltip>
        </HeaderWithInfo>
      ),
      id: 'annualCostConverted',
      accessor: 'annualCostConverted',
      width: 160,
      show: columnsRestrictions.hasLicenseCostAndChargebackAccess && Boolean(columnsConfiguration.annualCostConverted),
      Cell: ({ value }) => isNil(value) ? '-' : <Currency value={value} valueInCents />,
      textValue: ({ value }) => isNil(value) ? '' : value
    },
    {
      Header: 'Is past user',
      accessor: 'isDeletedInIdentitySources',
      Cell: ({ value: isDeletedInIdentitySources }) => isDeletedInIdentitySources ? 'Yes' : 'No',
      show: Boolean(columnsConfiguration.isDeletedInIdentitySources)
    },
    {
      accessor: 'firstName',
      show: false
    },
    {
      accessor: 'lastName',
      show: false
    },
    {
      accessor: 'imageUrl',
      show: false
    },
    {
      accessor: 'id',
      show: false
    },
    {
      accessor: 'isExternal',
      show: false
    },
    {
      accessor: 'photoUrl',
      show: false
    },
    {
      accessor: 'lifecycleStatus',
      show: false
    }
  ]
}
export const getColumns = ({ preDefinedColumnsMapping, columnsConfiguration, dynamicColumnsInfo = [], isPastUser = false, columnsRestrictions }) => {
  const preDefinedColumns = getPreDefinedColumns({ preDefinedColumnsMapping, columnsConfiguration, isPastUser, columnsRestrictions })
  const dynamicColumns = getColumnByFieldType({ columnsInfo: dynamicColumnsInfo, fieldIdentifier: 'key' })

  return preDefinedColumns.concat(dynamicColumns)
}

class Users extends React.Component {
  state = {
    sort: this.props.defaultSort,
    columns: getColumns({ ...this.props.tableInfo, columnsRestrictions: this.props.columnsRestrictions }),
    selectedUsers: [],
    view: this.props.defaultCustomSelectOption
  }

  componentDidMount () {
    this.fetchData(true)
  }

  componentDidUpdate (prevProps, prevState) {
    const tableInfoKey = this.getTableInfoKeyByView()

    const prevTableInfo = prevProps.tableInfos[tableInfoKey]
    const currentTableInfo = this.props.tableInfos[tableInfoKey]

    const columnsConfigurationWasChanged = (
      !isEqual(currentTableInfo.columnsConfiguration, prevTableInfo.columnsConfiguration) ||
      !isEqual(currentTableInfo.dynamicColumnsInfo, prevTableInfo.dynamicColumnsInfo)
    )

    const prevFilteredColumns = this.getFilteredKeys(prevProps)
    const newFilteredColumns = this.getFilteredKeys(this.props)
    const filtersWereChanged = !isEqual(prevFilteredColumns, newFilteredColumns)

    if (columnsConfigurationWasChanged || filtersWereChanged || this.props.idOrg !== prevProps.idOrg) {
      this.setState({ columns: getColumns({ ...this.getTableInfoByView(), isPastUser: this.state.view.value === 'pastEmployees', columnsRestrictions: this.props.columnsRestrictions }) })
      this.getUsers({ reset: true })
    }

    if (!isEqual(prevState.view.value, this.state.view.value)) {
      this.setState({ columns: getColumns({ ...this.getTableInfoByView(), isPastUser: this.state.view.value === 'pastEmployees', columnsRestrictions: this.props.columnsRestrictions }) })
      this.getUsers({ reset: true })
    }

    if (prevProps.defaultCustomSelectOption.label !== this.props.defaultCustomSelectOption.label) {
      this.setState({ view: this.props.defaultCustomSelectOption })
    }
  }

  getFilteredKeys (props) {
    if (!props.tableInfo.filters) {
      return null
    }
    return props.tableInfo.filters.filter(filter => filter.key).map(filter => filter.key)
  }

  getTableInfoKeyByView () {
    return this.state.view.value === 'pastEmployees' ? TABLES.deletedUsersTable.key : TABLES.usersTable.key
  }

  getTableInfoByView () {
    const { tableInfos } = this.props
    const tableInfoKey = this.getTableInfoKeyByView()
    return tableInfos[tableInfoKey]
  }

  getUsers = async ({ limit = ITEMS_PER_PAGE, offset = 0, reset = false, filters = this.props.tableInfo.filters }) => {
    const { idOrg, getCurrentUsers, tableInfo: { columnsConfiguration } } = this.props
    const { sort, view } = this.state

    const selectedFields = columnsConfiguration ? Object.keys(columnsConfiguration) : []
    const dynamicFields = selectedFields.filter(field => !Object.keys(TABLES.usersTable.preDefinedColumnsMapping).includes(field))

    await getCurrentUsers({ idOrg, fields: DEFAULT_FIELDS_TO_FETCH.concat(dynamicFields), limit, offset, sort: sortStringFromArray(sort), q: this.query, reset, filters: view.filters.concat(filters).filter(Boolean) })
  }

  onFilterChange = debounce((filters) => {
    this.getUsers({ filters, reset: true })
  }, 250)

  fetchFieldValues = (field) => {
    const { idOrg, getCurrentUsersFieldValues, tableInfo: { filtersOptions: options } } = this.props

    const isFieldSupportValues = isFieldSupportAutocompleteValues({
      fieldOptions: options,
      fieldOption: field
    })
    if (isFieldSupportValues) {
      getCurrentUsersFieldValues({ idOrg, fields: [field] })
    }
  }

  fetchData = (reset = false) => {
    const { users } = this.props

    this.getUsers({ offset: reset ? 0 : users.length, reset })
  }

  onSearch = debounce((q) => {
    this.query = q
    this.fetchData(true)
  }, 500)

  onSortedChange = debounce((sort) => {
    this.setState({ sort }, () => this.fetchData(true))
  }, 500)

  onConnectClick = () => {
    const { idOrg, history } = this.props
    history.push(`/team/${idOrg}/settings/usersAndEmployees`)

    Analytics.track('Click to go to services', {
      from: 'Empty state employees list'
    })
  }

  renderNoUsers = () => {
    const onClick = () => {
      Analytics.track('Click to define employees', {
        from: 'From users page'
      })
      this.onConnectClick()
    }
    return <EmptyState
      image={<img src={emptyImage} alt='No employees' />}
      title='Discover your employees'
      buttons={[
        <Button type={ButtonType.primary} onClick={onClick} label='Define employees' />
      ]}
    />
  }

  searchFilterMethod (row, search) {
    const values = [
      [row.firstName, row.lastName].join(' '),
      row.email
    ]
    return values.some(value => value && value.toLowerCase().includes(search))
  }

  prepareExportParams = ({ sort, query, filters }) => {
    const { idOrg } = this.props
    const { columns, view } = this.state

    return {
      idOrg,
      sort: mapSortArray(sort),
      query,
      fields: columns.filter(col => ((col.show === undefined || col.show) && !col.hideFromCSV)).map(col => col.id || col.accessor).flatMap(field => field === 'fullName' ? ['firstName', 'lastName', 'email'] : field),
      filters: filters.concat(view.filters)
    }
  }

  onShareReport = ({ sort: rawSort, query: rawQuery, filters: rawFilters }) => {
    const { toggleShareReportPopup, companyName } = this.props
    const { sort, query, fields, filters } = this.prepareExportParams({ sort: rawSort, query: rawQuery, filters: rawFilters })

    toggleShareReportPopup({ isOpen: true, pageName: 'Users - Employees', companyName, report: { config: { sort, query, fields, filters }, reportName: 'Users - Employees', reportKey: REPORT_KEY.USERS } })
  }

  exportToCsv = ({ sort: rawSort, query: rawQuery, filters: rawFilters }) => {
    const { idOrg, sort, query, fields, filters } = this.prepareExportParams({ sort: rawSort, query: rawQuery, filters: rawFilters })

    const sortParam = 'sort=' + sort.join(',')
    const queryParam = `q=${query}`
    const fieldsParam = `fields=${fields.join(',')}`
    const filtersParam = `filters=${encodeURIComponent(JSON.stringify(filters))}`

    const url = `${config.apiBaseUrl}/api/orgs/${idOrg}/users/csv?${sortParam}&${queryParam}&${fieldsParam}&${filtersParam}`
    const newWindow = window.open(url, '_blank')
    newWindow.opener = null
  }

  sortStringFromArray = sortArray => {
    return sortArray.map(s => `${s.id}:${s.desc ? 'desc' : 'asc'}`).join(',')
  }

  onStatusChange = (view) => {
    this.setState({ view })
  }

  fetchFields () {
    const { getUserDetailsFields, idOrg } = this.props
    getUserDetailsFields({ idOrg })
  }

  onSelectChange = selection => {
    this.setState({ selectedUsers: selection })
  }

  render () {
    const {
      fieldsValues,
      users,
      total,
      loading,
      loadingMore,
      tableInfo,
      customSelectOptions
    } = this.props
    const { columns } = this.state
    const configurableColumnsOptions = this.state.view.value === 'pastEmployees' ? this.props.configurablePastColumnsOptions : this.props.configurableColumnsOptions
    return (
      <Fragment>
        <Table
          tableKey={this.getTableInfoKeyByView()}
          emptyStateMessage={this.renderNoUsers()}
          data={users}
          columns={columns}
          loading={loading}
          loadingMore={loadingMore}
          exportable
          searchable
          searchFilterMethod={this.searchFilterMethod}
          getTdProps={setTdStyle}
          filterable
          filtersOptions={(this.getTableInfoByView() || tableInfo).filtersOptions}
          filterOptionsValuesPerKey={fieldsValues}
          configurableColumnsOptions={configurableColumnsOptions}
          configurableColumns
          manual
          fetchData={this.fetchData}
          onSortedChangeCB={this.onSortedChange}
          onSearch={this.onSearch}
          forceShowSearch
          exportFunction={this.exportToCsv}
          onShareReport={this.onShareReport}
          onFilterChange={this.onFilterChange}
          fetchFieldValues={this.fetchFieldValues}
          totalCount={total}
          getNoDataProps={getNoDataProps}
          customSelect
          customSelectOptions={customSelectOptions}
          customSelectSelectedValue={this.state.view}
          customSelectOnChange={this.onStatusChange}
        />
      </Fragment>
    )
  }
}

const setTdStyle = () => {
  return {
    style: {
      border: 0,
      padding: '3px 10px',
      color: colors.black
    }
  }
}

Users.propTypes = {
  title: PropTypes.string,
  hideExternal: PropTypes.bool,
  hideNative: PropTypes.bool,
  appName: PropTypes.string,
  apps: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    lastUsed: PropTypes.string.isRequired,
    isCore: PropTypes.bool.isRequired,
    normalizedScore: PropTypes.number.isRequired,
    users: PropTypes.number.isRequired,
    category: PropTypes.string.isRequired
  })),
  isSmallScreen: PropTypes.bool,
  isSupportsWritePermission: PropTypes.bool,
  deletedUsersMessage: PropTypes.node,
  importAppUsersStatus: PropTypes.oneOf(Object.values(IMPORT_APP_USERS_STATUS))
}

Users.defaultProps = {
  title: 'users',
  hideExternal: false,
  hideNative: false,
  isSmallScreen: false
}

export default Users
