import View from './view'
import { connect } from 'react-redux'
import {
  updateContractDetails,
  createContract,
  toggleContractPopup,
  getContract,
  getContracts,
  getAppContracts,
  toggleContractDetailHistoryPopup,
  toggleSelectFieldTypePopup,
  toggleAskToUpdateLicenseCostPopup,
  getLicensesTypes,
  addContractDetailsField,
  toggleAddApplication
} from '@actions/'
import { getCurrencySymbols } from '@selectors/ui'

import {
  getAllContractsById,
  getContractsGroups as getContractsGroupsSelector,
  getContractsFields as getContractsFieldsSelector,
  getAllContractsResources,
  isLoadingContractsFields,
  isLoadingContractsGroups,
  isLoadingSingleContract,
  getContractsGroupsForSelectGroup
} from '@selectors/contracts'
import isEmpty from 'lodash/isEmpty'
import { getCurrency, getCurrentOrg } from '@selectors/org'
import { partition } from 'lodash'
import AccessControl from '@lenses/accessControl'
import { SCOPES } from '@root/constants'

const mapStateToProps = (state, ownProps) => {
  const { idContract } = ownProps
  const { id: idOrg } = getCurrentOrg(state)
  const allContracts = getAllContractsById(state)
  const groups = getContractsGroupsSelector(state)
  const groupsForSelectGroup = getContractsGroupsForSelectGroup(state)
  const contractsFields = getContractsFieldsSelector(state)
  const [shownFields, hiddenFields] = partition(contractsFields, field => field.isShown)
  const currencySymbols = getCurrencySymbols(state)
  const { users: usersById, contracts: relatedContractsById, apps: contractApps } = getAllContractsResources(state)
  const orgDefaultCurrency = getCurrency(state)
  const hasLicenseAndChargebackWriteScope = AccessControl.isAccessibleWithState({ scopes: [SCOPES.LICENSE_AND_CHARGEBACK_WRITE], state })

  const isLoadingFields = isLoadingContractsFields(state)
  const isLoadingGroups = isLoadingContractsGroups(state)
  const isLoadingContract = isLoadingSingleContract(state)
  const hasData = shownFields.length > 0 && groups.length > 0 && (!idContract || (idContract && !isEmpty(allContracts[idContract])))
  const isLoading = isLoadingContract || isLoadingGroups || isLoadingFields
  const loading = isLoading && !hasData
  const loadingDetails = [{ idField: 1, idGroup: 1 }, { idField: 2, idGroup: 1 }, { idField: 3, idGroup: 1 }, { idField: 4, idGroup: 1 }, { idField: 5, idGroup: 1 }]
  const loadingGroups = [{ id: 1, label: '' }]

  return {
    allContracts: loading ? loadingDetails : allContracts,
    idOrg,
    loading,
    usersById: usersById || {},
    relatedContractsById: relatedContractsById || {},
    fields: shownFields,
    hiddenFields,
    groups: loading ? loadingGroups : groups,
    currencySymbols,
    groupsForSelectGroup,
    orgDefaultCurrency,
    contractApps,
    ...(ownProps.idApp ? { idApp: ownProps.idApp } : []),
    hasLicenseAndChargebackWriteScope
  }
}

const mapDispatchToProps = {
  getContract,
  getContracts,
  getAppContracts,
  updateContractDetails,
  createContract,
  toggleContractPopup,
  toggleContractDetailHistoryPopup,
  toggleSelectFieldTypePopup,
  toggleAskToUpdateLicenseCostPopup,
  getLicensesTypes,
  addContractDetailsField,
  toggleAddApplication,
  getAllContractsResources
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
