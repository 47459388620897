import capitalize from 'lodash/capitalize'
import { METRIC_FUNC } from '@reducers/dashboards/types'
import { formFieldTypes } from '@root/constants'

export const getFormattedData = ({ widget, aggregations, theme }) => {
  const categoricalColors = Object.entries(theme.palette.chart).filter(([key, value]) => key.startsWith('categorical')).map(([key, value]) => value)

  let valueProperty: string
  if (widget?.displayConfig?.groupBy?.metricFunc === METRIC_FUNC.SUM) {
    const isInnerGroupByDataLoaded = aggregations.length > 0 && aggregations[0]?.aggregations !== undefined
    if (isInnerGroupByDataLoaded) {
      valueProperty = 'aggregations'
    } else {
      // Data is still not loaded, show the previous data
      valueProperty = 'value'
    }
  } else {
    // Data is loaded if we have aggregations and their inner aggregations are undefined
    const isGroupByDataLoaded = aggregations.length > 0 && aggregations[0]?.aggregations === undefined
    if (widget?.displayConfig?.groupBy?.metricFunc === METRIC_FUNC.TOTAL && isGroupByDataLoaded) {
      valueProperty = 'value'
    } else {
      // Data is still not loaded, show the previous data
      valueProperty = 'aggregations'
    }
  }

  const getName = (entry) => {
    if (widget?.displayConfig?.groupBy?.field.type === formFieldTypes.bool) {
      if (entry.key === 0) {
        return 'No'
      } else if (entry.key === 1) {
        return 'Yes'
      }
    }

    return capitalize(entry.key)
  }

  return aggregations.map((entry, i) => ({ ...entry, value: entry[valueProperty], name: getName(entry), color: categoricalColors[i] }))
}
